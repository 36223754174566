import { useMutation, useQuery } from '@tanstack/react-query'
import { useURL } from 'hooks/useURL'
import { apiConfig } from './api'
export const templateSettings = {
	//Instagram
	audience_gender: { hasSettings: true, dataSource: 'ds1_data' },
	audience_authenticity: { hasSettings: false },
	audience_country: { hasSettings: true, dataSource: 'ds1_data' },
	content_distribution: { hasSettings: false },
	estimated_interactions: { hasSettings: false },
	domain_usage: { hasSettings: false },
	audience_cities: { hasSettings: true, dataSource: 'ds1_data' },
	audience_interests: { hasSettings: true, grow: false, dataSource: 'ds1_data' },
	audience_earnings: { hasSettings: false },
	er_change_graph: { hasSettings: false },
	er_change_bar_graph: { hasSettings: false },
	like_comment_distribution: { hasSettings: true, includeRaffles: false },
	post_distribution: { hasSettings: false },
	word_cloud: { hasSettings: false },
	mood_board: { hasSettings: true, grow: false, byEngagement: false },
	post_hours: { hasSettings: false },
	view_rate_change_graph: { hasSettings: false },
	ig_brand_affinity: { hasSettings: true, affinity: true, grow: false },
	audience_gender_bar_graph: { hasSettings: true, dataSource: 'ds1_data' },
	follower_change_graph: {
		hasSettings: true,
		datesOverridden: false,
		dates: 120,
	},
	hashtags: {
		hasSettings: true,
		orderedByLastUse: true,
		datesOverridden: false,
		dates: 120,
		grow: false,
	},
	locations: {
		hasSettings: true,
		orderedByLastUse: true,
		datesOverridden: false,
		dates: 120,
		grow: false,
	},
	mentions: {
		hasSettings: true,
		orderedByLastUse: true,
		datesOverridden: false,
		dates: 120,
		grow: false,
	},
	like_chart: {
		hasSettings: true,
		showLikes: true,
		showFollowers: true,
		datesOverridden: false,
		dates: 120,
	},
	//Meta View
	meta_view: {
		monthly: true,
		video_count: false,
		reels: true,
		gender_breakdown: false,
		total_likes_tt: true,
		posts_tt: true,
		average_shares: true,
		average_view: true,
		average_engagement: false,
		average_seen: false,
		average_er: true,
		branded_average_er: true,
		median_view: false,
		branded_median_view: false,
		follower_er: false,
		branded_average_view: false,
		unbranded_average_view: false,
		yt_watch: true,
		yt_avg_engagement: true,
		yt_average_view: true,
		yt_average_view_branded: true,
		yt_dislike: false,
		yt_monthly: true,
		yt_weekly: false,
		ig_country: true,
		ig_branded_average_er: true,
		ig_branded_median_view: false,
		ig_branded_average_view: false,
		ig_branded_reels_play_rate: false,
		ig_branded_reels_view_median: false,

		reels_by_er_plays: false,
	},

	//tiktok
	like_chart_tt: { hasSettings: false },
	follower_change_graph_tt: { hasSettings: false },
	watch_change_graph_tt: { hasSettings: false },
	er_change_graph_tt: { hasSettings: false },
	mentions_tt: { hasSettings: true, grow: false },
	hashtags_tt: { hasSettings: true, grow: false },
	video_distribution_tt: { hasSettings: false },
	video_like_distribution_tt: { hasSettings: false },
	follower_reachability_tt: { hasSettings: true, dataSource: 'ds2_data' },
	like_comment_distribution_tt: { hasSettings: false },
	er_watch_distribution_tt: { hasSettings: false },
	audience_country_tt: {
		hasSettings: true,
		dataSource: 'ds2_data',
		grow: false,
	},
	audience_cities_tt: { hasSettings: false },
	audience_gender_tt: { hasSettings: true, dataSource: 'ds2_data' },
	audience_type_tt: { hasSettings: false },
	post_frequency_tt: { hasSettings: false },
	post_hours_tt: { hasSettings: false },
	word_cloud_tt: { hasSettings: true, grow: false },

	//youtube
	subscriber_count_history_yt: { hasSettings: false },
	video_count_history_yt: { hasSettings: false },
	view_count_history_yt: { hasSettings: false },
	video_distribution_yt: { hasSettings: false },
	like_comment_distribution_yt: { hasSettings: false },
	er_watch_distribution_yt: { hasSettings: false },
	video_like_distribution_yt: { hasSettings: false },
	domains_yt: { hasSettings: false },
	post_frequency_yt: { hasSettings: false },
	post_hours_yt: { hasSettings: false },
	audience_country_yt: {
		hasSettings: true,
		grow: false,
		dataSource: 'ds2_data',
	},
	audience_gender_yt: { hasSettings: true, dataSource: 'ds2_data' },
	audience_languages_yt: {
		hasSettings: true,
		grow: false,
		dataSource: 'ds2_data',
	},
	er_change_graph_yt: { hasSettings: false },
}
const defaultTemplate = {
	templateName: 'Default',
	network: 'instagram',
	_id: 1,
	elements: [
		{
			id: '1616764881',
			key: 'like_chart',
			order: 0,
			category: 'Profil Bilgileri',
			settings: templateSettings.like_chart,
		},
		{
			id: '1616764882',
			key: 'follower_change_graph',
			order: 1,
			category: 'Profil Bilgileri',
			settings: templateSettings.follower_change_graph,
		},
		{
			id: '1616764883',
			key: 'er_change_graph',
			order: 2,
			category: 'Profil Bilgileri',
			settings: templateSettings.follower_change_graph,
		},
		{
			id: '1616764884',
			key: 'mentions',
			order: 0,
			category: 'İçerik Bilgileri',
			settings: templateSettings.mentions,
		},
		{
			id: '1616764887',
			key: 'hashtags',
			order: 1,
			category: 'İçerik Bilgileri',
			settings: templateSettings.hashtags,
		},
		{
			id: '1616764886',
			key: 'locations',
			order: 2,
			category: 'İçerik Bilgileri',
			settings: templateSettings.locations,
		},
		{
			id: '1616764888',
			key: 'like_comment_distribution',
			order: 3,
			category: 'İçerik Bilgileri',
			settings: templateSettings.like_comment_distribution,
		},
		{
			id: '1616764889',
			key: 'ig_brand_affinity',
			order: 0,
			category: 'Kitle Analizi',
			settings: templateSettings.ig_brand_affinity,
		},
		{
			id: '1616764890',
			key: 'audience_gender',
			order: 1,
			category: 'Kitle Analizi',
			settings: templateSettings.audience_gender,
		},
		{
			id: '1616764891',
			key: 'audience_country',
			order: 2,
			category: 'Kitle Analizi',
			settings: templateSettings.audience_country,
		},
		{
			id: '1616764892',
			key: 'audience_cities',
			order: 3,
			category: 'Kitle Analizi',
			settings: templateSettings.audience_cities,
		},
		{
			id: '1616764893',
			key: 'meta_view',
			order: 3,
			category: 'meta-view',
			settings: templateSettings.meta_view,
		},
	],
}
const defaultTiktokTemplate = {
	templateName: 'Default',
	network: 'tiktok',
	_id: 2,
	elements: [
		{
			id: '1622044888163',
			key: 'like_chart_tt',
			order: 0,
			category: 'Profil Bilgileri',
			settings: templateSettings.like_chart_tt,
		},
		{
			id: '1622044889621',
			key: 'follower_change_graph_tt',
			order: 1,
			category: 'Profil Bilgileri',
			settings: templateSettings.follower_change_graph_tt,
		},
		{
			id: '1622044898825',
			key: 'audience_country_tt',
			order: 0,
			category: 'Kitle Analizi',
			settings: templateSettings.audience_country_tt,
		},
		{
			id: '1622044901532',
			key: 'audience_gender_tt',
			order: 1,
			category: 'Kitle Analizi',
			settings: templateSettings.audience_gender_tt,
		},
		{
			id: '1622044910289',
			key: 'video_distribution_tt',
			order: 0,
			category: 'İçerik Bilgileri',
			settings: templateSettings.video_distribution_tt,
		},
		{
			id: '1622044911420',
			key: 'like_comment_distribution_tt',
			order: 1,
			category: 'İçerik Bilgileri',
			settings: templateSettings.video_distribution_tt,
		},
		{
			id: '1622044913508',
			key: 'er_watch_distribution_tt',
			order: 2,
			category: 'İçerik Bilgileri',
			settings: templateSettings.er_watch_distribution_tt,
		},
		{
			id: '1622044913502',
			key: 'meta_view',
			order: 2,
			category: 'meta-view',
			settings: templateSettings.meta_view,
		},
	],
}
const defaultYoutubeTemplate = {
	templateName: 'Default',
	network: 'youtube',
	_id: 3,
	elements: [
		{
			id: '1625479127001',
			key: 'video_distribution_yt',
			order: 0,
			category: 'İçerik Bilgileri',
			settings: templateSettings.video_distribution_yt,
		},
		{
			id: '1625479128291',
			key: 'like_comment_distribution_yt',
			order: 1,
			category: 'İçerik Bilgileri',
			settings: templateSettings.like_comment_distribution_yt,
		},
		{
			id: '1625479130184',
			key: 'er_watch_distribution_yt',
			order: 2,
			category: 'İçerik Bilgileri',
			settings: templateSettings.er_watch_distribution_yt,
		},
		{
			id: '1625479131625',
			key: 'video_like_distribution_yt',
			order: 3,
			category: 'İçerik Bilgileri',
			settings: templateSettings.video_like_distribution_yt,
		},
		{
			id: '1625479133596',
			key: 'post_frequency_yt',
			order: 4,
			category: 'İçerik Bilgileri',
			settings: templateSettings.post_frequency_yt,
		},
		{
			id: '1625479138958',
			key: 'audience_country_yt',
			order: 0,
			category: 'Kitle Analizi',
			settings: templateSettings.audience_country_yt,
		},
		{
			id: '1625479140096',
			key: 'audience_gender_yt',
			order: 1,
			category: 'Kitle Analizi',
			settings: templateSettings.audience_gender_yt,
		},
		{
			id: '1625479141639',
			key: 'audience_languages_yt',
			order: 2,
			category: 'Kitle Analizi',
			settings: templateSettings.audience_languages_yt,
		},
		{
			id: '1625479144888',
			key: 'subscriber_count_history_yt',
			order: 0,
			category: 'Profil Bilgileri',
			settings: templateSettings.subscriber_count_history_yt,
		},
		{
			id: '1625479146095',
			key: 'video_count_history_yt',
			order: 1,
			category: 'Profil Bilgileri',
			settings: templateSettings.video_count_history_yt,
		},
		{
			id: '1625479147366',
			key: 'view_count_history_yt',
			order: 2,
			category: 'Profil Bilgileri',
			settings: templateSettings.view_count_history_yt,
		},
		{
			id: '1622044913432',
			key: 'meta_view',
			order: 2,
			category: 'meta-view',
			settings: templateSettings.meta_view,
		},
	],
}
type BrandIdParam = { brand_id: string }
interface AxiosObject<T> {
	message: string
	code: number
	status: number
	data: T
}

export interface Data {
	_id: string
	network: string
	templateName: string
	elements: Element[]
	owner_id?: string
}

export interface Element {
	id: string
	category: string
	key: string
	order: number
	settings: Setting[]
	_id: string
}

export interface Setting {
	key?: string
	value: string | number | boolean
	_id: string | number
}
const useFetchLayouts = () => {
	return useQuery({
		queryKey: ['INFX', 'LAYOUTS'],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<{
				data: Data[]
				code: number
				message: string
			}>('api/ictemplates')
			const newData = [...data.data].map((d) => {
				const elements = [...d.elements].map((e) => {
					const settings = {}
					e.settings.forEach((s) => (s.key ? (settings[s.key] = s.value) : null))
					return { ...e, settings: settings || {} }
				})
				return { ...d, elements }
			})
			return [defaultTemplate, defaultTiktokTemplate, defaultYoutubeTemplate, ...newData]
		},
	})
}

const useDeleteTemplate = () => {
	return useMutation({
		mutationFn: async (data: { _id: string; templateName: string }) => {
			return null
		},
	})
}
const useUpdateTemplate = () => {
	const { value } = useURL('template_name')
	return useMutation({
		mutationFn: async () => {
			return null
		},
	})
}

const useCreateTemplate = () => {
	return useMutation({
		mutationFn: async (templateName: string) => {
			return null
		},
	})
}

const influencerx = {
	useFetchLayouts,
	useDeleteTemplate,
	useUpdateTemplate,
	useCreateTemplate,
}

export default influencerx
