import { brand } from 'api/brand'
import { campaignDataInput } from './campaignDataInput'
import { campaign } from './campaigns'
import influencer from './influencer'
import influencerx from './influencerx'
import { monetization } from './monetization'
import profile from './profile'
import { useGetEstimationCoefficients } from './misc'

const api = {
	brand: brand,
	campaign: campaign,
	campaignDataInput: campaignDataInput,
	influencerx: influencerx,
	influencer: influencer,
	monetization: monetization,
	profile: profile,
	useGetEstimationCoefficients,
}

export default api
