import Axios from 'axios'
const endpoint = window.endpoint
const reducer = (
	state = { influencers: { query: {}, data: [], page: 0, count: 0 } },
	action: { type: any; payload: any }
) => {
	switch (action.type) {
		case 'SEARCH_INFLUENCERS': {
			if (!action.payload) {
				return state
			}
			return { ...(state || {}), ...action.payload }
		}
		default:
			return state
	}
}

export const $search_influencers =
	(query = {}, page = 0, network: string, then = () => {}) =>
	(dispatch: any) => {
		const asyncifyInstagram = async () => {
			const res = await Axios.post(
				`${endpoint}/api/infx/identification/search/instagram`,
				{ query: { ...query, page } },
				window.getHeaders()
			)
			if (res && res.status === 200 && res.data) {
				dispatch({
					type: 'SEARCH_INFLUENCERS',
					payload: {
						influencers: {
							data: res?.data?.data?.influencers || [],
							count: res?.data?.data?.count || 0,
							query: { ...query },
							page,
						},
					},
				})
				then()
			} else {
				then()
			}
		}

		const asyncifyTiktok = async () => {
			const res = await Axios.post(
				`${endpoint}/api/infx/identification/search/tiktok`,
				{ query: { ...query, page } },
				window.getHeaders()
			)
			if (res && res.status === 200 && res.data) {
				dispatch({
					type: 'SEARCH_INFLUENCERS',
					payload: {
						influencers: {
							data: res.data.data.influencers,
							count: res.data.data.count,
							query: { ...query },
							page,
						},
					},
				})
				then()
			} else {
				then()
			}
		}
		const asyncifyYoutube = async () => {
			const res = await Axios.post(
				`${endpoint}/api/infx/identification/search/youtube`,
				{ query: { ...query, page } },
				window.getHeaders()
			)
			if (res && res.status === 200 && res.data) {
				dispatch({
					type: 'SEARCH_INFLUENCERS',
					payload: {
						influencers: {
							data: res.data.data.influencers,
							count: res.data.data.count,
							query: { ...query },
							page,
						},
					},
				})
				then()
			} else {
				then()
			}
		}

		if (network === 'instagram') {
			asyncifyInstagram()
		}
		if (network === 'tiktok') {
			asyncifyTiktok()
		}
		if (network === 'youtube') {
			asyncifyYoutube()
		}
	}

export default reducer
