import { all, fork } from 'redux-saga/effects'

import {
	create_community,
	create_community_bulletin,
	delete_community,
	delete_community_bulletin,
	delete_community_invitation,
	delete_community_member,
	get_communities,
	get_community,
	get_community_bulletin,
	get_community_invitation_requests,
	get_community_invitations,
	get_community_members,
	invite_persona_to_comunity,
	search_community_personas,
} from './community_api'
import { get_estimation_coefficients, post_send_feedback } from './hub_settings_api'
import {
	fetch_currency_info,
	fetch_influencer_available_networks,
	fetch_influencer_card_er_days_15,
	fetch_influencer_card_graphs,
	fetch_influencer_card_meta,
	fetch_influencer_card_similar,
	fetch_influencer_card_tiktok_meta,
	fetch_influencer_card_youtube_meta,
	fetch_influencer_content_timestamps,
	fetch_influencer_er_data,
	fetch_influencer_highlights,
	fetch_influencer_instagram_hype_auditor_analysis,
	fetch_influencer_instagram_posts,
	fetch_influencer_instagram_social_data_analysis,
	fetch_influencer_messages,
	fetch_influencer_posts,
	fetch_influencer_price_info,
	fetch_influencer_projects,
	fetch_influencer_reports,
	fetch_influencer_rerun_similars,
	fetch_influencer_story_links,
	fetch_influencer_tiktok_posts,
	fetch_influencer_youtube_posts,
	save_influencer_highlights,
} from './influencer_api'
import {
	delete_influencerx_templates,
	fetch_influencerx_templates,
	save_influencerx_templates,
	update_influencerx_templates,
} from './influencerx_api'
import { fetch_persona_data } from './persona_api'
import {
	fetch_brand_updates_api,
	fetch_persona_linked_tag_options_api,
	fetch_user_api,
	patch_opt_in_daily_digest_api,
	patch_user_api,
	patch_user_infx_template_api,
	patch_user_name_api,
	post_brand_updates_api,
	post_persona_linked_tag_options_api,
	post_profile_picture_api,
} from './profile_api'
import {
	delete_brand_project,
	get_brand_project,
	get_brand_projects,
	post_copy_brand_project,
	post_create_brand_project,
	post_publish_brand_project,
	post_unpublish_brand_project,
	post_update_brand_project,
	post_upload_brand_project_image,
} from './projects_api'
export default function* root() {
	yield all([
		// influencer card api
		fork(fetch_influencer_card_tiktok_meta),
		fork(fetch_influencer_card_meta),
		fork(fetch_influencer_card_graphs),
		fork(fetch_influencer_card_similar),
		fork(fetch_influencer_card_er_days_15),
		fork(fetch_influencer_reports),
		fork(fetch_influencer_posts),
		fork(fetch_influencer_highlights),
		fork(save_influencer_highlights),
		fork(fetch_influencer_projects),
		fork(fetch_influencer_price_info),
		fork(fetch_currency_info),
		fork(fetch_influencer_content_timestamps),
		fork(fetch_influencer_story_links),
		fork(fetch_influencer_available_networks),
		fork(fetch_influencer_messages),
		fork(fetch_influencer_instagram_posts),
		fork(fetch_influencer_tiktok_posts),
		fork(fetch_influencer_instagram_hype_auditor_analysis),
		fork(fetch_influencer_instagram_social_data_analysis),
		fork(fetch_influencer_youtube_posts),
		fork(fetch_influencer_card_youtube_meta),
		fork(fetch_influencer_er_data),

		// hub_settings api
		fork(get_estimation_coefficients),
		fork(post_send_feedback),

		//influencerx api
		fork(fetch_influencerx_templates),
		fork(save_influencerx_templates),
		fork(delete_influencerx_templates),
		fork(update_influencerx_templates),
		fork(fetch_persona_data),
		fork(fetch_influencer_rerun_similars),

		fork(fetch_user_api),
		fork(patch_user_name_api),
		fork(patch_user_api),
		fork(patch_user_infx_template_api),
		fork(fetch_brand_updates_api),
		fork(post_profile_picture_api),
		fork(patch_opt_in_daily_digest_api),

		fork(post_brand_updates_api),
		fork(fetch_persona_linked_tag_options_api),
		fork(post_persona_linked_tag_options_api),
		// fork(flow)

		// communities
		fork(get_communities),
		fork(get_community),
		fork(create_community),
		fork(delete_community),
		fork(get_community_invitation_requests),
		fork(get_community_invitations),
		fork(get_community_members),
		fork(invite_persona_to_comunity),
		fork(search_community_personas),
		fork(delete_community_member),
		fork(delete_community_bulletin),
		fork(create_community_bulletin),
		fork(get_community_bulletin),
		fork(delete_community_invitation),

		fork(get_brand_project),
		fork(get_brand_projects),
		fork(post_create_brand_project),
		fork(post_update_brand_project),
		fork(delete_brand_project),
		fork(post_publish_brand_project),
		fork(post_upload_brand_project_image),
		fork(post_unpublish_brand_project),
		fork(post_copy_brand_project),
	])
}
