import { motion } from 'framer-motion'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { ActiveBrand } from 'types/Brand'
export const BrandLister = ({
	data,
	close,
	showCount = 6,
}: {
	isLoading: boolean
	data: ActiveBrand[]
	close: () => void
	showCount?: number
}) => {
	const navigate = useNavigate()
	return (
		<motion.div
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 20 }}
			className='grid grid-cols-1 gap-2 rounded-lg bg-gray-100 p-2 md:grid-cols-2'
		>
			{(data || []).length === 0 ? (
				<div className='col-span-2 flex h-full w-full flex-col items-center justify-center'>
					<h3 className='text-base font-medium'>No Brands Found</h3>
				</div>
			) : null}
			{_(data || [])
				.take(showCount)
				.map((brand) => (
					<button
						onClick={() => {
							close()
							window.open(`/brand/${brand._id}`, '_blank')
						}}
						key={'brand_' + brand._id}
						className='flex flex-row items-center gap-2 rounded-lg bg-white p-2 text-left shadow transition-all hover:bg-gray-50'
					>
						{brand?.pp ? (
							<img src={brand.pp} className='h-10 w-10 rounded-lg border border-gray-300 bg-gray-100' alt='' />
						) : (
							<div className='flex h-10 w-10 items-center justify-center rounded-lg border border-gray-300 bg-gray-100'>
								{brand.name[0].toUpperCase()}
							</div>
						)}
						<div className='flex flex-col'>
							<h3 className='text-base font-medium'>{brand.name}</h3>
							<p className='text-sm text-gray-500'>{`${brand?.count_monitors || 0} Monitors`}</p>
						</div>
					</button>
				))
				.value()}
		</motion.div>
	)
}
