import { ArrowLeftOnRectangleIcon, PencilSquareIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useNetwork } from '@mantine/hooks'
import api from 'api'
import { useAuthProvider } from 'providers/AuthProvider'
import { useNavigate } from 'react-router-dom'
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from 'ui'

export const ProfileIcon = () => {
	const me = api.profile.useGetProfile()
	const { online } = useNetwork()
	return (
		<DropdownMenu>
			<DropdownMenuTrigger>
				<Avatar
					className={`ml-2 flex items-center justify-center border-2 ${online ? 'border-teal-600' : 'border-red-600'}`}
				>
					<AvatarImage src={me.data?.avatar} alt='user_photo' />
					<AvatarFallback className='text-white'>{me.data?.email?.[0]}</AvatarFallback>
				</Avatar>
			</DropdownMenuTrigger>
			<DropdownMenuContent className='w-48' align='end'>
				<ProfileDropdown />
				<DropdownMenuSeparator />
				<div className='flex flex-row justify-end px-1 py-1 text-xs font-light'>
					<span>Version {APP_VERSION}</span>
				</div>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
const ProfileDropdown = () => {
	const { getUser, logout } = useAuthProvider()
	const me = api.profile.useGetProfile()
	const navigate = useNavigate()

	return (
		<>
			<DropdownMenuLabel>
				<div className='flex flex-row space-x-1'>
					<Avatar>
						<AvatarImage src={me.data?.avatar} alt='user_photo' />
						<AvatarFallback>{me.data?.email?.[0]}</AvatarFallback>
					</Avatar>
					<div className='flex flex-col space-y-1'>
						<p className='text-sm font-medium leading-none'>{me.data?.firstName || getUser()?.email[0]}</p>
						<p className='text-xs leading-none text-muted-foreground'>{me.data?.company || ''}</p>
					</div>
				</div>
			</DropdownMenuLabel>

			<DropdownMenuSeparator />
			<DropdownMenuItem onClick={() => navigate('/profile')}>
				<PencilSquareIcon className='mr-2 h-5 w-5' />
				<span>Edit Profile</span>
			</DropdownMenuItem>

			{getUser()?.role !== 'brand' ? (
				<DropdownMenuItem onClick={() => navigate('/faq')}>
					<QuestionMarkCircleIcon className='mr-2 h-5 w-5' />
					<span>F.A.Q.</span>
				</DropdownMenuItem>
			) : null}
			<DropdownMenuSeparator />
			<DropdownMenuItem onClick={() => logout()}>
				<ArrowLeftOnRectangleIcon className='mr-2 h-5 w-5' />
				<span>Logout</span>
			</DropdownMenuItem>
		</>
	)
}
