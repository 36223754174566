import { QueueListIcon, UsersIcon } from '@heroicons/react/24/outline'
import { useDebouncedValue, useHotkeys, useOs } from '@mantine/hooks'
import { IconBriefcase, IconPlus, IconSearch } from '@tabler/icons'
import { useQuery } from '@tanstack/react-query'
import api from 'api'
import axios from 'axios'
import { CreateNewCampaign } from 'components/CreateNewCampaign'
import { AnimatePresence, motion } from 'framer-motion'
import { useBrandPermissionSelector } from 'hooks/useBrandPermissionSelector'
import { deburr } from 'lodash'
import React, { useRef, useState } from 'react'
import { AiOutlineMonitor } from 'react-icons/ai'
import { Button, Dialog, DialogContent, DialogTrigger } from 'ui'
import { create } from 'zustand'
import { BrandLister } from './BrandLister'
import { ItemLister } from './ItemLister'
import { ItemListerInfluencer } from './ItemListerInfluencer'
import { ItemListerMonitor } from './ItemListerMonitor'
import { SearchPill } from './SearchPill'
import { SearchTitle } from './SearchTitle'

type UseSearchType = {
	isOpened: boolean
	open: () => void
	close: () => void
	toggle: () => void
}
export const useSearch = create<UseSearchType>((set, get) => ({
	isOpened: false,
	open: () => set({ isOpened: true }),
	close: () => set({ isOpened: false }),
	toggle: () => set((s) => ({ isOpened: !get().isOpened })),
}))
export type CampaignSearchResult = {
	end_date: number
	id: string
	influencers: string[]
	start_date: number
	title: string
	search_term: string
	personas: {
		_id: string
		name: string
	}[]
	brand_owner: {
		_id: string
		name: string
	} | null
	brand_accounts: {
		_id: string
		name: string
	}[]
}

export type InfluencerSearchResult = {
	instagram_id: string | undefined
	instagram_username: string | undefined
	name: string
	tiktok_id: string | undefined
	tiktok_username: string | undefined
	youtube_id: string | undefined
	youtube_username: string | undefined
	_id: string
}
export type MonitorSearchResult = {
	brand_id: string
	name: string
	_id: string
	brand_name: string
	labels: string[]
}
export type SearchType = 'all' | 'campaigns' | 'influencers' | 'monitors' | 'brands' | 'new_campaign'
const useFetchCampaigns = () => {
	const { role_type } = useBrandPermissionSelector()

	const { isOpened } = useSearch()
	return useQuery({
		queryKey: ['IX/SEARCHBAR/CAMPAIGNS'],
		queryFn: async () => {
			const { data } = await axios.get(`${window.endpoint}/api/campaigns/search_basic`, window.getHeaders())
			return (data || []).map((campaign) => ({
				...campaign,
				search_term: deburr(
					`${campaign.title} ${campaign.influencers.join(' ')} ${campaign?.brand_owner?.name || ''} ${campaign.brand_accounts
						.map((c: CampaignSearchResult['brand_accounts'][number]) => c.name)
						.join(' ')}`
				).toLowerCase(),
			})) as CampaignSearchResult[]
		},
		enabled: !(role_type === 'brand' || role_type === 'member') && isOpened,
		staleTime: 1000 * 60 * 10,
	})
}
const useFetchInfuencers = (search: string) => {
	const { role_type } = useBrandPermissionSelector()

	const { isOpened } = useSearch()
	return useQuery({
		queryKey: ['IX/SEARCHBAR/CREATORS', search],
		queryFn: async () => {
			const { data } = await axios.get(`${window.endpoint}/api/infx/quick_search/${search}`, window.getHeaders())
			return (data?.data || []) as InfluencerSearchResult[]
		},
		enabled: !(role_type === 'brand' || role_type === 'member') && isOpened,
		staleTime: 1000 * 60 * 5,
	})
}
const useFetchMonitors = () => {
	const { isOpened } = useSearch()
	return useQuery({
		queryKey: ['IX/SEARCHBAR/MONITORS'],
		queryFn: async () => {
			const { data } = await axios.get(`${window.endpoint}/brand_api/monitors/search_basic`, window.getHeaders())
			return (data?.data || []) as MonitorSearchResult[]
		},
		enabled: isOpened,

		staleTime: 1000 * 60 * 10,
	})
}

export const SearchBar = () => {
	const { isOpened: opened, toggle, close, open } = useSearch()
	const [searchType, setSearchType] = useState<SearchType>('all')
	const searchRef = useRef<HTMLInputElement | null>(null)
	const [search, setSearch] = useState('')
	const [debouncedSearch] = useDebouncedValue(search, 400)

	const { data, isLoading } = useFetchCampaigns()
	const { data: influencers, isLoading: isInfluencersLoading } = useFetchInfuencers(
		(debouncedSearch || '').toLocaleLowerCase('tr')
	)
	const { data: monitors, isLoading: isMonitorsLoading } = useFetchMonitors()
	const { role_type } = useBrandPermissionSelector()
	const { data: brands } = api.brand.useAll()

	useHotkeys([
		['mod+k', () => toggle()],
		['ctrl+k', () => toggle()],
		['Esc', () => close()],
		['Escape', () => close()],
	])
	return (
		<Dialog
			open={opened}
			onOpenChange={(e) => {
				if (e) {
					open()
				} else {
					close()
					setSearchType('all')
					setSearch('')
				}
			}}
		>
			<DialogTrigger asChild>
				<SearchButton />
			</DialogTrigger>
			<DialogContent autoFocusEnabled={true} className='max-w-xs md:max-w-4xl p-3'>
				<input
					value={search}
					onChange={({ target: { value } }) => setSearch(value)}
					placeholder={'QUICK SEARCH'}
					className='w-full rounded-lg border-2 border-gray-200	 bg-transparent p-2 font-bold'
					onKeyDown={(k) => {
						if (k.metaKey || k.ctrlKey) {
							if (k.key === 'k') {
								k.preventDefault()
								toggle()
							}
						}
						if (k.key === 'Escape') {
							k.preventDefault()
							toggle()
						}
					}}
					ref={searchRef}
				/>
				<div className='flex flex-row flex-wrap gap-2'>
					<SearchPill title={'All'} type={'all'} onClick={setSearchType} selected={searchType} />
					{!['brand', 'member'].includes(role_type) ? (
						<>
							<SearchPill title={'Creators'} type={'influencers'} onClick={setSearchType} selected={searchType} />
							<SearchPill title={'Campaigns'} type={'campaigns'} onClick={setSearchType} selected={searchType} />
						</>
					) : null}
					<SearchPill title={'Brands'} type={'brands'} onClick={setSearchType} selected={searchType} />
					<SearchPill title={'Monitors'} type={'monitors'} onClick={setSearchType} selected={searchType} />
					<div className='flex flex-shrink flex-grow'></div>
					{!['brand', 'member'].includes(role_type) ? (
						<SearchPill title={'Create New Campaign'} type={'new_campaign'} onClick={setSearchType} selected={searchType} />
					) : null}
				</div>
				<AnimatePresence mode='sync'>
					{searchType === 'all' && (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							className='scrollable flex h-[500px] flex-col gap-2 overflow-y-auto'
						>
							<div className='mr-2 flex flex-col gap-2'>
								{!['brand', 'member'].includes(role_type) ? (
									<>
										<SearchTitle Icon={<UsersIcon className='h-5 w-5' />} title={'Creators'} count={(influencers || []).length} />
										<ItemListerInfluencer isLoading={isInfluencersLoading} data={influencers || []} />
									</>
								) : null}
								{!['brand', 'member'].includes(role_type) ? (
									<>
										<SearchTitle
											Icon={<QueueListIcon className='h-5 w-5' />}
											title={'Campaigns'}
											count={
												(data || []).filter((campaign) =>
													deburr(search)
														.toLocaleLowerCase()
														.split(' ')
														.every((s) => campaign.search_term.includes(s))
												).length
											}
										/>
										<ItemLister
											isLoading={isLoading}
											close={() => close()}
											data={(data || []).filter((campaign) =>
												deburr(search)
													.toLocaleLowerCase()
													.split(' ')
													.every((s) => campaign.search_term.includes(s))
											)}
										/>
									</>
								) : null}

								<SearchTitle
									Icon={<IconBriefcase size={20} />}
									title={'Brands'}
									count={
										(brands?.data || []).filter((brand) => brand.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
											.length
									}
								/>
								<div className='max-h-96 overflow-y-auto'>
									<BrandLister
										isLoading={brands?.last_updated !== 0}
										data={(brands?.data || []).filter((brand) =>
											brand.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
										)}
										close={() => close()}
										showCount={6}
									/>
								</div>
								<SearchTitle
									Icon={<AiOutlineMonitor className='h-5 w-5' />}
									title={'Monitors'}
									count={
										(monitors || []).filter((monitor) =>
											deburr(search)
												.toLocaleLowerCase()
												.split(' ')
												.some((s) =>
													deburr(`${monitor.name} ${monitor.labels.join(' ')} ${monitor.brand_name}`)
														.toLocaleLowerCase()
														.includes(s)
												)
										).length
									}
								/>
								<ItemListerMonitor
									close={() => close()}
									isLoading={isMonitorsLoading}
									data={(monitors || []).filter((monitor) =>
										deburr(search)
											.toLocaleLowerCase()
											.split(' ')
											.some((s) =>
												deburr(`${monitor.name} ${monitor.labels.join(' ')} ${monitor.brand_name}`)
													.toLocaleLowerCase()
													.includes(s)
											)
									)}
								/>
							</div>
						</motion.div>
					)}
					{searchType === 'campaigns' && (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							className='flex h-[500px] flex-col gap-2 '
						>
							<SearchTitle
								Icon={<QueueListIcon className='h-5 w-5' />}
								title={'Campaigns'}
								count={
									(data || []).filter((campaign) =>
										deburr(search)
											.toLocaleLowerCase()
											.split(' ')
											.every((s) => campaign.search_term.includes(s))
									).length
								}
							/>
							<div className='overflow-y-auto'>
								<ItemLister
									isLoading={isLoading}
									showCount={100}
									close={() => close()}
									data={(data || []).filter((campaign) =>
										deburr(search)
											.toLocaleLowerCase()
											.split(' ')
											.every((s) => campaign.search_term.includes(s))
									)}
								/>
							</div>
						</motion.div>
					)}
					{searchType === 'new_campaign' && (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							className='flex flex-col gap-2 '
						>
							<SearchTitle Icon={<IconPlus className='h-5 w-5' />} title={'New Campaign'} count={0} />
							<div className='max-h-[calc(70vh-150px)] overflow-y-auto p-1'>
								<CreateNewCampaign />
							</div>
						</motion.div>
					)}
					{searchType === 'influencers' && role_type !== 'brand' && (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							className='flex h-[500px] flex-col gap-2'
						>
							<SearchTitle Icon={<UsersIcon className='h-5 w-5' />} title={'Creators'} count={(influencers || []).length} />
							<div className=' overflow-y-auto'>
								<ItemListerInfluencer showCount={50} isLoading={isInfluencersLoading} data={influencers || []} />
							</div>
						</motion.div>
					)}
					{searchType === 'monitors' && (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							className='flex h-[500px] flex-col gap-2'
						>
							<SearchTitle
								Icon={<AiOutlineMonitor className='h-5 w-5' />}
								title={'Monitors'}
								count={
									(monitors || []).filter((monitor) =>
										deburr(search)
											.toLocaleLowerCase()
											.split(' ')
											.some((s) =>
												deburr(`${monitor.name} ${monitor.labels.join(' ')} ${monitor.brand_name}`)
													.toLocaleLowerCase()
													.includes(s)
											)
									).length
								}
							/>
							<div className='overflow-y-auto'>
								<ItemListerMonitor
									showCount={50}
									close={() => close()}
									isLoading={isMonitorsLoading}
									data={(monitors || []).filter((monitor) =>
										deburr(search)
											.toLocaleLowerCase()
											.split(' ')
											.some((s) =>
												deburr(`${monitor.name} ${monitor.labels.join(' ')} ${monitor.brand_name}`)
													.toLocaleLowerCase()
													.includes(s)
											)
									)}
								/>
							</div>
						</motion.div>
					)}
					{searchType === 'brands' && (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							className='flex h-[500px] flex-col gap-2 '
						>
							<SearchTitle
								Icon={<IconBriefcase size={20} />}
								title={'Brands'}
								count={
									(brands?.data || []).filter((brand) => brand.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
										.length
								}
							/>
							<div className=' overflow-y-auto'>
								<BrandLister
									isLoading={brands?.last_updated !== 0}
									data={(brands?.data || []).filter((brand) =>
										brand.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
									)}
									close={() => close()}
									showCount={50}
								/>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
			</DialogContent>
		</Dialog>
	)
}

export const SearchButton = React.forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(
	(props, ref) => {
		const os = useOs()

		return (
			<Button
				{...props}
				ref={ref}
				className={
					'h-8 md:w-72  items-center rounded-lg bg-white p-1.5 px-2.5 font-bold shadow outline-none focus-within:outline-none hover:ring-2 hover:ring-gray-200 focus:ring-2 focus:ring-gray-300 inline-flex'
				}
			>
				<IconSearch size={20} className='md:mr-2 text-gray-600' />
				<div className='hidden text-start w-full font-bold text-gray-600 md:block'>QUICK SEARCH</div>
				<kbd className='hidden pointer-events-none md:inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100'>
					<span className='text-xs'>{os === 'macos' ? '⌘' : 'Ctrl'}</span>K
				</kbd>
			</Button>
		)
	}
)
SearchButton.displayName = 'SearchButton'
