import { CheckIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Children, useEffect, useRef, useState } from 'react'
import useOutsideClick from '../hooks/useOutsideClick'
import { Popover } from './Popover'
const sizes = {
	// "small": "px-0.5 py-0 text-xs",
	small: 'px-1.5 py-0.5 text-xs',
	default: 'px-2.5 py-1 text-sm',
	large: 'px-4 py-2 text-tiny',
	xl: 'px-6 py-3 text-lg',
}

const Select = ({
	defaultValue = '',
	value = '',
	children,
	size = 'default',
	placeholder = '',
	onChange = (e) => {},
	style = {},
	rounded = 'rounded-lg',
	border = 'border',
}) => {
	return (
		<select
			placeholder={placeholder}
			value={defaultValue || value}
			onChange={onChange}
			style={style}
			className={`w-full ${sizes[size]} bg-white ${rounded} text-gray-600 shadow-sm focus:shadow-md focus:ring-1 focus:ring-gray-400 ${border} border-gray-200 transition-all`}
		>
			{placeholder && (
				<option className='text-gray-300' value={null}>
					{placeholder}
				</option>
			)}
			{children}
		</select>
	)
}
const Option = ({ value, children }) => {
	return <option value={value}>{children}</option>
}
const SelectHTML = ({
	children,
	size = 'default',
	defaultValue = '',
	placeholder,
	value,
	onChange,
	style = {},
	rounded = 'rounded-lg',
	border = 'border',
}) => {
	const [childrenWithProps, setChildrenWithProps] = useState(null)
	const [val, setVal] = useState(value || defaultValue)
	useEffect(() => {
		setChildrenWithProps(
			Children.map(children, (child, e) => {
				const clone = (
					<child.props.children.type
						{...child.props.children.props}
						value={child.props.value}
						onClick={() => {
							onChange(child.props.value)
							setVal(child.props.value)
						}}
					></child.props.children.type>
				)

				return clone
			})
		)
		return () => {
			setChildrenWithProps(null)
		}
	}, [children, onChange])
	return (
		<Popover style={{ zIndex: 1000 }} trigger='click' content={childrenWithProps} placement='top'>
			<div
				className={`flex h-8 w-full items-center justify-center border-gray-200 bg-white px-1 text-gray-600 shadow-sm transition-all focus:shadow-md  focus:ring-1 focus:ring-gray-400`}
			>
				{(childrenWithProps || []).filter((e) => e.props.value === val)}
			</div>
		</Popover>
	)
}
const Search = ({
	children,
	size = 'default',
	defaultValue = '',
	placeholder,
	value,
	onChange,
	style = {},
	rounded = 'rounded-lg',
	border = 'border',
}) => {
	const [childrenWithProps, setChildrenWithProps] = useState(null)
	const [val, setVal] = useState(value || defaultValue)
	useEffect(() => {
		setChildrenWithProps(
			Children.map(children, (child, e) => {
				const clone = (
					<child.props.children.type
						{...child.props.children.props}
						value={child.props.value}
						onClick={() => {
							onChange(child.props.value)
							setVal(child.props.value)
						}}
					></child.props.children.type>
				)

				return clone
			})
		)
		return () => {
			setChildrenWithProps(null)
		}
	}, [children, onChange])
	return (
		<Popover trigger='click' content={childrenWithProps} placement='top'>
			<div
				className={`h-full w-full ${sizes[size]} bg-white ${rounded} text-gray-600 shadow-sm focus:shadow-md focus:ring-1 focus:ring-gray-400 ${border} border-gray-200 transition-all`}
			>
				{(childrenWithProps || []).filter((e) => e.props.value === val)}
			</div>
		</Popover>
	)
}
const SelectInput = ({
	children,
	size = 'default',
	placeholder,
	value,
	onChange,
	style = {},
	rounded = 'rounded-lg',
	border = 'border',
}) => {
	return (
		<select
			placeholder={placeholder}
			defaultValue={''}
			onChange={onChange}
			style={style}
			className={`w-full ${sizes[size]} bg-white ${rounded} text-gray-600 shadow-sm focus:shadow-md focus:ring-1 focus:ring-gray-400 ${border} border-gray-200 transition-all`}
		>
			{placeholder && (
				<option selected className='text-gray-300'>
					{placeholder}
				</option>
			)}
			{children}
		</select>
	)
}
const Multiple = ({
	children,
	size = 'default',
	placeholder,
	value,
	onChange,
	style = {},
	rounded = 'rounded-lg',
	border = 'border',
	selectableOptions = [],
	selected,
}) => {
	return (
		<Popover
			content={
				<div className='w-full'>
					{selectableOptions.map((e) => (
						<div
							className={`mt-1 flex w-72 cursor-pointer justify-between rounded-lg px-3 py-2  ${
								selected.includes(e) ? 'bg-sky-200' : 'bg-white hover:bg-gray-200'
							} transition-colors`}
							onClick={() => {
								if (selected.includes(e)) onChange([...selected.filter((d) => d !== e)])
								else onChange([...selected, e])
							}}
						>
							{e}
							{selected.includes(e) && <CheckIcon className='w-5 text-sky-800' />}
						</div>
					))}
				</div>
			}
			placement='top'
			trigger={'click'}
		>
			<div
				className={`w-full ${sizes[size]} bg-white ${rounded} cursor-pointer text-gray-600 shadow-sm focus:shadow-md focus:ring-1 focus:ring-gray-400 ${border} border-gray-200 transition-all`}
			>
				{!selected.length ? (
					placeholder
				) : (
					<div className='inline-flex h-auto w-full flex-wrap'>
						{selected.map((e) => (
							<div className='m-0.5 rounded-lg  border bg-sky-100 px-1 py-px'>{e}</div>
						))}
					</div>
				)}
			</div>
		</Popover>
	)
}
const Tag = ({
	children,
	onSearch = () => {},
	size = 'default',
	maxHeight = 'max-h-24',
	placeholder,
	placement = 'bottom',
	name = '',
	value,
	onChange,
	style = {},
	rounded = 'rounded-lg',
	border = 'border',
	selectableOptions = [],
	selected,
}) => {
	const [val, setVal] = useState('')
	const [isOpen, setisOpen] = useState(false)
	useEffect(() => {
		onSearch(val)
		return () => {
			onSearch('')
		}
	}, [val])
	return (
		<PopoverCustom
			content={
				<div className={`w-full ${maxHeight} scrollable overflow-y-auto`}>
					{selectableOptions.concat(selected || []).length === 0 && (
						<div className='w-52 p-2 text-center text-xs'>
							Seçilebilecek bir şey mevcut değil. Aşağıdan yeni bir seçenek ekleyin.
						</div>
					)}
					{selectableOptions.concat(selected.filter((e) => !selectableOptions.includes(e))).map((e) => (
						<div
							className={`flex w-52 cursor-pointer justify-between py-2 ${maxHeight} mb-1 rounded-lg px-3  ${
								selected.includes(e) ? 'bg-sky-200' : 'bg-white hover:bg-gray-200'
							} transition-colors`}
							onClick={() => {
								if (selected.includes(e)) onChange([...selected.filter((d) => d !== e)])
								else onChange([...selected, e])
							}}
						>
							{e}
							{selected.includes(e) && <CheckIcon className='w-5 text-sky-800' />}
						</div>
					))}
				</div>
			}
			isOpen={isOpen}
			setisOpen={setisOpen}
			placement={placement}
			trigger={'click'}
		>
			<div
				onClick={() => setisOpen(true)}
				className={`w-full ${sizes[size]} bg-white ${rounded} cursor-pointer text-gray-600 shadow-sm focus:shadow-md focus:ring-1 focus:ring-gray-400 ${border} inline-flex items-center justify-center border-gray-200 transition-all`}
			>
				<div key={name} className='w-full flex-grow flex-wrap'>
					{selected.map((e) => (
						<div
							className='m-0.5 inline-flex  items-center rounded-lg border bg-sky-100 px-1 py-px'
							onClick={(f) => {
								f.preventDefault()
								onChange([...selected.filter((d) => d !== e)])
							}}
						>
							{e} <XMarkIcon className='ml-1 w-2' />
						</div>
					))}
					<input
						style={{ minWidth: 30, width: 'auto', maxWidth: '100%' }}
						className='flex-grow'
						placeholder={placeholder}
						value={val}
						onChange={({ target: { value } }) => setVal(value)}
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								onChange([...selected, val])
								setVal('')
							}
						}}
					></input>
				</div>
				<div>
					{placement !== 'top' && (
						<ChevronDownIcon
							onClick={() => setisOpen((e) => !e)}
							className={`h-4 w-4 transform text-sky-700 transition-transform ${isOpen ? 'rotate-180' : ''}`}
						/>
					)}
					{placement === 'top' && (
						<ChevronUpIcon
							onClick={() => setisOpen((e) => !e)}
							className={`h-4 w-4 transform text-sky-700 transition-transform ${isOpen ? 'rotate-180' : ''}`}
						/>
					)}
				</div>
			</div>
		</PopoverCustom>
	)
}
Select.Option = Option
Select.SelectHTML = SelectHTML
Select.SelectInput = SelectInput
Select.Search = Search
Select.Multiple = Multiple
Select.Tag = Tag
export { Select }

const PopoverCustom = ({
	children,
	content,
	placement = 'bottom',
	trigger = 'mouseenter',
	onClickOutside = true,
	isOpen,
	setisOpen,
}) => {
	const node = useRef(null)
	useOutsideClick(node, () => {
		setisOpen(false)
	})
	return (
		<div className='relative w-full'>
			{children}
			<div
				ref={node}
				className={`absolute z-100 bg-white px-2 py-3 ${
					placements[placement]
				}  rounded-lg border border-gray-200 shadow-md transition-opacity ${
					isOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
				}`}
			>
				{content}
			</div>
		</div>
	)
}
const placements = {
	top: 'top-0 left-1/2 -translate-x-1/2 transform -translate-y-full',
	'top-start': 'top-0 transform -translate-y-full',
	'top-end': 'top-0 left-0 transform -translate-y-full',

	right: '',
	'right-start': '',
	'right-end': '',

	bottom: 'bottom-0 transform translate-y-full',
	'bottom-start': 'bottom-0 left-0 transform translate-y-full',
	'bottom-end': 'bottom-0 right-0 transform translate-y-full',

	left: '',
	'left-start': '',
	'left-end': '',
}
