import { useQuery } from '@tanstack/react-query'
import { apiConfig } from './api'

export const useGetEstimationCoefficients = () => {
  return useQuery({
    queryKey: ['ESTIMATION_COEFFICIENTS'],
    queryFn: async () => {
      const { data } = await apiConfig.fetch<{
        data: Record<number, Record<number, { static: number; video: number; story: number; reels: number }>>
      }>('api/hub_settings/estimation_coefficients')
      return data.data
    },
  })
}
