import { put } from "redux-saga/effects";
import { LoadableTypes, LoadableValues } from "redux/loading.reducer";

export function* update_loadable(key: LoadableTypes, value: LoadableValues) {
	try {
		yield put({ type: "UPDATE_LOADABLE", payload: { key, value } });
	} catch (e) {
		console.error("error", e);
		yield put({ type: "UPDATE_LOADABLE_FAIL" });
		yield put({ type: "UPDATE_LOADABLE", payload: { key, value: "error" } });
	}
}
