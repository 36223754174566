const reducer = (
	state = {
		loading: false,
		previewVisible: false,
		previewImage: '',
		show_missing: true,
		story_stats: [],
		post_stats: [],
		imageHash: Date.now(),
		campaign_review: '',
		review_visible: false,
		content_review: [],
		delete_request: '',
		upload_hover: {},
		show_id_list: false,
		show_link_list: false,
		show_story_mention_list: false,
		ignored_content: {
			ig_posts: [],
			ig_stories: [],
			yt_posts: [],
			tt_posts: [],
		},
		fetched_post_stats: false,
		fetched_story_stats: false,
		loading_text: '',
		selected_contents: [],
		editing: null,
		di_grid: { page: 0, per_page: 20 },
		missing_contents: {
			loading: true,
			contents: { docs: [], count: 0, page: 0 },
			content_type: 'ig_post',
			selected_persona: null,
			selected_contents: [],
			page: 0,
			per_page: 24,
			date_from: 0,
			modal_page: 0,
			persona_search: '',
			content_search: '',
			date_to: Date.now(),
		},
	},
	{ type, payload }
) => {
	switch (type) {
		case 'SHOW_MISSING':
			return { ...state, show_missing: !state.show_missing }
		case 'POST_STATS':
			return { ...state, post_stats: payload }
		case 'PREVIEW':
			return {
				...state,
				preview_visible: payload.preview_visible,
				preview_image: payload.preview_image,
			}
		case 'STORY_STATS':
			return { ...state, story_stats: payload }
		case 'POSTS_WITH_IDS_DI':
			return { ...state, post_stats: [...payload], fetched_post_stats: true }
		case 'STORIES_WITH_IDS_DI':
			return { ...state, story_stats: [...payload], fetched_story_stats: true }
		case 'REVIEW_VISIBLE':
			return { ...state, review_visible: !state.review_visible }
		case 'SHOW_LINK_LIST':
			return { ...state, show_link_list: !state.show_link_list }
		case 'SHOW_STORY_MENTION_LIST':
			return {
				...state,
				show_story_mention_list: !state.show_story_mention_list,
			}
		case 'UPDATE_CAMPAIGN_REVIEW_INTERMIDIATE':
			return { ...state, campaign_review: payload }
		case 'CONTENT_REVIEW':
			return { ...state, content_review: payload }
		case 'REMOVE_STORY_STATISTICS':
			return state
		// return { ...state, campaign_review: payload }
		// case "UPDATE_STORY_DATA":
		//   if (!payload) return state
		//   const newStories = [...state.story_stats]
		//   newStories.splice(state.story_stats.findIndex(e => e.id === payload.data.id), 1, { ...payload.data })
		//   return { ...state, story_stats: newStories }
		// case "UPDATE_POST_DATA":
		//   if (!payload) return state
		//   const newPosts = [...state.post_stats]
		//   newPosts.splice(state.post_stats.findIndex(e => e.id === payload.data.id), 1, { ...payload.data })
		//   return { ...state, post_stats: newPosts }
		case 'IGNORED_CONTENT_CDI':
			return { ...state, ignored_content: { ...payload } }
		case 'SELECT_CONTENT_TYPE': {
			if (state.missing_contents.content_type === payload) {
				return state
			}
			return {
				...state,
				missing_contents: {
					...state.missing_contents,
					content_type: payload,
					selected_persona: null,
					persona_search: '',
					content_search: '',
					contents: { docs: [], count: 0, page: 0 },
					page: 0,
				},
			}
		}
		case 'SELECT_PERSONA': {
			if (state.missing_contents.selected_persona === payload) {
				return state
			}
			return {
				...state,
				missing_contents: {
					...state.missing_contents,
					selected_persona: payload,
					contents: { docs: [], count: 0, page: 0 },
					page: 0,
				},
			}
		}
		case 'CLOSE_MISSING_CONTENT': {
			return {
				...state,
				missing_contents: {
					...state.missing_contents,
					loading: true,
					contents: { docs: [], count: 0, page: 0 },
					content_type: 'ig_post',
					selected_persona: null,
					selected_contents: [],
					page: 0,
					per_page: 24,
					date_from: 0,
					modal_page: 0,
					persona_search: '',
					content_search: '',
					date_to: Date.now(),
				},
			}
		}
		case 'PERSONA_MISSING_CONTENTS': {
			return {
				...state,
				missing_contents: {
					...state.missing_contents,
					contents: {
						docs: [...payload.docs],
						count: payload.count,
						page: payload.page,
					},
				},
			}
		}
		case 'CHANGE_PAGE': {
			return {
				...state,
				missing_contents: { ...state.missing_contents, page: payload },
			}
		}
		case 'SELECT_MISSING_CONTENT': {
			const selected_contents = [...state.missing_contents.selected_contents]
			if (selected_contents.some((s) => s._id === payload._id)) {
				return {
					...state,
					missing_contents: {
						...state.missing_contents,
						selected_contents: selected_contents.filter((s) => s._id !== payload._id),
					},
				}
			} else {
				return {
					...state,
					missing_contents: {
						...state.missing_contents,
						selected_contents: [...selected_contents, payload],
					},
				}
			}
		}
		case 'CHANGE_MISSING_CONTENT_TIME_FILTER': {
			return {
				...state,
				missing_contents: {
					...state.missing_contents,
					date_from: payload.date_from,
					date_to: payload.date_to,
				},
			}
		}
		case 'MISSING_CONTENT_PER_PAGE': {
			return {
				...state,
				missing_contents: {
					...state.missing_contents,
					per_page: payload,
					page: 0,
				},
			}
		}
		case 'MISSING_CONTENT_MODAL_PAGE': {
			return {
				...state,
				missing_contents: { ...state.missing_contents, modal_page: payload },
			}
		}
		case 'MISSING_CONTENT_PERSONA_SEARCH': {
			return {
				...state,
				missing_contents: {
					...state.missing_contents,
					persona_search: payload,
				},
			}
		}
		case 'MISSING_CONTENT_CONTENT_SEARCH': {
			return {
				...state,
				missing_contents: {
					...state.missing_contents,
					content_search: payload,
				},
			}
		}
		case 'SELECT_CONTENT': {
			const selected_contents = [...state.selected_contents]
			if (selected_contents.includes(payload)) {
				return {
					...state,
					selected_contents: selected_contents.filter((s) => s !== payload),
				}
			} else {
				return {
					...state,
					selected_contents: [...selected_contents, payload],
				}
			}
		}
		case 'DESELECT_ALL_CONTENTS_DI': {
			return { ...state, selected_contents: [] }
		}
		case 'OPEN_EDIT_MODAL': {
			return { ...state, editing: payload }
		}
		case 'CLOSE_EDIT_MODAL': {
			return { ...state, editing: null }
		}
		case 'CHANGE_DI_PAGE': {
			if (payload < 0) {
				return state
			}
			// if (((payload) * state.di_grid.per_page) > state.di_grid.contents.count) return state
			return { ...state, di_grid: { ...state.di_grid, page: payload } }
		}
		case 'CHANGE_DI_PER_PAGE': {
			return { ...state, di_grid: { ...state.di_grid, per_page: payload } }
		}
		default:
			return state
	}
}

export default reducer

export const show_missing = () => ({ type: 'SHOW_MISSING' })
export const review_visible = () => ({ type: 'REVIEW_VISIBLE' })
export const show_link_list = () => ({ type: 'SHOW_LINK_LIST' })
export const preview = (preview_visible, preview_image) => ({
	type: 'PREVIEW',
	payload: { preview_visible, preview_image },
})
export const show_story_mention_list = () => ({
	type: 'SHOW_STORY_MENTION_LIST',
})
export const update_campaign_review = (review) => ({
	type: 'UPDATE_CAMPAIGN_REVIEW_INTERMIDIATE',
	payload: review,
})

export const deselect_all_selected_content = () => ({
	type: 'DESELECT_ALL_CONTENTS_DI',
})

export const $missing_content_type =
	(content_type, then = () => { }) =>
		(dispatch) => {
			dispatch({ type: 'SELECT_CONTENT_TYPE', payload: content_type })
		}

export const $missing_content_persona =
	(persona_id, then = () => { }) =>
		(dispatch) => {
			dispatch({ type: 'SELECT_PERSONA', payload: persona_id })
		}
export const $missing_content_page =
	(page, then = () => { }) =>
		(dispatch) => {
			dispatch({ type: 'CHANGE_PAGE', payload: page })
		}
export const $change_di_page =
	(page, then = () => { }) =>
		(dispatch) => {
			dispatch({ type: 'CHANGE_DI_PAGE', payload: page })
		}
export const $change_di_per_page =
	(page, then = () => { }) =>
		(dispatch) => {
			dispatch({ type: 'CHANGE_DI_PER_PAGE', payload: page })
		}

export const $missing_content_close =
	(then = () => { }) =>
		(dispatch) => {
			dispatch({
				type: 'UPDATE_LOADABLE',
				payload: { key: 'add_missing_content', value: 'closed' },
			})
			dispatch({
				type: 'CLOSE_MISSING_CONTENT',
				payload: { key: 'add_missing_content', value: 'closed' },
			})
		}

export const $missing_content_select =
	(content, then = () => { }) =>
		(dispatch) => {
			dispatch({ type: 'SELECT_MISSING_CONTENT', payload: content })
		}
export const $missing_content_time_change =
	(date_from, date_to, then = () => { }) =>
		(dispatch) => {
			dispatch({
				type: 'CHANGE_MISSING_CONTENT_TIME_FILTER',
				payload: { date_to, date_from },
			})
		}
export const $missing_content_persona_search =
	(persona_search, then = () => { }) =>
		(dispatch) => {
			dispatch({ type: 'MISSING_CONTENT_PERSONA_SEARCH', payload: persona_search })
		}

export const $post_missing_content_send =
	(campaign_id, contents, then = () => { }) =>
		(dispatch) => {
			dispatch({
				type: 'POST_ADD_MULTIPLE_CONTENTS',
				payload: { contents, campaign_id },
			})
		}

export const $missing_content_per_page =
	(per_page, then = () => { }) =>
		(dispatch) => {
			dispatch({ type: 'MISSING_CONTENT_PER_PAGE', payload: per_page })
		}
export const $missing_content_modal_page =
	(modal_page, then = () => { }) =>
		(dispatch) => {
			dispatch({ type: 'MISSING_CONTENT_MODAL_PAGE', payload: modal_page })
		}
export const $select_content =
	(content_id, then = () => { }) =>
		(dispatch) => {
			dispatch({ type: 'SELECT_CONTENT', payload: content_id })
		}
export const $open_edit_modal =
	(content_id, then = () => { }) =>
		(dispatch) => {
			dispatch({
				type: 'UPDATE_LOADABLE',
				payload: { key: 'data_input_edit_modal', value: 'waiting' },
			})
			dispatch({ type: 'OPEN_EDIT_MODAL', payload: content_id })
		}
export const $close_edit_modal =
	(then = () => { }) =>
		(dispatch) => {
			dispatch({
				type: 'UPDATE_LOADABLE',
				payload: { key: 'data_input_edit_modal', value: 'closed' },
			})
			dispatch({ type: 'CLOSE_EDIT_MODAL' })
		}
