import { getAllBrands } from 'api/brand'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { queryClient } from 'qc'
import { lazy, Suspense } from 'react'
import { Navigate, redirect, RouteObject } from 'react-router-dom'
import BrandModules, { CheckPermission } from './containers/BrandModules'
const AllMonitorsLazy = lazy(() => import('./containers/BrandMonitors/MonitorsList'))
const BrandCampaignsLazy = lazy(() => import('./containers/BrandCampaigns'))
const BrandIncluencersLazy = lazy(() => import('./containers/BrandInfluencers'))
const BrandSettingsLazy = lazy(() => import('./containers/BrandSettings'))
const BrandHomeLazy = lazy(() => import('./containers/BrandHome'))
const BrandMonitorsLazy = lazy(() => import('./containers/BrandMonitors'))
const TrackerLazy = lazy(() => import('./containers/BrandMonitors/Tracker'))
const EditMonitorLazy = lazy(() => import('./monitors/[monitorId]/edit'))
const CreateMonitorLazy = lazy(() => import('./monitors/create'))
const BrandCreditsLazy = lazy(() => import('./containers/BrandCredits'))
const BrandDashboardLazy = lazy(() => import('./'))
const BrandCreditsAdmin = lazy(() => import('./containers/BrandCreditsAdmin'))
const CreditsSelector = lazy(() => import('./credits/_components/CreditsSelector'))
const MonitorTTLazy = lazy(() => import('./containers/BrandMonitors/MonitorTT'))
const MonitorIGLazy = lazy(() => import('./monitors/[monitorId]/instagram'))
const MonitorYTLazy = lazy(() => import('./containers/BrandMonitors/MonitorYT'))
const TestPageLazy = lazy(() => import('containers/sqlite-utils'))
const SocketProvider = lazy(() => import('providers/SocketProvider'))
const MonitorReportLazy = lazy(() => import('./monitors/[monitorId]/report/page'))
const IndexPage = () => {
	return (
		<div className='flex h-sidebar w-full flex-col items-center justify-center rounded-lg bg-white shadow-md'>
			<LoadingSpinner className='h-7 w-7' />
			<p className='mt-2 font-semibold'>Loading</p>
		</div>
	)
}

export const brand_dashboard_routes: RouteObject = {
	path: 'brand',
	element: (
		<Suspense fallback={<div></div>}>
			<BrandDashboardLazy />
		</Suspense>
	),
	children: [
		{
			index: true,
			id: 'brand-index',
			loader: async () => {
				const data = await getAllBrands.queryFn()
				if (queryClient.getQueryData(getAllBrands.queryKey) === undefined) {
					queryClient.setQueryData(getAllBrands.queryKey, data)
				}
				if (data.count === 0) {
					return redirect('/home')
				}
				return redirect(`/brand/${data.data[0]._id}`)
			},
			errorElement: <div></div>,
			element: <IndexPage />,
		},
		{
			path: ':brand_id',
			element: <CheckPermission />,
			children: [
				{
					index: true,
					element: (
						<Suspense fallback={<div></div>}>
							<BrandHomeLazy />
						</Suspense>
					),
				},
				{
					path: 'modules',
					element: <BrandModules />,
				},
				{
					path: 'communities',
					element: <div></div>,
				},
				{
					path: 'projects',
					element: <div></div>,
				},
				{
					path: `campaigns`,
					element: (
						<Suspense fallback={<div></div>}>
							<BrandCampaignsLazy />
						</Suspense>
					),
				},
				{
					path: `creators`,
					element: (
						<Suspense fallback={<div></div>}>
							<BrandIncluencersLazy />
						</Suspense>
					),
				},
				{
					path: 'influencers',
					loader: ({ params }) => {
						return redirect(`/brand/${params.brand_id}/creators`)
					},
					Component: () => {
						return <div></div>
					},
				},
				{
					path: `settings`,
					element: (
						<Suspense fallback={<div></div>}>
							<BrandSettingsLazy />
						</Suspense>
					),
				},
				{
					path: `monitors`,
					element: (
						<Suspense fallback={<div></div>}>
							<TestPageLazy>
								<SocketProvider>
									<BrandMonitorsLazy />
								</SocketProvider>
							</TestPageLazy>
						</Suspense>
					),
					children: [
						{
							index: true,
							element: (
								<Suspense fallback={<div></div>}>
									<AllMonitorsLazy />
								</Suspense>
							),
						},
						{
							path: `create`,
							element: (
								<Suspense fallback={<div></div>}>
									<CreateMonitorLazy />
								</Suspense>
							),
						},
						{
							path: `:monitor_id`,
							element: (
								<Suspense fallback={<div></div>}>
									<TrackerLazy />
								</Suspense>
							),
							children: [
								{
									index: true,
									element: <Navigate to={'instagram'} />,
								},
								{
									path: 'report',
									element: (
										<Suspense fallback={<div></div>}>
											<MonitorReportLazy />
										</Suspense>
									),
								},
								{
									path: `instagram`,
									element: (
										<Suspense fallback={<div></div>}>
											<MonitorIGLazy />
										</Suspense>
									),
								},
								{
									path: `youtube`,
									element: (
										<Suspense>
											<MonitorYTLazy />
										</Suspense>
									),
								},
								{
									path: `tiktok`,
									element: (
										<Suspense>
											<MonitorTTLazy />
										</Suspense>
									),
								},
								{
									path: `edit`,
									element: (
										<Suspense fallback={<div></div>}>
											<EditMonitorLazy />
										</Suspense>
									),
								},
							],
						},
					],
				},
				{
					path: `credits`,
					element: (
						<Suspense fallback={<div></div>}>
							<CreditsSelector />
						</Suspense>
					),
					children: [
						{
							index: true,
							element: (
								<Suspense fallback={<div></div>}>
									<BrandCreditsLazy />
								</Suspense>
							),
						},
						{
							path: `admin`,
							element: (
								<Suspense fallback={<div></div>}>
									<BrandCreditsAdmin />
								</Suspense>
							),
						},
					],
				},
			],
		},
	],
}
