import { IconChevronLeft } from '@tabler/icons'
import { useAuthProvider } from 'providers/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { Alert, Button, Input } from 'xui'

const ForgotPassword = () => {
	const {
		loginProps: { email, setEmail },
		resetPassword,
		error,
		info,
		resetting: logging_in,
	} = useAuthProvider()

	const navigate = useNavigate()

	const login = async () => {
		resetPassword()
	}

	const Error = () => (error !== '' ? <Alert type={'warning'} message={error} /> : null)
	const Info = () => (info !== '' ? <Alert type={'info'} message={info} /> : null)

	return (
		<div className='flex w-96 flex-col gap-2'>
			<div className='relative h-10'>
				<div
					onClick={() => navigate('/auth/login')}
					className='fixed mb-2 inline-flex w-auto -translate-x-4 cursor-pointer items-center gap-2  rounded-lg p-2 font-semibold transition-all hover:bg-gray-200'
				>
					<IconChevronLeft size={24} />
					Back to Login
				</div>
			</div>

			<b className='text-lg font-semibold'>E-mail</b>
			<Input
				placeholder={'Your Email Address'}
				type={'email'}
				style={{ width: '100%', marginBottom: 10, textAlign: 'center' }}
				value={email}
				size='large'
				onChange={(e) => setEmail(e.target.value)}
				onPressEnter={login}
			/>

			<Button size='large' type={'primary'} style={{ width: '100%', marginBottom: 10 }} onClick={login}>
				{logging_in ? <Loading /> : 'Send Reset Link'}
			</Button>
			<Error />
			<Info />
		</div>
	)
}

const styles = {
	label: {
		fontWeight: '300',
		fontSize: 16,
		marginBottom: 5,
	},
}

export default ForgotPassword

const Loading = () => (
	<svg className='h-5 w-5 animate-spin text-white' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
		<circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
		<path
			className='opacity-75'
			fill='currentColor'
			d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
		></path>
	</svg>
)
