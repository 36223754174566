import { IconArrowUpRight, IconChevronRight, IconMinus } from '@tabler/icons'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { AnimatePresence, motion } from 'framer-motion'
import { cn } from 'lib/helper'
import React, { createContext, useContext } from 'react'
import { formatFloatingNumber, formatNum, formatNumber } from 'utils'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './Tooltip'

export const Widget = ({
	icon,
	title,
	children,
	loading = false,
	onClick,
	iconBackground = 'bg-sky-700',
	className = '',
	onClickItem,
}: {
	icon?: React.ReactNode
	title?: React.ReactNode
	children?: React.ReactNode
	loading?: boolean
	onClick?: () => void
	iconBackground?: string
	className?: string
	onClickItem?: React.ReactNode
}) => {
	return (
		<WidgetContext.Provider value={{ loading, iconBackground }}>
			<div
				onClick={onClick}
				className={cn(
					`group col-span-1 flex h-auto w-full flex-row items-center  gap-3 rounded-lg bg-white p-4 shadow-md ${
						onClick !== undefined ? 'cursor-pointer' : ''
					}`,
					className
				)}
			>
				{icon && (
					<Icon>
						<AnimatePresence>
							{loading ? (
								<motion.div key={'icon'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
									<LoadingSpinner color='white' className='h-6 w-6' />
								</motion.div>
							) : (
								<motion.div key={'icon'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
									{icon}
								</motion.div>
							)}
						</AnimatePresence>
					</Icon>
				)}
				<div className='flex flex-1 flex-col'>
					{title}
					<div className='flex flex-row empty:hidden items-end gap-2'>{children}</div>
				</div>
				{onClick !== undefined ? (
					onClickItem ? (
						onClickItem
					) : (
						<div className='flex h-auto w-auto items-center justify-center rounded-full bg-white p-2 transition-all hover:bg-slate-200 group-hover:bg-slate-200'>
							<IconChevronRight />
						</div>
					)
				) : null}
			</div>
		</WidgetContext.Provider>
	)
}

const Icon = ({ children }: { children: React.ReactNode }) => {
	const { iconBackground } = useWidgetContext()
	return <div className={`rounded-lg  p-3 text-white ${iconBackground}`}>{children}</div>
}

const Title = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => {
	return <div className={cn('text-tiny font-medium leading-5 text-slate-500', className)}>{children}</div>
}

const Value = ({ children, className = '' }: { children: number; className?: string }) => {
	const { loading } = useWidgetContext()
	return loading ? (
		<div className='h-8 w-20 animate-pulse rounded-lg bg-slate-100'></div>
	) : (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<div className={cn(` bg-white text-2xl font-semibold leading-8 text-slate-800 `, className)}>
						{formatNum(children)}
					</div>
				</TooltipTrigger>
				<TooltipContent>{formatNumber(children)}</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

const ValuePercentage = ({ children }: { children: number }) => {
	const { loading } = useWidgetContext()
	return loading ? (
		<div className='h-8 w-20 animate-pulse rounded-lg bg-slate-100'></div>
	) : (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<div className={` bg-white text-2xl font-semibold leading-8 text-slate-800 `}>{`${formatFloatingNumber(
						children,
						1
					)}%`}</div>
				</TooltipTrigger>
				<TooltipContent>{`${formatFloatingNumber(children)}%`}</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

const Change = ({ children }: { children: number }) => {
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger>
					<div
						className={`flex flex-row items-center bg-white text-tiny font-semibold leading-6 ${
							children > 0 ? 'text-green-600' : children < 0 ? 'text-red-600' : 'text-slate-600'
						}`}
					>
						{children > 0 ? (
							<IconArrowUpRight size={16} />
						) : children < 0 ? (
							<IconArrowUpRight size={16} rotate={180} />
						) : (
							<IconMinus size={16} />
						)}
						{children === 0 ? '0' : formatNum(children)}
					</div>
				</TooltipTrigger>
				<TooltipContent>Change within the last 24 hours</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

Widget.Icon = Icon
Widget.Title = Title
Widget.Value = Value
Widget.Change = Change
Widget.ValuePercentage = ValuePercentage

const WidgetContext = createContext<{ loading?: boolean; iconBackground?: string }>(null)

export function useWidgetContext() {
	const context = useContext(WidgetContext)
	if (!context) {
		throw new Error('WidgetContext.* component must be rendered as child of WidgetContext component')
	}
	return context
}
