import {
	UseMutationOptions,
	UseQueryOptions,
	UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
	useSuspenseQuery,
} from '@tanstack/react-query'

import { useParams, useSearchParams } from 'react-router-dom'

import { campaignApi } from 'config/api'
import dayjs from 'dayjs'
import { useDispatch } from 'hooks/tredux'
import { usePermissions } from 'hooks/usePermissions'
import _, { Dictionary } from 'lodash'
import { useAuthProvider } from 'providers/AuthProvider'
import { instantiate_filters } from 'redux/campaign.reducer'
import { $update_loadable } from 'redux/loading.reducer'
import { notification } from 'xui'
import { z } from 'zod'
import { create } from 'zustand'
import { apiConfig } from './api'
import { Campaign, IgAccount, TtAccount, YtAccount } from './campaign.types'
type BrandIdParam = { brand_id: string }
export interface AxiosObject<T> {
	message: string
	code: number
	status: number
	data: T
}

type GetCampaignByID<T> = UseQueryOptions<CampaignDataMapper, undefined, T, string[]>
export function useGetCampaignById<T = CampaignDataMapper>(options?: GetCampaignByID<T>): UseQueryResult<T, undefined> {
	const config = campaignApi.GET_SINGLE
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const dispatch = useDispatch()
	const { getUser } = useAuthProvider()
	const is_brand = () => getUser().role === 'brand'
	const is_identification = () => getUser().role === 'identification'
	const [search, setSearchParams] = useSearchParams()

	return useQuery({
		queryKey: [config.key, campaign_id],
		queryFn: async () => {
			try {
				if (search.get('access_token')) {
					const { data } = await apiConfig.fetch<Campaign>(
						campaignApi.GET_SINGLE_WITH_TOKEN.url(campaign_id, search.get('access_token'))
					)
					const newData = dataMapper(data)
					setSearchParams({
						access_token: search.get('access_token'),
					})
					dispatch(
						instantiate_filters(
							(newData?.personas_all || []).map((persona) => persona._id),
							newData.start_date,
							newData.end_date
						)
					)
					return newData
				}
				if (is_brand() || is_identification()) {
					const { data } = await apiConfig.fetch<Campaign>(campaignApi.GET_SINGLE_BRAND.url(campaign_id))
					const newData = dataMapper(data)

					dispatch(
						instantiate_filters(
							(newData?.personas_all || []).map((persona) => persona._id),
							newData.start_date,
							newData.end_date
						)
					)
					return newData
				}
				const { data } = await apiConfig.fetch<Campaign>(config.url(campaign_id))
				const newData = dataMapper(data)

				dispatch(
					instantiate_filters(
						(newData?.personas_all || []).map((persona) => persona._id),
						newData.start_date,
						newData.end_date
					)
				)

				return newData
			} catch (error) {
				notification['error']({
					message: 'This campaign is unavailable. You may not have access to view campaigns.',
					duration: 3,
				})
			}
		},

		enabled: campaign_id !== undefined,
		staleTime: 1000 * 60 * 60,
		...options,
	})
}
export const useRefreshState = create<{ refresh: boolean; setRefresh: (value: boolean) => void }>((set) => ({
	refresh: false,
	setRefresh: (value) => set({ refresh: value }),
}))
export const useRefreshCampaign = () => {
	const config = campaignApi.GET_SINGLE
	const client = useQueryClient()
	const { setRefresh } = useRefreshState()
	const { campaign_id } = useParams<{ campaign_id: string }>()

	return useMutation({
		mutationFn: async () => {
			const { data } = await apiConfig.fetch<Campaign>(campaignApi.GET_REFRESH_CAMPAIGN_REQUEST.url(campaign_id))
			return dataMapper(data)
		},
		onMutate: () => {
			setRefresh(true)
		},

		mutationKey: ['REFRESH_CAMPAIGN', campaign_id],
		onError: () => {
			setRefresh(false)
			notification['error']({
				message: 'This campaign is unavailable. You may not have access to view campaigns.',
				duration: 3,
			})
		},
		onSuccess: (data) => {
			setRefresh(false)
			client.setQueryData([config.key, campaign_id], data)
			notification['success']({
				message: `KAMPANYA YENİLENDİ`,
				description: `Kampanya kapsamındaki yeni içerikler kontrol edildi.`,
				placement: 'bottom-right',
			})
		},
	})
}
const useMutateShareRequest = () => {
	const config = campaignApi.GET_SHARE_REQUEST
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const campaign = useGetCampaignById()
	return useMutation({
		mutationFn: async () => {
			const { data } = await apiConfig.fetch<any>(config.url(campaign_id))
			return data
		},
		onSuccess: () => {
			campaign.refetch()
		},
	})
}
const useDeleteInfluencer = () => {
	const config = campaignApi.DELETE_INFLUENCER_REQUEST
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const refresh = useRefreshCampaign()
	const dispatch = useDispatch()
	return useMutation({
		mutationFn: async (id: string) => {
			const { data } = await apiConfig.delete_api({ api_path: config.url(campaign_id, id) })
			return null
		},
		onSuccess: () => {
			dispatch($update_loadable('delete_influencer_campaign_modal', 'closed'))
			refresh.mutate()
		},
	})
}
const useGetCampaignMessages = () => {
	const config = campaignApi.GET_CAMPAIGN_MESSAGES_REQUEST
	const { campaign_id } = useParams<{ campaign_id: string }>()
	return useQuery({
		queryKey: [config.key, campaign_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<CampaignMessages[]>(config.url(campaign_id))
			return data
		},
		refetchInterval: 1000 * 60,
		enabled: campaign_id !== undefined,
	})
}

const spark_ads_authorization = z.enum(['0', '7', '30', '60', '180'])
type Order = {
	_id: string
	order_id: string
	__v: number
	campaign_code: string
	createdAt: string
	handle_name: string
	invite_link: string
	quota: {
		total_video_limit: number
		current_video_count: number
		per_creator_video_limit: number
	}
	updatedAt: string
	video_ids: string[]
	campaign_id: string
	tcm_campaign_id: string
	insight: {
		_id: string
		order_id: string
		video_id: string
		__v: number
		audience_interest_distribution: []
		average_view_time: number
		campaign_id: string
		comments: number
		createdAt: string
		daily_stats: []
		engagement_count: number
		engagement_rate: number
		handle_name: string
		lifetime_device_distribution: []
		lifetime_gender_distribution: []
		lifetime_top_age_distribution: []
		lifetime_top_country_distribution: []
		likes: number
		most_popular_country_state_distribution: {
			country: string
			locale_distribution: []
		}
		reach: number
		shares: number
		six_seconds_views: number
		tcm_campaign_id: string
		total_play_time: number
		two_seconds_views: number
		updatedAt: string
		video_completion_rate: number
		video_first_release_time: string
		video_views: number
		video_views_by_source: {
			following: number
			for_you: number
			hashtag: number
			personal_profile: number
			search: number
			sound: number
		}
		video_views_organic: number
		video_views_paid: number
	}
}
export type TTCM =
	| {
		_id: string
		campaign_id: string
		__v: number
		brand_name: string
		campaign_code: string
		createdAt: string
		default_spark_ads_requested_authorization_days: null | z.infer<typeof spark_ads_authorization>
		deliverables: {
			deliverable_name: string
			spark_ads_requested_authorization_days: z.infer<typeof spark_ads_authorization>
			_id: string
		}[]
		description: string
		handle_names: string[]
		order_source: string
		status: 'draft'
		tcm_created: boolean
		updatedAt: string
		video_number: number
	}
	| {
		_id: string
		campaign_id: string
		campaign_code: string
		orders: Order[]
		brand_name: string
		description: string
		handle_names: string[]
		order_source: string
		video_number: number
		default_spark_ads_requested_authorization_days: number
		status: 'active'
		tcm_created: boolean
		deliverables: {
			deliverable_name: string
			spark_ads_requested_authorization_days: z.infer<typeof spark_ads_authorization>
			_id: string
		}[]

		createdAt: string
		updatedAt: string
		__v: number
	}
export const useGetTTCM = () => {
	const { campaign_id } = useParams<{ campaign_id: string }>()
	return useSuspenseQuery({
		queryKey: ['CAMPAIGN/TTCM', campaign_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<{
				campaigns: TTCM[]
				message: string
			}>(`api/campaigns/${campaign_id}/ttcm/list`)
			return data?.campaigns || []
		},
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		staleTime: 1000 * 60 * 60,
	})
}

const useGetCampaignMessagesSingular = () => {
	const config = campaignApi.GET_CAMPAIGN_MESSAGES_REQUEST
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const { user_type } = usePermissions()
	return useQuery({
		queryKey: [config.key, campaign_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<CampaignMessages[]>(config.url(campaign_id))
			return data
		},
		initialData: [],
		initialDataUpdatedAt: 0,
		enabled: campaign_id !== undefined && (user_type === 'creatorden' || user_type === 'admin'),
		staleTime: 1000 * 60 * 60,
	})
}
const useAddInfluencerRequest = () => {
	const config = campaignApi.POST_ADD_INFLUENCER_REQUEST
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const refresh = useRefreshCampaign()
	return useMutation({
		mutationFn: async (handle: string) => {
			const { data: response } = await apiConfig.post<any>(config.url(campaign_id, handle), {})
			return response
		},
		mutationKey: [config.key, campaign_id],
		onSuccess: () => {
			refresh.mutate()
		},
	})
}

type MutationOptions<T> = Omit<UseMutationOptions<unknown, unknown, T, unknown>, 'mutationFn'>
const useAddBulkInfluencers = (options?: Partial<MutationOptions<any>>) => {
	const config = campaignApi.POST_ADD_BULK_INSTAGRAM_INFLUENCERS_REQUEST
	const { campaign_id } = useParams<{ campaign_id: string }>()

	return useMutation({
		mutationFn: async (usernames: string[]) => {
			const { data: response } = await apiConfig.post<
				AxiosObject<{
					campaign: any
					not_found_usernames: string[]
				}>
			>(config.url(campaign_id), config.body(usernames))
			if (response.data.not_found_usernames.length > 0) {
				notification.info({
					message: 'Bazı kullanıcılar bulunamadı',
					description: response.data.not_found_usernames.join(', '),
				})
			}
			return response
		},
		...options,
	})
}
export const campaign = {
	useById: useGetCampaignById,
	useRefresh: useRefreshCampaign,
	useShare: useMutateShareRequest,
	useDeleteInfluencer: useDeleteInfluencer,
	useMessages: useGetCampaignMessages,
	useAddInfluencer: useAddInfluencerRequest,
	useAddBulkInfluencers: useAddBulkInfluencers,
	useMessagesSingular: useGetCampaignMessagesSingular,
	useGetTTCM,
}

export type CampaignDataMapper = ReturnType<typeof dataMapper>
export type CampaignContent = CampaignDataMapper['contents'][number]
export const dataMapper = (data: Campaign) => {
	const dataModified = { ...data }
	_.each(
		_.map(data.ig_posts, (x) => x.id),
		(id) => {
			if (!_.find(data.ig_posts_insights || [], (ss) => ss.id === id)) {
				dataModified.ig_posts_insights.push({
					id: id,
					impression: '',
					reach: '',
					profile_visit: '',
					saved: '',
					paid_support: false,
					raffle: false,
				})
			}
		}
	)
	_.each(
		_.map(data.ig_stories, (x) => x.id),
		(id) => {
			if (!_.find(data.ig_stories_insights || [], (ss) => ss.id === id)) {
				dataModified.ig_stories_insights.push({
					id: id,
					impression: '',
					reach: '',
					link_visit: '',
					tag_visit: '',
					replies: '',
					shared: '',
					cden_visit: '',
					profile_visit: '',
				})
			}
		}
	)
	_.each(
		_.map(data.tt_posts, (x) => x.id),
		(id) => {
			if (!_.find(data.tt_posts_insights || [], (ss) => ss.id === id)) {
				dataModified.tt_posts_insights.push({
					id: id,
					shared: '',
					watch_avg_duration: '',
					watched_per_plays: '',
					reach: '',
				})
			}
		}
	)
	_.each(
		_.map(data.yt_posts, (x) => x.id),
		(id) => {
			if (!_.find(data.yt_posts_insights || [], (ss) => ss.id === id)) {
				dataModified.yt_posts_insights.push({
					id: id,
					video_display: '',
					video_ctr: '',
					reach: '',
					watch_avg_duration: '',
				})
			}
		}
	)

	const sub_account: Dictionary<IgAccount | TtAccount | YtAccount> = _.keyBy(
		[...data.ig_accounts, ...data.yt_accounts, ...data.tt_accounts],
		'_id'
	)

	// eslint-disable-next-line no-unsafe-optional-chaining
	const sub_personas = _([...data?.personas])
		.map((persona) => {
			if (persona.instagram_id) {
				persona.ig_account = sub_account[persona.instagram_id] as IgAccount
			}
			if (persona.youtube_id) {
				persona.yt_account = sub_account[persona.youtube_id] as YtAccount
			}
			if (persona.tiktok_id) {
				persona.tt_account = sub_account[persona.tiktok_id] as TtAccount
			}
			return persona
		})
		.value()
	dataModified.content_all = _.keyBy(
		[
			..._(data.ig_posts || [])
				.map((d) => {
					d.content_type = 'ig_post'
					d.persona_id = _(sub_personas).find((persona) => persona?.ig_account?.id === d.owner.id)?._id
					return d
				})
				.value(),
			..._(data.ig_stories || [])
				.map((d) => {
					d.content_type = 'ig_story'
					d.persona_id = _(sub_personas).find((persona) => persona?.ig_account?.id === d.owner.id)?._id
					return d
				})
				.value(),
			..._(data.yt_posts || [])
				.map((d) => {
					d.content_type = 'yt_post'
					d.taken_at_timestamp = dayjs(d.timestamp).unix()
					d.persona_id = _(sub_personas).find((persona) => persona?.yt_account?.id === d.channel_id)?._id
					return d
				})
				.value(),
			..._(data.tt_posts || [])
				.map((d) => {
					d.content_type = 'tt_post'
					d.taken_at_timestamp = dayjs(d.timestamp).unix()
					d.persona_id = _(sub_personas).find((persona) => persona?.tt_account?.id === d.tiktok_id)?._id
					return d
				})
				.value(),
		],
		'id'
	)
	dataModified.insights_all = _.keyBy(
		[
			...dataModified.ig_posts_insights,
			...dataModified.ig_stories_insights,
			...dataModified.yt_posts_insights,
			...dataModified.tt_posts_insights,
		],
		'id'
	)
	const p = [...(data?.personas || [])].map((persona) => {
		if (persona.instagram_id) {
			persona.ig_account = sub_account[persona.instagram_id] as IgAccount
			persona.ig_posts = (data.ig_posts || [])
				.filter((p) => p.owner.id === persona.ig_account.id)
				.map((p) => p.id) as string[]
			persona.ig_stories = (data.ig_stories || []).filter((p) => p.owner.id === persona.ig_account.id).map((p) => p.id)
			persona.ig_static_count = (data.ig_posts || [])
				.filter((p) => p.owner.id === persona.ig_account.id && p.is_video === false)
				.map((p) => p.id).length
			persona.ig_video_count = (data.ig_posts || [])
				.filter((p) => p.owner.id === persona.ig_account.id && p.is_video === true && p.product_type !== 'clips')
				.map((p) => p.id).length
			persona.ig_reels_count = (data.ig_posts || [])
				.filter((p) => p.owner.id === persona.ig_account.id && p.is_video === true && p.product_type === 'clips')
				.map((p) => p.id).length
		}
		if (persona.tiktok_id) {
			persona.tt_account = sub_account[persona.tiktok_id] as TtAccount
			persona.tt_posts = (data.tt_posts || []).filter((p) => p.tiktok_id === persona.tt_account.id).map((p) => p.id)
		}
		if (persona.youtube_id) {
			persona.yt_account = sub_account[persona.youtube_id] as YtAccount
			persona.yt_posts = (data.yt_posts || []).filter((p) => p.channel_id === persona.yt_account.id).map((p) => p.id)
		}
		return persona
	})
	dataModified.personas_all = [...p]

	const contents = _.keyBy(
		_.map(dataModified.content_all, (c, k) => ({
			content: c,
			insight: dataModified.insights_all[k],
			persona: dataModified.personas_all.find((p) => p._id === c.persona_id),
			review: data.content_review.find((p) => p.content_id === c.id) || {
				content_id: c.id,
				review: '',
				visible: false,
				internal: '',
			},
		})),
		(k) => k.content.id
	)
	return { ...dataModified, contents }
}

export interface CampaignMessages {
	content: string
	createdAt: string
	owner: {
		_id: string
		username: string
		email: string
	}
	room: string
	updatedAt: string
	__enc_content: boolean
	__v: number
	_id: string
}
