const reducer = (
	state = {
		feedback_sending: false,
		feedback_sent: false,
		feedback_fail: false,

		estimation_coefficients: null,
	},
	action: { type: any, payload: any },
) => {
	switch (action.type) {
		case "ESTIMATION_COEFFICIENTS":
			{
				if (!action.payload) {
					return state;
				}
				return { ...(state || {}), estimation_coefficients: action.payload };
			}
		case "FEEDBACK_SENDING":
			{
				return {
					...(state || {}),
					feedback_sending: true,
					feedback_sent: false,
					feedback_fail: false,
				};
			}
		case "FEEDBACK_SENT":
			{
				return {
					...(state || {}),
					feedback_sending: false,
					feedback_sent: true,
					feedback_fail: false,
				};
			}
		case "FEEDBACK_FAIL":
			{
				return {
					...(state || {}),
					feedback_sending: false,
					feedback_sent: false,
					feedback_fail: true,
				};
			}
		case "FEEDBACK_CLEAR":
			{
				return {
					...(state || {}),
					feedback_sending: false,
					feedback_sent: false,
					feedback_fail: false,
				};
			}
		default:
			return state;
	}
};

export const $feedback_clear = () => (dispatch: any) => {
	dispatch({ type: "FEEDBACK_CLEAR" });
};
export const $feedback_send = (feedback: string) => (dispatch: any) => {
	dispatch({ type: "FEEDBACK_SENDING" });
	dispatch({ type: "FEEDBACK_SEND", payload: { feedback } });
};
export const $get_estimation_coefficients = () => (dispatch: any) =>
	dispatch({
		type: "GET_ESTIMATION_COEFFICIENTS",
		payload: { api_path: "/api/hub_settings/estimation_coefficients" },
	});

export default reducer;
