export const SearchTitle = ({ title, count = 0, Icon }: { title: string; count?: number; Icon: React.ReactNode }) => {
	return (
		<div className='flex h-[29px] w-full flex-shrink-0 flex-grow-0 flex-row items-center justify-between border-b border-gray-100 pb-1 '>
			<span className='flex flex-row items-center gap-2'>
				{Icon}
				<h3 className='text-base font-semibold'>{title}</h3>
			</span>
			<div className='flex flex-row items-center gap-2'>
				{count > 0 ? <p className='text-xs text-gray-400'>{count >= 50 ? '50+' : count} results</p> : null}
			</div>
		</div>
	)
}
