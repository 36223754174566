import IXLoading from 'components/IXLoading'
import { Suspense, lazy } from 'react'
import { Outlet, RouteObject, redirect } from 'react-router-dom'
import { ContentList } from './ContentList'

function Loading() {
	return (
		<div className='h-sidebar w-full'>
			<IXLoading text='Please wait.' show block={false} is_absolute={false} />
		</div>
	)
}

const CampaignPresentationSelectedLazy = lazy(() => import('./Presentation/CampaignPresentationSelected'))
const CampaignPresentationSelected = () => (
	<Suspense fallback={<Loading />}>
		<CampaignPresentationSelectedLazy />
	</Suspense>
)

const CampaignPresentationLazy = lazy(() => import('./Presentation'))
const CampaignPresentation = () => (
	<Suspense fallback={<Loading />}>
		<CampaignPresentationLazy />
	</Suspense>
)

const CampaignPresentationRootLazy = lazy(() => import('./Presentation/CampaignPresentationRoot'))
const CampaignPresentationRoot = () => (
	<Suspense fallback={<Loading />}>
		<CampaignPresentationRootLazy />
	</Suspense>
)

const ListCampaignsPageLazy = lazy(() => import('containers/CampaignsPage'))
const ListCampaignsPage = () => (
	<Suspense fallback={<Loading />}>
		<ListCampaignsPageLazy />
	</Suspense>
)

const CampaignsPageLazy = lazy(() => import('.'))
const CampaignsPage = () => (
	<Suspense fallback={<Loading />}>
		<CampaignsPageLazy />
	</Suspense>
)
const CampaignStatisticsLazy = lazy(() => import('./Statistics'))
const CampaignStatistics = () => (
	<Suspense fallback={<Loading />}>
		<CampaignStatisticsLazy />
	</Suspense>
)
const CampaignDataInputLazy = lazy(() => import('./DataInput'))
const CampaignDataInput = () => (
	<Suspense fallback={<Loading />}>
		<CampaignDataInputLazy loading={false} />
	</Suspense>
)
const CampaignInfluencerReportLazy = lazy(() => import('./InfluencerReport'))
const CampaignInfluencerReport = () => (
	<Suspense fallback={<Loading />}>
		<CampaignInfluencerReportLazy />
	</Suspense>
)
const CampaignGeneralReportLazy = lazy(() => import('./GeneralReport/index_legacy'))
const CampaignGeneralReport = () => (
	<Suspense fallback={<Loading />}>
		<CampaignGeneralReportLazy />
	</Suspense>
)
const TTCMLazy = lazy(() => import('./ttcm/page'))
const TTCM = () => (
	<Suspense fallback={<Loading />}>
		<TTCMLazy />
	</Suspense>
)
const TTCMIdLazy = lazy(() => import('./ttcm/[id]/page'))
const TTCMId = () => (
	<Suspense fallback={<Loading />}>
		<TTCMIdLazy />
	</Suspense>
)

const CampaignSettingsLazy = lazy(() => import('./Settings'))
const CampaignSettings = () => (
	<Suspense fallback={<Loading />}>
		<CampaignSettingsLazy />
	</Suspense>
)

const ContentCalendarLazy = lazy(() => import('components/ContentCalendar'))
const ContentCalendar = () => (
	<Suspense fallback={<Loading />}>
		<ContentCalendarLazy />
	</Suspense>
)

const isOnlyBrand = () => {
	const user = JSON.parse(localStorage.getItem('user'))
	return user.role === 'brand' || user.role === 'none'
}
const isAdmin = () => {
	const user = JSON.parse(localStorage.getItem('user'))
	return user.role === 'admin'
}
export const campaign_routes: RouteObject[] = [
	{
		index: true,
		loader: async () => {
			if (isOnlyBrand()) return redirect('/home')
			return null
		},
		element: <ListCampaignsPage />,
	},
	{
		path: ':campaign_id',
		element: <CampaignsPage />,
		children: [
			{
				index: true,
				element: <ContentList />,
			},
			{
				path: 'ttcm',
				loader: async ({ params }) => {
					if (isOnlyBrand()) return redirect(`/campaigns/${params.campaign_id}`)
					return null
				},
				Component: () => {
					return <Outlet />
				},
				children: [
					{
						index: true,
						element: <TTCM />,
					},
					{
						path: ':id',
						element: <TTCMId />,
					},
				],
			},
			{
				path: 'calendar',
				loader: async ({ params }) => {
					if (isOnlyBrand()) return redirect(`/campaigns/${params.campaign_id}`)
					return null
				},
				element: <ContentCalendar />,
			},
			{
				path: 'data_input',
				loader: async ({ params }) => {
					if (isOnlyBrand()) return redirect(`/campaigns/${params.campaign_id}`)
					return null
				},
				element: <CampaignDataInput />,
			},
			{
				path: 'statistics',
				element: <CampaignStatistics />,
			},
			{
				path: 'influencers',
				children: [
					{
						index: true,
						element: <CampaignInfluencerReport />,
					},
					{
						path: ':persona_id',
						element: <CampaignInfluencerReport />,
					},
				],
			},
			{
				path: 'general_report',
				element: <CampaignGeneralReport />,
			},
			{
				path: 'settings',
				loader: async ({ params }) => {
					if (isOnlyBrand()) return redirect(`/campaigns/${params.campaign_id}`)
					return null
				},
				element: <CampaignSettings />,
			},
			{
				path: 'presentation',
				loader: async ({ params }) => {
					if (isOnlyBrand()) return redirect(`/campaigns/${params.campaign_id}`)
					return null
				},
				element: <CampaignPresentation />,
				children: [
					{
						index: true,
						element: <CampaignPresentationRoot />,
					},
					{
						path: ':persona_id',
						element: <CampaignPresentationSelected />,
					},
				],
			},
		],
	},
]
