import axios from 'axios'
import _ from 'lodash'
import { call, delay, put, race, takeLatest } from 'redux-saga/effects'
import { notification } from 'xui'
import { update_loadable } from './loading.saga'
const { endpoint } = window

const api = {
	fetch_api: async ({ api_path }) => {
		return fetch(`${endpoint}${api_path}`, window.getHeaders())
			.then((r) => r.json())
			.then((r) => (r && r.data && r.data ? r.data : null))
	},
	fetch_api_axios: async ({ api_path }) =>
		await axios.get(`${endpoint}${api_path}`, window.getHeaders(), { timeout: 60000 }),

	post_api: async (payload) => {
		const { api_path, body } = payload
		return fetch(`${endpoint}${api_path}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify(body),
		})
			.then((r) => r.json())
			.then((r) => (r && r.data ? r.data : null))
	},
	post_api_axios: async ({ api_path, body }) => {
		const data = await axios.post(`${endpoint}${api_path}`, body, window.getHeaders())
		return data
	},
	delete_api: async ({ api_path }) => {
		const res = await axios.delete(`${endpoint}${api_path}`, window.getHeaders())
		if (res && res.status === 200 && res.data) {
			return res.data
		}
	},
}

function* get_communities_request({ payload: { brand_id, community_id } }) {
	try {
		const data = yield call(api.fetch_api, { api_path: `/brand_api/${brand_id}/communities` })
		yield put({ type: 'COMMUNITIES', payload: _(data).keyBy('_id').value() })
		if (community_id) yield get_community_request({ payload: { brand_id, community_id } })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITIES_FAIL' })
	}
}
export function* get_communities() {
	yield takeLatest('GET_COMMUNITIES', get_communities_request)
}

function* get_community_request({ payload: { brand_id, community_id } }) {
	try {
		const data = yield call(api.fetch_api, { api_path: `/brand_api/${brand_id}/communities/${community_id}` })
		yield put({ type: 'COMMUNITY', payload: data })
		yield get_community_members_request({ payload: { brand_id, community_id } })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* get_community() {
	yield takeLatest('GET_COMMUNITY', get_community_request)
}

function* post_create_community_request({ payload: { brand_id, name, description, file } }) {
	try {
		yield update_loadable('community_create_modal', 'loading')
		const data = yield call(api.post_api, { api_path: `/brand_api/${brand_id}/communities/`, body: { name, description } })
		yield post_update_community_photo_request({ payload: { brand_id, community_id: data._id, file, id: data._id } })
		yield update_loadable('community_create_modal', 'closed')
		yield get_communities_request({ payload: { brand_id } })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* create_community() {
	yield takeLatest('POST_CREATE_COMMUNITY', post_create_community_request)
}

function* post_search_community_personas({
	payload: {
		brand_id,
		min_er = 0,
		max_er = 9999,
		min_followers = 0,
		max_followers = 9999999999,
		regex_biography = '',
		regex_username = '',
		regex_name = '',
		ageGroup = '',
		genderGroup = '',
	},
}) {
	try {
		yield update_loadable('community_loading_influencers', 'loading')
		const data = yield call(api.post_api, {
			api_path: `/brand_api/${brand_id}/communities/search_personas`,
			body: {
				query: {
					min_er,
					max_er,
					min_followers,
					max_followers,
					regex_biography,
					regex_username,
					regex_name,
					age: ageGroup,
					gender: genderGroup,
				},
			},
		})

		yield put({ type: 'COMMUNITY_SEARCH_PERSONAS', payload: { data, count: data.length } })
		yield update_loadable('community_loading_influencers', 'closed')
	} catch (e) {
		console.error('error', e)
		yield update_loadable('community_loading_influencers', 'closed')
		yield put({ type: 'COMMUNITY_FAIL' })
		notification.error({ message: 'Creator Search', description: 'An error occurred while searching for influencers.' })
	}
}
export function* search_community_personas() {
	yield takeLatest('POST_SEARCH_COMMUNITY_PERSONAS', post_search_community_personas)
}
function* delete_community_request({ payload: { brand_id, community_id } }) {
	try {
		yield update_loadable('community_delete', 'loading')
		yield call(api.delete_api, { api_path: `/brand_api/${brand_id}/communities/${community_id}` })
		yield update_loadable('community_delete', 'closed')
		yield delay(1000)

		yield get_communities_request({ payload: { brand_id } })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* delete_community() {
	yield takeLatest('DELETE_COMMUNITY', delete_community_request)
}

function* get_community_members_request({ payload: { brand_id, community_id } }) {
	try {
		const data = yield call(api.fetch_api, { api_path: `/brand_api/${brand_id}/communities/${community_id}/members` })
		yield put({
			type: 'COMMUNITY_MEMBERS',
			payload: {
				community_id,
				data,
				ig_posts: _(data).map('ig_posts').flatten().compact().value(),
				ig_stories: _(data).map('ig_stories').flatten().compact().value(),
			},
		})
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* get_community_members() {
	yield takeLatest('GET_COMMUNITY_MEMBERS', get_community_members_request)
}
function* get_community_invitations_request({ payload: { brand_id, community_id } }) {
	try {
		const data = yield call(api.fetch_api, { api_path: `/brand_api/${brand_id}/communities/${community_id}/invitations` })
		yield put({ type: 'COMMUNITY_INVITATIONS', payload: { data, community_id } })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* get_community_invitations() {
	yield takeLatest('GET_COMMUNITY_INVITATIONS', get_community_invitations_request)
}
function* delete_community_invitations_request({ payload: { brand_id, community_id, persona_id } }) {
	try {
		yield update_loadable('invite_communuty_' + persona_id, 'loading')
		yield call(api.delete_api, {
			api_path: `/brand_api/${brand_id}/communities/${community_id}/invitations/${persona_id}`,
		})
		yield get_community_invitations_request({ payload: { brand_id, community_id } })
		yield get_community_request({ payload: { brand_id, community_id } })
		yield get_community_invitation_requests_request({ payload: { brand_id, community_id } })
		yield update_loadable('invite_communuty_' + persona_id, 'closed')
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* delete_community_invitation() {
	yield takeLatest('DELETE_COMMUNITY_INVITATION', delete_community_invitations_request)
}
function* get_community_invitation_requests_request({ payload: { brand_id, community_id } }) {
	try {
		const data = yield call(api.fetch_api, { api_path: `/brand_api/${brand_id}/communities/${community_id}/requests` })
		yield put({ type: 'COMMUNITY_INVITATION_REQUESTS', payload: { data, community_id } })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* get_community_invitation_requests() {
	yield takeLatest('GET_COMMUNITY_INVITATION_REQUESTS', get_community_invitation_requests_request)
}

function* post_invite_community_request({ payload: { brand_id, community_id, persona_id } }) {
	try {
		yield update_loadable('invite_community_' + persona_id, 'loading')
		yield call(api.post_api, {
			api_path: `/brand_api/${brand_id}/communities/${community_id}/invitations/${persona_id}/invite`,
			body: {},
		})
		yield get_communities_request({ payload: { brand_id } })
		yield get_community_request({ payload: { brand_id, community_id } })
		yield get_community_invitations_request({ payload: { brand_id, community_id } })
		yield update_loadable('invite_community_' + persona_id, 'closed')
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* invite_persona_to_comunity() {
	yield takeLatest('POST_INVITE_COMMUNITY_MEMBER', post_invite_community_request)
}

function* delete_community_member_request({ payload: { brand_id, community_id, persona_id } }) {
	try {
		yield update_loadable('remove_community_' + persona_id, 'loading')
		const data = yield call(api.delete_api, {
			api_path: `/brand_api/${brand_id}/communities/${community_id}/members/${persona_id}`,
		})
		yield get_communities_request({ payload: { brand_id } })
		yield get_community_request({ payload: { brand_id, community_id } })
		if (data.message === 'This persona is not a member of this community.')
			throw new Error('This persona is not a member of this community.')
		yield update_loadable('remove_community_' + persona_id, 'closed')
		yield update_loadable('community_remove_influencer_modal', 'closed')
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'DELETE_COMMUNITY_MEMBER_ERR' })
		notification.error({
			message: 'An Error Occurred',
			description: 'This persona is not a member of this community.',
		})
	}
}
export function* delete_community_member() {
	yield takeLatest('DELETE_COMMUNITY_MEMBER', delete_community_member_request)
}

function* delete_community_bulletin_request({ payload: { brand_id, community_id, bulletin_id } }) {
	try {
		yield update_loadable('bulletin_delete_' + bulletin_id, 'loading')
		yield call(api.delete_api, { api_path: `/brand_api/${brand_id}/communities/${community_id}/messages/${bulletin_id}` })
		yield fetch_community_bulletin_request({ payload: { brand_id, community_id } })
		yield update_loadable('bulletin_delete_' + bulletin_id, 'closed')
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* delete_community_bulletin() {
	yield takeLatest('DELETE_COMMUNITY_BULLETIN', delete_community_bulletin_request)
}
function* fetch_community_bulletin_request({ payload: { brand_id, community_id } }) {
	try {
		const data = yield call(api.fetch_api, {
			api_path: `/brand_api/${brand_id}/communities/${community_id}/messages/list`,
		})
		yield put({ type: 'COMMUNITY_BULLETINS', payload: { community_id, data } })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* get_community_bulletin() {
	yield takeLatest('GET_COMMUNITY_BULLETINS', fetch_community_bulletin_request)
}

function* create_community_bulletin_request({ payload: { brand_id, community_id, title, text } }) {
	try {
		yield update_loadable('community_create_bulletin_modal', 'loading')
		yield call(api.post_api, {
			api_path: `/brand_api/${brand_id}/communities/${community_id}/messages`,
			body: {
				title,
				text,
				parent_id: null,
			},
		})
		yield fetch_community_bulletin_request({ payload: { brand_id, community_id } })
		yield update_loadable('community_create_bulletin_modal', 'closed')
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
	}
}
export function* create_community_bulletin() {
	yield takeLatest('CREATE_COMMUNITY_BULLETIN', create_community_bulletin_request)
}

function* post_update_community_photo_request({ payload: { brand_id, community_id, file, id } }) {
	try {
		const f = new FormData()
		f.append('file', file)
		f.append('id', id)
		f.append('force', true)

		const { posts, timeout } = yield race({
			posts: call(api.post_api_axios, { api_path: `/brand_api/${brand_id}/communities/${community_id}/picture`, body: f }),
			timeout: delay(60 * 1000),
		})
		if (timeout) throw new Error('timeout of 60000ms exceeded')
		yield get_communities_request({ payload: { brand_id } })
		yield get_community_request({ payload: { brand_id, community_id } })
		yield get_community_invitations_request({ payload: { brand_id, community_id } })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'COMMUNITY_FAIL' })
		notification.error({
			message: 'Error Occurred while sending photo',
			description: e.message,
		})
		yield update_loadable('community_create_modal', 'closed')
	}
}
export function* post_update_community_photo() {
	yield takeLatest('POST_UPDATE_COMMUNITY_PHOTO', post_update_community_photo_request)
}
