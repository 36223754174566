import IXLoading from 'components/IXLoading'
import { Suspense, lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { ContentList } from './ContentList'

function Loading() {
	return (
		<div className='h-sidebar w-full'>
			<IXLoading text='Please wait.' show block={false} is_absolute={false} />
		</div>
	)
}

const CampaignsPageLazy = lazy(() => import('.'))
const CampaignsPage = () => (
	<Suspense fallback={<Loading />}>
		<CampaignsPageLazy />
	</Suspense>
)
const CampaignStatisticsLazy = lazy(() => import('./Statistics'))
const CampaignStatistics = () => (
	<Suspense fallback={<Loading />}>
		<CampaignStatisticsLazy />
	</Suspense>
)

const CampaignInfluencerReportLazy = lazy(() => import('./InfluencerReport'))
const CampaignInfluencerReport = () => (
	<Suspense fallback={<Loading />}>
		<CampaignInfluencerReportLazy />
	</Suspense>
)
const CampaignGeneralReportLazy = lazy(() => import('./GeneralReport/index_legacy'))
const CampaignGeneralReport = () => (
	<Suspense fallback={<Loading />}>
		<CampaignGeneralReportLazy />
	</Suspense>
)

export const campaign_tokenized_routes: RouteObject[] = [
	{
		path: ':campaign_id',
		element: <CampaignsPage />,
		children: [
			{
				index: true,
				element: <ContentList />,
			},
			{
				path: `statistics`,
				element: <CampaignStatistics />,
			},
			{
				path: `influencers`,
				children: [
					{
						index: true,
						element: <CampaignInfluencerReport />,
					},
					{
						path: `:persona_id`,
						element: <CampaignInfluencerReport />,
					},
				],
			},
			{
				path: `general_report`,
				element: <CampaignGeneralReport />,
			},
		],
	},
]
