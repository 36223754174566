import dayjs from 'dayjs'
import _ from 'lodash'
import { Persona } from 'types/Persona'
import { Campaign } from './../types/Campaign.d'
const initialState = {
	can_user_manage: false,
	campaign: {
		_id: '',
		campaign_type: '',
		brand_accounts: [],
		brief: '',
		brand: '',
		budget: '',

		review: '',
		target_impression: '',
		target_reach: '',
		target_link_visit: '',
		target_engagement: '',
		review_visible: false,
		personas: [],
		ig_posts: [],
		ig_stories: [],
		tt_posts: [],
		yt_posts: [],
		influencers: [],
		posts: [],
		stories: [],
		hashtags: [],
		blacktags: [],
		locationtags: [],
		shortcodetags: [],
		viewers: [],
		title: '',
		description: '',
		start_date: 0,
		end_date: 0,
		content_review: [],
		created_at: 0,
		updated_at: 0,
		__v: 0,
		campaign_owner: '',
		access_short_url: '',
		access_token: '',
		access_url: '',
		is_event: false,
		brand_owner: { _id: '', name: '' },
		view_count: 0,
		ig_accounts: [],
		tt_accounts: [],
		yt_accounts: [],
		ig_posts_insights: [],
		ig_stories_insights: [],
		tt_posts_insights: [],
		yt_posts_insights: [],
		ig_posts_mapping: {},
		content_all: [],
		insights_all: [],
		personas_all: [],
	},
	loading: true,
	show: 'all',
	hashtag: '',
	blacktag: '',
	title: '',
	brief: '',
	filter_influencer: '',
	filter_content_type: ['story', 'photo_post', 'video_post', 'reels', 'youtube', 'tiktok'],
	filter_start_date: null,
	filter_end_date: null,
	selected_users: [],
	menu_key: 'all_content',
	display_usernames: true,
	access_short_url: null,
	post_stats: [],
	story_stats: [],
	post_mappings: {},
	fetched_post_mappings: false,
	fetched_post_stats: false,
	fetched_story_stats: false,
	query_param: '',
	review: '',
	visibleCount: 50,
	contents: {},
	contents_last_updated: 0,
	content_last_updated: 0,
	ignored_content: {},
	review_visible: false,
	campaign_messages: [],
	sidebar_collapsed: false,
} as Campaign

const reducer = (state = initialState, { type, payload }: any): Campaign => {
	switch (type) {
		case 'SINGLE_WITH_TOKEN':
			return {
				...state,
				campaign: { ...payload.dataModified },
				contents: { ...payload.contents },
				selected_users: [..._.map(payload?.dataModified?.personas_all || [], (p: Persona) => p._id)],
				content_last_updated: Date.now(),
				filter_start_date: dayjs(payload.dataModified.start_date),
				filter_end_date: dayjs(payload.dataModified.end_date),
				loading: false,
				post_mappings: { ...payload.dataModified.ig_posts_mapping },
				fetched_post_mappings: true,
				review: payload.dataModified.review || '',
				review_visible: payload.dataModified.review_visible || false,
			}
		case 'STORIES_WITH_TOKEN':
			return { ...state, story_stats: [...payload], fetched_story_stats: true }
		case 'STORIES_WITH_IDS_BRAND':
			return { ...state, story_stats: [...payload], fetched_story_stats: true }
		case 'STORIES_WITH_IDS':
			return { ...state, story_stats: [...payload], fetched_story_stats: true }
		case 'POSTS_WITH_TOKEN':
			return { ...state, post_stats: [...payload], fetched_post_stats: true }
		case 'POSTS_WITH_IDS':
			return { ...state, post_stats: [...payload], fetched_post_stats: true }
		case 'POSTS_WITH_IDS_BRAND':
			return { ...state, post_stats: [...payload], fetched_post_stats: true }
		case 'MAPPING_WITH_TOKEN':
			return {
				...state,
				post_mappings: { ...payload },
				fetched_post_mappings: true,
			}
		case 'MAPPING_WITH_IDS':
			return {
				...state,
				post_mappings: { ...payload },
				fetched_post_mappings: true,
			}
		case 'MAPPING_WITH_IDS_BRAND':
			return {
				...state,
				post_mappings: { ...payload },
				fetched_post_mappings: true,
			}
		case 'SINGLE':
			return {
				...state,
				campaign: { ...payload.dataModified },
				contents: { ...payload.contents },
				selected_users: [..._.map(payload?.dataModified?.personas_all || [], (p: Persona) => p._id)],
				content_last_updated: Date.now(),
				filter_start_date: dayjs(payload.dataModified.start_date),
				filter_end_date: dayjs(payload.dataModified.end_date),
				loading: false,
				post_mappings: { ...payload.dataModified.ig_posts_mapping },
				fetched_post_mappings: true,
				review: payload.dataModified.review || '',
				review_visible: payload.dataModified.review_visible || false,
			}
		case 'ADD_INFLUENCER_BULK': {
			return {
				...state,
				campaign: { ...payload.dataModified },
				contents: { ...payload.contents },
				selected_users: [..._.map(payload?.dataModified?.personas_all || [], (p: Persona) => p._id)],
				content_last_updated: Date.now(),
				filter_start_date: dayjs(payload.dataModified.start_date),
				filter_end_date: dayjs(payload.dataModified.end_date),
				loading: false,
				post_mappings: { ...payload.dataModified.ig_posts_mapping },
				fetched_post_mappings: true,
				review: payload.dataModified.review || '',
				review_visible: payload.dataModified.review_visible || false,
			}
		}
		case 'SINGLE_BRAND':
			return {
				...state,
				campaign: { ...payload.dataModified },
				contents: { ...payload.contents },
				selected_users: [..._.map(payload?.dataModified?.personas_all || [], (p: Persona) => p._id)],
				content_last_updated: Date.now(),
				filter_start_date: dayjs(payload.dataModified.start_date),
				filter_end_date: dayjs(payload.dataModified.end_date),
				loading: false,
				post_mappings: { ...payload.dataModified.ig_posts_mapping },
				fetched_post_mappings: true,
				review: payload.dataModified.review || '',
				review_visible: payload.dataModified.review_visible || false,
				can_user_manage: payload.dataModified.can_user_manage || false,
			}
		case 'SHARE':
			return { ...state, access_short_url: payload.access_url }
		case 'IGNORED_CONTENT':
			return { ...state, ignored_content: payload }
		case 'MENU_KEY':
			return { ...state, menu_key: payload }
		case 'FILTER_INFLUENCER':
			return { ...state, filter_influencer: payload }
		case 'VISIBLE_COUNT':
			return { ...state, visibleCount: state.visibleCount + payload }
		case 'HIDE_CONTENT':
			const posts = _.filter(state.campaign.posts || [], (p) => p.id !== payload)
			const stories = _.filter(state.campaign.stories || [], (p) => p.id !== payload)
			return { ...state, campaign: { ...state.campaign, posts, stories } }
		case 'UPDATE_CONTENT_TYPE':
			if (state.filter_content_type.includes(payload)) {
				return {
					...state,
					filter_content_type: [..._.filter(state.filter_content_type, (d) => d !== payload)],
				}
			} else {
				return {
					...state,
					filter_content_type: [...state.filter_content_type, payload],
				}
			}
		case 'SELECT_ALL_CONTENT_TYPES': {
			return {
				...state,
				filter_content_type: ['story', 'photo_post', 'video_post', 'reels', 'youtube', 'tiktok'],
			}
		}
		case 'DESELECT_ALL_CONTENT_TYPES': {
			return { ...state, filter_content_type: [] }
		}
		case 'UPDATE_FILTER_START_DATE':
			return { ...state, filter_start_date: payload }
		case 'UPDATE_FILTER_END_DATE':
			return { ...state, filter_end_date: payload }
		case 'UPDATE_SELECTED_USERS':
			return { ...state, selected_users: [...payload] }
		case 'UPDATE_CAMPAIGN_STATE': {
			const { personas, ig_accounts, ig_posts, ig_stories, tt_accounts, tt_posts, yt_accounts, yt_posts, ...rest } =
				payload.campaign
			return { ...state, campaign: { ...state.campaign, ...rest } }
		}

		case 'REFRESH':
			return {
				...state,
				selected_users: [..._.map(payload?.dataModified?.personas_all || [], (p: Persona) => p._id)],
				campaign: { ...payload.dataModified },
				contents: { ...payload.contents },
				content_last_updated: Date.now(),
				loading: false,
			}
		case 'SUSTAIN_QUERY':
			return { ...state, query_param: payload }
		case 'UPDATE_POST_DATA':
			if (!payload) {
				return state
			}
			return {
				...state,
				contents: {
					...state.contents,
					[payload.data.id]: {
						...state.contents[payload.data.id],
						insight: { ...payload.data },
					},
				},
			}
		case 'UPDATE_STORY_DATA':
			if (!payload) {
				return state
			}
			return {
				...state,
				contents: {
					...state.contents,
					[payload.data.id]: {
						...state.contents[payload.data.id],
						insight: { ...payload.data },
					},
				},
			}
		case 'INSTANTIATE_FILTERS':
			return {
				...state,
				...payload,
			}

		case 'UPDATE_CONTENT_FIELD':
			if (!payload) {
				return state
			}
			return {
				...state,
				contents: {
					...state.contents,
					[payload.data.id]: {
						...state.contents[payload.data.id],
						insight: { ...payload.data },
					},
				},
			}
		case 'UPDATE_CONTENT_REVIEW':
			if (!payload) {
				return state
			}
			return {
				...state,
				contents: {
					...state.contents,
					[payload.content_id]: {
						...state.contents[payload.content_id],
						review: { ...payload },
					},
				},
			}
		case 'CALCULATE_WEEKLY':
			if (!payload) {
				return state
			}
			return {
				...state,
				contents: {
					...state.contents,
					[payload.id]: {
						...state.contents[payload.id],
						content: { ...payload },
					},
				},
			}
		case 'UPLOAD_STATISTICS':
			if (!payload) {
				return state
			}
			return {
				...state,
				contents: {
					...state.contents,
					[payload.id]: {
						...state.contents[payload.id],
						content: {
							...state.contents[payload.id].content,
							bucket_statistics: [...(state.contents[payload.id].content?.bucket_statistics || []), payload.data.url],
						},
					},
				},
			}
		case 'REMOVE_STATISTICS':
			if (!payload) {
				return state
			}
			return {
				...state,
				contents: {
					...state.contents,
					[payload.id]: {
						...state.contents[payload.id],
						content: {
							...state.contents[payload.id].content,
							bucket_statistics: (state.contents[payload.id].content?.bucket_statistics || []).filter((d) => d !== payload.url),
						},
					},
				},
			}
		case 'LOADING':
			return { ...state, loading: true }
		case 'SELECT_ALL_USERS': {
			return {
				...state,
				selected_users: payload,
			}
		}
		case 'DESELECT_ALL_USERS': {
			return { ...state, selected_users: [] }
		}
		case 'CAMPAIGN_MESSAGES': {
			return { ...state, campaign_messages: payload }
		}
		case 'IG_BULK_ADDED': {
			return {
				...state,
				campaign: { ...state.campaign, ...payload },
				content_last_updated: Date.now(),

				loading: false,
			}
		}
		case 'COLLAPSE_CAMPAIGN_SIDEBAR':
			return { ...state, sidebar_collapsed: !state.sidebar_collapsed }

		default:
			return state
	}
}

export default reducer

function parseQuery(queryString: any) {
	const query: any = {}
	const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
	for (let i = 0; i < pairs.length; i++) {
		const pair = pairs[i].split('=')
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
	}
	return query
}

export const $get_single_with_token =
	(campaign_id: string, query: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: string; access_token: any } }) => void) => {
		const q: any = parseQuery(query.search)
		dispatch({
			type: 'GET_SINGLE_WITH_TOKEN',
			payload: { campaign_id, access_token: q.access_token },
		})
	}
export const $get_single =
	(campaign_id: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any } }) => void) => {
		dispatch({ type: 'GET_SINGLE', payload: { campaign_id } })
	}
export const $get_single_brand =
	(campaign_id: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any } }) => void) => {
		dispatch({ type: 'GET_SINGLE_BRAND', payload: { campaign_id } })
	}
export const $get_share =
	(campaign_id: any, short_url: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any; short_url: any } }) => void) => {
		dispatch({ type: 'GET_SHARE', payload: { campaign_id, short_url } })
	}
export const $get_ignored_content =
	(campaign_id: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any } }) => void) => {
		dispatch({ type: 'GET_IGNORED_CONTENT', payload: { campaign_id } })
	}

export const $get_stories_with_token =
	(campaign_id: any, query: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any; access_token: any } }) => void) => {
		const q = parseQuery(query)
		dispatch({
			type: 'GET_STORIES_WITH_TOKEN',
			payload: { campaign_id, access_token: q.access_token },
		})
	}
export const $get_stories_with_ids =
	(ids: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { ids: any } }) => void) => {
		dispatch({ type: 'GET_STORIES_WITH_IDS', payload: { ids } })
	}
export const $get_stories_with_ids_brand =
	(campaign_id: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any } }) => void) => {
		dispatch({ type: 'GET_STORIES_WITH_IDS_BRAND', payload: { campaign_id } })
	}
export const $get_posts_with_token =
	(campaign_id: any, query: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any; access_token: any } }) => void) => {
		const q: any = parseQuery(query)
		dispatch({
			type: 'GET_POSTS_WITH_TOKEN',
			payload: { campaign_id, access_token: q.access_token },
		})
	}
export const $get_posts_with_ids =
	(ids: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { ids: any } }) => void) => {
		dispatch({ type: 'GET_POSTS_WITH_IDS', payload: { ids } })
	}
export const $get_posts_with_ids_brand =
	(campaign_id: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any } }) => void) => {
		dispatch({ type: 'GET_POSTS_WITH_IDS_BRAND', payload: { campaign_id } })
	}
export const $get_mapping_with_token =
	(campaign_id: any, query: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any; access_token: any } }) => void) => {
		const q = parseQuery(query)
		dispatch({
			type: 'GET_MAPPING_WITH_TOKEN',
			payload: { campaign_id, access_token: q.access_token as any },
		})
	}
export const $get_mapping_with_ids =
	(ids: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { ids: any } }) => void) => {
		dispatch({ type: 'GET_MAPPING_WITH_IDS', payload: { ids } })
	}
export const $get_mapping_with_ids_brand =
	(campaign_id: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any } }) => void) => {
		dispatch({ type: 'GET_MAPPING_WITH_IDS_BRAND', payload: { campaign_id } })
	}
export const $get_remove_influencer =
	(campaign_id: any, influencer_handle: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { campaign_id: any; influencer_handle: any } }) => void) => {
		dispatch({
			type: 'GET_DELETE_INFLUENCER',
			payload: { campaign_id, influencer_handle },
		})
	}
export const $get_add_influencer =
	(influencer_handle: any, campaign_id: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload: { influencer_handle: any; campaign_id: any } }) => void) => {
		dispatch({
			type: 'GET_ADD_INFLUENCER',
			payload: { influencer_handle, campaign_id },
		})
	}
export const $get_refresh_campaign =
	(campaign_id: any, then = () => {}) =>
	(dispatch: (arg0: { type: string; payload?: { campaign_id: any } }) => void) => {
		dispatch(load())
		dispatch({ type: 'GET_REFRESH_CAMPAIGN', payload: { campaign_id } })
	}
export const change_menu = (menu: any) => {
	return { type: 'MENU_KEY', payload: menu }
}
export const filter_influencer = (inf: any) => {
	return { type: 'FILTER_INFLUENCER', payload: inf }
}

export const add_visible = (plus: any) => {
	return { type: 'VISIBLE_COUNT', payload: plus }
}
export const hide_content = (id: any) => {
	return { type: 'HIDE_CONTENT', payload: id }
}
export const update_content_type = (c_type: any) => {
	return { type: 'UPDATE_CONTENT_TYPE', payload: c_type }
}
export const update_filter_start_date = (date: any) => {
	return { type: 'UPDATE_FILTER_START_DATE', payload: date }
}
export const update_filter_end_date = (date: any) => {
	return { type: 'UPDATE_FILTER_END_DATE', payload: date }
}
export const update_selected_users = (influencers: any) => {
	return { type: 'UPDATE_SELECTED_USERS', payload: influencers }
}
const load = () => {
	return { type: 'LOADING' }
}
export const $update_campaign_state = (campaign: string, is_event: boolean) => {
	return { type: 'UPDATE_CAMPAIGN_STATE', payload: { campaign, is_event } }
}
export const sustain_query_param = (query: any) => {
	return { type: 'SUSTAIN_QUERY', payload: query?.search || '' }
}
export const select_all_content_types = () => {
	return { type: 'SELECT_ALL_CONTENT_TYPES' }
}
export const deselect_all_content_types = () => {
	return { type: 'DESELECT_ALL_CONTENT_TYPES' }
}
export const select_all_users = (users: string[]) => {
	return { type: 'SELECT_ALL_USERS', payload: users }
}
export const deselect_all_users = () => {
	return { type: 'DESELECT_ALL_USERS' }
}

export const $post_bulk_ig =
	(campaign_id: any, usernames: string[], then = () => {}) =>
	(dispatch: (arg0: { type: string; payload?: { campaign_id: any; usernames: string[] } }) => void) => {
		dispatch(load())
		dispatch({ type: 'POST_ADD_BULK_IG', payload: { campaign_id, usernames } })
	}

export const collapse_sidebar = () => {
	return { type: 'COLLAPSE_CAMPAIGN_SIDEBAR' }
}

export const instantiate_filters = (personas: string[], start_date: string | number, end_date: string | number) => {
	return {
		type: 'INSTANTIATE_FILTERS',
		payload: {
			selected_users: personas,
			content_last_updated: Date.now(),
			filter_start_date: dayjs(start_date),
			filter_end_date: dayjs(end_date),
		},
	}
}
