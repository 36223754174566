import { AgeGroups, GenderGroups } from "types/Community";
import { Project, ProjectApplicants, ProjectContentDetails } from "types/Projects"

type InitialStateProps = {
  projects: Record<string, Project>,
  loadingProjects: boolean,
  lastUpdated: number,
  projectDetails: Record<string, Project & { lastUpdated: number }>,
  applicants: Record<string, ProjectApplicants[]>,
  detailedApplicants: Record<string, ProjectApplicants[]>,
  seen_count: Record<string, number>,
}
export const DefaultProject = {
  _id: "",
  brand_id: "",
  title: "",
  about_brand: "",
  __v: 0,
  content_details: [],
  createdAt: "",
  description: "",
  end_date: "",
  bucket_pp: "",
  has_bucket_pp: false,
  objective: "",
  project_rules: "",
  start_date: "",
  status: "draft",
  target_audience: {
    audience_type: "",
    community_id: null,
    audience_age_groups: [],
    audience_gender_groups: []
  },
  target_audience_description: "",
  timeline_v1: "",
  updatedAt: ""
}
const initialState = {
  projects: {},
  loadingProjects: true,
  lastUpdated: 0,
  projectDetails: {},
  seen_count: {},
  applicants: {},
  detailedApplicants: {}
} as InitialStateProps;

const projects = (state = initialState, { type, payload }: { type: string, payload: any }) => {
  switch (type) {

    case "PROJECTS": {
      return { ...state, projects: payload.projects, applicants: payload.applications, seen_count: payload.seen_count, loadingProjects: false, lastUpdated: Date.now() }
    }
    case "PROJECT": {
      return {
        ...state, projectDetails: {
          ...state.projectDetails,
          [payload.data.project._id]: {
            ...state.projectDetails[payload.data.project._id],
            ...payload.data.project,
            lastUpdated: Date.now()

          }
        },
        detailedApplicants: {
          ...state.detailedApplicants,
          [payload.data.project._id]: payload.data.applications,
        },

        lastUpdated: Date.now()
      }
    }

    default:
      return state
  }
}
export default projects


export const $getBrandProjects = (brand_id: string, project_id?: string | undefined) => (dispatch: any) => {
  dispatch({
    type: "FETCH_PROJECTS",

    payload: {
      api_path: `/brand_api/${brand_id}/projects`,
      brand_id,
      project_id
    }
  })
}

export const $getBrandProject = (brand_id: string, project_id: string) => (dispatch: any) => {
  dispatch({
    type: "FETCH_PROJECT",

    payload: {
      api_path: `/brand_api/${brand_id}/projects/${project_id}`,
      brand_id,
      project_id
    }
  })
}
export const $createBrandProject = (brand_id: string, title: string) => (dispatch: any) => {
  dispatch({
    type: "POST_CREATE_PROJECT",

    payload: {
      api_path: `/brand_api/${brand_id}/projects/`,
      brand_id,
      title
    }
  })
}
type ProjectType = {
  title?: string,
  about_brand?: string,
  content_details?: { title: string, description: string }[],
  description?: string,
  end_date?: string,
  start_date?: string,
  timeline_v1?: string,
  objective?: string,
  project_rules?: string,
  status?: "draft",
  target_audience_description?: string,
  target_audience?: {
    audience_type?: "manual" | "community",
    community_id?: null | string,
    influencer_ages?: AgeGroups[],
    influencer_genders?: GenderGroups[],
    audience_age_groups?: AgeGroups[],
    audience_gender_groups?: GenderGroups[],
    min_engagement_rate?: number,
    min_followers?: number,
    min_engagement?: number,
    audience_interests?: string[],
    keywords?: string[],
  }
}
export const $copyBrandProject = (brand_id: string, title: string, project: ProjectType) => (dispatch: any) => {
  dispatch({
    type: "POST_COPY_PROJECT",

    payload: {
      api_path: `/brand_api/${brand_id}/projects/`,
      brand_id,
      title,
      project
    }
  })
}
export const $updateBrandProject = (brand_id: string, project_id: string, project: ProjectType) => (dispatch: any) => {
  dispatch({
    type: "POST_UPDATE_PROJECT",

    payload: {
      api_path: `/brand_api/${brand_id}/projects/${project_id}`,
      brand_id,
      project_id,
      project
    }
  })
}

export const $deleteBrandProject = (brand_id: string, project_id: string) => (dispatch: any) => {
  dispatch({
    type: "DELETE_PROJECT",

    payload: {
      api_path: `/brand_api/${brand_id}/projects/${project_id}`,
      brand_id,
      project_id
    }
  })
}
export const $publishBrandProject = (brand_id: string, project_id: string) => (dispatch: any) => {
  dispatch({
    type: "POST_PUBLISH_PROJECT",

    payload: {
      api_path: `/brand_api/${brand_id}/projects/${project_id}/publish`,
      brand_id,
      project_id
    }
  })
}
export const $unpublishActiveBrandProject = (brand_id: string, project_id: string) => (dispatch: any) => {
  dispatch({
    type: "POST_UNPUBLISH_PROJECT",

    payload: {
      api_path: `/brand_api/${brand_id}/projects/${project_id}/unpublish`,
      brand_id,
      project_id
    }
  })
}
export const $uploadProjectPicture = (brand_id: string, project_id: string, file: any) => (dispatch: any) => {
  dispatch({
    type: "POST_UPLOAD_IMAGE",

    payload: {
      api_path: `/brand_api/${brand_id}/projects/${project_id}/picture`,
      brand_id,
      project_id,
      file
    }
  })
}
export const $deleteProjectPicture = (brand_id: string, project_id: string) => (dispatch: any) => {
  dispatch({
    type: "DELETE_UPLOAD_IMAGE",

    payload: {
      api_path: `/brand_api/${brand_id}/projects/${project_id}/picture`,
      brand_id,
      project_id
    }
  })
}