import axios from 'axios'
import { endpoint } from 'config/api'
import { queryClient } from 'qc'
import { Navigate, Route, RouteObject, Routes, redirect, useSearchParams } from 'react-router-dom'
import { Container } from './Container'
import ForgotPassword from './ForgotPassword'
import Login from './Login'
import Register from './Register'
import ResetPassword from './ResetPassword'

export const AuthRoutes = () => {
	return (
		<div className='m-0'>
			<Routes>
				<Route path='auth' element={<Container />}>
					<Route path='login' element={<Login />} />
					<Route path='register' element={<Register />} />
					<Route path='forgot-password' element={<ForgotPassword />} />
					<Route path='reset-password*' element={<ResetPassword />} />
					<Route path='*' element={<NoMatch />} />
				</Route>

				<Route path='*' element={<NoMatch />} />
			</Routes>
		</div>
	)
}
export const auth_routes: RouteObject = {
	path: 'auth',
	element: <Container />,
	loader: async ({ request, context, params }) => {
		try {
			console.log('auth_routes', request);
			const data = await axios.get(`${endpoint}/auth/check`, { withCredentials: true, timeout: 10000 })
			if (data.status !== 200) {
				if (!request.url.includes('/auth/')) return redirect('/auth/login')
				return false;
			}
			if (!queryClient.getQueryData(['HUB', 'AUTH'])) {
				queryClient.setQueryData(['HUB', 'AUTH'], data.data)
			}
			return redirect('/')
		} catch (e) {
			if (!request.url.includes('/auth/')) return redirect('/auth/login')
			return false;
		}
	},
	children: [
		{
			path: 'login',
			element: <Login />,
		},
		{
			path: 'register',
			element: <Register />,
		},
		{
			path: 'forgot-password',
			element: <ForgotPassword />,
		},
		{
			path: 'reset-password*',
			element: <ResetPassword />,
		},
	],
}
const a =
	'?reset_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Inl1c3VmQGNyZWF0b3JkZW4uY29tIiwiaWF0IjoxNjgzNTU5NTAyfQ.JbcxghuyvYbawOIo1Sgqm-5S_wBKo2-9FMr9lQZ2io0'
const NoMatch = () => {
	const [search, setSearch] = useSearchParams()
	if (search.get('reset_token')) return <Navigate to={`/auth/reset-password?reset_token=${search.get('reset_token')}`} />
	return <Navigate to='/auth/login' />
}
export default AuthRoutes
