import {
	IconBrandInstagram,
	IconBrandTiktok,
	IconBrandYoutube,
	IconCopy,
	IconLink,
	IconThumbDown,
	IconThumbUp,
} from '@tabler/icons'
import { useDetect } from 'api/detection'
import { useSelector } from 'hooks/tredux'
import { useBrandPermissionSelector } from 'hooks/useBrandPermissionSelector'
import { useCampaignQueries } from 'hooks/useCampaignQueries'
import { usePermissions } from 'hooks/usePermissions'
import _ from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { AiOutlineMessage, AiOutlinePlayCircle } from 'react-icons/ai'
import { Persona } from 'types/Persona'
import {
	Sheet,
	SheetContent,
	SheetFooter,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from 'ui'
import { formatNumber, tr_thousandmillion_raw } from 'utils'
import { useContentCardContext } from '.'
import { SidebarHeader } from '../components/SidebarHeader'
// import { useCampaignQueries } from 'hooks/useCampaignQueries'

export const ContentDetails = ({ children }: { children: ReactNode }) => {
	const { content, persona } = useContentCardContext()
	const [collab, setCollab] = useState<null | Persona | undefined>(null)
	const $campaign = useSelector((state) => state.$campaign)
	const { user_type } = useBrandPermissionSelector()
	const { details, set_details } = useCampaignQueries()
	useEffect(() => {
		if (content.content_type !== 'ig_post') return
		const c = content?.collaborators
		if (c === null) {
			return setCollab(null)
		} else {
			setCollab(
				(c || []).length > 0
					? $campaign.campaign.personas_all.find(
							(d: { ig_account: { id: string | undefined } }): any => d?.ig_account?.id === c?.[0]
					  )
					: null
			)
		}
		return () => {
			setCollab(null)
		}
	}, [$campaign.campaign.personas_all, content?.collaborators])

	return (
		<>
			<Sheet
				open={details === content._id}
				onOpenChange={(open) => {
					if (!open) return set_details()
					set_details(content._id)
				}}
			>
				<SheetTrigger asChild>{children}</SheetTrigger>
				<SheetContent className='flex h-[calc(100vh-16px)] w-[440px] flex-col'>
					<SheetHeader>
						<SheetTitle>Content</SheetTitle>
					</SheetHeader>
					<SidebarHeader
						onClick={() => window.open(`https://www.instagram.com/${persona.name}`, '_blank')}
						persona={persona}
						image={
							persona?.ig_account?.bucket_pp ||
							persona?.tt_account?.bucket_profile_picture ||
							persona?.yt_account?.thumbnail_url ||
							'/images/placeholder.png'
						}
						name={persona.name}
						timestamp={content.taken_at_timestamp * 1000}
						score={
							{
								ig_post: persona?.ig_account?.hub_analysis?.score || 0,
								ig_story: persona?.ig_account?.hub_analysis?.score || 0,
								tt_post: persona?.tt_account?.hub_analysis?.score || 0,
								yt_post: persona?.yt_account?.hub_analysis?.score || 0,
							}[content.content_type]
						}
					/>
					<div className='scrollable h-auto flex-1 overflow-y-scroll rounded-md bg-gray-200 p-2'>
						{collab !== null && collab !== undefined && (
							<SidebarHeader
								persona={collab}
								image={collab.ig_account.bucket_pp}
								name={collab.name}
								timestamp={content.taken_at_timestamp * 1000}
								score={collab.ig_account.hub_analysis?.score || 0}
							/>
						)}
						{user_type === 'admin' ? <ContentID /> : null}
						<div className='flex w-full flex-col space-y-2 overflow-hidden rounded-lg bg-white p-2 shadow-md'>
							{content?.is_video ? <Video /> : <ContentImage />}
							<div className='flex h-auto flex-row justify-evenly rounded-lg bg-gray-800 p-2 text-white shadow-md empty:hidden'>
								{!!(content.view_count || content.video_play_count || content.video_view_count) && (
									<div className={' flex h-full w-auto flex-col items-center justify-center'}>
										<h3 className='text-lg font-semibold'>
											{tr_thousandmillion_raw(content.video_play_count || content.view_count || content.video_view_count || 0)}
										</h3>
										<p className='inline-flex items-center text-xs'>
											<AiOutlinePlayCircle />
											Plays
										</p>
									</div>
								)}

								<LikeCount />
								<DislikeCount />
								{content?.comments ? (
									<div className={' flex h-full w-auto flex-col items-center justify-center'}>
										<h3 className='text-lg font-semibold'>{tr_thousandmillion_raw(content.comments || 0)}</h3>
										<p className='inline-flex items-center text-xs'>
											<AiOutlineMessage />
											Comments
										</p>
									</div>
								) : null}
							</div>
							<CTAUrl />
							{content?.title ? <h3 className='text-base font-semibold'>{content?.title}</h3> : null}
							<p style={{ maxHeight: 150 }} className={'scrollable overflow-y-auto text-xs empty:hidden'}>
								{content?.caption || content.description}
							</p>
							<div className='mt-2 inline-flex h-auto w-full flex-wrap empty:hidden'>
								{(content?.mentions || []).map((d, id) => (
									<p
										key={content._id + d + id + 'mention'}
										className='mb-1 mr-1 h-auto w-auto flex-shrink flex-grow-0 rounded-xl bg-gray-700 px-2 py-1 text-xs text-white shadow-md'
									>
										@{d}
									</p>
								))}
								{(content?.hashtags || []).map((d, id) => (
									<p
										key={content._id + d + id + 'hashtag'}
										className='mb-1 mr-1 h-auto w-auto flex-shrink flex-grow-0 rounded-xl bg-gray-700 px-2 py-1 text-xs text-white shadow-md'
									>
										#{d}
									</p>
								))}
							</div>
						</div>
						<InsightList />
						{/* {user_type === 'admin' ? ( */}
						{/* 	<ErrorBoundary fallback={<div>an error occurred while detecting</div>}> */}
						{/* 		<Detect id={content._id} url={content.bucket_thumbnail_url || content.thumbnail_url} /> */}
						{/* 	</ErrorBoundary> */}
						{/* ) : null} */}
					</div>
					<SheetFooter className='empty:hidden'>
						<InstagramFooter />
						<YoutubeFooter />
						<TiktokFooter />
					</SheetFooter>
				</SheetContent>
			</Sheet>
		</>
	)
}
const Detect = (props: { id: string; url: string }) => {
	const detect = useDetect({ id: props?.id || '', url: props?.url || '' })
	return <pre>{JSON.stringify(detect.data, null, 2)}</pre>
}
const CTAUrl = () => {
	const { content } = useContentCardContext()
	if (content.content_type !== 'ig_story') return null
	if (!content?.story_cta_url) return null
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipContent>{content?.story_cta_url}</TooltipContent>
				<TooltipTrigger>
					<a
						href={content.story_cta_url}
						target='_blank'
						rel='noreferrer'
						className='flex h-auto cursor-pointer flex-row justify-center rounded-lg bg-gray-800 p-2 text-xs font-semibold text-white shadow-md hover:text-white'
					>
						<IconLink size={20} className='mr-2' />
						<p className='line-clamp-1'>{content?.link_domain || content?.story_cta_url}</p>
					</a>
				</TooltipTrigger>
			</Tooltip>
		</TooltipProvider>
	)
}
const LikeCount = () => {
	const { content } = useContentCardContext()
	if (content.content_type === 'ig_story') return null
	// if (!(content?.like_count || content?.likes || content?.likes_count)) return null

	return (
		<div className={' flex h-full w-auto flex-col items-center justify-center'}>
			<h3 className='text-lg font-semibold'>
				{tr_thousandmillion_raw(content?.like_count || content?.likes || content?.likes_count || 0)}
			</h3>
			<p className='inline-flex items-center text-xs'>
				<IconThumbUp size={12} />
				Likes
			</p>
		</div>
	)
}

const DislikeCount = () => {
	const { content } = useContentCardContext()
	if (content.content_type !== 'yt_post') return null
	if (!content?.dislike_count) return null
	return (
		<div className={' flex h-full w-auto flex-col items-center justify-center'}>
			<h3 className='text-lg font-semibold'>{tr_thousandmillion_raw(content.dislike_count || 0)}</h3>
			<p className='inline-flex items-center text-xs'>
				<IconThumbDown />
				Dislikes
			</p>
		</div>
	)
}
const CommentCount = () => {
	const { content } = useContentCardContext()
	if (content.comments === null) return null
	return (
		<div className={' flex h-full w-auto flex-col items-center justify-center'}>
			<h3 className='text-lg font-semibold'>{tr_thousandmillion_raw(content.comments || 0)}</h3>
			<p className='inline-flex items-center text-xs'>
				<AiOutlineMessage />
				Comments
			</p>
		</div>
	)
}
const InsightList = () => {
	const { insight } = useContentCardContext()
	return (
		<div className='mt-2 grid h-auto w-full grid-flow-row grid-cols-2 gap-2'>
			{_(insight)
				.omit(['id', '_id', '__v', 'locked', 'raffle', 'statistic_files', 'paid_support'])
				.pickBy((i) => i !== 0 && i !== null && i !== '')
				.map((i, k) => (
					<div
						key={'insight_' + k}
						className='flex h-auto w-full flex-col items-center justify-center rounded-lg bg-white p-2 shadow-md'
					>
						<h2 className='text-lg font-semibold text-gray-700'>{formatNumber(i)}</h2>
						<h4 className='capitalize'>{(k || '').replaceAll('_', ' ')}</h4>
					</div>
				))
				.value()}
		</div>
	)
}
const Footer = ({ children }: { children?: React.ReactNode }) => {
	return <div className='flex flex-row justify-end'>{children}</div>
}
const YoutubeFooter = () => {
	const { content } = useContentCardContext()
	if (content.content_type !== 'yt_post') return null
	return (
		<a
			className='inline-flex h-auto w-auto items-center rounded-lg bg-yt-post px-2 py-1 text-white shadow-md hover:bg-red-800 hover:text-white hover:shadow-lg'
			href={`https://www.youtube.com/watch?v=${content.id}`}
			target='_blank'
			rel='noreferrer'
		>
			<IconBrandYoutube className='mr-1' />
			Show On Youtube
		</a>
	)
}
const InstagramFooter = () => {
	const { content } = useContentCardContext()
	if (content.content_type !== 'ig_post') return null
	return (
		<a
			className='inline-flex h-auto w-auto items-center rounded-lg bg-orange-500 px-2 py-1 text-white shadow-md'
			href={`https://www.instagram.com/p/${content.shortcode}/`}
			target='_blank'
			rel='noreferrer'
		>
			<IconBrandInstagram className='mr-1' />
			Show On Instagram
		</a>
	)
}

const TiktokFooter = () => {
	const { content } = useContentCardContext()
	if (content.content_type !== 'tt_post') return null
	return (
		<a
			className='inline-flex h-auto w-auto items-center rounded-lg bg-gray-700 px-2 py-1 text-white shadow-md hover:text-white'
			href={`https://www.tiktok.com/@${content.username}/video/${content.id}?is_copy_url=1&is_from_webapp=v1`}
			target='_blank'
			rel='noreferrer'
		>
			<IconBrandTiktok className='mr-1' />
			Show On Tiktok
		</a>
	)
}
Footer.Youtube = YoutubeFooter
Footer.Instagram = InstagramFooter
Footer.Tiktok = TiktokFooter

const ContentID = () => {
	const { content } = useContentCardContext()
	const { user_type } = usePermissions()
	if (user_type !== 'admin') return null
	return (
		<div className='mb-2 inline-flex w-full items-center justify-between rounded-lg bg-white px-3 py-2 font-semibold text-gray-600 shadow-md'>
			<p>{`Content _id: ${content._id}`}</p>
			<div
				onClick={() => {
					navigator.clipboard.writeText(content._id)
				}}
				className='flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-white transition-colors hover:bg-gray-200'
			>
				<IconCopy size={16} />
			</div>
		</div>
	)
}

const Video = () => {
	const { content } = useContentCardContext()
	return (
		<video
			preload='none'
			src={content.bucket_content_url}
			poster={content.bucket_thumbnail_url}
			autoPlay={false}
			controls
			className='rounded-lg shadow-md'
		/>
	)
}

const ContentImage = () => {
	const { content } = useContentCardContext()
	return (
		<img
			src={`${
				content.bucket_content_url || content.bucket_thumbnail_url || content.thumbnail_url || '/images/placeholder.png'
			}`}
			alt=''
			className='rounded-lg shadow-md'
		/>
	)
}

const StatItem = ({ title, value, Icon }: { title: string; value: null | number; Icon: React.ReactNode }) => {
	return (
		<div className={' flex h-full w-auto flex-col items-center justify-center'}>
			<h3 className='text-lg font-semibold'>{value ? tr_thousandmillion_raw(value) : '-'}</h3>
			<p className='inline-flex items-center text-xs'>
				{Icon}
				{title}
			</p>
		</div>
	)
}
