import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs, { Dayjs } from 'dayjs'
import _ from 'lodash'
import { MonitorLabelTags, MonitorReport } from 'types/Brand'
export const monitorActions = {
	BRAND_MONITORS: 'BRAND_MONITORS',
	BRAND_BASIC_MONITOR: 'BRAND_BASIC_MONITOR',
	SET_SELECTED_MONITOR: 'SET_SELECTED_MONITOR',
	OPEN_MONITOR_MODAL: 'OPEN_MONITOR_MODAL',
	CLOSE_MONITOR_MODAL: 'CLOSE_MONITOR_MODAL',
	UPDATE_MONITOR_NAME: 'UPDATE_MONITOR_NAME',
	CHANGE_SELECTED_LABEL: 'CHANGE_SELECTED_LABEL',
	CREATE_NEW_LABEL: 'CREATE_NEW_LABEL',
	REMOVE_LABEL: 'REMOVE_LABEL',
}
interface Label {
	_id?: string
	name: string
	tags: MonitorLabelTags[]
	greentags: string[]
	blacktags: string[]
}
type DateFilterType = 'this_month' | 'last_month' | 'this_year' | 'last_six_month' | 'all_time' | 'custom'
interface State {
	count: number
	current_monitor: null | string

	last_updated: number
	selected_monitor?: string | null
	basic_last_updated: number
	modal: {
		_id: null | string
		editing: boolean
		name: string
		labels: Label[]
		selectedLabel: string
	}
	reports: Record<string, MonitorReport>
	filters: {
		selectedInfluencers: string[]
		selectedLabels: string[]
		showMore: boolean
		caption: string
		selected_share_of_voice: string[]
		content_type_filter: ('ig_post' | 'ig_story')[]
		date_filter: {
			start: Dayjs | null
			end: Dayjs
			type: DateFilterType
		}
	}
	compare: boolean
	authenticated: boolean
	monitors_loading: boolean
	monitor_loading: boolean
	monitor_rerunning: boolean
	monitor_deleting: boolean
	contentCountLimit: number
}
const initialState = {
	data: {},
	card_data: {},
	current_monitor: null,
	ig_metas: {},
	ig_metas_by_id: {},
	ig_posts: {},
	ig_stories: {},
	yt_posts: {},
	tt_posts: {},
	count: 0,
	last_updated: 0,
	selected_monitor: null,
	basic_last_updated: 0,
	modal: {
		_id: null,
		editing: false,
		name: '',
		labels: [],
		selectedLabel: '',
	},
	reports: {},
	filters: {
		selectedInfluencers: [],
		selectedLabels: [],
		showMore: false,
		caption: '',
		selected_share_of_voice: [],
		content_type_filter: ['ig_post', 'ig_story'],
		date_filter: { start: dayjs().subtract(6, 'months'), end: dayjs(), type: 'last_six_month' },
	},
	compare: false,
	authenticated: false,
	monitors_loading: false,
	monitor_loading: false,
	monitor_rerunning: false,
	monitor_deleting: false,
	contentCountLimit: 10000,
} as State

const monitor_reducer = createSlice({
	name: 'monitor',
	initialState,
	reducers: {
		get_report_from_socket: (state, action: PayloadAction<{ _id: string; report: any }>) => {
			state.reports[action.payload._id] = action.payload.report
		},

		reset_filters: (state) => {
			state.filters = initialState.filters
		},
		monitor_filter_selected_influencer: (state, action: PayloadAction<string>) => {
			state.filters.selectedInfluencers = _.xor(state.filters.selectedInfluencers, [action.payload])
		},
		monitor_filter_selected_influencer_zero: (state, action: PayloadAction<string[]>) => {
			state.filters.selectedInfluencers = [...action.payload]
		},
		monitor_filter_reset_selected_influencer: (state) => {
			state.filters.selectedInfluencers = []
		},
		monitor_filter_selected_label: (state, action: PayloadAction<string>) => {
			state.filters.selectedLabels = _.xor(state.filters.selectedLabels, [action.payload])
		},
		monitor_filter_reset_selected_label: (state) => {
			state.filters.selectedLabels = []
		},
		monitor_filter_selected_share_of_voice: (state, action: PayloadAction<string>) => {
			state.filters.selected_share_of_voice = _.xor(state.filters.selected_share_of_voice, [action.payload])
		},
		monitor_filter_reset_selected_share_of_voice: (state) => {
			state.filters.selected_share_of_voice = []
		},
		monitor_filter_content_type_filter: (state, action: PayloadAction<'ig_post' | 'ig_story'>) => {
			state.filters.content_type_filter = _.xor(state.filters.content_type_filter, [action.payload])
		},
		monitor_filter_change_caption: (state, action: PayloadAction<string>) => {
			state.filters.caption = action.payload
		},
		monitor_filter_reset_caption: (state) => {
			state.filters.caption = ''
		},
		monitor_show_more: (state, action: PayloadAction<boolean>) => {
			state.filters.showMore = action.payload
		},
		monitor_filter_change_start_date: (state, action: PayloadAction<Dayjs | null>) => {
			state.filters.date_filter.start = action.payload
		},
		monitor_filter_change_end_date: (state, action: PayloadAction<Dayjs>) => {
			state.filters.date_filter.end = action.payload
		},
		compareModal: (state, action: PayloadAction<boolean>) => {
			state.compare = action.payload
		},
		update_monitors_loading: (state, action: PayloadAction<boolean>) => {
			state.monitors_loading = action.payload
		},
		update_monitor_loading: (state, action: PayloadAction<boolean>) => {
			state.monitor_loading = action.payload
		},
		update_monitor_date_filter_type: (state, action: PayloadAction<DateFilterType>) => {
			state.filters.date_filter.type = action.payload
		},
		update_monitor_rerun_loading: (state, action: PayloadAction<boolean>) => {
			state.monitor_rerunning = action.payload
		},
		update_monitor_delete_loading: (state, action: PayloadAction<boolean>) => {
			state.monitor_deleting = action.payload
		},
		update_authenticated: (state, action: PayloadAction<boolean>) => {
			state.authenticated = action.payload
		},
		change_current_monitor: (state, action: PayloadAction<string>) => {
			state.current_monitor = action.payload
		},
		change_content_count_limit: (state, action: PayloadAction<number>) => {
			state.contentCountLimit = action.payload
		},
	},
})

export default monitor_reducer.reducer
export const {
	get_report_from_socket,
	change_content_count_limit,
	reset_filters,
	monitor_filter_selected_influencer,
	monitor_filter_reset_selected_influencer,
	monitor_filter_selected_label,
	monitor_filter_reset_selected_label,
	monitor_filter_selected_share_of_voice,
	monitor_filter_reset_selected_share_of_voice,
	monitor_filter_content_type_filter,
	monitor_filter_change_caption,
	monitor_filter_reset_caption,
	monitor_show_more,
	monitor_filter_change_start_date,
	monitor_filter_change_end_date,
	compareModal,
	update_monitors_loading,
	update_monitor_rerun_loading,
	update_monitor_delete_loading,
	update_authenticated,
	change_current_monitor,
	update_monitor_loading,
	update_monitor_date_filter_type,
	monitor_filter_selected_influencer_zero,
} = monitor_reducer.actions
