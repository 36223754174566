import { IconEyeOff } from '@tabler/icons'
import { LoadingSpinner } from 'components/LoadingSpinner'

export const Card = ({
	children,
	title,
	loading = false,
	recalculating = false,
	noData = false,
	className = '',
	info = null,
	contentBackground = true,
	rightComponent = null,
	noSetHeight = false,
}: {
	children: React.ReactNode
	title: React.ReactNode
	loading?: boolean
	recalculating?: boolean
	noData?: boolean
	className?: string
	info?: React.ReactNode
	contentBackground?: boolean
	rightComponent?: React.ReactNode
	noSetHeight?: boolean
}) => {
	return (
		<div className={`${className} flex flex-col overflow-hidden rounded-lg border border-slate-100 bg-white shadow-md`}>
			<div className='flex flex-row justify-between gap-2 border-b border-slate-100 bg-slate-100 p-2'>
				{title}
				<Spacer />
				{recalculating ? (
					<div className='inline-flex items-center justify-center gap-1'>
						<LoadingSpinner className='h-3 w-3' />
						<p className='text-xs font-medium'>Recalculating</p>
					</div>
				) : null}
				{info}
				{rightComponent}
			</div>
			<div className='h-full w-full p-2'>
				{loading && (
					<div className='flex h-72 w-full flex-col items-center justify-center gap-2 rounded-lg bg-gray-200 p-2'>
						<LoadingSpinner className='h-8 w-8' />
						<p className='text-tiny font-semibold'>Loading</p>
					</div>
				)}
				{noData && !loading && (
					<div className='flex h-72 flex-col items-center justify-center gap-3 rounded-lg bg-gray-200 p-2'>
						<IconEyeOff size={32} />
						<p className='font-semibold'>No Data</p>
					</div>
				)}
				{!loading && !noData && (
					<div
						className={`${noSetHeight ? '' : 'max-h-72'} w-full overflow-hidden ${
							contentBackground ? 'rounded-md bg-gray-200 p-2' : ''
						}`}
					>
						{children}
					</div>
				)}
			</div>
		</div>
	)
}

const Title = ({ children, icon }: { children: string; icon?: React.ReactNode }) => {
	return (
		<div className='line-clamp-1 inline-flex items-center text-tiny font-semibold'>
			{icon && <span className='mr-1'>{icon}</span>}
			{children}
		</div>
	)
}

const Spacer = () => {
	return <div className='h-auto flex-shrink flex-grow' />
}
const Body = ({ children }) => {
	return <div>{children}</div>
}

Card.Title = Title
Card.Spacer = Spacer
Card.Body = Body
