import { UseMutationOptions, useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError, HttpStatusCode } from 'axios'
import { useParams } from 'react-router-dom'
import { apiConfig } from './api'
import { useToast } from 'hooks/useToast'

type Plan = {
	_id: string
	brand_id: string
	status: 'active' | 'expired' | 'inactive'
	start_date: string
	end_date: string
	credits_monitor: number
	credits_influencer: number
	credits_campaign: number
	credits_list: number
	createdAt: string
	updatedAt: string
	__v: number
}
const usePlan = () => {
	const { brand_id } = useParams<{ brand_id: string }>()
	return useQuery({
		queryKey: ['BRAND', 'PLAN', brand_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<FetchContainer<Plan>>(`brand_api/${brand_id}/account/plan`)
			return data?.data
		},
	})
}

const useCredits = () => {
	const { brand_id } = useParams<{ brand_id: string }>()
	return useQuery({
		queryKey: ['BRAND', 'CREDITS', brand_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<any>(`brand_api/${brand_id}/account/credits`)
			return data
		},
	})
}
const useActivities = (page: number) => {
	const { brand_id } = useParams<{ brand_id: string }>()
	return useQuery({
		queryKey: ['BRAND', 'ACTIVITIES', brand_id, page],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<FetchContainer<any>>(`brand_api/${brand_id}/account/activity/list/${page}`)
			return data?.data
		},
	})
}
interface FetchContainer<T> {
	data: T
	message: 'ok' | string
	status: HttpStatusCode
}
const usePlanAdmin = () => {
	const { brand_id } = useParams<{ brand_id: string }>()
	return useQuery({
		queryKey: ['BRAND', 'PLAN', 'ADMIN', brand_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<FetchContainer<null | unknown>>(`admin_api/brand/${brand_id}/plan`)
			return data?.data
		},
	})
}
export type CreatePlan = {
	status: 'active' | 'expired' | 'inactive'
	start_date: Date // default: moment().toDate()
	end_date: Date
	credits_monitor: number // default: 0
	credits_influencer: number // default: 0
	credits_campaign: number // default: 0
	credits_list: number // default: 0
}
type CreatePlanMutation = UseMutationOptions<any, any, CreatePlan>
const useCreatePlanAdmin = (options?: CreatePlanMutation) => {
	const { brand_id } = useParams<{ brand_id: string }>()
	return useMutation({
		mutationFn: async (body: CreatePlan) => {
			const { data } = await apiConfig.post<any>(`admin_api/brand/${brand_id}/plan`, body)
			return data
		},
		...options,
	})
}
type AdminCredits = {
	_id: string
	brand_id: string
	__v: number
	createdAt: string
	credist_list: number
	credits_campaign: number
	credits_influencer: number
	credits_monitor: number
	updatedAt: string
	credits_list: number
}
const useCreditsAdmin = () => {
	const { brand_id } = useParams<{ brand_id: string }>()
	return useQuery({
		queryKey: ['BRAND', 'CREDITS', 'ADMIN', brand_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<FetchContainer<AdminCredits>>(`admin_api/brand/${brand_id}/credits`)
			return data?.data
		},
	})
}

export const AddCreditsCreditType = ['list', 'monitor', 'campaign', 'influencer'] as const
export type AddCredits = {
	credit_type_name: undefined | typeof AddCreditsCreditType[number]
	// one of: ["]
	amount: number // default: 0,
	credit_duration: number // default: 30 days after consumption
	credit_expiry_date: Date | null // default: null // when to expire until consumed
}

type AddCreditMutation = UseMutationOptions<FetchContainer<Credits>, AxiosError, AddCredits>
const useAddCredits = (options?: AddCreditMutation) => {
	const { brand_id } = useParams<{ brand_id: string }>()
	return useMutation({
		mutationFn: async (body) => {
			const { data } = await apiConfig.post<FetchContainer<Credits>>(`admin_api/brand/${brand_id}/credits`, body)
			return data
		},
		...options,
	})
}
type ActivityName =
	| 'monitor-label-buy'
	| 'list-add-credits'
	| 'campaign-add-credits'
	| 'influencer-card-add-credits'
	| 'monitor-add-credits'
type Status = 'pending' | 'expired' | 'used' | 'unused' | 'success' | 'failed'
type CreditType = 'list' | 'monitor' | 'campaign' | 'influencer'
export interface Credits {
	_id: string
	brand_id: string
	activity_name: ActivityName
	target_id?: string
	credit_ids: string[]
	has_credit_change: boolean
	changed_key: string
	previous_value: null | boolean
	next_value: null | boolean
	status: Status
	reason: string
	createdAt: string
	updatedAt: string
	__v: number
	user: any
	credits: Credit[]
}

export interface Credit {
	_id: string
	brand_id: string
	credit_type: CreditType
	duration: number
	has_expiry: boolean
	expiry: any
	is_used: boolean
	used_for: string
	used_at?: string
	used_expiry?: string
	status: Status
	__v: number
	createdAt: string
	updatedAt: string
}
const useListActivities = (page: number) => {
	const { brand_id } = useParams<{ brand_id: string }>()
	return useQuery({
		queryKey: ['BRAND', 'ACTIVITIES', 'ADMIN', brand_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<FetchContainer<Credits[]>>(`admin_api/brand/${brand_id}/activity/list/${page}`)
			return data?.data
		},
		initialData: [],
		initialDataUpdatedAt: 0,
	})
}

const useCancelActivity = () => {
	const { brand_id } = useParams<{ brand_id: string }>()
	const activities = useListActivities(0)
	const { toast } = useToast()
	return useMutation({
		mutationFn: async (activity_id: string) => {
			const { data } = await apiConfig.post<FetchContainer<unknown>>(
				`admin_api/brand/${brand_id}/activity/${activity_id}/cancel`,
				{}
			)
			return data
		},
		onSuccess: () => {
			activities.refetch()
		},
		onError: (error: AxiosError) => {
			toast({
				title: 'An error occurred whilst cancelling activity',
				description: error.message,
				variant: 'destructive',
				duration: 5000,
			})
		},
	})
}

export const monetization = {
	usePlan,
	useCredits,
	useActivities,
	admin: {
		usePlan: usePlanAdmin,
		useCreatePlan: useCreatePlanAdmin,
		useCredits: useCreditsAdmin,
		useAddCredits,
		useListActivities,
		useCancelActivity,
	},
}
