import axios from 'axios'
import _ from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { notification } from 'xui'
import { update_loadable } from './loading.saga'

const { endpoint } = window

const api = {
	fetch_api: async ({ api_path }) => {
		return fetch(`${endpoint}${api_path}`, window.getHeaders())
			.then((r) => r.json())
			.then((r) => (r ? r || null : null))
	},
	delete_api: async ({ api_path, body }) => {
		const data = await fetch(`${endpoint}${api_path}`, { body: body, credentials: 'include', method: 'DELETE' })
		return data
	},
	post_api: async (payload) => {
		const { api_path, body } = payload
		return fetch(`${endpoint}${api_path}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify(body),
		})
			.then((r) => r.json())
			.then((r) => (r && r.data ? r.data : null))
	},
	post_api_axios_ids: async ({ api_path, body }) => {
		const data = await axios.post(`${endpoint}${api_path}`, body.ids, window.getHeaders())
		return data
	},
	post_api_axios: async ({ api_path, body }) => {
		const data = await axios.post(`${endpoint}${api_path}`, body, window.getHeaders())
		return data
	},
	fetch_api_coded: async ({ api_path }) => {
		return fetch(`${endpoint}${api_path}`, window.getHeaders()).then((r) => ({ status: r.status, data: r.body }))
	},
	fetch_api_axios: async ({ api_path }) => await axios.get(`${endpoint}${api_path}`, window.getHeaders()),
}

function* get_brand_project_request({ payload: { brand_id, project_id } }) {
	try {
		const data = yield call(api.fetch_api, { api_path: `/brand_api/${brand_id}/projects/${project_id}` })
		yield put({
			type: 'PROJECT',
			payload: {
				data: data.data,
				project_id,
			},
		})
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'PROJECT_FAIL' })
		//yield put(push(`/brand/${brand_id}/projects`));
	}
}
export function* get_brand_project() {
	yield takeLatest('FETCH_PROJECT', get_brand_project_request)
}
function* get_brand_projects_request({ payload: { brand_id, project_id } }) {
	try {
		const data = yield call(api.fetch_api, { api_path: `/brand_api/${brand_id}/projects` })
		const projects = _(data.data)
			.map((d) => d.project)
			.keyBy('_id')
			.value()
		const applicants = _(data.data)
			.map((d) => ({ key: d.project._id, value: d.applications }))
			.keyBy('key')
			.mapValues('value')
			.value()
		const seen_count = _(data.data)
			.map((d) => ({ key: d.project._id, value: d.seen_count }))
			.keyBy('key')
			.mapValues('value')
			.value()
		yield put({
			type: 'PROJECTS',
			payload: {
				projects,
				applications: applicants,
				seen_count,
			},
		})
		if (project_id) yield get_brand_project_request({ payload: { brand_id, project_id } })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'PROJECTS_FAIL' })
	}
}
export function* get_brand_projects() {
	yield takeLatest('FETCH_PROJECTS', get_brand_projects_request)
}

function* post_copy_brand_project_request({ payload: { brand_id, title, project } }) {
	try {
		const data = yield call(api.post_api, {
			api_path: `/brand_api/${brand_id}/projects`,
			body: {
				title,
				status: 'draft',
				start_date: new Date(Date.now() + 6.048e8).toISOString(),
				end_date: new Date(Date.now() + 6.048e8 * 2).toISOString(),
			},
		})

		yield post_update_brand_project_request({
			payload: { project: { ...project, _id: data._id, title }, brand_id, project_id: data._id },
		})
		notification.success({ message: 'Project Copied', description: 'Project copied successfully' })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'PROJECTS_FAIL' })
	}
}
export function* post_copy_brand_project() {
	yield takeLatest('POST_COPY_PROJECT', post_copy_brand_project_request)
}

function* post_create_brand_project_request({ payload: { brand_id, title } }) {
	try {
		yield update_loadable('project_create_modal', 'loading')
		const data = yield call(api.post_api, {
			api_path: `/brand_api/${brand_id}/projects`,
			body: {
				title,
				status: 'draft',
				start_date: new Date(Date.now() + 6.048e8).toISOString(),
				end_date: new Date(Date.now() + 6.048e8 * 2).toISOString(),
			},
		})
		yield get_brand_projects_request({ payload: { brand_id } })
		yield update_loadable('project_create_modal', 'closed')
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'PROJECTS_FAIL' })
	}
}
export function* post_create_brand_project() {
	yield takeLatest('POST_CREATE_PROJECT', post_create_brand_project_request)
}
function* post_update_brand_project_request({ payload: { brand_id, project_id, project } }) {
	try {
		yield update_loadable('update_brand_project', 'loading')
		yield call(api.post_api, { api_path: `/brand_api/${brand_id}/projects/${project_id}`, body: project })
		yield get_brand_projects_request({ payload: { brand_id, project_id } })
		yield update_loadable('update_brand_project', 'closed')
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'PROJECTS_FAIL' })
	}
}
export function* post_update_brand_project() {
	yield takeLatest('POST_UPDATE_PROJECT', post_update_brand_project_request)
}

function* delete_brand_project_request({ payload: { brand_id, project_id } }) {
	try {
		yield update_loadable('project_delete_modal', 'loading')
		yield call(api.delete_api, { api_path: `/brand_api/${brand_id}/projects/${project_id}` })
		yield get_brand_projects_request({ payload: { brand_id } })
		//yield put(push(`/brand/${brand_id}/projects`));
		yield update_loadable('project_delete_modal', 'closed')
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'PROJECTS_FAIL' })
	}
}
export function* delete_brand_project() {
	yield takeLatest('DELETE_PROJECT', delete_brand_project_request)
}

function* post_publish_brand_project_request({ payload: { brand_id, project_id } }) {
	try {
		yield update_loadable('publish_brand_project', 'loading')
		yield call(api.post_api, { api_path: `/brand_api/${brand_id}/projects/${project_id}/publish`, body: {} })
		yield get_brand_projects_request({ payload: { brand_id, project_id } })
		yield update_loadable('publish_brand_project', 'other')
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'PROJECTS_PUBLISH_ERROR' })
	}
}
export function* post_publish_brand_project() {
	yield takeLatest('POST_PUBLISH_PROJECT', post_publish_brand_project_request)
}

function* post_unpublish_brand_project_request({ payload: { brand_id, project_id } }) {
	try {
		yield update_loadable('project_unpublish_modal', 'loading')
		yield call(api.post_api, { api_path: `/brand_api/${brand_id}/projects/${project_id}/unpublish`, body: {} })
		yield get_brand_projects_request({ payload: { brand_id, project_id } })
		yield update_loadable('project_unpublish_modal', 'closed')
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'POST_UNPUBLISH_PROJECT_ERROR' })
	}
}
export function* post_unpublish_brand_project() {
	yield takeLatest('POST_UNPUBLISH_PROJECT', post_unpublish_brand_project_request)
}

function* post_upload_brand_project_image_request({ payload: { brand_id, project_id, file } }) {
	try {
		const f = new FormData()
		f.append('file', file)
		f.append('id', project_id)
		f.append('force', true)
		yield call(api.post_api_axios, { api_path: `/brand_api/${brand_id}/projects/${project_id}/picture`, body: f })
		yield get_brand_projects_request({ payload: { brand_id, project_id } })
	} catch (e) {
		console.error('error', e)
		yield put({ type: 'PROJECTS_PUBLISH_ERROR' })
	}
}
export function* post_upload_brand_project_image() {
	yield takeLatest('POST_UPLOAD_IMAGE', post_upload_brand_project_image_request)
}
