import { combineReducers } from '@reduxjs/toolkit'
import $XTemplate from './InfluencerX/XTemplate.reducer'
import $campaign from './campaign.reducer'
import $campaign_di from './campaign_data_input.reducer'
import $community from './community.reducer'
import $hub_settings from './hub_settings.reducer'
import $loading from './loading.reducer'
import $monitor from './monitor.reducer'
import $monitor_modal from './monitor_modal.reducer'
import $profile from './profile.reducer'
import $projects from './projects.reducer'
import $search from './search.reducer'
const reducers = combineReducers({
	$search,
	$hub_settings,
	$XTemplate,
	$campaign,
	$campaign_di,
	$profile,
	$loading,
	$monitor,
	$monitor_modal,
	$community,
	$projects,
})

export default reducers

export type AppState = ReturnType<typeof reducers>
