import dayjs from 'dayjs';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { Dispatch } from "redux";
import { IGMetas, IGPostsMonitor, IGStoriesMonitor, Monitor, MonitorCardData, MonitorLabels, MonitorLabelTags, MonitorReport } from "types/Brand";

interface State {
  open: boolean,
  name: string,
  page: number,
  labelName: string,
  type: "Create" | "Edit",
  labels: Record<string, Record<string, MonitorLabelTags>>,
  selectedLabel: string,
  include_usernames: string[],
  exclude_usernames: string[],
  _id: string | null,
  admin: {
    tracking_groups: ("A" | "B" | "C" | "D" | "E" | "X")[]
    content_time_limit: number,
    start_date: number,
    end_date: number,
    brand_id: null | string
  },
  createTag: {
    keyword_operator: 'and' | 'or',
    keywords: string[],
    name: string
    tag_type: "include" | "exclude"
    id: string,
    content_types: ("ig_post" | "ig_story")[],
    addable: string
  },
  updateTag: {
    open: boolean,
    keyword_operator: 'and' | 'or',
    keywords: string[],
    name: string
    tag_type: "include" | "exclude"
    id: string,
    content_types: ("ig_post" | "ig_story")[],
    addable: string
  }
  loading: boolean
}
export const monitorModalInitialState = {
  open: false,
  type: "Create",
  name: "",
  _id: null,
  labelName: "",
  page: 0,
  labels: {

  },
  selectedLabel: "",
  include_usernames: [],
  exclude_usernames: [],
  admin: {
    tracking_groups: ["A", "B", "C", "D", "E", "X"],
    content_time_limit: 30,
    start_date: dayjs().subtract(3, "year").unix() * 1000,
    end_date: Date.now(),
    brand_id: null
  },
  createTag: {
    keyword_operator: 'or',
    keywords: [],
    name: "",
    tag_type: "include",
    id: "",
    content_types: ["ig_post", "ig_story"],
    addable: ''
  },
  updateTag: {
    open: false,
    keyword_operator: 'or',
    keywords: [],
    name: "",
    tag_type: "include",
    id: "",
    content_types: ["ig_post", "ig_story"],
    addable: ''
  },
  loading: false
} as State;

const monitor_modal = createSlice({
  name: "monitorModal",
  initialState: monitorModalInitialState,
  reducers: {
    openModal: (state, action: PayloadAction<{
      type: "Create" | "Edit",
      details?: {
        labels: Record<string, Record<number, MonitorLabelTags>>,
        selectedLabel: string,
        include_usernames: string[],
        exclude_usernames: string[],
        name: string,
        _id: string | null,
      },
      admin?: {
        tracking_groups: ("A" | "B" | "C" | "D" | "E" | "X")[]
        content_time_limit: number,
        start_date: number,
        end_date: number,
        brand_id: null | string
      }
    }>) => {
      return {
        ...state,
        open: true,
        type: action.payload.type,
        ...(action?.payload?.details || {}),
        admin: { ...state.admin, ...(action?.payload?.admin || {}) }
      };
    },
    closeModal: (state) => {
      return monitorModalInitialState
    },
    updateMonitorName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    changeLabelName: (state, action: PayloadAction<string>) => {
      state.labelName = action.payload
    },
    addNewLabel: (state) => {
      state.labels = { ...state.labels, [state.labelName]: {} };
      state.selectedLabel = state.labelName;
      state.labelName = "";
    },
    removeLabel: (state, action: PayloadAction<string>) => {
      state.selectedLabel = '';
      delete state.labels[action.payload];
    },
    selectLabel: (state, action: PayloadAction<string>) => {
      state.selectedLabel = action.payload;
    },
    createTagName: (state, action: PayloadAction<string>) => {
      state.createTag.name = action.payload;
    },
    createTagAddKeyword: (state, action: PayloadAction<string>) => {
      state.createTag.keywords.push(action.payload);
    },
    createTagRemoveKeyword: (state, action: PayloadAction<string>) => {
      state.createTag.keywords = _.without(state.createTag.keywords, action.payload);
    },
    createTagKeywordOperator: (state, action: PayloadAction<'and' | 'or'>) => {
      state.createTag.keyword_operator = action.payload;
    },
    createTagContentType: (state, action: PayloadAction<'ig_post' | 'ig_story'>) => {
      state.createTag.content_types = _.xor(state.createTag.content_types, [action.payload]);
    },
    createTagType: (state, action: PayloadAction<'include' | 'exclude'>) => {
      state.createTag.tag_type = action.payload;
    },
    createTagUpdateAddable: (state, action: PayloadAction<string>) => {
      state.createTag.addable = action.payload;
    },
    createTag: (state) => {
      const id = Date.now()
      state.labels[state.selectedLabel] = { ...state.labels[state.selectedLabel], [id]: { ...state.createTag, id } };
      state.createTag = monitorModalInitialState.createTag
    },
    updateTagName: (state, action: PayloadAction<string>) => {
      state.updateTag.name = action.payload;
    },
    updateTagAddKeyword: (state, action: PayloadAction<string>) => {
      state.updateTag.keywords.push(action.payload);
    },
    updateTagRemoveKeyword: (state, action: PayloadAction<string>) => {
      state.updateTag.keywords = _.without(state.updateTag.keywords, action.payload);
    },
    updateTagKeywordOperator: (state, action: PayloadAction<'and' | 'or'>) => {
      state.updateTag.keyword_operator = action.payload;
    },
    updateTagContentType: (state, action: PayloadAction<'ig_post' | 'ig_story'>) => {
      state.updateTag.content_types = _.xor(state.updateTag.content_types, [action.payload]);
    },
    updateTagType: (state, action: PayloadAction<'include' | 'exclude'>) => {
      state.updateTag.tag_type = action.payload;
    },
    updateTagUpdateAddable: (state, action: PayloadAction<string>) => {
      state.updateTag.addable = action.payload;
    },
    startUpdateTag: (state, action: PayloadAction<string>) => {
      state.updateTag = { ...state.labels[state.selectedLabel][action.payload], addable: '', id: action.payload, open: true };
    },
    cancelUpdateTag: (state) => {
      state.updateTag = monitorModalInitialState.updateTag;
    },
    updateTag: (state) => {
      state.labels[state.selectedLabel][state.updateTag.id] = { ...state.updateTag, id: state.updateTag.id };
      state.updateTag = monitorModalInitialState.updateTag;
    },
    deleteTag: (state) => {
      delete state.labels[state.selectedLabel][state.updateTag.id];
      state.updateTag = monitorModalInitialState.updateTag;
    },
    addIncludedInfluencer: (state, action: PayloadAction<string>) => {
      state.include_usernames.push(action.payload);
    },
    removeIncludedInfluencer: (state, action: PayloadAction<string>) => {
      state.include_usernames = _.without(state.include_usernames, action.payload);
    },
    addExcludedInfluencer: (state, action: PayloadAction<string>) => {
      state.exclude_usernames.push(action.payload);
    },
    removeExcludedInfluencer: (state, action: PayloadAction<string>) => {
      state.exclude_usernames = _.without(state.exclude_usernames, action.payload);
    },
    adminChangeStartDate: (state, action: PayloadAction<number>) => {
      state.admin.start_date = action.payload;
    },
    adminChangeEndDate: (state, action: PayloadAction<number>) => {
      state.admin.end_date = action.payload;
    },
    adminChangeContentTimeLimit: (state, action: PayloadAction<number>) => {
      state.admin.content_time_limit = action.payload;
    },
    adminChangeTrackingGroups: (state, action: PayloadAction<("A" | "B" | "C" | "D" | "E" | "X")>) => {
      state.admin.tracking_groups = _.xor(state.admin.tracking_groups, [action.payload]);
    },
    adminChangeBrand: (state, action: PayloadAction<string>) => {
      state.admin.brand_id = action.payload;
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    updateLoadingState: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  }
})
export const {
  closeModal,
  openModal,
  changeLabelName,
  updateMonitorName,
  addNewLabel,
  removeLabel,
  selectLabel,
  createTagName,
  createTagAddKeyword,
  createTagRemoveKeyword,
  createTagKeywordOperator,
  createTagContentType,
  createTagType,
  createTagUpdateAddable,
  createTag,
  updateTagName,
  updateTagAddKeyword,
  updateTagRemoveKeyword,
  updateTagKeywordOperator,
  updateTagContentType,
  updateTagType,
  updateTagUpdateAddable,
  startUpdateTag,
  cancelUpdateTag,
  updateTag,
  addIncludedInfluencer,
  removeIncludedInfluencer,
  addExcludedInfluencer,
  removeExcludedInfluencer,
  adminChangeStartDate,
  adminChangeEndDate,
  adminChangeContentTimeLimit,
  adminChangeTrackingGroups,
  adminChangeBrand,
  deleteTag,
  changePage,
  updateLoadingState
} = monitor_modal.actions

export default monitor_modal.reducer