import { VariantProps, cva } from 'class-variance-authority'
import { cn } from 'lib/helper'
import * as React from 'react'

const badgeVariants = cva(
	'inline-flex items-center border font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
	{
		variants: {
			variant: {
				background: 'bg-foreground hover:bg-primary/80 border-transparent text-white',
				default: 'bg-primary  border-transparent text-background',
				secondary: 'bg-secondary hover:bg-secondary/80 border-transparent text-secondary-foreground',
				dark: 'bg-stone-600 hover:bg-foreground/80 text-white border-transparent',

				destructive: 'bg-destructive hover:bg-destructive/80 border-transparent text-destructive-foreground',
				outline: 'text-foreground bg-white',
				teal: 'bg-gradient-to-br from-teal-500 to-teal-600 text-white border-teal-600',
			},
			size: {
				small: 'px-1 py-0.5 rounded text-[0.6rem]',
				default: 'px-2.5 py-0.5 rounded-md text-xs',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
)

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> { }

function Badge({ className, variant = 'default', size, ...props }: BadgeProps) {
	return <div className={cn(badgeVariants({ variant, size }), className)} {...props} />
}

export { Badge, badgeVariants }
