import React, { useEffect, useState } from 'react'
import { useEventListener } from './useEventListener';

export const useDevelopmentMode = () => {
  const [developmentMode, setDevelopmentMode] = useState(false)
  const [buttonPresses, setButtonPresses] = useState("")
  function handler({ key }) {
    if ((String(key) === "h")) {
      setButtonPresses(prev => `${prev}h`)
    }
    else {
      setButtonPresses("")
    }
  }
  useEventListener('keydown', handler);
  useEffect(() => {
    if (buttonPresses === "hhhhh") {
      setDevelopmentMode(true)
    }
  }, [buttonPresses])

  return (
    developmentMode
  )
}
