import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useRef } from 'react'

import { Button } from 'ui'
import styles from './XModal.module.css'

export type ModalProps = {
	size?: 'small' | 'medium' | 'large' | 'large-left' | 'full'
	bodyStyle?: object
	cancelText?: string
	footer?: boolean | React.ReactElement
	okText?: string
	okType?: 'primary' | 'default' | 'secondary' | 'dashed' | 'danger' | 'link'
	okButtonProps?: object
	title?: string
	visible: boolean
	onCancel?: any
	onOk?: () => void
	children: React.ReactElement | JSX.Element | any
	zIndex?: number
	id?: string
	customHeader?: React.ReactNode | undefined
	subFooter?: React.ReactNode | undefined
}
const XModal = ({
	size = 'small',
	bodyStyle = {},
	cancelText = 'Cancel',
	footer = true,
	okText = 'OK',
	okType = 'primary',
	okButtonProps = {},
	title = 'Modal',
	visible = false,
	onCancel,
	onOk,
	children,
	zIndex = 0,
	customHeader,
}: ModalProps) => {
	const ref = useRef(null)
	return (
		<Transition appear show={visible} as={Fragment}>
			<Dialog
				as='div'
				className='fixed inset-0 overflow-hidden'
				onClose={onCancel}
				initialFocus={ref}
				style={{ zIndex: zIndex * 10 + 1000 }}
			>
				<input className='invisible fixed top-0 ' ref={ref} style={{ visibility: 'hidden' }} />
				<div className='min-h-screen px-4 text-center'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<Dialog.Overlay className='fixed inset-0 bg-gray-400 bg-opacity-60' />
					</Transition.Child>
					<span className='inline-block h-screen align-middle' aria-hidden='true'>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 scale-95'
						enterTo='opacity-100 scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 scale-100'
						leaveTo='opacity-0 scale-95'
					>
						<div className={`${styles[size]} ${styles.modal} z-1000 p-3 transition-all`}>
							<div className='flex flex-col'>
								{customHeader === undefined ? (
									<div className={styles.title}>
										<span>{title}</span>
										<div
											onClick={onCancel}
											className={`flex h-7 w-7 cursor-pointer items-center justify-center rounded-full transition-colors hover:bg-gray-200`}
										>
											<XMarkIcon className='h-5 w-5 cursor-pointer' />
										</div>
									</div>
								) : (
									customHeader
								)}
								<div id='modal_body' className='flex h-full w-full flex-shrink flex-grow transition-all'>
									<div className='h-full w-full overflow-x-hidden px-4 py-2 ' style={bodyStyle}>
										{children}
									</div>
								</div>
								{footer === true && (
									<div id='modal-footer' className={styles.footer}>
										<Button variant={'danger'} size={'large'} onClick={onCancel}>
											{cancelText}
										</Button>
										<Button variant={okType} size={'large'} onClick={onOk}>
											{okText}
										</Button>
									</div>
								)}
								{footer !== true && footer !== false && (
									<div id='modal-footer' className={styles.footer}>
										{footer}
									</div>
								)}
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	)
}
export { XModal }
