import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { usePostHog } from 'posthog-js/react'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
const { endpoint } = window

type User = {
	_id: string
	active: boolean
	role: 'admin' | 'creatorden' | 'brand' | 'none' | 'identification'
	email: string
	firstName: string
	lastName: string
}
const AuthContext = React.createContext<AuthContextType | null>(null)
type AuthContextType = {
	loading: boolean
	loggedIn: boolean
	role: string
	getUser: () => User | null
	user: User | null
	is_brand: boolean
	is_only_brand: boolean
	is_admin: boolean
	logout: () => void
	login: () => void
	register: () => void
	info: ReactNode
	error: ReactNode
	logging_in: boolean
	registering: boolean
	resetting: boolean
	resetPassword: () => void
	reset_password_request: () => void
	loginProps: {
		email: string
		password: string
		firstName: string
		lastName: string
		setEmail: (email: string) => void
		setPassword: (password: string) => void
		setFirstName: (firstName: string) => void
		setLastName: (lastName: string) => void
	}
}

const useCheckAuth = () => {
	const navigate = useNavigate()
	const posthog = usePostHog()
	return useQuery({
		queryKey: ['HUB', 'AUTH'],
		queryFn: async () => {
			const { data, status } = await axios.get<User>(`${endpoint}/auth/check`, { withCredentials: true })
			if (status !== 200) {
				navigate('/auth/login')
				return null
			}
			window.localStorage.setItem('user', JSON.stringify(data))
			posthog?.identify(data._id, {
				email: data.email,
			})
			return data as User
		},

		staleTime: 1000 * 60 * 60 * 24 * 7,
		gcTime: 1000 * 60 * 60 * 24,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchOnMount: false,
	})
}

export const useAuthProvider = () => {
	const context = useContext(AuthContext)
	if (!context) {
		throw new Error('AuthContext.* component must be rendered as child of AuthContext component')
	}
	return context
}
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const { data, isLoading: loading, refetch } = useCheckAuth()

	const [loggedIn, set_loggedIn] = useState(false)

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [logging_in, set_logging_in] = useState(false)
	const [registering, set_registering] = useState(false)
	const [resetting, setResetting] = useState(false)
	const [error, set_error] = useState('')
	const [info, set_info] = useState<string | React.ReactNode>('')
	const navigate = useNavigate()
	const [search] = useSearchParams()
	const queryClient = useQueryClient()
	const logout = () => {
		axios.get(`${window.endpoint}/auth/logout`, { withCredentials: true }).then(() => {
			window.localStorage.removeItem('user')
			queryClient.invalidateQueries()
			set_info('')
			setEmail('')
			setPassword('')
			navigate('/auth/login')
			refetch()
		})
	}

	const register = async () => {
		if (registering) return

		if (firstName === '' || lastName === '' || password === '') return

		try {
			set_registering(true)
			set_error('')
			set_info('')
			const s = await axios.post(`${endpoint}/auth/register`, { email, password, firstName, lastName })

			if (s && s.data) {
				if (s.data.status === 200) {
					set_registering(false)
					set_info('Registration successful. Please confirm your email.')
				} else {
					throw s.data.message
				}
			} else {
				throw 'Unknown Error'
			}
		} catch (register_error) {
			if (register_error.response && [400, 401].indexOf(register_error.response.status) > -1) {
				set_error('Registration is not allowed. You need to have @creatorden email address.')
			} else {
				set_error('Are you already registered?')
			}
		} finally {
			set_registering(false)
		}
	}

	const login = async () => {
		if (logging_in) return
		set_error('')
		set_info('')
		set_logging_in(true)
		try {
			const s = await axios.post(
				`${endpoint}/auth/login`,
				{ email: email.trim(), password: password },
				{ withCredentials: true }
			)
			if (s) {
				const { user } = s.data
				localStorage.setItem('user', JSON.stringify(user))
				set_info('You have successfully logged in.')
				set_loggedIn(true)
				refetch()
				navigate('/')
			} else {
				throw 'error'
			}
		} catch (login_error) {
			if (login_error.response && [400, 401].indexOf(login_error.response?.status) > -1) {
				set_error('Username or Password may not be correct.')
			} else if (login_error.response?.status === 429) {
				set_error('Please wait a few seconds before retrying.')
			} else {
				set_error('Connection is not stable. Check your network or contact us.')
			}
		} finally {
			set_logging_in(false)
		}
	}

	const resetPassword = async () => {
		try {
			if (resetting) return
			setResetting(true)
			try {
				const s = await axios.post(`${endpoint}/auth/send_reset_link`, { email })
				if (s && s.data.code === 200) {
					set_info('Your link has been sent. Please check your email.')
				} else {
					throw 'error'
				}
			} catch (login_error) {
				set_error('There was a problem. If the problem persists, contact x@creatorden.com')
			} finally {
				setResetting(false)
			}
		} catch (error) {
			set_error('There was a problem. If the problem persists, contact us.')
		}
		// resetting
	}

	const reset_password_request = async () => {
		try {
			if (resetting) return
			setResetting(true)
			try {
				const s = await axios.post(`${endpoint}/auth/reset_password`, { email, key: search.get('reset_token'), password })
				if (s && s.data.code === 200) {
					set_info('Your password has been reset. You can login now.')
					navigate('/auth/login')
				} else {
					throw 'error'
				}
			} catch (login_error) {
				set_error('There was a problem. If the problem persists, contact x@creatorden.com')
			} finally {
				setResetting(false)
			}
		} catch (error) {
			set_error('There was a problem. If the problem persists, contact us.')
		}
	}

	useEffect(() => {
		if (search.get('reset_token')) {
			set_info(
				<>
					Type your <b>email</b> and <b>new password</b> to reset your password
				</>
			)
		} else {
			set_info('')
		}
	}, [search.get('reset_token')])

	const value = {
		loading,
		loggedIn: data !== undefined,
		getUser: () => data,
		role: data?.role,
		logout,
		login,
		user: data,
		is_brand: data?.role === 'brand' || data?.role === 'admin',
		is_admin: data?.role === 'admin',
		is_only_brand: data?.role === 'brand' || data?.role === 'none',
		loginProps: {
			email,
			password,
			firstName,
			lastName,
			setEmail,
			setPassword,
			setFirstName,
			setLastName,
		},
		register,
		info,
		error,
		logging_in,
		registering,
		resetting,
		resetPassword,
		reset_password_request,
	}
	return (
		<AuthContext.Provider value={value}>
			{loading ? (
				<div className='flex h-screen w-full flex-col items-center justify-center'>
					<LoadingSpinner className='h-10 w-10' />
				</div>
			) : (
				children
			)}
		</AuthContext.Provider>
	)
}
