
import { applyMiddleware, compose, createStore, configureStore as reduxConfigureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import reducers from "./reducers";
import sagas from "../sagas";


export default function configureStore(preloadedState: any) {
	const sagaMiddleware = createSagaMiddleware();
	const store = reduxConfigureStore({
		preloadedState,
		reducer: reducers,
		middleware: [thunk, sagaMiddleware]
	})
	// createStore(
	// 	createRootReducer(), // root reducer with router state
	// 	preloadedState,
	// 	compose(
	// 		applyMiddleware(
	// 			thunk,
	// 			sagaMiddleware,
	// 		),
	// 	),
	// );

	sagaMiddleware.run(sagas);

	window.store = store;
	return store;
}
const store = reduxConfigureStore({
	reducer: reducers,
})

export type AppDispatch = typeof store.dispatch