import { useMutation, useQuery } from '@tanstack/react-query'
import { apiConfig } from './api'
import { defaults } from 'lodash'
import { useToast } from 'hooks/useToast'

type Profile = {
  _id: string
  active: boolean
  role: string
  email: string
  firstName: string
  lastName: string
  opt_in_daily_digest: boolean
  avatar: string
  company: string
  ig: string
  yt: string
  tt: string
  position: string
  phone: string
  username: string
}
type AxiosRespose<T> = {
  data: T
  status: number
  message: string
}
const useGetProfile = () => {
  return useQuery({
    queryKey: ['PROFILE'],
    queryFn: async () => {
      const { data } = await apiConfig.fetch<AxiosRespose<Profile>>('api/me')
      return data?.data
    },
    staleTime: 1000 * 60 * 60 * 24,
  })
}

const useUpdateProfilePhoto = () => {
  const { toast } = useToast()
  const profile = useGetProfile()
  return useMutation({
    mutationKey: ['PROFILE', 'PHOTO'],
    mutationFn: async (data: { photo: File }) => {
      const f = new FormData()
      f.append('file', data.photo)
      f.append('force', 'true')

      const response = await apiConfig.post('api/me/avatar', f)
    },
    onSuccess: () => {
      profile.refetch()
      toast({
        title: 'Profile photo updated',
      })
    },
    onError: (e) => {
      toast({
        title: 'Failed to update profile photo',
        variant: 'destructive',
      })
    },
  })
}

const useUpdateOptDailyDigest = () => {
  const profile = useGetProfile()
  return useMutation({
    mutationKey: ['PROFILE', 'OPT_DAILY_DIGEST'],
    mutationFn: async () => {
      const response = await apiConfig.patch('api/me/opt_in_daily_digest', {})
    },
    onSuccess: () => {
      profile.refetch()
    },
  })
}

export default {
  useGetProfile,
  useUpdateProfilePhoto,
  useUpdateOptDailyDigest,
}
