import React from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import { NavigationBar } from './NavigationBar'

export const SiteLayout = () => {
    return (
        <div className='flex flex-col w-full h-auto'>
            <NavigationBar />
            <Outlet />
        </div>
    )
}
