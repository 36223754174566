import { IconChevronLeft } from '@tabler/icons'
import useAnalytics from 'hooks/useAnalytics'
import { createContext, ReactNode, useContext, useState } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'

export const Sidebar = ({
	header,
	children,
	footer,
}: {
	header?: ReactNode
	children: ReactNode
	footer?: ReactNode
}) => {
	const [collapsed, setCollapsed] = useState(false)
	return (
		<SidebarContext.Provider
			value={{
				collapsed,
				toggleCollapse: () => setCollapsed((prev) => !prev),
			}}
		>
			<div
				className={`h-sidebar-footer ${
					!collapsed ? 'w-[270px]' : 'w-[62px]'
				} sticky top-16 flex flex-shrink-0 flex-col gap-2 rounded-lg border border-slate-100 bg-white p-2 shadow-md transition-all duration-500`}
			>
				{header && <div className='rounded-md bg-slate-100 p-1'>{header}</div>}
				<div className='flex flex-1 flex-col gap-2'>{children}</div>
				{footer && <div>{footer}</div>}
				<div
					onClick={() => setCollapsed((prev) => !prev)}
					className='absolute -right-2.5 top-5 z-1000 rounded-full border border-slate-300 bg-slate-100 shadow-md '
				>
					<IconChevronLeft size={16} color='#555' className={`${collapsed ? 'rotate-180' : ''} transition-all`} />
				</div>
			</div>
		</SidebarContext.Provider>
	)
}

const Item = ({ icon, title, link }: { icon: React.ReactNode; title: React.ReactNode; link: string }) => {
	const { collapsed } = useSidebarContext()
	const $gtm = useAnalytics()
	const { pathname } = useLocation()
	const isPath = matchPath({ path: link, caseSensitive: true, end: true }, pathname)

	return (
		<Link
			to={link}
			// onClick={() => $gtm.menu_click(title, location.pathname)}
			className={`relative flex items-center rounded-lg p-2 text-gray-500 hover:text-gray-700  ${
				isPath ? ' bg-slate-200 text-gray-800' : 'hover:bg-slate-100'
			} transition-all`}
		>
			<div className='relative z-100 flex flex-row items-center gap-1'>
				{icon}
				<span
					className={`ml-1 text-sm font-semibold ${
						collapsed ? 'w-0 overflow-hidden opacity-0' : ' w-auto overflow-hidden opacity-100'
					} text-gray-500 transition-all`}
				>
					{title}
				</span>
			</div>
		</Link>
	)
}

const SidebarContext = createContext<{ collapsed: boolean; toggleCollapse: () => void }>(null)

export function useSidebarContext() {
	const context = useContext(SidebarContext)
	if (!context) {
		throw new Error('SidebarContext.* component must be rendered as child of SidebarContext component')
	}
	return context
}

const Header = ({
	children,
	rightComponent,
	onClick,
}: {
	children: ReactNode
	rightComponent: ReactNode
	onClick?: () => void
}) => {
	const { collapsed } = useSidebarContext()
	return (
		<div onClick={onClick} className='flex cursor-pointer select-none flex-row items-center gap-1'>
			{children}
			{!collapsed ? rightComponent : null}
		</div>
	)
}

Sidebar.Item = Item
Sidebar.Header = Header
