import { cn } from 'lib/helper'

export const YoutubeIcon = ({ color = 'red', className }: { color?: 'red' | 'black' | 'white'; className?: string }) => {
  const c = {
    red: 'red',
    black: '#282828',
    white: '#fff',
  }[color]
  return (
    <svg
      className={cn('w-5 h-5', className)}
      xmlns='http://www.w3.org/2000/svg'
      height='800'
      width='1080'
      viewBox='-35.20005 -41.33325 305.0671 247.9995'
    >
      {color === 'white' ? (
        <path
          d='M93.333 117.559V47.775l61.334 34.893zm136.43-91.742c-2.699-10.162-10.651-18.165-20.747-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.555 7.652 7.603 15.655 4.904 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.904 56.849c2.699 10.163 10.65 18.165 20.747 20.883 18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.683-4.934c10.096-2.718 18.048-10.72 20.747-20.883 4.904-18.42 4.904-56.85 4.904-56.85s0-38.43-4.904-56.849'
          fill='#fff'
        />
      ) : (
        <path
          d='M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85'
          fill={c}
        />
      )}

      {color !== 'white' ? <path d='M93.333 117.559l61.333-34.89-61.333-34.894z' fill='#fff' /> : null}
    </svg>
  )
}

export const YoutubeIconBlack = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='800' width='1080' viewBox='-35.20005 -41.33325 305.0671 247.9995'>
      <path
        d='M229.763 25.817c-2.699-10.162-10.65-18.165-20.747-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.554 7.652 7.602 15.655 4.904 25.817 0 44.237 0 82.667 0 82.667s0 38.43 4.904 56.85c2.698 10.162 10.65 18.164 20.747 20.881 18.3 4.935 91.682 4.935 91.682 4.935s73.383 0 91.683-4.935c10.097-2.717 18.048-10.72 20.747-20.88 4.904-18.422 4.904-56.851 4.904-56.851s0-38.43-4.904-56.85'
        fill='#282828'
      />
      <path d='M93.333 117.558l61.334-34.89-61.334-34.893z' fill='#fff' />
    </svg>
  )
}

export const YTW = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='800' width='1080' viewBox='-35.20005 -41.33325 305.0671 247.9995'>
      <path
        d='M93.333 117.559V47.775l61.334 34.893zm136.43-91.742c-2.699-10.162-10.651-18.165-20.747-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.555 7.652 7.603 15.655 4.904 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.904 56.849c2.699 10.163 10.65 18.165 20.747 20.883 18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.683-4.934c10.096-2.718 18.048-10.72 20.747-20.883 4.904-18.42 4.904-56.85 4.904-56.85s0-38.43-4.904-56.849'
        fill='#fff'
      />
    </svg>
  )
}
