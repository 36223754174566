import { zodResolver } from '@hookform/resolvers/zod'
import { IconCalendar, IconExternalLink, IconX } from '@tabler/icons'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import { useToast } from 'hooks/useToast'
import { cn } from 'lib/helper'
import _ from 'lodash'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import {
	Badge,
	Button,
	Calendar,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from 'ui'
import { z } from 'zod'
import { useSearch } from './SearchBar'

const createNewCampaignValidator = z.object({
	title: z.string().min(1, {
		message: 'Kampanya adı en az 1 karakter olmalıdır.',
	}),
	description: z.string().optional(),
	start_date: z.date({
		required_error: 'Başlangıç tarihi geçerli bir tarih olmalıdır.',
		invalid_type_error: 'Başlangıç tarihi geçerli bir tarih olmalıdır.',
	}),
	end_date: z.date({
		required_error: 'Bitiş tarihi geçerli bir tarih olmalıdır.',
		invalid_type_error: 'Bitiş tarihi geçerli bir tarih olmalıdır.',
	}),
	hashtags: z.array(z.string()),
	hashtag: z.string().optional(),
})

type CreateNewCampaignTypes = z.infer<typeof createNewCampaignValidator>

const useCreateCampaign = () => {
	const { toast } = useToast()
	const navigate = useNavigate()
	const { close } = useSearch()
	return useMutation({
		mutationFn: async (data: CreateNewCampaignTypes) => {
			const result = await axios.post(
				`${window.endpoint}/api/campaigns`,
				{
					hashtags: data.hashtags,
					title: data.title,
					start_date: dayjs(data.start_date).unix() * 1000,
					end_date: dayjs(data.end_date).unix() * 1000,
				},
				window.getHeaders()
			)
			// window.open(`/campaigns/${result.data._id}`, `_blank`)
			return result.data
		},
		onSuccess: (data) => {
			toast({
				title: 'Kampanya oluşturuldu',
				description: 'Kampanya başarıyla oluşturuldu.',
				action: (
					<Button
						onClick={() => {
							navigate(`/campaigns/${data._id}`)
						}}
					>
						<IconExternalLink size={16} className='mr-2' />
						<span>Kampanya'ya git</span>
					</Button>
				),
			})
			close()
		},
		onError: (error) => {
			toast({
				title: 'Kampanya oluşturulamadı',
				description: 'Kampanya oluşturulamadı. Lütfen daha sonra tekrar deneyin.',
				variant: 'destructive',
			})
		},
	})
}
export const CreateNewCampaign = () => {
	const [warning, setWarning] = useState('')
	const form = useForm({
		resolver: zodResolver(createNewCampaignValidator),
		defaultValues: {
			title: '',
			description: '',
			start_date: null,
			end_date: null,
			hashtags: [],
			hashtag: '',
		},
	})

	const { mutate, isPending } = useCreateCampaign()

	const onSubmit = (data: CreateNewCampaignTypes) => {
		console.log(data)
		mutate(data)
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)}>
				<div className='flex flex-col space-y-2 min-h-[300px] w-full'>
					{warning && <div>{warning}</div>}

					<FormField
						control={form.control}
						name='title'
						render={({ field }) => {
							return (
								<FormItem>
									<FormLabel>Kampanya Adı</FormLabel>
									<FormControl>
										<Input {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)
						}}
					/>
					<div className='flex w-full flex-row gap-2'>
						<FormField
							control={form.control}
							name='start_date'
							render={({ field }) => {
								return (
									<FormItem className='flex-1'>
										<FormLabel>Başlangıç Tarihi</FormLabel>
										<Popover>
											<PopoverTrigger asChild>
												<FormControl>
													<Button className={cn('h-10 w-full text-left font-normal', !field.value && 'text-muted-foreground')}>
														{field.value ? format(field.value, 'PPP') : <span>Başlangıç Tarihi</span>}
														<IconCalendar className='ml-auto h-4 w-4 opacity-50' />
													</Button>
												</FormControl>
											</PopoverTrigger>
											<PopoverContent className='z-[500] w-auto p-0'>
												<Calendar mode='single' selected={field.value} onSelect={field.onChange} initialFocus />
											</PopoverContent>
										</Popover>
										<FormMessage />
									</FormItem>
								)
							}}
						/>
						<FormField
							control={form.control}
							name='end_date'
							render={({ field }) => {
								return (
									<FormItem className='flex-1'>
										<FormLabel>Bitiş Tarihi</FormLabel>
										<Popover>
											<PopoverTrigger asChild>
												<FormControl>
													<Button className={cn('h-10 w-full flex-1 text-left font-normal', !field.value && 'text-muted-foreground')}>
														{field.value ? format(field.value, 'PPP') : <span>Bitiş Tarihi</span>}
														<IconCalendar className='ml-auto h-4 w-4 opacity-50' />
													</Button>
												</FormControl>
											</PopoverTrigger>
											<PopoverContent className='z-[500] w-auto p-0'>
												<Calendar mode='single' selected={field.value} onSelect={field.onChange} initialFocus />
											</PopoverContent>
										</Popover>
										<FormMessage />
									</FormItem>
								)
							}}
						/>
					</div>
					<FormField
						control={form.control}
						name='hashtag'
						render={({ field }) => {
							return (
								<FormItem>
									<FormLabel>Etiket Ekle</FormLabel>
									<FormItem className='flex flex-row gap-2'>
										<FormControl>
											<Input {...field} />
										</FormControl>
										<Button
											type='button'
											disabled={!field.value}
											onClick={() => {
												form.setValue('hashtags', [...form.getValues('hashtags'), field.value])
												field.onChange('')
											}}
										>
											EKLE
										</Button>
									</FormItem>
								</FormItem>
							)
						}}
					/>
					<FormField
						name='hashtags'
						control={form.control}
						render={({ field }) => {
							return (
								<FormItem className='flex flex-row flex-wrap items-center gap-2'>
									{field.value.map((hashtag) => {
										return (
											<Badge
												onClick={() => {
													form.setValue('hashtags', _.without(field.value, hashtag))
												}}
												key={hashtag}
											>
												{hashtag}
												<IconX className='ml-1' size={16} />
											</Badge>
										)
									})}
								</FormItem>
							)
						}}
					/>

					<div className='flex flex-row items-center justify-end'>
						<Button loading={isPending} size='large' variant='primary' type='submit'>
							Create <AiOutlinePlusCircle className='ml-2' />
						</Button>
					</div>
				</div>
			</form>
		</Form>
	)
}
