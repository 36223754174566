import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Input, XModal } from 'xui'
import { $feedback_clear, $feedback_send } from '../../redux/hub_settings.reducer'

const FeedbackModal = ({ modalStyle = {} }) => {
	const dispatch = useDispatch()
	const $hub_settings = useSelector((x) => x.$hub_settings)
	const [visible, set_visible] = React.useState(false)
	const [feedback, set_feedback] = React.useState('')
	const [warning_text, set_warning_text] = React.useState('')

	useEffect(() => {
		if ($hub_settings.feedback_sent || $hub_settings.feedback_fail) {
			if ($hub_settings.feedback_sent) {
				set_feedback('')
				setTimeout(() => {
					dispatch($feedback_clear(feedback))
				}, 10000)
			}
			if ($hub_settings.feedback_fail) {
				set_warning_text("Your feedback couldn't be sent. If the problem persists, you can email us at hub@creatorden.com")
			}
		}
	}, [$hub_settings.feedback_sent, $hub_settings.feedback_fail])

	const handle_create = () => {
		set_warning_text('')
		dispatch($feedback_clear(feedback))
		if (feedback && feedback.length > 10) {
			dispatch($feedback_send(feedback))
		} else {
			set_warning_text('Your feedback is too short.')
		}
	}

	const handle_cancel = () => {
		set_visible(false)
		dispatch($feedback_clear(feedback))
	}

	return (
		<>
			<XModal
				title='What could be better?'
				visible={visible}
				onOk={handle_create}
				confirmLoading={$hub_settings.feedback_sending}
				onCancel={handle_cancel}
				style={{ ...modalStyle }}
				bodyStyle={{ padding: '15px 20px' }}
				cancelText={'CLOSE'}
				okText={'SEND'}
			>
				<div>
					{!$hub_settings.feedback_sent && (
						<div className='text-md whitespace-pre-line font-normal'>
							{'Tell us as many details as possible such as the page and the section you are referring to.'}
						</div>
					)}

					{!$hub_settings.feedback_sent && (
						<Input.TextArea
							rows={8}
							className='border-thin my-2 w-full rounded border-2 border-gray-100 px-2 py-1 font-semibold leading-relaxed'
							style={{ fontSize: 14, verticalAlign: 'middle' }}
							value={feedback}
							onChange={({ target: { value = '' } }) => set_feedback(value)}
							placeholder={'i.e. Brand Dashboard - Campaigns'}
						/>
					)}

					{warning_text !== '' && <Alert style={{ fontSize: 14, fontWeight: 500 }} type={'warning'} message={warning_text} />}
					{$hub_settings.feedback_sent === true && <Alert type={'success'} message={'Thank your for your feedback!'} />}
				</div>
			</XModal>
			<button
				onClick={() => set_visible(true)}
				className='text-normal ml-2 hidden h-8 w-8 flex-row items-center justify-center rounded-full border-2 border-gray-100 bg-gray-50 px-1 py-1 text-xs font-semibold shadow outline-none hover:border-gray-200 focus:outline-none md:flex'
			>
				{/* <div>FEEDBACK</div> */}
				<div className='text-gray-500'>
					<svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth={2}
							d='M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z'
						/>
					</svg>
				</div>
			</button>
		</>
	)
}

export default FeedbackModal
