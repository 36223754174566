import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import api from 'api'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import useCampaign from 'hooks/useCampaign'
import { useCampaignQueries } from 'hooks/useCampaignQueries'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Skeleton } from 'ui'
import { ContentCard } from './ContentCards'
import { useSelector } from 'hooks/tredux'

export const ContentList = () => {
	const { data: campaign } = api.campaign.useById()

	const { start_date, end_date, content_types, creators } = useCampaignQueries()

	const content = useMemo(() => {
		const sd = dayjs(start_date)
		const ed = dayjs(end_date)
		return _(campaign?.contents || {})
			.filter((content) => dayjs(content.content.taken_at_timestamp * 1000).isBetween(sd, ed, 'day', '[]'))
			.filter(({ content }) => {
				if (content_types.length === 0) return false
				if (content_types.length === 6) return true
				else if (content_types.includes('reels') && content.content_type === 'ig_post' && content.product_type === 'clips') {
					return true
				} else if (
					content_types.includes('video_post') &&
					content.content_type === 'ig_post' &&
					content.is_video === true &&
					content.product_type !== 'clips'
				) {
					return true
				} else if (
					content_types.includes('photo_post') &&
					content.content_type === 'ig_post' &&
					content.is_video === false
				) {
					return true
				} else if (content_types.includes('story') && content.content_type === 'ig_story') {
					return true
				} else if (content_types.includes('youtube') && content.content_type === 'yt_post') {
					return true
				} else if (content_types.includes('tiktok') && content.content_type === 'tt_post') {
					return true
				}

				return false
			})
			.filter((content) => {
				return creators.includes(content.persona._id)
			})
			.sortBy((content) => content.content.taken_at_timestamp)
			.reverse()
			.value()
	}, [campaign?.contents, creators, start_date, end_date, content_types])
	return (
		<div className='mt-2  rounded-lg bg-gray-300 p-2'>
			<AnimatePresence mode='wait'>
				{content.length > 0 ? (
					<motion.div
						className='grid h-auto w-full grid-cols-campaign-posts gap-2'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						{_(content)
							.map((data) => <RenderPosts data={data} key={data.content._id} />)
							.value()}
					</motion.div>
				) : (
					<motion.div
						className='relative grid h-auto w-full grid-cols-campaign-posts gap-2'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						{Array.from({ length: 12 }).map((_, i) => (
							<Skeleton className='h-80 w-full' key={i} />
						))}
						<div className='absolute left-1/2 top-20 -translate-x-1/2 rounded-lg bg-yellow-200 px-4 py-2 text-xl font-semibold shadow-md'>
							İçerik bulunamadı.
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	)
}

export const RenderPosts = React.memo(
	({ data }: { data: ReturnType<ReturnType<typeof useCampaign>['get_content']>['contents'][number] }) => {
		return (
			<ErrorBoundary FallbackComponent={Error}>
				<ContentCard data={data} />
			</ErrorBoundary>
		)
	}
)
RenderPosts.displayName = 'RenderPosts'
export const Error = () => (
	<div
		style={{ height: 320, minWidth: 180, maxWidth: 330 }}
		className='flex flex-col items-center justify-center rounded-lg bg-white p-2 shadow-md'
	>
		<ExclamationCircleIcon className='h-10 w-10 text-orange-700' />
		<div className='text-center text-xs text-orange-700'>Bu kart ile alakadar bir sorun meydana geldi.</div>
	</div>
)
