import { LoadingSpinner } from 'components/LoadingSpinner'
import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Badge } from 'ui'
import { MonitorSearchResult } from '.'

export const ItemListerMonitor = ({
	isLoading,
	data,
	close,
	showCount = 6,
}: {
	isLoading: boolean
	data: MonitorSearchResult[]
	close: () => void
	showCount?: number
}) => {
	return (
		<AnimatePresence mode='wait'>
			{isLoading ? (
				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 20 }}
					className='flex h-full w-full flex-col items-center justify-center gap-2 border border-gray-100 p-4 shadow'
				>
					<LoadingSpinner className='h-5 w-5' />
					<p>Loading</p>
				</motion.div>
			) : (
				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 20 }}
					className='grid h-auto w-full flex-1 grid-cols-1 gap-2 rounded-lg bg-gray-100 p-2 md:grid-cols-2'
				>
					{data.length > 0 ? (
						_(data)
							.take(showCount)
							.map((item) => {
								return (
									<div
										key={'monitor' + item._id}
										onClick={() => {
											window.open(`/brand/${item.brand_id}/monitors/${item._id}`, '_blank')
										}}
										className='flex cursor-pointer flex-col gap-1 rounded-lg border border-gray-50 bg-white p-2 text-black transition-all hover:bg-gray-200 hover:text-black'
									>
										<h3 className='text-base font-medium'>{item.name}</h3>
										<div className='flex'>
											<Link
												onClick={(e) => {
													e.stopPropagation()
												}}
												to={`/brand/${item.brand_id}`}
											>
												<Badge>{`${item.brand_name}`}</Badge>
											</Link>
										</div>

										<div className='mt-1 flex flex-row flex-wrap gap-1'>
											{_(item.labels)
												.take(3)
												.map((label) => {
													return (
														<Badge variant='dark' key={label + item._id}>
															{label}
														</Badge>
													)
												})
												.value()}
											{item.labels.length > 3 && <Badge variant='dark'>{`+${item.labels.length - 3} more`}</Badge>}
										</div>
									</div>
								)
							})
							.value()
					) : (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							className='col-span-2 flex h-full w-full flex-col items-center justify-center'
						>
							<h3 className='text-base font-medium'>No Monitors Found</h3>
						</motion.div>
					)}
				</motion.div>
			)}
		</AnimatePresence>
	)
}
