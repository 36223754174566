import { SearchType } from '.'

export const SearchPill = ({
	title,
	type,
	onClick,
	selected,
}: {
	title: string
	selected: SearchType
	type: SearchType
	onClick: React.Dispatch<React.SetStateAction<SearchType>>
}) => {
	return (
		<span
			onClick={() => onClick(type)}
			className={`px-3 py-1 font-mono text-xs 
            ${
													selected === type
														? 'border-sky-400 bg-sky-600 text-white'
														: 'border-gray-100 bg-white text-gray-700  hover:bg-sky-700 hover:text-white'
												} 
            line-clamp-1 cursor-pointer rounded-lg border font-semibold shadow-sm 
            transition-all 
            `}
		>
			{title}
		</span>
	)
}
