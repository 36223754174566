export const endpoint = window.endpoint
export const authHeader = window.getHeaders()

export type queryType = 'get' | 'post' | 'put' | 'delete'
export const campaignApi = {
	GET_SINGLE_WITH_TOKEN: {
		// done
		key: 'GET_SINGLE_WITH_TOKEN',
		url: (campaign_id: string, access_token: string) => `api/view_campaign/${campaign_id}?access_token=${access_token}`,
		type: 'get' as queryType,
	},
	GET_SINGLE: {
		// done
		key: 'GET_SINGLE',
		url: (campaign_id: string) => `api/campaigns/${campaign_id}`,
		type: 'get' as queryType,
	},
	GET_SINGLE_REFRESH: {
		// done
		key: 'GET_SINGLE_REFRESH',
		url: (campaign_id: string) => `api/campaigns/${campaign_id}`,
		type: 'get' as queryType,
	},
	GET_SINGLE_BRAND: {
		// done
		key: 'GET_SINGLE_BRAND',
		url: (campaign_id: string) => `brand_api/campaign/${campaign_id}`,
		type: 'get' as queryType,
	},
	GET_SHARE_REQUEST: {
		// done
		key: 'GET_SHARE_REQUEST',
		url: (campaign_id: string) => `api/campaigns/${campaign_id}/share`,
		type: 'get' as queryType,
	},

	GET_REFRESH_CAMPAIGN_REQUEST: {
		// done
		key: 'GET_REFRESH_CAMPAIGN_REQUEST',
		url: (campaign_id: string) => `api/campaigns/${campaign_id}/refresh`,
		type: 'get' as queryType,
	},
	DELETE_INFLUENCER_REQUEST: {
		// done
		key: 'DELETE_INFLUENCER_REQUEST',
		url: (campaign_id: string, influencer_handle: string) => `api/campaigns/${campaign_id}/persona/${influencer_handle}`,
		type: 'delete' as queryType,
	},
	GET_CAMPAIGN_MESSAGES_REQUEST: {
		key: 'GET_CAMPAIGN_MESSAGES_REQUEST',
		url: (campaign_id: string) => `api/message/campaign_${campaign_id}`,
		type: 'get' as queryType,
	},
	POST_ADD_INFLUENCER_REQUEST: {
		key: 'POST_ADD_INFLUENCER_REQUEST',
		url: (campaign_id: string, influencer_handle: string) => `api/campaigns/${campaign_id}/persona/${influencer_handle}`,
		type: 'post' as queryType,
	},
	POST_ADD_BULK_INSTAGRAM_INFLUENCERS_REQUEST: {
		key: 'POST_ADD_BULK_INSTAGRAM_INFLUENCERS_REQUEST',
		url: (campaign_id: string) => `api/campaigns/${campaign_id}/persona/ig_bulk`,
		type: 'post' as queryType,
		body: (usernames: string[]) => ({ usernames }),
	},
} as const

export const brandApi = {
	GET_BRANDS_REQUEST: {
		key: 'GET_BRANDS_REQUEST',
		url: () => `brand_api`,
		type: 'get' as queryType,
	},
	GET_BRAND_REQUEST: {
		key: 'GET_BRAND_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}`,
		type: 'get' as queryType,
	},
	GET_BRAND_INVITATIONS_REQUEST: {
		key: 'GET_BRAND_INVITATIONS_REQUEST',
		url: () => `brand_api/invitations`,
		type: 'get' as queryType,
	},
	GET_BRAND_CAMPAIGNS_REQUEST: {
		key: 'GET_BRAND_CAMPAIGNS_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/campaigns`,
		type: 'post' as const,
		body: ({ date_from, date_to }: { date_from: number | null; date_to: number }) => ({ date_from, date_to }),
	},
	GET_BRAND_CONTENTS_REQUEST: {
		key: 'GET_BRAND_CONTENTS_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/campaigns/content`,
		type: 'post' as queryType,
		body: ({ date_from, date_to }: { date_from: number | null; date_to: number }) => ({ date_from, date_to }),
	},
	POST_BRAND_CAMPAIGN_ADD_BY_LINK_REQUEST: {
		key: 'POST_BRAND_CAMPAIGN_ADD_BY_LINK_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/campaigns/add`,
		type: 'post' as queryType,
		body: ({ link }: { link: string }) => ({ link }),
	},
	POST_BRAND_CAMPAIGN_REMOVE_MANUAL_REQUEST: {
		key: 'POST_BRAND_CAMPAIGN_REMOVE_MANUAL_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/campaigns/remove`,
		type: 'post' as queryType,
		body: ({ campaign_id }: { campaign_id: string }) => ({ campaign_id }),
	},
	GET_BRAND_INFLUENCERS_REQUEST: {
		key: 'GET_BRAND_INFLUENCERS_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/influencers`,
		type: 'post' as queryType,
		body: ({ date_from, date_to }: { date_from: number | null; date_to: number }) => ({ date_from, date_to }),
	},
	GET_BRAND_RECENT_CONTENT_REQUEST: {
		key: 'GET_BRAND_RECENT_CONTENT_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/dashboard/recent_content`,
		type: 'post' as queryType,
		body: ({ date_from, date_to }: { date_from: number | null; date_to: number }) => ({ date_from, date_to }),
	},
	GET_BRAND_PROGRESS_GRAPH_REQUEST: {
		key: 'GET_BRAND_PROGRESS_GRAPH_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/dashboard/progress_graph_data`,
		type: 'post' as queryType,
		body: ({ date_from, date_to }: { date_from: number | null; date_to: number }) => ({ date_from, date_to }),
	},
	GET_BRAND_WIDGET_DATA_REQUEST: {
		key: 'GET_BRAND_WIDGET_DATA_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/dashboard/widget_data`,
		type: 'post' as queryType,
		body: ({ date_from, date_to }: { date_from: number | null; date_to: number }) => ({ date_from, date_to }),
	},
	POST_BRAND_CREATE_REQUEST: {
		key: 'POST_BRAND_CREATE_REQUEST',
		url: () => `brand_api/create`,
		type: 'post' as queryType,
		body: ({ name, is_agency, industry }: { name: string; is_agency: boolean; industry: string }) => ({
			name,
			is_agency,
			industry,
		}),
	},
	POST_BRAND_MEMBER_ROLE_REQUEST: {
		key: 'POST_BRAND_MEMBER_ROLE_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/manage/change_role`,
		type: 'post' as queryType,
		body: (bodyValues: {
			user_id: string
			role_type: string
			opt_view_campaigns: boolean
			opt_view_content_insights: boolean
			opt_view_brand_influencers_tab: boolean
			opt_view_brand_monitors_tab: boolean
			opt_manage_campaigns: boolean
		}) => ({ ...bodyValues }),
	},
	POST_BRAND_INVITE_MEMBER_REQUEST: {
		key: 'POST_BRAND_INVITE_MEMBER_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/manage/add_team_member`,
		type: 'post' as queryType,
		body: (bodyValues: {
			email: string
			role_type: string
			opt_view_campaigns: boolean
			opt_view_content_insights: boolean
			opt_view_brand_influencers_tab: boolean
			opt_view_brand_monitors_tab: boolean
		}) => ({ ...bodyValues }),
	},
	POST_BRAND_REMOVE_INVITATION_REQUEST: {
		key: 'POST_BRAND_REMOVE_INVITATION_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/manage/remove_invitation`,
		type: 'post' as queryType,
		body: (bodyValues: { email: string }) => ({ ...bodyValues }),
	},
	POST_APPROVE_BRAND_INVITATION_REQUEST: {
		key: 'POST_APPROVE_BRAND_INVITATION_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/manage/approve_membership`,
		type: 'post' as queryType,
		body: () => ({}),
	},
	POST_DELETE_BRAND_INVITATION_REQUEST: {
		key: 'POST_DELETE_BRAND_INVITATION_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/manage/xxxxxxxx`,
		type: 'post' as queryType,
		body: (email: string) => ({ email }),
	},
	POST_BRAND_ADD_AGENCY_REQUEST: {
		key: 'POST_BRAND_ADD_AGENCY_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/manage/add_agency`,
		type: 'post' as queryType,
		body: (agency_id: string) => ({ agency_id }),
	},
	POST_BRAND_REMOVE_AGENCY_REQUEST: {
		key: 'POST_BRAND_REMOVE_AGENCY_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/manage/remove_agency`,
		type: 'post' as queryType,
		body: (agency_id: string) => ({ agency_id }),
	},
	POST_BRAND_CHANGE_PARENT_BRAND_REQUEST: {
		key: 'POST_BRAND_CHANGE_PARENT_BRAND_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/manage/add_parent_brand`,
		type: 'post' as queryType,
		body: (parent_id: string) => ({ parent_id }),
	},
	POST_BRAND_REMOVE_PARENT_BRAND_REQUEST: {
		key: 'POST_BRAND_REMOVE_PARENT_BRAND_REQUEST',
		url: (brand_id: string) => `brand_api/${brand_id}/manage/remove_parent_brand`,
		type: 'post' as queryType,
		body: (parent_id: string) => ({ parent_id }),
	},
} as const

export const campaignDataInputApi = {
	GET_CALCULATE_WEEKLY: {
		key: 'GET_CALCULATE_WEEKLY',
		url: (content_type: string, content_id: string) => `api/${content_type}/${content_id}/calculate_weekly`,
		type: 'get' as queryType,
	},
	GET_REFRESH_IGNORED_CONTENT: {
		key: 'GET_REFRESH_IGNORED_CONTENT',
		url: (campaign_id: string) => `api/campaigns/${campaign_id}/content/hidden`,
		type: 'get' as queryType,
	},
	GET_ADD_MISSING_CONTENT: {
		key: 'GET_ADD_MISSING_CONTENT',
		url: (campaign_id: string, shortcode: string) => `/api/campaigns/${campaign_id}/add_post/${shortcode}`,
		type: 'get' as queryType,
	},
	POST_ADD_MISSING_STORY: {
		key: 'POST_ADD_MISSING_STORY',
		url: () => `api/story`,
		body: (data: FormData) => data,
		type: 'post' as queryType,
	},
	PATCH_UPDATE_CONTENT_FIELD: {
		key: 'PATCH_UPDATE_CONTENT_FIELD',
		url: (content_type: string, id: string) => `api/stats/${content_type}/${id}`,
		type: 'patch' as queryType,
		body: (args: { key: string; value: string | number | boolean }) => ({ [args.key]: args.value }),
	},
	PATCH_UPDATE_CONTENT_FIELD_BRAND: {
		key: 'PATCH_UPDATE_CONTENT_FIELD',
		url: (campaign_id: string, content_type: string, id: string) =>
			`brand_api/campaign/${campaign_id}/manage/${content_type}/${id}`,
		type: 'patch' as queryType,
		body: (args: { key: string; value: string | number | boolean }) => ({ [args.key]: args.value }),
	},
	POST_UPLOAD_STATISTICS: {
		key: 'POST_UPLOAD_STATISTICS',
		url: (content_type: string) => `api/${content_type}/stats/upload`,
		body: (data: { file: File; id: string }) => {
			const formData = new FormData()
			formData.append('file', data.file)
			formData.append('id', data.id)
			formData.append('force', 'true')
			return formData
		},
		type: 'post' as queryType,
	},
	POST_UPLOAD_STATISTICS_BRAND: {
		key: 'POST_UPLOAD_STATISTICS_BRAND',
		url: (campaign_id: string, content_type: string, id: string) =>
			`brand_api/campaign/${campaign_id}/manage/${content_type}/${id}/statistics/upload`,
		body: (data: { file: File; id: string }) => {
			const formData = new FormData()
			formData.append('file', data.file)
			formData.append('id', data.id)
			formData.append('force', 'true')
			return formData
		},
		type: 'post' as queryType,
	},
	POST_REMOVE_STATISTICS: {
		key: 'POST_REMOVE_STATISTICS',
		url: (content_type: string) => `api/${content_type}/stats/remove`,
		body: (data: { id: string; url: string }) => ({ ...data }),
		type: 'post' as queryType,
	},
	DELETE_REMOVE_STATISTICS_BRAND: {
		key: 'POST_REMOVE_STATISTICS_BRAND',
		url: (campaign_id: string, content_type: string, id: string) =>
			`brand_api/campaign/${campaign_id}/manage/${content_type}/${id}/statistics/remove`,
		body: (data: { link: string }) => ({ ...data }),
		type: 'delete' as queryType,
	},
	POST_UPLOAD_ASSET: {
		key: 'POST_UPLOAD_ASSET',
		url: (content_type: string) => `/api/${content_type}/asset`,
		body: (data: { file: File; id: string }) => {
			const formData = new FormData()
			formData.append('file', data.file)
			formData.append('id', data.id)
			formData.append('force', 'true')
			return formData
		},
		type: 'post' as queryType,
	},
	GET_STORIES_WITH_IDS: {
		key: 'GET_STORIES_WITH_IDS',
		url: () => `/api/stats/story`,
		body: (ids: string[]) => ({ ids }),
		type: 'post' as queryType,
	},
	GET_POSTS_WITH_IDS: {
		key: 'GET_POSTS_WITH_IDS',
		url: () => `/api/stats/post`,
		body: (ids: string[]) => ({ ids }),
		type: 'post' as queryType,
	},
	POST_HIDE_CONTENT: {
		key: 'POST_HIDE_CONTENT',
		url: (campaign_id: string, content_type: string, network: string, content_id: string) =>
			`api/campaigns/${campaign_id}/content/hide/${network}/${content_type}/${content_id}`,
		body: () => {},
		type: 'post' as queryType,
	},
	POST_DELETE_REQUEST: {
		key: 'POST_DELETE_REQUEST',
		url: (campaign_id: string, network: string, content_type: string, content_id: string) =>
			`api/campaigns/${campaign_id}/content/delete_request/${network}/${content_type}/${content_id}`,
		body: () => {},
		type: 'post' as queryType,
	},
	POST_UPDATE_CAMPAIGN_REVIEW: {
		key: 'POST_UPDATE_CAMPAIGN_REVIEW',
		url: (campaign_id: string) => `api/campaigns/${campaign_id}/update_review`,
		body: (data: { review: string; visible: boolean }) => ({ ...data }),
		type: 'post' as queryType,
	},
	POST_UPDATE_CONTENT_REVIEW: {
		key: 'POST_UPDATE_CONTENT_REVIEW',
		url: (campaign_id: string) => `api/campaigns/${campaign_id}/update_content_review`,
		body: (data: { content_id: string; review: string; visible: boolean; internal: string }) => ({ ...data }),
		type: 'post' as queryType,
	},
	POST_ADD_CONTENT: {
		key: 'POST_ADD_CONTENT',
		url: (campaign_id: string, network: string, content_type: string, content_id: string) =>
			`api/campaigns/${campaign_id}/content/add/${network}/${content_type}/${content_id}`,
		body: () => {},
		type: 'post' as queryType,
	},
	POST_PERSONA_MISSING_CONTENT: {
		key: 'POST_PERSONA_MISSING_CONTENT',
		url: (content_type: string) => `api/${content_type}/of`,
		body: (data: { date_from: number; date_to: number; persona_id: string; page: number; per_page: number }) => ({
			...data,
		}),
		type: 'post' as queryType,
	},
	POST_ADD_MULTIPLE_CONTENT: {
		key: 'POST_ADD_MULTIPLE_CONTENT',
		url: (campaign_id: string, content_id: string, content_type: 'ig_post' | 'ig_story' | 'yt_post' | 'tt_post') =>
			`api/campaigns/${campaign_id}/content/add/${
				{
					ig_post: 'instagram',
					ig_story: 'instagram',
					yt_post: 'youtube',
					tt_post: 'tiktok',
				}[content_type]
			}/${content_type.split('_')[1]}/${content_id}`,
		body: () => {},
		type: 'post' as queryType,
	},
	POST_HIDE_MULTIPLE_CONTENT: {
		key: 'POST_HIDE_MULTIPLE_CONTENT',
		url: (campaign_id: string, content_id: string, content_type: 'ig_post' | 'ig_story' | 'yt_post' | 'tt_post') =>
			`api/campaigns/${campaign_id}/content/hide/${
				{
					ig_post: 'instagram',
					ig_story: 'instagram',
					yt_post: 'youtube',
					tt_post: 'tiktok',
				}[content_type]
			}/${content_type.split('_')[1]}/${content_id}`,
		body: () => {},
		type: 'post' as queryType,
	},
	POST_LOCK_MULTIPLE_CONTENT: {
		key: 'POST_LOCK_MULTIPLE_CONTENT',
		url: (content_type: 'ig_post' | 'ig_story' | 'yt_post' | 'tt_post', id: string) => `api/stats/${content_type}/${id}`,
		body: (content_id: string) => ({ key: 'locked', value: true, id: content_id }),
		type: 'post' as queryType,
	},
	POST_ADD_MISSING_IG_STORY: {
		key: 'POST_ADD_MISSING_IG_STORY',
		url: () => `api/ig_story`,
		body: ({
			is_video,
			cta_url,
			owner_username,
			hashtags,
			mentions,
			taken_at_timestamp,
			file,
		}: {
			is_video: boolean
			cta_url?: string
			owner_username: string
			hashtags: string[]
			mentions: string[]
			taken_at_timestamp: number
			file: File
		}) => {
			const d = new FormData()
			d.append('files[]', file, file.name)
			d.append('hashtags', JSON.stringify(hashtags))
			d.append('mentions', JSON.stringify(mentions))
			d.append('taken_at_timestamp', JSON.stringify(taken_at_timestamp * 1000))
			d.append('owner_username', owner_username)
			d.append('is_video', JSON.stringify(is_video))
			d.append('cta_url', cta_url)
			return d
		},
	},
} as const

export const influencerApi = {
	INFX_TIKTOK: {
		key: 'INFX_TIKTOK',
		url: (persona_id: string) => `api/infx/${persona_id}/tiktok`,
		body: () => {},
		type: 'get' as queryType,
	},
	INFLUENCER: {
		key: 'INFX_INFLUENCER',
		url: (persona_id: string, network: 'instagram' | 'youtube' | 'tiktok') => `api/infx/${persona_id}/${network}`,
		body: () => {},
		type: 'get' as queryType,
	},
	POSTS: {
		key: 'INFX_POSTS',
		url: (persona_id: string, network: 'instagram' | 'youtube' | 'tiktok') => `api/infx/${persona_id}/posts/${network}`,
		body: () => {},
		type: 'get' as queryType,
	},
	INFX_TIKTOK_POSTS: {
		key: 'INFX_TIKTOK_POSTS',
		url: (persona_id: string) => `api/infx/${persona_id}/posts/tiktok`,
		body: () => {},
		type: 'get' as queryType,
	},
	GRAPH_DATA: {
		key: 'INFX_GRAPH_DATA',
		url: (username: string) => `api/inf/${username}/graph`,
		body: () => {},
		type: 'get' as queryType,
	},
	INFX_ER_DATA_15: {
		key: 'INFX_ER_DATA_15',
		url: (persona_id: string, network: 'instagram' | 'youtube' | 'tiktok') =>
			`api/infx/${persona_id}/report_er15/${network}`,
		body: () => {},
		type: 'get' as queryType,
	},
	INFX_VR_DATA_15: {
		key: 'INFX_VR_DATA_15',
		url: (persona_id: string, network: 'instagram' | 'youtube' | 'tiktok') =>
			`api/infx/${persona_id}/report_vr15/${network}`,
		body: () => {},
		type: 'get' as queryType,
	},
	REPORT: {
		key: 'INFX_REPORT',
		url: () => `api/reports/`,
		body: () => [
			'audience_interest_report',
			'audience_gender_report',
			'audience_age_report',
			'influencer_engagement_rate_report',
			'impression_rate_image_post_by_followers',
			'impression_rate_video_post_by_followers',
			'impression_rate_story_by_followers',
			'influencer_reels_play_rate_report',
			'hub_audience_interest_affinity_report',
		],
		type: 'post' as queryType,
	},
	STORY_LINKS: {
		key: 'INFX_STORY_LINKS',
		url: (persona_id: string) => `api/infx/${persona_id}/ig_story_links`,
		body: () => {},
		type: 'get' as queryType,
	},
	CONTENT_TIMESTAMPS: {
		key: 'INFX_CONTENT_TIMESTAMPS',
		url: (persona_id: string) => `api/infx/${persona_id}/ig_content_type_by_timestamp`,
		body: () => {},
		type: 'get' as queryType,
	},
	SIMILARS: {
		key: 'INFX_SIMILARS',
		url: (persona_id: string, network: 'instagram' | 'youtube' | 'tiktok') => `api/infx/${persona_id}/similars/${network}`,
		body: () => {},
		type: 'get' as queryType,
	},
	PROJECTS: {
		key: 'INFX_PROJECTS',
		url: (persona_id: string) => `api/infx/${persona_id}/campaign_stats`,
		body: () => {},
		type: 'get' as queryType,
	},
	MESSAGES: {
		key: 'INFX_MESSAGES',
		url: (persona_id: string) => `api/message/${persona_id}`,
		body: () => {},
		type: 'get' as queryType,
	},
	NETWORKS: {
		key: 'INFX_NETWORKS',
		url: (persona_id: string) => `api/infx/${persona_id}/available`,
		body: () => {},
		type: 'get' as queryType,
	},
	CAMPAIGN_STATS: {
		key: 'INFX_CAMPAIGN_STATS',
		url: (persona_id: string) => `api/infx/${persona_id}/campaign_stats`,
		body: () => {},
		type: 'get' as queryType,
	},
	CONTACTS: {
		key: 'INFX_CONTACTS',
		url: (persona_id: string) => `api/infx/${persona_id}/contacts`,
		body: () => {},
		type: 'get' as queryType,
	},
	HIGHLIGHTS: {
		key: 'INFX_HIGHLIGHTS',
		url: (persona_id: string) => `api/infx/${persona_id}/highlights`,
		body: () => {},
		type: 'get' as queryType,
	},
	RERUN_SIMILARS: {
		key: 'INFX_RERUN_SIMILARS',
		url: (persona_id: string, network: 'instagram' | 'youtube' | 'tiktok') =>
			`api/infx/${persona_id}/similars_rerun/${network}`,
		body: () => {},
		type: 'get' as queryType,
	},
	SAVE_HIGHLIGHTS: {
		key: 'INFX_SAVE_HIGHLIGHTS',
		url: (persona_id: string) => `api/infx/${persona_id}/highlights`,
		body: (data: { network: string; persona_id: string; available: any; current: any }) => ({ ...data }),
		type: 'post' as queryType,
	},
}
