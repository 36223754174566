import {
	IconBriefcase,
	IconChartBar,
	IconChevronDown,
	IconDeviceDesktop,
	IconHome,
	IconLayoutKanban,
	IconListSearch,
	IconPackages,
	IconQrcode,
	IconQuestionMark,
	IconSettings,
	IconUser,
	IconUserSearch,
} from '@tabler/icons'
import api from 'api'
import { ProfileIcon } from 'components/Profile/ProfileIcon'
import { useAuthProvider } from 'providers/AuthProvider'
import { ReactNode, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Popover, PopoverContent, PopoverTrigger } from 'ui'
import FeedbackModal from './FeedbackModal'
import { SearchBar } from './SearchBar'
import { SourceItem } from './SourceItem'

export const NavigationBar = () => {
	const navigate = useNavigate()

	return (
		<>
			<div
				id='chub-navbar'
				className='sticky top-2 rounded-lg border border-white bg-white px-4 py-2 shadow-md transition-all'
				style={{ zIndex: 80, borderColor: '#eee' }}
			>
				<div className='flex flex-row items-center justify-between'>
					<div className='app-menu flex h-full cursor-pointer flex-row items-center'>
						<img
							onClick={() => navigate('/')}
							style={{ height: 20, marginRight: 13, cursor: 'pointer' }}
							className='hidden w-auto sm:hidden md:block'
							src='/images/logo-shub.png'
							alt='shub'
						/>

						<img
							onClick={() => navigate('/')}
							style={{ height: 20, marginRight: 13, cursor: 'pointer' }}
							className='block w-auto sm:block md:hidden'
							src='/images/192.png'
							alt='shub'
						/>
						<MenuItems />
					</div>
					<div className='flex flex-grow items-center justify-end  pl-2'>
						<div className=' flex flex-grow items-center justify-end '>
							<SearchBar />
							<FeedbackModal />
							<button
								onClick={() => navigate('/faq')}
								className='text-normal ml-2 flex h-8 w-8 flex-row items-center justify-center rounded-[32px] border-2 border-gray-100 bg-gray-50 px-1 py-1 text-xs font-semibold shadow outline-none hover:border-gray-200 focus:outline-none'
							>
								<div className='text-gray-500'>
									<IconQuestionMark size={20} />
								</div>
							</button>
							{/* <ModeToggle /> */}
							<ProfileIcon />
							<SourceItem />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const MenuItems = () => {
	const { role, getUser } = useAuthProvider()

	const { data: brands } = api.brand.useAll()
	const location = useLocation()
	const [opened, setOpened] = useState(false)
	const is_what = () => {
		if (location.pathname.indexOf('/CreatorDenReport') === 0) return 'report'
		if (location.pathname.indexOf('/campaigns') === 0) return 'campaigns'
		if (location.pathname.indexOf('/feed') === 0) return 'feed'
		if (location.pathname.indexOf('/identification') === 0) return 'identification'
		if (location.pathname.indexOf('/profile') === 0) return 'profile'
		if (location.pathname.indexOf('/projects') === 0) return 'projects'
		if (location.pathname.indexOf('/brand') === 0) return 'brand'

		if (location.pathname.indexOf('/monitor') === 0) return 'monitor'
		if (location.pathname.indexOf('/qr') === 0) return 'qr'
		if (location.pathname.indexOf('/tags') === 0) return 'tags'
		if (location.pathname.indexOf('/faq') === 0) return 'faq'

		if (location.pathname.indexOf('/admin') === 0) return 'admin'
		if (location.pathname.indexOf('/x') === 0) return 'influencer'
		if (location.pathname.indexOf('/old-identification') === 0) return 'old-identification'
		if (location.pathname === (['creatorden', 'admin'].indexOf(getUser().role) > -1 ? '/home' : '/')) return 'home'
		if (location.pathname === (['creatorden', 'admin'].indexOf(getUser().role) > -1 ? '/' : '/home')) return 'identify'
		return ''
	}
	const is_only_brand = getUser().role === 'brand' || getUser().role === 'none'
	const is_admin = getUser().role === 'admin'
	return (
		<Popover open={opened} onOpenChange={(v) => setOpened(v)}>
			<PopoverTrigger>
				<div className='flex flex-row items-center rounded-lg border border-slate-100 bg-slate-50 px-4 py-1 text-slate-600 shadow'>
					{{
						home: <IconHome size={18} />,
						monitor: <IconDeviceDesktop size={18} />,
						campaigns: <IconLayoutKanban size={18} />,
						feed: <IconDeviceDesktop size={18} />,
						profile: <IconUser size={18} />,
						projects: <IconPackages size={18} />,
						brand: <IconBriefcase size={18} />,
						admin: <IconSettings size={18} />,
						report: <IconChartBar size={18} />,
						identify: <IconUserSearch size={18} />,
						influencer: <IconUser size={18} />,
						qr: <IconQrcode size={18} />,
						tags: <IconListSearch size={18} />,
						faq: <IconQuestionMark size={18} />,
						'old-identification': <IconUserSearch size={18} />,
						'': <IconHome size={18} />,
					}[is_what()] || <IconHome size={18} />}
					<p className='mx-2 text-sm font-semibold uppercase text-slate-600'>{is_what()}</p>
					<IconChevronDown size={18} className={`${opened ? 'rotate-180' : ''} transition-all`} />
				</div>
			</PopoverTrigger>
			<PopoverContent>
				<div
					onClick={() => {
						setOpened(false)
					}}
					className='flex flex-col space-y-1'
					style={{ width: 175 }}
				>
					{is_only_brand && (
						<MenuItem
							selected={is_what() === 'home'}
							to={role === 'admin' || role === 'creatorden' ? '/home' : '/'}
							icon={<IconHome size={24} />}
						>
							Home
						</MenuItem>
					)}
					{!is_only_brand && (
						<MenuItem
							selected={is_what() === 'home'}
							icon={<IconHome size={24} />}
							to={role === 'admin' || role === 'creatorden' ? '/home' : '/'}
						>
							Home
						</MenuItem>
					)}
					{!is_only_brand && (
						<MenuItem selected={is_what() === 'identify'} to='/' icon={<IconUserSearch size={24} />}>
							Identify
						</MenuItem>
					)}

					{!is_only_brand && (
						<MenuItem selected={is_what() === 'feed'} to='/feed' icon={<IconDeviceDesktop stroke={2} size={24} />}>
							Feed
						</MenuItem>
					)}
					{!is_only_brand && (
						<MenuItem selected={is_what() === 'report'} to='/CreatorDenReports' icon={<IconChartBar size={24} />}>
							C-Reports
						</MenuItem>
					)}
					{!is_only_brand && (
						<MenuItem selected={is_what() === 'tags'} to='/tags' icon={<IconListSearch size={24} />}>
							Keyword Search
						</MenuItem>
					)}

					{role === 'admin' && (
						<MenuItem selected={is_what() === 'admin'} to='/admin' icon={<IconSettings size={24} />}>
							Admin
						</MenuItem>
					)}
					{role !== 'none' && brands?.count > 0 && (
						<MenuItem selected={is_what() === 'brand'} to='/brand' icon={<IconBriefcase size={24} />}>
							Brand
						</MenuItem>
					)}
					{!is_only_brand && brands?.count > 0 && (
						<MenuItem selected={is_what() === 'qr'} to='/qr' icon={<IconQrcode size={24} />}>
							QR
						</MenuItem>
					)}
					{is_admin && (
						<MenuItem selected={is_what() === 'identification'} to='/old-identification' icon={<IconUserSearch size={24} />}>
							Old Identification
						</MenuItem>
					)}
				</div>
			</PopoverContent>
		</Popover>
	)
}

const MenuItem = ({
	icon,
	to,
	selected,
	children,
}: {
	icon: ReactNode
	to: string
	selected: boolean
	children: string
}) => {
	return (
		<Link
			to={to}
			className={`flex flex-row items-center gap-2 rounded-lg ${
				selected
					? 'bg-sky-400 text-white hover:text-white'
					: 'bg-white text-slate-800 hover:bg-slate-200 hover:text-slate-800'
			}  p-2 font-semibold  transition-all  `}
		>
			{icon}
			{children}
		</Link>
	)
}
