import { NotificationTypes } from './notification.types'
import { Store } from 'react-notifications-component'

export const notification = {
	success: ({ placement = 'top-right', key, message = '', description = '', duration = 3 }: NotificationTypes) =>
		Store.addNotification({
			title: message,
			message: description || message || '',
			type: 'success',
			insert: 'top',
			container: placement.includes('-') ? placement : 'top-right',
			animationIn: ['animate__animated', 'animate__fadeIn'],
			animationOut: ['animate__animated', 'animate__fadeOut'],
			id: key,
			dismiss: { duration: 1000 * duration, onScreen: true },
		}),
	error: ({ placement = 'top-right', key, message = '', description = '', duration = 3 }: NotificationTypes) =>
		Store.addNotification({
			title: message,
			message: description || message || '',
			type: 'danger',
			insert: 'top',
			container: placement,
			animationIn: ['animate__animated', 'animate__fadeIn'],
			animationOut: ['animate__animated', 'animate__fadeOut'],
			id: key,
			dismiss: { duration: 1000 * duration, onScreen: true },
		}),
	warning: ({ placement = 'top-right', key, message = '', description = '', duration = 3 }: NotificationTypes) =>
		Store.addNotification({
			title: message,
			message: description || message || '',
			type: 'warning',
			insert: 'top',
			container: placement,
			animationIn: ['animate__animated', 'animate__fadeIn'],
			animationOut: ['animate__animated', 'animate__fadeOut'],
			id: key,
			dismiss: { duration: 1000 * duration, onScreen: true },
		}),
	info: ({ placement = 'top-right', key, message = '', description = '', duration = 3 }: NotificationTypes) =>
		Store.addNotification({
			title: message,
			message: description || message || '',
			type: 'info',
			insert: 'top',
			container: placement,
			animationIn: ['animate__animated', 'animate__fadeIn'],
			animationOut: ['animate__animated', 'animate__fadeOut'],
			id: key,
			dismiss: { duration: 1000 * duration, onScreen: true },
		}),
}
