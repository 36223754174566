import dayjs from 'dayjs'
import _ from 'lodash'
import {
	ArrayParam,
	BooleanParam,
	DateParam,
	NumberParam,
	StringParam,
	useQueryParam,
	withDefault,
} from 'use-query-params'

export const Networks = ['instagram', 'youtube', 'tiktok'] as const
export type Network = (typeof Networks)[number]
export const NetworkParam = withDefault(ArrayParam, ['instagram', 'youtube', 'tiktok'])

export const StartDateParam = withDefault(DateParam, undefined)
export const EndDateParam = withDefault(DateParam, new Date())

export const CampaignParam = withDefault(ArrayParam, undefined)
export const PageParam = withDefault(NumberParam, 0)
export const PerPageParam = withDefault(NumberParam, 10)

export const CampaignSortSettings = [
	{ key: 'end_date', value: 'End Date' },
	{ key: 'content', value: 'Content Count' },
	{ key: 'influencers', value: 'Creator Count' },
	{ key: 'engagement', value: 'Engagement Count' },
	{ key: 'reach', value: 'Total Reach' },
	{ key: 'link_visit', value: 'Link Visits' },
] as const
export const CampaignSortSettingNames = _(CampaignSortSettings).keyBy('key').mapValues('value').value()
export type CampaignSortSettingParam = (typeof CampaignSortSettings)[number]['key']
export const CampaignSortParam = withDefault(StringParam, 'end_date')
export const BrandCampaignsTab = withDefault(StringParam, 'campaigns')

export const BrandCreatorsTab = withDefault(StringParam, 'creators')
export const CreatorSortSettings = [
	{ key: 'last_content_date', value: 'Last Content Date' },
	{ key: 'campaigns', value: 'Campaigns' },
	{ key: 'content', value: 'Content' },
	{ key: 'engagement', value: 'Engagement' },
	{ key: 'reach', value: 'Reach' },
	{ key: 'link_visit', value: 'Link Visit' },
	{ key: 'video_views', value: 'Video Views' },
	{ key: 'impressions', value: 'Impressions' },
] as const
export const CreatorSortSettingNames = _(CreatorSortSettings).keyBy('key').mapValues('value').value()
export type CreatorSortSettingParam = (typeof CreatorSortSettings)[number]['key']
export const CreatorSortParam = withDefault(StringParam, 'last_content_date')

export const SortDirectionParam = withDefault(BooleanParam, true)
export const SearchParam = withDefault(StringParam, '')
export const ShowPreviewParam = withDefault(BooleanParam, true)
export const SelectedCampaignsParam = withDefault(ArrayParam, [])

export const DateFilterSettings = [
	'this_month',
	'last_month',
	'this_year',
	'last_6_months',
	'all_time',
	'custom',
] as const
export type DateFilterSettingParam = (typeof DateFilterSettings)[number]
export const DateFilterParam = withDefault(StringParam, 'all_time')
export const convertDateToUnix = (date: Date) => dayjs(date).unix()
export const convertTimespanToUnix = (start_date: Date | undefined, end_date: Date) => {
	return {
		active_date_from: start_date ? dayjs(start_date).startOf('d').unix() * 1000 : 0,
		active_date_to: dayjs(end_date).endOf('d').unix() * 1000,
	}
}

export const brand = {
	networks: 'network',
	start_date: 'start_date',
	end_date: 'end_date',
	date_filter_type: 'date_filter_type',
	campaigns: 'campaign',
	campaign_tab: 'campaign_tab',
	creators_tab: 'creators_tab',
	page: 'page',
	per_page: 'per_page',
	sort_by_campaign: 'sort_by_campaign',
	sort_by_creator: 'sort_by_creator',
	desc: 'desc',
	search: 'search',
	selected_campaigns: 'selected_campaigns',
	preview: 'preview',
} as const

export const useBrandNetwork = (): [Network[], (network: Network[]) => void] => {
	const [network, setNetwork] = useQueryParam(brand.networks, NetworkParam)
	return [network as Network[], setNetwork as (network: Network[]) => void]
}

export const useBrandSelectedCampaigns = () => {
	return useQueryParam(brand.selected_campaigns, SelectedCampaignsParam)
}
const networkToType = {
	instagram: 'ig_post',
	youtube: 'yt_post',
	tiktok: 'tt_post',
}
export const mapNetworksToBrandType = (networks: Network[]) => {
	return networks.map((n) => networkToType[n])
}
