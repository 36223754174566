import { cva } from 'class-variance-authority'
import React from 'react'
import { ButtonProps } from './Button.d'

const s = cva('base', {
	variants: {
		type: {
			primary: 'text-white bg-sky-600 hover:bg-sky-700 border-sky-600',
			'primary-colorful':
				'text-white transition-all bg-gradient-to-br from-sky-500 to-sky-600 hover:from-sky-600 hover:to-sky-700',
			success: 'text-white transition-all bg-green-500 hover:bg-green-600',
			secondary: 'text-black bg-gray-100 border-gray-200 hover:bg-gray-200',
			default: 'text-black bg-white border-gray-200 hover:bg-gray-100',
			dashed: 'text-black bg-white border-gray-200 border-dashed hover:bg-gray-100',
			danger: 'text-white bg-red-500 hover:bg-red-600 border-red-500',
			info: 'font-semibold text-gray-500 bg-yellow-300 hover:bg-yellow-400',
			link: 'border-transparent text-sky-400',
			text: 'bg-white border-transparent hover:bg-gray-100 text-slate-600',
		},
		size: {
			small: 'px-1.5 py-0.5 text-xs',
			default: 'px-2.5 py-1 text-sm',
			large: 'px-4 py-2 text-tiny',
			xl: 'px-6 py-3 text-lg',
		},
	},
	defaultVariants: {
		size: 'default',
		type: 'default',
	},
})

export const Button: React.FC<ButtonProps> = ({
	onClick = () => {},
	title,
	type = 'default',
	size = 'default',
	icon = null,
	disabled,
	shape = null,
	classes = '',
	className = '',
	children,
	style = {},
	rounded = 'rounded-md',
	border = 'border',
	shadow = 'shadow-sm',
	...rest
}) => {
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			type='button'
			className={`
			inline-flex w-full items-center justify-center font-normal focus:outline-none focus:ring-0 sm:w-auto
        
		
        ${shape ? 'rounded-full px-2 py-2' : rounded} 
        ${border} 
        ${shadow} 
        ${s({ size, type })}
        ${disabled ? 'cursor-not-allowed bg-gray-300 opacity-50' : ''} 
		transition-all
		duration-200
		hover:scale-x-100
		active:scale-95
		${classes} 
		${className}
        `}
			style={style}
			{...rest}
		>
			{title}
			{icon}
			{children}
		</button>
	)
}
