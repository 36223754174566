import { UserIcon } from '@heroicons/react/24/outline'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Badge, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'ui'
import { CampaignSearchResult } from '.'

export const ItemLister = ({
	isLoading,
	data,
	close,
	showCount = 3,
}: {
	isLoading: boolean
	data: CampaignSearchResult[]
	close: () => void
	showCount?: number
}) => {
	return (
		<AnimatePresence mode='wait'>
			{isLoading ? (
				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 20 }}
					className='flex h-auto w-full flex-col items-center justify-center gap-2 border border-gray-100 p-4 shadow'
				>
					<LoadingSpinner className='h-5 w-5' />
					<p>Loading</p>
				</motion.div>
			) : (
				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 20 }}
					className='flex h-auto w-full flex-col gap-2 rounded-lg bg-gray-100 p-2'
				>
					{data.length > 0 ? (
						_(data)
							.take(showCount)
							.map((item) => {
								return (
									<div
										onClick={() => {
											window.open(`/campaigns/${item.id}`, '_blank')
										}}
										key={'campaign_' + item.id}
										className='flex cursor-pointer flex-col gap-1 rounded-lg border border-gray-50 bg-white p-2 text-black transition-colors hover:bg-gray-200 hover:text-black'
									>
										<div className='flex flex-row items-center divide-slate-200'>
											<h3 className='mr-2 text-base font-medium'>{item.title}</h3>
											<span>•</span>
											<h6 className='ml-2 text-xs font-normal'>{`${item.influencers.length} Creators`}</h6>
										</div>

										<div className='flex flex-row flex-wrap gap-1 empty:hidden'>
											{item.brand_owner && (
												<Link
													onClick={(e) => {
														e.stopPropagation()
													}}
													target='_blank'
													rel='noreferrer'
													to={`/brand/${item.brand_owner._id}`}
												>
													<Badge>{`${item.brand_owner.name}`}</Badge>
												</Link>
											)}
											{item?.brand_accounts.map((account) => {
												return (
													<Link
														onClick={(e) => {
															e.stopPropagation()
														}}
														to={`/brand/${account._id}`}
														key={'brand_account_' + item.id + account._id}
														target='_blank'
														rel='noreferrer'
													>
														<Badge variant='default'>{account.name}</Badge>
													</Link>
												)
											})}
										</div>

										<div className='mt-1 flex w-full flex-row flex-wrap gap-1'>
											{_(item.personas)
												.uniqBy('name')
												.take(10)
												.map((influencer) => {
													return (
														<Link
															key={item.id + influencer._id}
															onClick={(e) => {
																e.stopPropagation()
															}}
															to={`/x/${influencer._id}`}
															target='_blank'
															rel='noreferrer'
														>
															<Badge variant='teal'>
																<UserIcon className='mr-1 h-3 w-3  stroke-2' />
																{influencer.name}
															</Badge>
														</Link>
													)
												})
												.value()}
											{item.influencers.length > 10 && (
												<TooltipProvider>
													<Tooltip>
														<TooltipTrigger>
															<Badge variant='teal'>+{item.personas.length - 10}</Badge>
														</TooltipTrigger>
														<TooltipContent side='top' className='max-w-xs'>
															{item.personas
																.slice(10)
																.map((persona) => persona.name)
																.join(', ')}
														</TooltipContent>
													</Tooltip>
												</TooltipProvider>
											)}
										</div>
									</div>
								)
							})
							.value()
					) : (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							className='col-span-2 flex h-full w-full flex-col items-center justify-center'
						>
							<h3 className='text-base font-medium'>No Campaigns Found</h3>
						</motion.div>
					)}
				</motion.div>
			)}
		</AnimatePresence>
	)
}
