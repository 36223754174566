import { call, put, takeLatest } from 'redux-saga/effects'
const { endpoint } = window

const api = {
	fetch_api: async ({ api_path }) => {
		return fetch(`${endpoint}${api_path}`, window.getHeaders())
			.then((r) => r.json())
			.then((r) => (r ? r || null : null))
	},
}

function* fetcher_persona_data({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_PERSONA_DATA', payload: data })
	} catch (e) {}
}

export function* fetch_persona_data() {
	yield takeLatest('PERSONA_API_FETCH_PERSONA', fetcher_persona_data)
}
