import { UseMutationOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { campaignApi, campaignDataInputApi } from 'config/api'
import { useSelector } from 'hooks/tredux'
import { usePermissions } from 'hooks/usePermissions'
import { useToast } from 'hooks/useToast'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { deselect_all_selected_content } from 'redux/campaign_data_input.reducer'
import { $update_loadable } from 'redux/loading.reducer'
import { notification } from 'xui'
import { apiConfig } from './api'
import { dataMapper, useGetCampaignById, useRefreshCampaign } from './campaigns'
type BrandIdParam = { brand_id: string }
interface AxiosObject<T> {
	message: string
	code: number
	status: number
	data: T
}

const useGetCalculateWeekly = () => {
	const config = campaignDataInputApi.GET_CALCULATE_WEEKLY
	const queryContext = useQueryClient()
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const { data: c } = useGetCampaignById()
	return useMutation({
		mutationFn: async (d: { content_type: string; content_id: string }) => {
			const { data } = await apiConfig.fetch<CalculateWeeklyIGPost | CalculateWeeklyIGStory>(
				config.url(d.content_type, d.content_id)
			)
			return data
		},
		onSuccess: (data) => {
			queryContext.setQueryData([campaignApi.GET_SINGLE.key, campaign_id], (campaign: typeof c) => ({
				...campaign,
				contents: {
					...campaign.contents,
					[data.id]: {
						...campaign.contents[data.id],
						content: {
							...campaign.contents[data.id].content,
							...data,
						},
					},
				},
			}))
		},
	})
}

export interface IgnoredContent {
	ig_posts: IgPost[]
	ig_stories: IgStory[]
	tt_posts: TtPost[]
	yt_posts: YtPost[]
}

export interface IgPost {
	_id: string
	id: string
	caption: string
	owner: Owner
	shortcode: string
	bucket_thumbnail_url: string
	product_type: string
	username: string
}

export interface Owner {
	username: string
}

export interface IgStory {
	_id: string
	id: string
	product_type: string
	username: string
	bucket_thumbnail_url?: string
}

export interface TtPost {
	_id: string
	id: string
	bucket_thumbnail_url: string
	caption: string
	username: string
}

export interface YtPost {
	_id: string
	id: string
	bucket_thumbnail_url: string
	channel_id: string
	description: string
	username: string
}

const useGetRefreshIgnoredContent = () => {
	const config = campaignDataInputApi.GET_REFRESH_IGNORED_CONTENT

	const { campaign_id } = useParams<{ campaign_id: string }>()
	return useQuery({
		queryKey: [config.key, campaign_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<IgnoredContent>(config.url(campaign_id))
			return data
		},
		initialData: {
			ig_posts: [],
			ig_stories: [],
			tt_posts: [],
			yt_posts: [],
		},
		initialDataUpdatedAt: 0,
		enabled: !!campaign_id,
		staleTime: 1000 * 60 * 60,
	})
}

const useAddMissingContent = () => {
	const config = campaignDataInputApi.GET_ADD_MISSING_CONTENT
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const refreshCampaign = useRefreshCampaign()
	return useMutation({
		mutationFn: async (shortcode: string) => {
			return await apiConfig.fetch(config.url(campaign_id, shortcode))
		},
		onSuccess: () => {
			refreshCampaign.mutate()
		},
	})
}

type AddMissingStoryType = {
	is_video: boolean
	cta_url?: string
	owner_username: string
	hashtags: string[]
	mentions: string[]
	taken_at_timestamp: number
	file: File
}
type MutationOptions<T> = Omit<UseMutationOptions<unknown, unknown, T, unknown>, 'mutationFn'>
const useAddMissingStory = (options?: Partial<MutationOptions<AddMissingStoryType>>) => {
	const config = campaignDataInputApi.POST_ADD_MISSING_IG_STORY
	return useMutation({
		mutationFn: async (data) => {
			return await apiConfig.post<any>(config.url(), config.body(data))
		},
		...options,
	})
}

const useUpdateContentField = () => {
	const config = campaignDataInputApi.PATCH_UPDATE_CONTENT_FIELD
	const queryContext = useQueryClient()
	const { data: c } = useGetCampaignById()
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const { user_type } = usePermissions()
	const configBrand = campaignDataInputApi.PATCH_UPDATE_CONTENT_FIELD_BRAND
	return useMutation({
		mutationFn: async (d: { content_type: string; key: string; value: string | number | boolean; id: string }) => {
			let data: { id: string } & object
			if (user_type === 'brand') {
				const k = await apiConfig.patch<typeof data>(
					configBrand.url(campaign_id, d.content_type, d.id),
					config.body({
						key: d.key,
						value: d.value,
					})
				)
				data = k.data
			} else {
				const k = await apiConfig.patch<typeof data>(
					config.url(d.content_type, d.id),
					config.body({
						key: d.key,
						value: d.value,
					})
				)
				data = k.data
			}

			return data
		},
		onSuccess: (data) => {
			queryContext.setQueryData([campaignApi.GET_SINGLE.key, campaign_id], (campaign: typeof c) => {
				return {
					...campaign,
					contents: {
						...campaign.contents,
						[data.id]: {
							...campaign.contents[data.id],
							insight: {
								...campaign.contents[data.id].insight,

								...data,
							},
						},
					},
				}
			})
		},
	})
}

const useUploadStatistics = () => {
	const config = campaignDataInputApi.POST_UPLOAD_STATISTICS
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const { data: c } = useGetCampaignById()
	const queryContext = useQueryClient()
	return useMutation({
		mutationFn: async (body: { file: File[]; id: string; content_type: string }) => {
			const p = await Promise.allSettled(
				body.file.map(async (file) => {
					const { data } = await apiConfig.post(config.url(body.content_type), config.body({ file: file, id: body.id }))
					return data
				})
			)
			return {
				statistics: p.map((e: any) => e.value),
				id: body.id,
			}
		},
		onSuccess: (data) => {
			queryContext.setQueryData([campaignApi.GET_SINGLE.key, campaign_id], (campaign: typeof c) => {
				return {
					...campaign,
					contents: {
						...campaign.contents,
						[data.id]: {
							...campaign.contents[data.id],
							content: {
								...campaign.contents[data.id].content,
								bucket_statistics: [
									...campaign.contents[data.id].content.bucket_statistics,
									...data.statistics.map((k) => k.url),
								],
							},
							insight: {
								...campaign.contents[data.id].insight,
								statistic_files: [...campaign.contents[data.id].insight.statistic_files, ...data.statistics.map((k) => k.url)],
								...data,
							},
						},
					},
				}
			})
		},
	})
}

const usePostRemoveStatistics = () => {
	const config = campaignDataInputApi.POST_REMOVE_STATISTICS
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const { data: c } = useGetCampaignById()
	const queryContext = useQueryClient()
	const configBrand = campaignDataInputApi.DELETE_REMOVE_STATISTICS_BRAND
	const { user_type } = usePermissions()
	return useMutation({
		mutationFn: async (data: { content_type: string; id: string; link: string }) => {
			if (user_type === 'brand') {
				await apiConfig.post(configBrand.url(campaign_id, data.content_type, data.id), configBrand.body({ link: data.link }))
			} else {
				await apiConfig.post(config.url(data.content_type), config.body({ url: data.link, id: data.id }))
			}
			return data
		},
		onSuccess: (data) => {
			queryContext.setQueryData([campaignApi.GET_SINGLE.key, campaign_id], (campaign: typeof c) => {
				return {
					...campaign,
					contents: {
						...campaign.contents,
						[data.id]: {
							...campaign.contents[data.id],
							content: {
								...campaign.contents[data.id].content,
								bucket_statistics: campaign.contents[data.id].content.bucket_statistics.filter((e) => e !== data.link),
							},
							insight: {
								...campaign.contents[data.id].insight,
								statistic_files: campaign.contents[data.id].insight.statistic_files.filter((e) => e !== data.link),
								...data,
							},
						},
					},
				}
			})
		},
	})
}
const usePostUploadAsset = () => {
	const config = campaignDataInputApi.POST_UPLOAD_ASSET
	return useMutation({
		mutationFn: async (data: { file: File; id: string; content_type: string }) => {
			return await apiConfig.post(config.url(data.content_type), config.body({ file: data.file, id: data.id }))
		},
		onSuccess: () => {
			notification.success({
				message: 'Upload Asset',
				description: 'Asset file successfully uploaded',
			})
		},
	})
}

const useGetStoryIDS = () => {
	const config = campaignDataInputApi.GET_STORIES_WITH_IDS
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const { data: campaign } = useGetCampaignById()
	return useQuery({
		queryKey: [config.key],
		queryFn: async () => {
			const ids = _.map(campaign?.stories, (x) => x)
			const { data } = await apiConfig.post<any[]>(config.url(), config.body(ids))
			_.each(ids, (id) => {
				if (!_.find(data || [], (ss) => ss.id === id)) {
					data.push({
						id: id,
						impression: '',
						reach: '',
						link_visit: '',
						tag_visit: '',
						replies: '',
						shared: '',
						cden_visit: '',
						profile_visit: '',
						statistic_files: [],
					})
				}
			})
			return data
		},
		enabled: campaign !== undefined,
	})
}

const useGetPostIDS = () => {
	const config = campaignDataInputApi.GET_POSTS_WITH_IDS
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const { data: campaign } = useGetCampaignById()
	return useQuery({
		queryKey: [config.key],
		queryFn: async () => {
			const ids = _.map(campaign?.posts, (x) => x)
			const { data } = await apiConfig.post<any[]>(config.url(), config.body(ids))
			_.each(ids, (id) => {
				if (!_.find(data || [], (ss) => ss.id === id)) {
					data.push({
						id: id,
						impression: '',
						reach: '',
						profile_visit: '',
						saved: '',
						statistic_files: [],
						paid_support: false,
						raffle: false,
					})
				}
			})
			return data
		},
		enabled: campaign !== undefined,
	})
}

const useHideContent = () => {
	const config = campaignDataInputApi.POST_HIDE_CONTENT
	const refreshCampaign = useRefreshCampaign()
	const ignoredContent = useGetRefreshIgnoredContent()
	const dispatch = useDispatch()
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const navigate = useNavigate()
	return useMutation({
		mutationFn: async (data: {
			network: 'instagram' | 'youtube' | 'tiktok'
			content_type: 'story' | 'post'
			content_id: string
		}) => {
			await apiConfig.post(config.url(campaign_id, data.content_type, data.network, data.content_id), config.body())
			navigate(`/campaigns/${campaign_id}/data_input`)
		},
		onSuccess: () => {
			refreshCampaign.mutate()
			ignoredContent.refetch()
			dispatch($update_loadable('lock_all_modal', 'closed'))
			dispatch(deselect_all_selected_content())
			notification['success']({
				message: 'İÇERİK KİLİTLEME',
				description: 'İçerik başarıyla gizlendi.',
				placement: 'bottom-right',
			})
		},
	})
}
const useHideMultipleContent = ({ onSuccess }: { onSuccess?: () => void }) => {
	const config = campaignDataInputApi.POST_HIDE_CONTENT
	const refreshCampaign = useRefreshCampaign()
	const ignoredContent = useGetRefreshIgnoredContent()
	const dispatch = useDispatch()
	const { campaign_id } = useParams<{ campaign_id: string }>()
	return useMutation({
		mutationFn: async (data: { contents: ReturnType<typeof dataMapper>['contents'][number]['content'][] }) => {
			await Promise.allSettled(
				data.contents.map(
					async (e) =>
						await apiConfig.post(
							config.url(
								campaign_id,
								e.content_type.split('_')[1],
								{ ig_post: 'instagram', ig_story: 'instagram', yt_post: 'youtube', tt_post: 'tiktok' }[e.content_type],
								e.id
							),
							config.body()
						)
				)
			)
		},
		onSuccess: () => {
			refreshCampaign.mutate()
			ignoredContent.refetch()
			onSuccess()
			notification['success']({
				message: 'İÇERİK GİZLEME',
				description: 'Seçilen Tüm içerikler gizlendi.',
				placement: 'bottom-right',
			})
		},
	})
}
const useLockContent = () => {
	const config = campaignDataInputApi.POST_LOCK_MULTIPLE_CONTENT
	const refreshCampaign = useRefreshCampaign()
	const ignoredContent = useGetRefreshIgnoredContent()
	const dispatch = useDispatch()
	return useMutation({
		mutationFn: async (data: { content_type: 'ig_post' | 'ig_story' | 'yt_post' | 'tt_post'; content_id: string }) => {
			return await apiConfig.post(config.url(data.content_type, data.content_id), config.body(data.content_id))
		},
		onSuccess: () => {
			refreshCampaign.mutate()
			ignoredContent.refetch()
			dispatch($update_loadable('lock_all_modal', 'closed'))
			dispatch(deselect_all_selected_content())
			notification['success']({
				message: 'İÇERİK KİLİTLEME',
				description: 'İçerik başarıyla kilitlendi.',
				placement: 'bottom-right',
			})
		},
	})
}
const useLockMultipleContent = ({ onSuccess }: { onSuccess?: () => void }) => {
	const config = campaignDataInputApi.POST_LOCK_MULTIPLE_CONTENT
	const refreshCampaign = useRefreshCampaign()
	const ignoredContent = useGetRefreshIgnoredContent()

	return useMutation({
		mutationFn: async (data: { contents: ReturnType<typeof dataMapper>['contents'][number]['content'][] }) => {
			await Promise.allSettled(
				data.contents.map(async (e) => await apiConfig.patch(config.url(e.content_type, e.id), config.body(e.id)))
			)
		},
		onSuccess: () => {
			refreshCampaign.mutate()
			ignoredContent.refetch()
			onSuccess()
			notification['success']({
				message: 'İÇERİK KİLİTLEME',
				description: 'Seçilen Tüm içerikler kilitlendi.',
				placement: 'bottom-right',
			})
		},
	})
}
const useDeleteContent = () => {
	const config = campaignDataInputApi.POST_DELETE_REQUEST
	const { campaign_id } = useParams<{ campaign_id: string }>()
	return useMutation({
		mutationFn: async (data: { content_type: 'ig_post' | 'ig_story' | 'yt_post' | 'tt_post'; content_id: string }) => {
			const network = { ig_post: 'instagram', ig_story: 'instagram', yt_post: 'youtube', tt_post: 'tiktok' }[
				data.content_type
			]
			const content_type = data.content_type.split('_')[1] as 'post' | 'story'
			return await apiConfig.post(config.url(campaign_id, network, content_type, data.content_id), config.body())
		},
		onSuccess: () => {
			notification['success']({
				message: `SİLME TALEBİ GÖNDERİLDİ`,
				description: `Kampanyadan silinmesini istediğiniz içerikler için ilettiğiniz talep gönderildi.`,
				placement: 'bottom-right',
			})
		},
	})
}

const useUpdateCampaignReview = () => {
	const config = campaignDataInputApi.POST_UPDATE_CAMPAIGN_REVIEW
	const $campaign_di = useSelector((state) => state.$campaign_di)
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const { refetch } = useGetCampaignById()
	const { toast } = useToast()
	return useMutation({
		mutationFn: async (data?: { review: string; visible: boolean }) => {
			if (data) {
				return await apiConfig.post(
					config.url(campaign_id),
					config.body({
						review: data.review,
						visible: data.visible,
					})
				)
			}
			return await apiConfig.post(
				config.url(campaign_id),
				config.body({
					review: $campaign_di.campaign_review,
					visible: $campaign_di.review_visible,
				})
			)
		},
		onSuccess: () => {
			toast({
				title: 'Success',
				description: 'Review updated successfully',
			})
			refetch()
		},
		onError: () => {
			notification.error({ message: 'Error', description: 'Error while updating campaign review' })
		},
	})
}

const useUpdateContentReview = () => {
	const config = campaignDataInputApi.POST_UPDATE_CONTENT_REVIEW
	const { campaign_id } = useParams<{ campaign_id: string }>()

	const { data: c } = useGetCampaignById()
	const queryContext = useQueryClient()
	return useMutation({
		mutationFn: async (data: { content_id: string; review: string; visible: boolean; internal: string }) => {
			const { data: d } = await apiConfig.post<any>(
				config.url(campaign_id),
				config.body({
					visible: data.visible,
					content_id: data.content_id,
					internal: data.internal,
					review: data.review,
				})
			)

			const review = d.content_review.find((e) => e.content_id === data.content_id)
			return review
		},

		onSuccess: (data) => {
			queryContext.setQueryData([campaignApi.GET_SINGLE.key, campaign_id], (campaign: typeof c) => {
				return {
					...campaign,
					contents: {
						...campaign.contents,
						[data.content_id]: {
							...campaign.contents[data.content_id],
							review: {
								...(campaign.contents[data.content_id]?.review || {}),
								...data,
							},
						},
					},
				}
			})
		},
	})
}

const useAddContent = () => {
	const config = campaignDataInputApi.POST_ADD_CONTENT
	return useMutation({
		mutationFn: async (data: { campaign_id: string; network: string; content_type: string; content_id: string }) => {
			return await apiConfig.post(
				config.url(data.campaign_id, data.network, data.content_type, data.content_id),
				config.body()
			)
		},
	})
}

type MissingContent = {
	count: number
	docs: (MissingContentIGStory | MissingContentIGPost | MissingContentYTPost | MissingContentTTPost)[]
	page: number
}
type MissingContentTTPost = {
	_id: string
	id: string
	bucket_thumbnail_url: string
	caption: string
	hashtags: string[]
	mentions: string[]
	tiktok_id: string
	timestamp: string
	username: string
}
type MissingContentYTPost = {
	_id: string
	id: string
	channel_id: string
	channel_title: string
	description: string
	hashtags: string[]
	mentions: string[]
	timestamp: string
	title: string
	bucket_thumbnail_url: string
}

type MissingContentIGPost = {
	owner: {
		id: string
		username: string
	}
	_id: string
	id: string
	caption: string
	hashtags: string[]
	mentions: string[]
	shortcode: string
	taken_at_timestamp: number
	updated_at: number
	bucket_thumbnail_url: string
	bucket_content_url: string
}

type MissingContentIGStory = {
	bucket_content_url: string
	bucket_thumbnail_url: string
	custom_tappable_objects: []
	id: string
	owner: { id: string; username: string }
	story_cta_url: string | undefined
	taken_at_timestamp: number
	tappable_objects: {
		__typename: string
		id: string
		title: string
		artist: string
		_id: string
	}[]

	updated_at: number
	_id: string
}

const usePersonaMissingContent = () => {
	const config = campaignDataInputApi.POST_PERSONA_MISSING_CONTENT
	const { content_type, selected_persona, date_from, date_to, page, per_page } = useSelector(
		(state) => state.$campaign_di.missing_contents
	)

	return useQuery({
		queryKey: [config.key, content_type, selected_persona, date_from, date_to, page, per_page],
		queryFn: async () => {
			const { data } = await apiConfig.post<MissingContent>(
				config.url(content_type),
				config.body({
					date_from,
					date_to,
					page,
					per_page,
					persona_id: selected_persona,
				})
			)
			return data
		},
		enabled: selected_persona !== null,
	})
}

const useAddMultipleContent = () => {
	const config = campaignDataInputApi.POST_ADD_MULTIPLE_CONTENT
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const { selected_contents } = useSelector((state) => state.$campaign_di.missing_contents)
	const refresh = useRefreshCampaign()
	const dispatch = useDispatch()
	return useMutation({
		mutationFn: async () => {
			const p = await Promise.allSettled(
				selected_contents.map(async (content) => {
					const { data } = await apiConfig.post(
						config.url(
							campaign_id,
							{
								ig_post: content.shortcode,
								ig_story: content.id,
								yt_post: content.id,
								tt_post: content.id,
							}[content.content_type],
							content.content_type
						),
						config.body()
					)
					return data
				})
			)
			return p
		},
		onSuccess: () => {
			dispatch($update_loadable('add_missing_content', 'closed'))
			notification.success({
				message: 'Success',
				description: 'Content added successfully',
			})
			refresh.mutate()
		},
		onError: (err) => {
			notification.error({
				message: 'Error',
				description: JSON.stringify(err),
			})
		},
	})
}

export const campaignDataInput = {
	useCalculateWeekly: useGetCalculateWeekly,
	useIgnoredContent: useGetRefreshIgnoredContent,
	useAddMissingContent: useAddMissingContent,
	useAddMultipleContent: useAddMultipleContent,
	useAddMissingStory: useAddMissingStory,
	useUpdateContentField: useUpdateContentField,
	useUploadStatistics: useUploadStatistics,
	useRemoveStatistics: usePostRemoveStatistics,
	useUploadAsset: usePostUploadAsset,
	useGetStoryIDS: useGetStoryIDS,
	useHideMultipleContent: useHideMultipleContent,
	useLockMultipleContent: useLockMultipleContent,
	useGetPostIDS: useGetPostIDS,
	useHideContent: useHideContent,
	useLockContent: useLockContent,
	useDeleteContent: useDeleteContent,
	useUpdateCampaignReview: useUpdateCampaignReview,
	useUpdateContentReview: useUpdateContentReview,
	useAddContent: useAddContent,
	usePersonaMissingContent: usePersonaMissingContent,
}

export interface CalculateWeeklyIGStory {
	owner: Owner
	bcu_error: number
	btu_error: number
	_id: string
	id: string
	__v: number
	bcu_delete_check: number
	bcu_deleted: boolean
	bcu_size: number
	bcu_size_checked: boolean
	bucket_statistics: string[]
	caption: any
	custom_tappable_objects: any[]
	display_resources: DisplayResource[]
	display_url: string
	expiring_at_timestamp: number
	filter_type: number
	has_hashtags: boolean
	has_mentions: boolean
	hashtags: any[]
	media_type: number
	mentions: string[]
	product_type: string
	taken_at_timestamp: number
	tappable_objects: TappableObject[]
	updated_at: number
	video_resources: any[]
	follower_count: number
	follower_yearweek: string
	has_followers: boolean
	bucket_content_url: string
	has_bcu: boolean
	bucket_thumbnail_url: string
	has_btu: boolean
	estimated_impressions: number
	estimated_impressions_updated_at: number
	custom_hashtags: string[]
	is_video: boolean
	bull: boolean
	custom_mentions: any[]
	has_dupe: boolean
	resolved_url: string
	tag_extract_date: number
}

export interface CalculateWeeklyIGPost {
	location: any
	owner: Owner
	bcu_error: number
	bcu_webm_check: number
	btu_error: number
	custom_mentions: any[]
	pinned_for_users_ids: any[]
	bull: boolean
	_id: string
	id: string
	__v: number
	bcu_delete_check: number
	bcu_deleted: boolean
	bcu_size: number
	bcu_size_checked: boolean
	bucket_statistics: any[]
	caption: string
	caption_is_edited: boolean
	carousel_posts: CarouselPost[]
	collaborators: any[]
	comments: number
	edge_liked_by: EdgeLikedBy[]
	edge_media_preview_like: EdgeMediaPreviewLike[]
	edge_media_to_comment: EdgeMediaToComment[]
	engagement: number
	estimated_impressions: number
	estimated_impressions_updated_at: number
	has_bcu: boolean
	has_btu: boolean
	has_collab: boolean
	has_collab_followers: boolean
	has_hashtags: boolean
	has_location: boolean
	has_mentions: boolean
	has_tags: boolean
	hashtags: any[]
	is_carousel: boolean
	is_likes_hidden: boolean
	likes: number
	location_checked: boolean
	mentions: string[]
	mentions_checked: boolean
	product_type: string
	shortcode: string
	tagged: string[]
	tagged_users: TaggedUser[]
	taken_at_timestamp: number
	updated_at: number
	video_play_count: number
	video_view_count: number
	follower_count: number
	follower_yearweek: string
	has_followers: boolean
	last_twelve: boolean
	accessibility_caption: string
	comments_disabled: boolean
	is_video: boolean
	bucket_content_url: string
	bucket_thumbnail_url: string
	thumbnail_src: string
	tagged_ids: string[]
	cleanup_date: number
	has_dupe: boolean
	__typename: string
	should_download: boolean
	display_url: string
	thumbnail_resources: ThumbnailResource[]
	custom_hashtags: string[]
	display_resources: DisplayResource[]
	is_ad: boolean
	tag_extract_date: number
}

export interface Owner {
	id: string
	username: string
}

export interface CarouselPost {
	is_video: boolean
	display_url: string
	accessibility_caption: string
	shortcode: string
	id: string
	tagged_users: TaggedUser[]
	_id: string
}

export interface TaggedUser {
	id: string
	username: string
	_id: string
}

export interface EdgeLikedBy {
	count: number
	date: string
	_id: string
}

export interface EdgeMediaPreviewLike {
	count: number
	_id: string
}

export interface EdgeMediaToComment {
	count: number
	date: string
	_id: string
}

export interface ThumbnailResource {
	src: string
	_id: string
}

export interface DisplayResource {
	src: string
	_id: string
}
export interface TappableObject {
	__typename: string
	username: string
	_id: string
}
