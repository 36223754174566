import React, { forwardRef, useState } from 'react'
import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { Button } from './Button/Button';

const Popover = ({ children,
    content,
    placement = "bottom",
    trigger = "mouseenter",
    onClickOutside = true,
    destroy = true,
}) => {
    const [isOpen, setisOpen] = useState(false)
    const Child = forwardRef((props, ref) => {
        return <div ref={ref}>{children}</div>;
    });
    const Content = forwardRef((props, ref) => {
        return <div ref={ref} >{content}</div>;
    });
    if (destroy === false) return (
        <Tippy maxWidth={1200} visible={isOpen} zIndex={80} allowHTML={true} onClickOutside={() => onClickOutside ? setisOpen(false) : null} offset={[0, 0]} arrow={false} interactive={true} theme={"light"} popperOptions={{ modifiers: {} }} content={<Content />} placement={placement}>
            <div onClick={() => setisOpen(e => !e)} ><Child /></div>
        </Tippy>
    )
    if (trigger !== "mouseenter") return (
        <Tippy maxWidth={1200} visible={isOpen} zIndex={80} allowHTML={true} onClickOutside={() => onClickOutside ? setisOpen(false) : null} offset={[0, 0]} arrow={false} interactive={true} theme={"light"} popperOptions={{ modifiers: {} }} content={isOpen ? <Content /> : null} placement={placement}>
            <div onClick={() => setisOpen(e => !e)} ><Child /></div>
        </Tippy>
    )

    return <Tippy trigger={trigger} maxWidth={"1200px"} offset={[0, 0]} zIndex={1000} arrow={false} interactive={true} theme={"light"} content={<Content />} placement={placement}>
        <Child />
    </Tippy>
}
const Popconfirm = ({ children, content, onClickOutside = true, placement = "right", title, onConfirm, onConfirmTitle = "OK" }) => {
    const [isOpen, setisOpen] = useState(false)
    const Child = forwardRef((props, ref) => {
        return <div ref={ref}>{children}</div>;
    });
    const Content = forwardRef((props, ref) => {
        return <div ref={ref} className="w-52 h-auto flex flex-col">{title}
            <div className="w-full flex flex-row justify-end space-x-2">
                <Button type="secondary" onClick={() => setisOpen(false)}>İptal Et</Button>
                <Button type="danger" onClick={onConfirm}>{onConfirmTitle}</Button>
            </div>
        </div>;
    });
    return <Tippy maxWidth={1200} visible={isOpen} zIndex={120} allowHTML={true} onClickOutside={() => onClickOutside ? setisOpen(false) : null} offset={[0, 0]} interactive={true} theme={"light"} popperOptions={{ modifiers: {} }} content={<Content />} placement={placement}>
        <div onClick={() => setisOpen(e => !e)} ><Child /></div>
    </Tippy>
}
Popover.Popconfirm = Popconfirm
export { Popover }