import { default as Axios, default as axios } from 'axios'
import _ from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
const { endpoint } = window

const api = {
	fetch_api: async ({ api_path }) => {
		return fetch(`${endpoint}${api_path}`, window.getHeaders())
			.then((r) => r.json())
			.then((r) => (r ? r || null : null))
	},
	post_api: async ({ api_path, body }) => {
		const res = await Axios.post(`${endpoint}${api_path}`, body, window.getHeaders())
		if (res && res.status === 200 && res.data) {
			return res.data
		}
	},
	fetch_api_remote: async ({ api_path }) => {
		return fetch(`${api_path}`)
			.then((r) => r.json())
			.then((r) => (r ? r || null : null))
	},
	fetch_api_timeout: async ({ api_path }) => await axios.get(`${api_path}`, window.getHeaders(), { timeout: 30000 }),
}

function* fetcher_influencer_card_tiktok_meta({ payload: { api_path } }) {
	try {
		const result = yield call(api.fetch_api, { api_path })
		yield put({
			type: 'FETCH_INFLUENCER_CARD_TIKTOK_META',
			payload: result.data,
		})
	} catch (e) {}
}
function* fetcher_influencer_card_youtube_meta({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_CARD_YOUTUBE_META', payload: data.data })
	} catch (e) {}
}

function* fetcher_influencer_card_meta({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_CARD_META', payload: data.data })
	} catch (e) {}
}
function* fetcher_influencer_er_data({ payload: { persona_id, network } }) {
	try {
		const data = yield call(api.fetch_api, { api_path: `/api/infx/${persona_id}/report_er15/${network}` })
		yield put({
			type: 'FETCH_INFLUENCER_ER_DATA',
			payload: { data: data, network },
		})
	} catch (e) {}
}

function* fetcher_influencer_card_graphs({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_CARD_GRAPHS', payload: data })
	} catch (e) {}
}
function* fetcher_influencer_card_similar({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_CARD_SIMILAR', payload: data })
	} catch (e) {}
}
function* fetcher_influencer_card_er_days_15({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_CARD_ER_DAYS_15', payload: data })
	} catch (e) {}
}
function* fetcher_influencer_reports({ payload: { api_path } }) {
	try {
		const postBody = [
			'audience_interest_report',
			'audience_gender_report',
			'audience_age_report',
			'influencer_engagement_rate_report',
			'impression_rate_image_post_by_followers',
			'impression_rate_video_post_by_followers',
			'impression_rate_story_by_followers',
			'influencer_reels_play_rate_report',
			'hub_audience_interest_affinity_report',
		]
		const data = yield call(api.post_api, { api_path, body: postBody })
		yield put({ type: 'FETCH_INFLUENCER_REPORTS', payload: data })
	} catch (e) {}
}
function* fetcher_influencer_posts({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_POSTS', payload: { data } })
	} catch (e) {}
}
function* fetcher_influencer_highlights({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_HIGHLIGHTS', payload: { data } })
	} catch (e) {}
}
function* saver_influencer_highlights({ payload: { api_path, body } }) {
	try {
		const data = yield call(api.post_api, { api_path, body })
		yield put({ type: 'SAVE_INFLUENCER_HIGHLIGHTS', payload: { data } })
	} catch (e) {}
}
function* fetcher_influencer_projects({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({
			type: 'FETCH_INFLUENCER_PROJECTS',
			payload: { data: data.data },
		})
	} catch (e) {}
}
function* fetcher_influencer_price_info({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({
			type: 'FETCH_INFLUENCER_PRICE_INFO',
			payload: { data: data.data },
		})
	} catch (e) {}
}
function* fetcher_currency_info({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api_remote, { api_path })
		yield put({ type: 'FETCH_CURRENCY_INFO', payload: { data } })
	} catch (e) {}
}
function* fetcher_influencer_story_links({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_STORY_LINKS', payload: { data } })
	} catch (e) {}
}
function* fetcher_influencer_content_timestamps({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_CONTENT_TIMESTAMPS', payload: { data } })
	} catch (e) {}
}
function* fetcher_influencer_available_networks({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_AVAILABLE_NETWORKS', payload: data })
	} catch (e) {}
}
function* fetcher_influencer_messages({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_MESSAGES', payload: data })
	} catch (e) {}
}
function* fetcher_influencer_tiktok_posts({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		const ids = []
		const filteredData = _.compact(
			data.data.map((e) => {
				if (ids.indexOf(e.id.replace(/\?.*/g, '')) === -1) {
					ids.push(e.id)
					return e
				}
				return null
			})
		)
		yield put({ type: 'FETCH_INFLUENCER_TIKTOK_POSTS', payload: filteredData })
	} catch (e) {}
}
function* fetcher_influencer_rerun_similars({ payload: { api_path, persona_id, network = 'instagram' } }) {
	try {
		yield put({
			type: 'UPDATE_LOADABLE',
			payload: { key: 'rerun_similars', value: 'loading' },
		})
		const data = yield call(api.fetch_api, { api_path: `/api/infx/${persona_id}/similars_rerun/${network}` })
		yield fetcher_influencer_card_similar({
			payload: { api_path: `/api/infx/${persona_id}/similars/${network}` },
		})
		yield put({
			type: 'UPDATE_LOADABLE',
			payload: { key: 'rerun_similars', value: 'closed' },
		})
	} catch (e) {
		yield fetcher_influencer_card_similar({
			payload: { api_path: `/api/infx/${persona_id}/similars/${network}` },
		})
		yield put({
			type: 'UPDATE_LOADABLE',
			payload: { key: 'rerun_similars', value: 'closed' },
		})
	}
}
function* fetcher_influencer_youtube_posts({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_YOUTUBE_POSTS', payload: data })
	} catch (e) {}
}
function* fetcher_influencer_instagram_posts({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'FETCH_INFLUENCER_INSTAGRAM_POSTS', payload: data })
	} catch (e) {}
}
function* fetcher_influencer_instagram_hype_auditor_analysis({ payload: { api_path, network } }) {
	try {
		const data = yield call(api.post_api, { api_path })
		yield put({
			type: 'FETCH_INFLUENCER_HYPE_AUDITOR_ANALYSIS',
			payload: { data: data, network, last_update: Date.now() },
		})
	} catch (e) {
		yield put({
			type: 'FETCH_INFLUENCER_HYPE_AUDITOR_ANALYSIS',
			payload: { data: null, network, last_update: Date.now() },
		})
	}
}
function* fetcher_influencer_instagram_social_data_analysis({ payload: { api_path, network } }) {
	try {
		const data = yield call(api.post_api, { api_path })
		yield put({
			type: 'FETCH_INFLUENCER_SOCIAL_DATA_ANALYSIS',
			payload: { data: data?.data || null, network, last_update: Date.now() },
		})
	} catch (e) {
		yield put({
			type: 'FETCH_INFLUENCER_SOCIAL_DATA_ANALYSIS',
			payload: { data: null, network, last_update: Date.now() },
		})
	}
}
export function* fetch_influencer_card_tiktok_meta() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_CARD_TIKTOK_META', fetcher_influencer_card_tiktok_meta)
}
export function* fetch_influencer_card_youtube_meta() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_CARD_YOUTUBE_META', fetcher_influencer_card_youtube_meta)
}
export function* fetch_influencer_card_meta() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_CARD_META', fetcher_influencer_card_meta)
}

export function* fetch_influencer_card_graphs() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_CARD_GRAPHS', fetcher_influencer_card_graphs)
}

export function* fetch_influencer_card_similar() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_CARD_SIMILAR', fetcher_influencer_card_similar)
}

export function* fetch_influencer_card_er_days_15() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_CARD_ER_DAYS_15', fetcher_influencer_card_er_days_15)
}

export function* fetch_influencer_reports() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_REPORTS', fetcher_influencer_reports)
}
export function* fetch_influencer_posts() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_POSTS', fetcher_influencer_posts)
}
export function* fetch_influencer_highlights() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_HIGHLIGHTS', fetcher_influencer_highlights)
}
export function* save_influencer_highlights() {
	yield takeLatest('INFLUENCER_API_SAVE_INFLUENCER_HIGHLIGHTS', saver_influencer_highlights)
}
export function* fetch_influencer_projects() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_PROJECTS', fetcher_influencer_projects)
}
export function* fetch_influencer_price_info() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_PRICE_INFO', fetcher_influencer_price_info)
}
export function* fetch_currency_info() {
	yield takeLatest('CURRENCY_API_FETCH_CURR', fetcher_currency_info)
}
export function* fetch_influencer_story_links() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_STORY_LINKS', fetcher_influencer_story_links)
}
export function* fetch_influencer_content_timestamps() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_CONTENT_TIMESTAMPS', fetcher_influencer_content_timestamps)
}
export function* fetch_influencer_available_networks() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_AVAILABLE_NETWORKS', fetcher_influencer_available_networks)
}
export function* fetch_influencer_messages() {
	yield takeLatest('INFLUENCER_API_FETCH_INFLUENCER_MESSAGES', fetcher_influencer_messages)
}

export function* fetch_influencer_instagram_posts() {
	yield takeLatest('INFLUENCER_API_FETCH_INSTAGRAM_POSTS', fetcher_influencer_instagram_posts)
}
export function* fetch_influencer_tiktok_posts() {
	yield takeLatest('INFLUENCER_API_FETCH_TIKTOK_POSTS', fetcher_influencer_tiktok_posts)
}
export function* fetch_influencer_youtube_posts() {
	yield takeLatest('INFLUENCER_API_FETCH_YOUTUBE_POSTS', fetcher_influencer_youtube_posts)
}

export function* fetch_influencer_instagram_hype_auditor_analysis() {
	yield takeLatest('INFLUENCER_API_FETCH_HYPE_AUDITOR_ANALYSIS', fetcher_influencer_instagram_hype_auditor_analysis)
}
export function* fetch_influencer_instagram_social_data_analysis() {
	yield takeLatest('INFLUENCER_API_FETCH_SOCIAL_DATA_ANALYSIS', fetcher_influencer_instagram_social_data_analysis)
}
export function* fetch_influencer_er_data() {
	yield takeLatest('INFLUENCER_API_FETCH_ER_DATA', fetcher_influencer_er_data)
}

export function* fetch_influencer_rerun_similars() {
	yield takeLatest('INFLUENCER_API_FETCH_RERUN_SIMILARS', fetcher_influencer_rerun_similars)
}
