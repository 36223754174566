import { SideDrawer } from "xui";
import React, { useContext, useState } from "react";

interface SideDrawerProps {
  title?: string | null | undefined,
  footer?: null | React.ReactNode | undefined,
  width?: any | undefined,
  closeButton?: boolean | undefined,
  style?: any | undefined,
  content?: React.ReactNode | null | undefined,
  backdrop?: boolean,
}
interface SideDrawerContextState {
  visible: boolean,
  setVisible: (visible: boolean) => void,
  drawer: SideDrawerProps,
  createNewDrawer: (
    {
      title,
      footer,
      width,
      closeButton,
      style,
      content,
      backdrop,
    }: SideDrawerProps) => void,
  removeDrawer: () => void,
  createFooter: (footer?: null | React.ReactNode) => void,
}
const contextDefaultValues: SideDrawerContextState = {
  visible: false,
  setVisible: (visible: boolean) => { },
  drawer: {
    title: null,
    footer: null,
    width: null,
    closeButton: false,
    style: {},
    content: null,
    backdrop: true
  },

  createNewDrawer: (props: SideDrawerProps) => { },
  removeDrawer: () => { },
  createFooter: (footer?: null | React.ReactNode) => { },
};
const SideDrawerContext = React.createContext<SideDrawerContextState>(
  contextDefaultValues
);
export const useSideDrawerProvider = () => {
  return useContext(SideDrawerContext);
};
export const SideDrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const [visible, setVisible] = useState(false)
  const [drawer, setDrawer] = useState<SideDrawerProps>({
    title: null,
    footer: null,
    width: null,
    closeButton: false,
    style: {},
    content: null,
    backdrop: true
  })
  const createNewDrawer = ({
    title = null,
    footer = null,
    width = null,
    closeButton = false,
    style = {},
    content = null,
    backdrop = true
  }: SideDrawerProps) => {
    setDrawer({
      title,
      footer,
      width,
      closeButton,
      style,
      content,
      backdrop
    })
    setVisible(true)
  }
  const removeDrawer = () => {
    setDrawer({
      title: null,
      footer: null,
      width: null,
      closeButton: false,
      style: {},
      content: null,
      backdrop: true
    }); setVisible(false)
  }
  const createFooter = (footer?: null | React.ReactNode) => setDrawer(d => ({ ...d, footer: (footer || null) }))
  const value = {
    visible, setVisible, drawer, createNewDrawer, removeDrawer, createFooter
  }
  return <SideDrawerContext.Provider value={value}>
    {children}
    <SideDrawer
      isOpen={visible}
      setIsOpen={() => { setVisible(false); removeDrawer() }}
      title={drawer.title}
      footer={drawer.footer}
      width={drawer.width}
      closeButton={drawer.closeButton}
      style={{ ...drawer.style }}
      backdrop={drawer.backdrop || false}
    >
      {drawer.content}
    </SideDrawer>
  </SideDrawerContext.Provider>;
};
