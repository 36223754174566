import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'
type BrandNavigationProps = {
	title?: null | string | React.ReactNode | React.ReactElement
	rightComponent?: null | string | React.ReactNode | React.ReactElement
	tabs?: any
	currentTab?: any
	onTabSelected?: any
	goBackButton?: boolean
	onGoBack?: () => void
}

const BrandNavigation = ({ title = null, rightComponent = null, goBackButton, onGoBack }: BrandNavigationProps) => {
	return (
		<div className='sticky top-16 z-30 mb-3 flex h-auto w-full flex-row items-center justify-between rounded-lg border border-gray-200 bg-white p-3 shadow-md'>
			<div className='flex-shrink- flex w-auto flex-grow flex-row items-center gap-3'>
				{goBackButton ? (
					<div
						onClick={onGoBack}
						className='flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white transition-colors hover:bg-slate-200'
					>
						<ChevronLeftIcon className='h-6 w-6' />
					</div>
				) : null}
				<div className='line-clamp-1 min-w-[180px] text-lg font-semibold '>{title}</div>
			</div>
			<div className={'flex w-auto flex-shrink flex-grow flex-row justify-end gap-2'}>{rightComponent}</div>
		</div>
	)
}

export default BrandNavigation
