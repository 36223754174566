import React, { Children, FocusEvent, useEffect, useState } from 'react'
import { Button } from '../Button/Button'
const sizes = {
	// "small": "px-0.5 py-0 text-xs",
	small: 'px-1.5 py-0.5 text-xs',
	default: 'px-2.5 py-1 text-sm',
	large: 'px-4 py-2 text-tiny',
	xl: 'px-6 py-3 text-lg',
}

type InputProps = {
	autoFocus?: boolean
	pattern?: string
	onPressEnter?: any
	disabled?: boolean
	maxLength?: number
	prefix?: any
	size?: 'small' | 'default' | 'large' | 'xl'
	suffix?: any

	allowClear?: boolean
	placeholder?: string
	rounded?: string
	border?: string
	className?: string
	validated?: boolean
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>
const Input = ({
	autoFocus = false,
	onPressEnter = () => {},
	style = {},
	name,
	disabled = false,
	id,
	maxLength = 600,
	prefix,
	size = 'default',
	suffix,

	allowClear,
	placeholder = '',
	rounded = 'rounded-lg',
	border = 'border',
	className = '',
	validated = true,
	...rest
}: InputProps) => {
	return (
		<input
			placeholder={placeholder}
			disabled={disabled}
			id={id}
			maxLength={maxLength}
			type={rest.type || 'text'}
			autoFocus={autoFocus}
			onKeyDown={(event) => {
				if (event.key === 'Enter') onPressEnter()
			}}
			name={name}
			style={style}
			{...rest}
			className={`w-full ${sizes[size]} ${
				disabled ? 'bg-gray-200' : 'bg-white'
			} ${rounded} text-gray-600 shadow-sm focus:shadow-md ${
				disabled ? 'cursor-not-allowed' : ''
			} focus:ring-1 focus:ring-gray-400 ${border} ${
				validated ? 'border-gray-200' : 'border-red-400'
			} outline-none transition-all focus:outline-none ${className}`}
		/>
	)
}
type TextAreaProps = {
	defaultValue?: string
	name?: string
	disabled?: boolean
	id?: string
	maxLength?: number
	type?: string
	onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void
	onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void
	value?: any
	onChange: (d: React.ChangeEvent<HTMLTextAreaElement>) => void
	placeholder?: string
	className?: string
	size?: 'small' | 'default' | 'large' | 'xl'
}
const TextArea = ({
	defaultValue = '',
	disabled = false,
	id,
	size = 'default',
	maxLength = 1200,
	type = 'text',
	onChange,
	placeholder = '',
	name,
	value,
	className,
	onBlur = (e: FocusEvent<HTMLTextAreaElement>) => {},
	onFocus = (e: FocusEvent<HTMLTextAreaElement>) => {},
}: TextAreaProps) => {
	return (
		<textarea
			value={defaultValue || value}
			placeholder={placeholder}
			disabled={disabled}
			id={name || id}
			maxLength={maxLength}
			onBlur={onBlur}
			onFocus={onFocus}
			name={name}
			onChange={(e) => onChange(e)}
			className={`h-32 w-full  rounded-lg border border-gray-200 bg-white text-sm shadow-sm focus:shadow-md focus:ring-1 focus:ring-gray-400 ${sizes[size]} p-1 transition-all ${className}`}
		></textarea>
	)
}
type SearchProps = {
	defaultValue?: string
	autoFocus?: boolean
	pattern?: string
	onPressEnter?: any
	style?: object
	name?: string
	disabled?: boolean
	id?: string
	maxLength?: number
	prefix?: any
	size?: 'small' | 'default' | 'large' | 'xl'
	suffix?: any
	type?: any
	value?: any
	onChange?: (d: React.ChangeEvent<HTMLInputElement>) => void
	allowClear?: boolean
	placeholder?: string
	rounded?: string
	border?: string
	min?: number
	max?: number
	className?: string
	onSearch?: any
	onClick?: any
	icon?: any
	buttonTitle?: string
	enterButton?: any
	buttonDisabled?: boolean
	buttonType?: 'default' | 'link' | 'primary' | 'secondary' | 'dashed' | 'danger' | 'info'
}
const Search = ({
	defaultValue = '',
	disabled = false,
	id,
	maxLength = 600,
	prefix,
	size,
	style = {},
	suffix,
	type = 'text',
	value,
	onChange,
	onPressEnter,
	allowClear,
	placeholder = '',
	onSearch,
	onClick,
	icon,
	buttonTitle,
	enterButton = '',
	buttonDisabled = false,
	buttonType = 'default',
}: SearchProps) => {
	return (
		<div className='inline-flex w-auto space-x-1' style={style}>
			<Input
				onPressEnter={onPressEnter}
				value={value || defaultValue}
				disabled={disabled}
				onChange={onChange}
				placeholder={placeholder}
			></Input>
			<Button type={buttonType} size={size} disabled={disabled || buttonDisabled} onClick={onSearch}>
				{icon}
				{buttonTitle}
				{enterButton}
			</Button>
		</div>
	)
}
type GroupProps = { children?: any; overflow?: string; className?: string }
const Group = ({ children, overflow = 'overflow-hidden', className }: GroupProps) => {
	const [date, setDate] = useState(false)
	const [childrenWithProps, setChildrenWithProps] = useState(null)
	useEffect(() => {
		setChildrenWithProps(
			Children.map(children, (child) => {
				const clone = (
					<child.type key={child.key} ref={child.ref} {...child.props} rounded='' border='border-0' shadow='shadow-none' />
				)
				if ('showTime' in child.props) {
					setDate(true)
				}
				return clone
			})
		)
		return () => {
			setChildrenWithProps(null)
			setDate(false)
		}
	}, [children])

	return (
		<div className={`inline-flex rounded-lg ${date ? '' : overflow} border border-gray-200 shadow-sm ${className}`}>
			{childrenWithProps}
		</div>
	)
}
const Label = ({
	htmlFor = '',
	className = '',
	title = '',
	Icon,
}: {
	htmlFor?: string
	className?: string
	title: string
	Icon?: any
}) => {
	return (
		<label htmlFor={htmlFor} className={`mb-1 mt-3 inline-flex items-center font-semibold text-slate-700 ${className}`}>
			{Icon && <Icon className='mr-1 h-5 w-5' />}
			<p>{title}</p>
		</label>
	)
}
const FormItem = ({
	title = '',
	name = '',
	className = '',
	value = '',
	disabled = false,
	onChange,
	type = 'input',
	placeholder = '',
}: {
	title: string
	name: string
	className?: string
	value: string
	disabled?: boolean
	onChange: (d: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	type: 'input' | 'textarea'
	placeholder: string
}) => {
	return (
		<div className={`${className} flex flex-col space-y-2`}>
			<Label htmlFor={name} title={title} />
			{type === 'input' ? (
				<Input name={name} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} />
			) : (
				<TextArea name={name} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} />
			)}
		</div>
	)
}

export type TextAreaWithFooterProps = {
	children?: React.ReactNode
	info?: string
	header: React.ReactNode
	footer: React.ReactNode
	error?: undefined | string
	errorItem?: React.ReactNode
} & TextAreaProps
const TextAreaWithFooter = ({
	defaultValue = '',
	disabled = false,
	id,
	maxLength = 1200,
	type = 'text',
	onChange,
	placeholder = '',
	name,
	value,
	className,
	onBlur = (e: FocusEvent<HTMLTextAreaElement>) => {},
	onFocus = (e: FocusEvent<HTMLTextAreaElement>) => {},
	header,
	footer,
	error,
	errorItem,
	info,
}: TextAreaWithFooterProps) => {
	return (
		<div className='flex h-auto w-full flex-col rounded-lg'>
			<div className='flex h-auto w-full items-center rounded-t-lg border border-b-0 border-slate-200 px-2 py-3 shadow-md'>
				{header}
			</div>

			<textarea
				value={defaultValue || value}
				placeholder={placeholder}
				disabled={disabled}
				id={name || id}
				maxLength={maxLength}
				onBlur={onBlur}
				onFocus={onFocus}
				name={name}
				onChange={(e) => onChange(e)}
				className={`h-44 w-full border border-gray-200 bg-white p-1 px-2.5 py-1 text-sm transition-all focus:ring-1 ${className}`}
			></textarea>
			<div className='h-auto w-full rounded-b-lg border border-t-0 border-slate-200 px-2 py-3'>
				{error ? errorItem : footer}
			</div>
		</div>
	)
}
Input.TextArea = TextArea
Input.TextAreaWithFooter = TextAreaWithFooter
Input.Search = Search
Input.Group = Group
Input.Label = Label
Input.FormItem = FormItem
export { Input }
