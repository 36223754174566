import { useViewportSize } from '@mantine/hooks'

export function TailwindIndicator() {
  // eslint-disable-next-line no-undef
  if (import.meta.env.PROD) return null
  const { height, width } = useViewportSize()
  return (
    <div className='fixed bottom-1 right-1 z-50 flex h-6 w-32 items-center justify-center rounded-full bg-gray-800 p-3 font-mono text-xs text-white'>
      <div className='block sm:hidden'>xs</div>
      <div className='hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden'>sm</div>
      <div className='hidden md:block lg:hidden xl:hidden 2xl:hidden'>md</div>
      <div className='hidden lg:block xl:hidden 2xl:hidden'>lg</div>
      <div className='hidden xl:block 2xl:hidden'>xl</div>
      <div className='hidden 2xl:block'>2xl</div>
      <div>{`  - ${width}px`}</div>
    </div>
  )
}
