import { IconAt, IconCopy, IconHash, IconLink, IconPlayerPlay } from '@tabler/icons'
import dayjs from 'dayjs'
import { motion, useAnimation } from 'framer-motion'
import _ from 'lodash'
import { createContext, useContext, useEffect, useRef, useState } from 'react'

import useCampaign from 'hooks/useCampaign'
import { Button, Icons } from 'ui'
import { formatNum } from 'utils'
import { ReelsIcon, StoryIcon } from '../CampaignIcons'
import { ContentDetails } from './ContentDetails'

const color = {
	ig_post: 'bg-ig-post',
	ig_story: 'bg-ig-story',
	yt_post: 'bg-yt-post',
	tt_post: 'bg-tt-post',
}

export const ContentCard = ({
	data,
}: {
	data: ReturnType<ReturnType<typeof useCampaign>['get_content']>['contents'][number] | null
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)
	const onOpen = () => setIsOpen(true)
	if (data === null) return null
	return (
		<ContentCardContext.Provider value={{ ...data }}>
			<div className='group flex flex-col  h-auto w-full overflow-hidden  rounded-lg border border-slate-200 shadow-md'>
				<Header>
					<Avatar />
					<Col>
						<Username />
						<PublishDate />
					</Col>
					<Spacer />
					<InstagramContentType />
					<NetworkType />
				</Header>
				<div className=' relative h-72 '>
					<Image />
					<Overlay>
						<Hashtags />
						<Mentions />
						<ShowDetails />
					</Overlay>
					<Glossary />
					<BottomSheet onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
				</div>
			</div>
		</ContentCardContext.Provider>
	)
}

const getLikes = (content: ContentType['content']) => {
	if (content.content_type === 'ig_post') return content.likes
	if (content.content_type === 'ig_story') return null
	if (content.content_type === 'yt_post') return content.like_count
	if (content.content_type === 'tt_post') return content.likes_count
	return null
}

const getComments = (content: ContentType['content']) => {
	if (content.content_type === 'ig_post') return content.comments
	if (content.content_type === 'ig_story') return null
	if (content.content_type === 'yt_post') return content.comment_count
	if (content.content_type === 'tt_post') return content.comments_count
	return null
}

const getViewCount = (content: ContentType['content']) => {
	if (content.content_type === 'ig_post') return content.view_count
	if (content.content_type === 'ig_story') return content.view_count
	if (content.content_type === 'yt_post') return content.view_count
	if (content.content_type === 'tt_post') return content.views_count
	return null
}

const Glossary = () => {
	const { content } = useContentCardContext()
	if (content.content_type === 'ig_story') return null

	return (
		<div className='absolute z-10 bottom-16 h-auto flex items-center justify-center  w-auto left-0 right-0'>
			<div className='flex flex-row rounded-md font-semibold px-2 py-1 items-center bg-slate-900 text-white'>
				<div className='inline-flex w-full items-center justify-center space-x-1 px-1.5 py-0.5 font-semibold'>
					{content.is_video && (
						<div className='inline-flex items-center justify-center' style={{ height: 10 }}>
							<Icons.play size={12} />
							<b className='ml-1 text-xs'>{formatNum(content.video_play_count || content.video_view_count || 0)}</b>
						</div>
					)}
					{getViewCount(content) > 0 ? (
						<>
							<Icons.play size={12} />
							<b className='ml-1 text-xs'>{formatNum(getViewCount(content) || 0)}</b>
						</>
					) : null}
					<Icons.likes size={12} />
					<b className='ml-1 text-xs'>{getLikes(content) ? formatNum(getLikes(content) || 0) : '-'}</b>
					<Icons.comments size={12} />
					<b className='ml-1 text-xs'>{formatNum(getComments(content) || 0)}</b>
				</div>
			</div>
		</div>
	)
}
type ContentType = ReturnType<ReturnType<typeof useCampaign>['get_content']>['contents'][number]
const ContentCardContext = createContext<
	ReturnType<ReturnType<typeof useCampaign>['get_content']>['contents'][number] | null
>(null)

export function useContentCardContext() {
	const context = useContext(ContentCardContext)
	if (!context) {
		throw new Error('ProductCard.* component must be rendered as child of ProductCard component')
	}
	return context
}

const Header = ({ children }: { children?: React.ReactNode }) => {
	const { content, persona, insight } = useContentCardContext()
	return (
		<div
			className={`h-auto  w-full  items-center justify-center ${color[content.content_type]} flex flex-row gap-1 px-2 py-1`}
		>
			{children}
		</div>
	)
}
const Avatar = () => {
	const { content, persona, insight } = useContentCardContext()
	return (
		<img
			src={
				{
					ig_post: persona?.ig_account?.bucket_pp,
					ig_story: persona?.ig_account?.bucket_pp,
					tt_post: persona?.tt_account?.bucket_profile_picture,
					yt_post: persona?.yt_account?.thumbnail_url,
				}[content.content_type]
			}
			alt='inf_profile_picture'
			className='h-7 w-7 rounded-md object-cover'
		/>
	)
}
const NetworkType = () => {
	const { content } = useContentCardContext()
	return (
		<div className='text-sm font-semibold text-white'>
			{
				{
					ig_post: <Icons.instagram stroke={1.5} />,
					ig_story: <Icons.instagram stroke={1.5} />,
					yt_post: <Icons.youtubeReal color='white' />,
					tt_post: <Icons.tiktok stroke={1.5} />,
				}[content.content_type]
			}
		</div>
	)
}
const InstagramContentType = () => {
	const { content } = useContentCardContext()

	return (
		<div className='flex flex-shrink-0 flex-row items-center gap-1'>
			{content.content_type === 'ig_post' && content.product_type === 'clips' ? (
				<ReelsIcon className='h-[16px] w-[16px]' fill='white' />
			) : null}
			{content.content_type === 'ig_post' && content?.is_video ? <IconPlayerPlay fill='white' color='white' /> : null}
			{content.content_type === 'ig_story' ? <StoryIcon stroke='white' className='h-[16px] w-[16px]' /> : null}
			{content.content_type === 'ig_post' && content.is_carousel && <IconCopy color='white' stroke={1.5} />}
			{content.content_type === 'ig_story' && content?.story_cta_url && <IconLink color='white' stroke={1.4} />}
		</div>
	)
}
const Username = () => {
	const { content, persona } = useContentCardContext()
	return (
		<p className='line-clamp-1 w-auto flex-shrink truncate  text-sm font-semibold text-white'>
			{persona?.ig_account?.username || persona?.yt_account?.title || persona?.tt_account?.username}
		</p>
	)
}
const Spacer = () => {
	return <div className='flex-1' />
}
const Col = ({ children }: { children?: React.ReactNode }) => {
	return <div className='flex flex-shrink flex-col w-[calc(100%-85px)]'>{children}</div>
}
const PublishDate = () => {
	const { content } = useContentCardContext()
	return (
		<div className='text-xxs font-light text-white'>
			{dayjs((content?.taken_at_timestamp || 0) * 1000).format('DD/MM/YY')}
		</div>
	)
}
const Image = () => {
	const { content } = useContentCardContext()
	return (
		<img
			id={content._id}
			className='absolute inset-0 z-10 h-72 w-full object-cover object-center transition-all '
			src={
				content?.bucket_thumbnail_url || content?.bucket_content_url || content?.thumbnail_url || '/images/placeholder.png'
			}
			onError={(e) => {
				e.currentTarget.src = '/images/placeholder.png'
			}}
		/>
	)
}
const Overlay = ({ children }: { children?: React.ReactNode }) => {
	return (
		<div className='absolute inset-0 z-20 flex  flex-col items-center justify-center gap-1 bg-black bg-opacity-0 opacity-0 filter transition-all duration-300 group-hover:bg-opacity-20 group-hover:opacity-100 '>
			{children}
		</div>
	)
}
const ShowDetails = () => {
	const { content } = useContentCardContext()
	return (
		<ContentDetails>
			<Button>
				<div className='flex flex-row items-center justify-center text-black'>
					{
						{
							ig_post: <Icons.instagram stroke={1.5} />,
							ig_story: <StoryIcon stroke='black' className='h-[16px] w-[16px]' />,
							yt_post: <Icons.youtubeReal color='black' className='w-4 h-4' />,
							tt_post: <Icons.tiktok stroke={2} size={16} />,
						}[content.content_type]
					}
					<div className='ml-2 text-sm font-semibold '>Show Details</div>
				</div>
			</Button>
		</ContentDetails>
	)
}
const Hashtags = () => {
	const { content } = useContentCardContext()
	return (
		<>
			{_(content?.hashtags || [])
				.take(3)
				.map((hashtag) => (
					<div
						key={content._id + '_hashtag_' + hashtag}
						className='flex flex-row items-center justify-center gap-1 text-xs font-medium text-white'
					>
						<IconHash size={14} />
						<p>{hashtag}</p>
					</div>
				))
				.value()}
		</>
	)
}
const Mentions = () => {
	const { content } = useContentCardContext()
	return (
		<>
			{_(content?.mentions || [])
				.take(3)
				.map((hashtag) => (
					<div
						key={content._id + '_mention_' + hashtag}
						className='flex flex-row items-center justify-center gap-1 text-xs font-medium text-white'
					>
						<IconAt size={14} />
						<p>{hashtag}</p>
					</div>
				))
				.value()}
		</>
	)
}

ContentCard.Header = Header
ContentCard.Avatar = Avatar
ContentCard.NetworkType = NetworkType
ContentCard.InstagramContentType = InstagramContentType
ContentCard.Username = Username
ContentCard.Spacer = Spacer
ContentCard.Col = Col
ContentCard.PublishDate = PublishDate
ContentCard.Image = Image
ContentCard.Overlay = Overlay
ContentCard.ShowDetails = ShowDetails
ContentCard.Hashtags = Hashtags
ContentCard.Mentions = Mentions
ContentCard.BottomSheet = BottomSheet

function BottomSheet({ isOpen, onClose, onOpen }: { isOpen: boolean; onClose: () => void; onOpen: () => void }) {
	const prevIsOpen = usePrevious(isOpen)
	const controls = useAnimation()
	const { content } = useContentCardContext()

	function onDragEnd() {
		if (isOpen) {
			controls.start('hidden')
			onClose()
		} else {
			controls.start('visible')
			onOpen()
		}
	}

	useEffect(() => {
		if (prevIsOpen && !isOpen) {
			controls.start('hidden')
		} else if (!prevIsOpen && isOpen) {
			controls.start('visible')
		}
	}, [controls, isOpen, prevIsOpen])
	if (!(content.caption !== undefined || content.description !== undefined)) return null

	return (
		<div className={`absolute bottom-4 left-0 right-0 top-60 z-20 flex items-end`}>
			<motion.div
				initial='hidden'
				animate={controls}
				transition={{
					type: 'spring',
					damping: 40,
					stiffness: 400,
				}}
				variants={{
					visible: { y: 80 },
					hidden: { y: 260 },
				}}
				style={{
					display: 'inline-block',
					backgroundColor: 'white',

					width: '100%',
					height: 300,
					borderTopRightRadius: 8,
					borderTopLeftRadius: 8,
				}}
				className='border border-gray-200 px-1 shadow-md'
			>
				<div onClick={() => onDragEnd()} className='flex h-auto w-full items-center justify-center py-1'>
					<div className='h-1.5 w-12 cursor-pointer rounded-lg bg-gray-400'></div>
				</div>

				{
					<div
						onClick={() => onDragEnd()}
						className={`h-[220px] w-full ${isOpen ? 'overflow-y-auto' : 'overflow-y-hidden'
							} scrollable flex flex-col space-y-2 break-words p-2 text-xs`}
					>
						{content?.title ? <div className='mb-1 text-sm font-semibold'>{content.title}</div> : null}
						{content?.caption || content?.description}
					</div>
				}
			</motion.div>
		</div>
	)
}

function usePrevious(value) {
	const previousValueRef = useRef()

	useEffect(() => {
		previousValueRef.current = value
	}, [value])

	return previousValueRef.current
}
