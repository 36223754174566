import { Action } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { notification } from 'xui'
import { update_loadable } from './loading.saga'
const { endpoint, getHeaders } = window

interface FetchUserAction extends Action, IUser {
	type: 'PROFILE_API_GET_USER'
}
interface FetchBrandUpdatesAction extends Action, IUser {
	type: 'PROFILE_API_GET_BRAND_UPDATES'
}
interface PostBrandUpdatesAction extends Action, IPostBrandUpdate {
	type: 'PROFILE_API_POST_BRAND_UPDATES'
}
interface FetchPersonaLinkedTagOptions extends Action, IUser {
	type: 'ADMIN_API_GET_PERSONA_LINKED_TAG_OPTIONS'
}
interface IUser {
	payload: { api_path: string; type: string }
}

interface PostPersonaLinkedTagOptions extends Action, ITO {
	type: 'ADMIN_API_POST_PERSONA_LINKED_TAG_OPTIONS'
}
interface PostPersonaListDefaultTemplateI extends Action, DT {
	type: 'ADMIN_API_POST_LIST_SET_DEFAULT_TEMPLATE'
}
interface DT {
	payload: {
		api_path: string
		type: string
		network: string
		template_id: string
	}
}
interface ITO {
	payload: {
		api_path: string
		type: string
		name: string
		regex: string
		greentags: string[]
		match_type: string
		is_active: boolean
		is_private: boolean
		then: () => void
	}
}
interface IPostBrandUpdate {
	payload: {
		api_path: string
		type: string
		v: string
		title: string
		description: string
		_id?: string
		is_visible: boolean
	}
}

interface PatchUserDailyDigestAction extends Action, IUser {
	type: 'PROFILE_API_UPDATE_DAILY_DIGEST'
}
interface PatchUserInfXTemplate extends Action, IInfXTemplate {
	type: 'PROFILE_API_PATCH_UPDATE_INFX_TEMPLATE'
}
interface IInfXTemplate {
	payload: {
		api_path: string
		type: string
		network: 'instragram' | 'youtube' | 'tiktok'
		template_name: string
	}
}
interface PatchUserNameSurnameAction extends Action, IPatchUserNameSurname {
	type: 'PROFILE_API_PATCH_USER_NAME_SURNAME'
}
interface IPatchUserNameSurname {
	payload: {
		api_path: string
		type: string
		firstName: string
		lastName: string
	}
}
interface PostProfilePictureAction extends Action, IPostProfilePicture {
	type: 'PROFILE_API_POST_PROFILE_PICTURE'
}
interface IPostProfilePicture {
	payload: { api_path: string; type: string; photo: Blob }
}
interface PatchUserProfile extends Action, IPatchUserProfile {
	type: 'PROFILE_API_PATCH_USER'
}
interface IPatchUserProfile {
	payload: { api_path: string; type: string; others: Record<string, any> }
}

const api = {
	fetch_api: async ({ api_path }: { api_path: string }) =>
		fetch(`${endpoint}${api_path}`, getHeaders())
			.then((r) => r.json())
			.then((r) => (r && r.data ? r.data : null)),

	post_api: async ({ api_path, body }: { api_path: string; body: Record<string, any> }) => {
		return await axios.post(`${endpoint}${api_path}`, body, getHeaders())
	},
	patch_api: async ({ api_path, body }: { api_path: string; body: Record<string, any> }) => {
		return await axios.patch(`${endpoint}${api_path}`, body, getHeaders())
	},
} as Record<string, ({ api_path, body }: { api_path: string; body?: Record<string, any> }) => Promise<AxiosResponse>>

function* fetch_user({ payload: { api_path, type } }: FetchUserAction): any {
	try {
		const data = yield call(api?.[type], { api_path })
		yield put({ type: 'FETCH_ME', payload: data })
	} catch (e) {}
}

export function* fetch_user_api() {
	yield takeLatest<FetchUserAction>('PROFILE_API_GET_USER', fetch_user)
}

function* patch_user_name({ payload: { api_path, type, firstName, lastName } }: PatchUserNameSurnameAction): any {
	try {
		const data: AxiosResponse = yield call(api?.[type], { api_path, body: { firstName, lastName } })
		if (data.status === 200) {
			yield put({ type: 'UPDATE_USER_NAME_SURNAME', payload: data.data.data })
			notification.success({
				message: 'Name and Surname updated succesfully.',
				description: '-',
			})
		}
	} catch (e) {}
}

export function* patch_user_name_api() {
	yield takeLatest<PatchUserNameSurnameAction>('PROFILE_API_PATCH_USER_NAME_SURNAME', patch_user_name)
}
const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
function* patch_user_infx_template({ payload: { api_path, type, network, template_name } }: PatchUserInfXTemplate): any {
	try {
		const data: AxiosResponse = yield call(api?.[type], { api_path, body: { network, template_name } })
		if (data.status === 200) {
			yield put({ type: 'UPDATE_INFX_PROFILE', payload: data.data.data })
			notification.success({
				placement: 'bottom-right',
				message: 'Favorite template updated succesfully.',
				description: `Your template for ${capitalize(network || '')} has been updated to ${template_name}.`,
			})
		}
	} catch (e) {}
}

export function* patch_user_infx_template_api() {
	yield takeLatest<PatchUserInfXTemplate>('PROFILE_API_PATCH_UPDATE_INFX_TEMPLATE', patch_user_infx_template)
}
function* post_profile_picture({ payload: { api_path, type, photo } }: PostProfilePictureAction): any {
	const f = new FormData()
	f.append('file', photo)
	f.append('force', 'true')
	try {
		const data = yield call(api?.[type], { api_path, body: f })
		if (data.status === 200) {
			yield put({ type: 'UPDATE_PROFILE_PICTURE', payload: data.data.data })
			notification.success({
				message: 'Name and Surname updated succesfully.',
				description: '-',
			})
		}
	} catch (e) {}
}

export function* post_profile_picture_api() {
	yield takeLatest<PostProfilePictureAction>('PROFILE_API_POST_PROFILE_PICTURE', post_profile_picture)
}
function* patch_opt_in_daily_digest({ payload: { api_path, type } }: PatchUserDailyDigestAction): any {
	try {
		const data = yield call(api?.[type], { api_path, body: {} })
		if (data.status === 200) {
			yield put({ type: 'UPDATE_DAILY_DIGEST', payload: data.data.data })
			notification.success({
				message: 'Updated Daily digest mails.',
				description: '-',
			})
		}
	} catch (e) {}
}

export function* patch_opt_in_daily_digest_api() {
	yield takeLatest<PatchUserDailyDigestAction>('PROFILE_API_UPDATE_DAILY_DIGEST', patch_opt_in_daily_digest)
}
function* patch_user({ payload: { api_path, type, ...others } }: PatchUserProfile): any {
	try {
		const data = yield call(api?.[type], { api_path, body: { ...others } })
		if (data.status === 200) {
			yield put({ type: 'UPDATE_USER', payload: data.data.data })
			notification.success({
				message: 'Your profile updated succesfully.',
				description: '-',
			})
		}
	} catch (e) {}
}
export function* patch_user_api() {
	yield takeLatest<PatchUserProfile>('PROFILE_API_PATCH_USER', patch_user)
}
function* fetch_brand_updates({ payload: { api_path, type } }: FetchBrandUpdatesAction): any {
	try {
		const data = yield call(api?.[type], { api_path })
		yield put({ type: 'FETCH_BRAND_UPDATES', payload: data })
	} catch (e) {}
}

export function* fetch_brand_updates_api() {
	yield takeLatest<FetchBrandUpdatesAction>('PROFILE_API_GET_BRAND_UPDATES', fetch_brand_updates)
}

function* post_brand_updates({ payload: { api_path, type, ...rest } }: PostBrandUpdatesAction): any {
	try {
		yield put({
			type: 'UPDATE_LOADABLE',
			payload: { key: 'hub_news_list_edit', value: 'loading' },
		})
		const data = yield call(api?.[type], { api_path, body: { ...rest } })
		yield fetch_brand_updates({
			payload: { api_path: '/admin_api/brand_updates', type: 'fetch_api' },
			type: 'PROFILE_API_GET_BRAND_UPDATES',
		})
		yield put({
			type: 'UPDATE_LOADABLE',
			payload: { key: 'hub_news_list_edit', value: 'closed' },
		})
		yield put({ type: 'POST_BRAND_UPDATES', payload: data })
	} catch (e) {}
}

export function* post_brand_updates_api() {
	yield takeLatest<PostBrandUpdatesAction>('PROFILE_API_POST_BRAND_UPDATES', post_brand_updates)
}

function* fetch_persona_linked_tag_options({ payload: { api_path, type } }: FetchPersonaLinkedTagOptions): any {
	try {
		const data = yield call(api?.[type], { api_path })
		yield put({ type: 'PERSONA_LINKED_TAG_OPTIONS', payload: data })
	} catch (e) {}
}

export function* fetch_persona_linked_tag_options_api() {
	yield takeLatest<FetchPersonaLinkedTagOptions>(
		'ADMIN_API_GET_PERSONA_LINKED_TAG_OPTIONS',
		fetch_persona_linked_tag_options
	)
}

function* post_persona_linked_tag_options({
	payload: { api_path, type, name, regex, greentags, match_type, is_active, is_private, then },
}: PostPersonaLinkedTagOptions): any {
	yield update_loadable('create_post_persona_linked_tag_options', 'loading')
	try {
		const data = yield call(api?.[type], { api_path, body: { name, regex, greentags, match_type, is_active, is_private } })
		if (data.status === 200) {
			then()
			yield update_loadable('create_post_persona_linked_tag_options', 'closed')
			yield fetch_persona_linked_tag_options({
				payload: { api_path: '/admin_api/personalinkedtagoptions', type: 'fetch_api' },
				type: 'ADMIN_API_GET_PERSONA_LINKED_TAG_OPTIONS',
			})
			notification.success({
				message: 'Tag Created',
				description: '-',
			})
		}
	} catch (e) {
		yield update_loadable('create_post_persona_linked_tag_options', 'closed')
	}
}

export function* post_persona_linked_tag_options_api() {
	yield takeLatest<PostPersonaLinkedTagOptions>(
		'ADMIN_API_POST_PERSONA_LINKED_TAG_OPTIONS',
		post_persona_linked_tag_options
	)
}
function* post_admin_list_default_template({
	payload: { api_path, type, template_id, network },
}: PostPersonaListDefaultTemplateI): any {
	yield update_loadable('create_post_persona_linked_tag_options', 'loading')
	try {
		const data = yield call(api?.[type], { api_path, body: { template_id, network } })
		if (data.status === 200) {
			yield update_loadable('create_post_persona_linked_tag_options', 'closed')
			notification.success({
				message: 'Default Template Updated',
				description: '-',
			})
		}
	} catch (e) {
		yield update_loadable('create_post_persona_linked_tag_options', 'closed')
	}
}

export function* post_admin_list_default_template_api() {
	yield takeLatest<PostPersonaListDefaultTemplateI>(
		'ADMIN_API_POST_LIST_SET_DEFAULT_TEMPLATE',
		post_admin_list_default_template
	)
}
