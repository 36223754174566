function H1({ children }: { children: React.ReactNode }) {
	return <h1 className='scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl'>{children}</h1>
}
function H2({ children }: { children: React.ReactNode }) {
	return (
		<h2 className='scroll-m-20 pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0'>{children}</h2>
	)
}
function H3({ children }: { children: React.ReactNode }) {
	return <h3 className='scroll-m-20 text-2xl font-semibold tracking-tight'>{children}</h3>
}

function H4({ children }: { children: React.ReactNode }) {
	return <h4 className='scroll-m-20 text-xl font-semibold tracking-tight'>{children}</h4>
}

function Lead({ children }: { children: React.ReactNode }) {
	return <p className='text-xl text-muted-foreground'>{children}</p>
}
function P({ children }: { children: React.ReactNode }) {
	return <p className='leading-7 [&:not(:first-child)]:mt-6'>{children}</p>
}
function Small({ children }: { children: React.ReactNode }) {
	return <small className='text-sm font-medium leading-none'>{children}</small>
}
function Muted({ children }: { children: React.ReactNode }) {
	return <p className='text-sm text-muted-foreground'>{children}</p>
}
export { H1, H2, H3, H4, Lead, P, Small, Muted }
