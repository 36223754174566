import { call, put, takeLatest } from 'redux-saga/effects'
const { endpoint } = window

const api = {
	fetch_api: async ({ api_path }) =>
		fetch(`${endpoint}${api_path}`, window.getHeaders())
			.then((r) => r.json())
			.then((r) => (r && r.data ? r.data : null)),

	post_api: async (payload) => {
		const { api_path, body } = payload
		return fetch(`${endpoint}${api_path}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify(body),
		})
			.then((r) => r.json())
			.then((r) => (r && r.data ? r.data : null))
	},
}

function* get_estimation_coefficients_request({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		yield put({ type: 'ESTIMATION_COEFFICIENTS', payload: data })
	} catch (e) {}
}

function* post_send_feedback_request({ payload: { feedback } }) {
	try {
		const data = yield call(api.post_api, { api_path: `/api/hub_settings/feedback`, body: { feedback } })
		if (!data) {
			throw new Error('request error')
		}
		yield put({ type: 'FEEDBACK_SENT' })
	} catch (e) {
		yield put({ type: 'FEEDBACK_FAIL' })
	}
}

export function* get_estimation_coefficients() {
	yield takeLatest('GET_ESTIMATION_COEFFICIENTS', get_estimation_coefficients_request)
}

export function* post_send_feedback() {
	yield takeLatest('FEEDBACK_SEND', post_send_feedback_request)
}
