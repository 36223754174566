/* eslint-disable react/no-unknown-property */
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ReloadPrompt from 'components/ReloadPrompt/ReloadPrompt'
import { SiteLayout } from 'components/SiteLayout'
import { TailwindIndicator } from 'components/TailwindIndicator'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import localeData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { AuthProvider } from 'providers/AuthProvider'
import { SideDrawerProvider } from 'providers/SideDrawerProvider'
import { Suspense, lazy } from 'react'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Provider } from 'react-redux'
import { Outlet, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'
import 'react-table/react-table.css'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import './App.css'
import IXLoading from './components/IXLoading'
import './components/hoc-fixed-table/styles.css'

// import { ThemeProvider } from 'ui/ThemeProvider'
import configureStore from './redux/configureStore'

import axios from 'axios'
import ScrollToTop from 'components/ScrollToTop'
import { endpoint } from 'config/api'
import { auth_routes } from 'containers/Auth'
import InfXNetworkLoader from 'containers/InfluencerX/page'
import TestPage from 'containers/test'
import { queryClient } from 'qc'
import 'react-notifications-component/dist/theme.css'
import { Toaster } from 'ui/Toaster'
import { brand_dashboard_routes } from './containers/BrandDashboard/BrandDashboardRoutes'
import { campaign_routes } from './containers/Campaign/CampaignRoutes'
import { campaign_tokenized_routes } from './containers/Campaign/CampaignTokenizedRoutes'
const store = configureStore()

// broadcastQueryClient({
// 	queryClient,
// 	broadcastChannel: 'creatorhub-broadcast-channel',
// })
const suffixes = {
	1: "'inci",
	5: "'inci",
	8: "'inci",
	70: "'inci",
	80: "'inci",
	2: "'nci",
	7: "'nci",
	20: "'nci",
	50: "'nci",
	3: "'üncü",
	4: "'üncü",
	100: "'üncü",
	6: "'ncı",
	9: "'uncu",
	10: "'uncu",
	30: "'uncu",
	60: "'ıncı",
	90: "'ıncı",
}

dayjs.locale('tr', {
	months: 'Ocak_Şubat_Mart_Nisan_Mayıs_Haziran_Temmuz_Ağustos_Eylül_Ekim_Kasım_Aralık'.split('_'),
	monthsShort: 'Oca_Şub_Mar_Nis_May_Haz_Tem_Ağu_Eyl_Eki_Kas_Ara'.split('_'),
	weekdays: 'Pazar_Pazartesi_Salı_Çarşamba_Perşembe_Cuma_Cumartesi'.split('_'),
	weekdaysShort: 'Paz_Pts_Sal_Çar_Per_Cum_Cts'.split('_'),
	weekdaysMin: 'Pz_Pt_Sa_Ça_Pe_Cu_Ct'.split('_'),
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD.MM.YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY HH:mm',
		LLLL: 'dddd, D MMMM YYYY HH:mm',
	},
	calendar: {
		sameDay: '[bugün saat] LT',
		nextDay: '[yarın saat] LT',
		nextWeek: '[gelecek] dddd [saat] LT',
		lastDay: '[dün] LT',
		lastWeek: '[geçen] dddd [saat] LT',
		sameElse: 'L',
	},
	relativeTime: {
		future: '%s sonra',
		past: '%s önce',
		s: 'birkaç saniye',
		ss: '%d saniye',
		m: 'bir dakika',
		mm: '%d dakika',
		h: 'bir saat',
		hh: '%d saat',
		d: 'bir gün',
		dd: '%d gün',
		M: 'bir ay',
		MM: '%d ay',
		y: 'bir yıl',
		yy: '%d yıl',
	},
	ordinal(number, period) {
		switch (period) {
			case 'd':
			case 'D':
			case 'Do':
			case 'DD':
				return number
			default:
				if (number === 0) {
					// special case for zero
					return `${number}'ıncı`
				}
				var a = number % 10
				var b = (number % 100) - a
				var c = number >= 100 ? 100 : null
				return number + (suffixes[a] || suffixes[b] || suffixes[c])
		}
	},
	week: {
		dow: 1, // Monday is the first day of the week.
		doy: 7, // The week that contains Jan 7th is the first week of the year.
	},
})
dayjs.extend(duration)
dayjs.extend(weekOfYear)
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)

dayjs.extend(relativeTime)
dayjs.extend(localeData)
dayjs().localeData()

function Loading() {
	return (
		<div className='fixed inset-0 bg-gray-300'>
			<IXLoading text='Please wait.' show />
		</div>
	)
}
const InfXLazy = lazy(() => import('./containers/InfluencerX'))
const AdminPageLazy = lazy(() => import('./containers/Admin'))
const CreatorDenReportLazy = lazy(() => import('./containers/CreatorDenReport'))
const FAQLazy = lazy(() => import('./containers/FAQ'))
const FeedLazy = lazy(() => import('./containers/Feed'))
const HubProfileLazy = lazy(() => import('./containers/HubProfile'))
const ProfileLazy = lazy(() => import('./containers/Profile'))
const UserHomeLazy = lazy(() => import('./containers/UserHome'))
const HomeLazy = lazy(() => import('containers/Home'))
const QRLazy = lazy(() => import('containers/QR'))
const TagsLazy = lazy(() => import('containers/Tags'))
const Identification = lazy(() => import('containers/Identification'))
// const App = function () {
// 	const { loggedIn, getUser } = useAuthProvider()
// 	const is_admin = useMemo(() => getUser()?.role === 'admin', [getUser()?.role])
// 	// const is_brand = getUser().role === 'brand' || getUser().role === 'admin';
// 	const is_only_brand = useMemo(() => getUser()?.role === 'brand' || getUser()?.role === 'none', [getUser()?.role])
//
// 	// const is_campaign = window.location.pathname.substring(0, 3) === '/c/'
// 	// const is_profile_alt = !!_.find(
// 	// 	[
// 	// 		'doguhanokumus',
// 	// 		'niyazisunter',
// 	// 		'beriloktay',
// 	// 		'aykutcetinkaya',
// 	// 		'canerdundar',
// 	// 		'mervedesen',
// 	// 		'ozantabak',
// 	// 		'tulinyilmamm',
// 	// 		'mustafabulek',
// 	// 		'mervebozkir',
// 	// 		'dilandag',
// 	// 		'pinarocbe',
// 	// 		'emrebakbak',
// 	// 		'battalmertkaya',
// 	// 	],
// 	// 	(x) => window.location.pathname.indexOf(`/${x}`) === 0
// 	// )
// 	// const is_qr_profile = is_profile_alt || window.location.pathname.substr(0, 3) === '/p/'
// 	// // const { is_campaigns, is_feed, is_report, is_projects } = state;
//
// 	// if (is_campaign) {
// 	// 	return (
// 	// 		<div className='CRoot'>
// 	// 			<Routes>
// 	// 				<Route
// 	// 					path='/c/*'
// 	// 					element={
// 	// 						<Suspense fallback={<Loading />}>
// 	// 							<CampaignTokenizedLazy />
// 	// 						</Suspense>
// 	// 					}
// 	// 				/>
// 	// 			</Routes>
// 	// 		</div>
// 	// 	)
// 	// } else if (is_qr_profile && is_profile_alt) {
// 	// 	return (
// 	// 		<div className='CRoot'>
// 	// 			<Routes>
// 	// 				<Route
// 	// 					path=':name'
// 	// 					element={
// 	// 						<Suspense>
// 	// 							<ProfileLazy />
// 	// 						</Suspense>
// 	// 					}
// 	// 				/>
// 	// 			</Routes>
// 	// 		</div>
// 	// 	)
// 	// } else if (is_qr_profile && !is_profile_alt) {
// 	// 	return (
// 	// 		<div className='CRoot'>
// 	// 			<Routes>
// 	// 				<Route
// 	// 					path='p'
// 	// 					element={
// 	// 						<Suspense fallback={<Loading />}>
// 	// 							<ProfileLazy />
// 	// 						</Suspense>
// 	// 					}
// 	// 				>
// 	// 					<Route
// 	// 						path=':name'
// 	// 						element={
// 	// 							<Suspense fallback={<Loading />}>
// 	// 								<ProfileLazy />
// 	// 							</Suspense>
// 	// 						}
// 	// 					/>
// 	// 				</Route>
// 	// 			</Routes>
// 	// 		</div>
// 	// 	)
// 	// }
//
// 	return (
// 		<Routes>
// 			{loggedIn ? (
// 				<Route path='/' element={<SiteLayout />}>
// 					{is_only_brand ? (
// 						<>
// 							<Route
// 								id='brand_home'
// 								index
// 								element={
// 									<Suspense fallback={<Loading />}>
// 										<UserHomeLazy />
// 									</Suspense>
// 								}
// 							/>
// 						</>
// 					) : (
// 						<>
// 							<Route
// 								id='identification'
// 								index
// 								element={
// 									<Suspense fallback={<Loading />}>
// 										<Identification />
// 									</Suspense>
// 									// <Identification />
// 								}
// 							/>
// 							<Route
// 								id='identification-beta'
// 								path='old-identification'
// 								element={
// 									<Suspense fallback={<Loading />}>
// 										<HomeLazy />
// 									</Suspense>
// 								}
// 							/>
// 							<Route
// 								id='brand_home'
// 								path='home'
// 								element={
// 									<Suspense fallback={<Loading />}>
// 										<UserHomeLazy />
// 									</Suspense>
// 								}
// 							/>
// 						</>
// 					)}
// 					{is_admin ? (
// 						<Route
// 							id='admin'
// 							path='admin'
// 							element={
// 								<Suspense fallback={<Loading />}>
// 									<AdminPageLazy />
// 								</Suspense>
// 							}
// 						/>
// 					) : null}
// 					{!is_only_brand ? (
// 						<Route
// 							id='reports'
// 							path='CreatorDenReports'
// 							element={
// 								<Suspense fallback={<Loading />}>
// 									<CreatorDenReportLazy />
// 								</Suspense>
// 							}
// 						/>
// 					) : null}
// 					{!is_only_brand ? (
// 						<Route
// 							id='faq'
// 							path='faq'
// 							element={
// 								<Suspense fallback={<Loading />}>
// 									<FAQLazy />
// 								</Suspense>
// 							}
// 						/>
// 					) : null}
// 					{!is_only_brand ? (
// 						<Route
// 							id='profile'
// 							path='profile'
// 							element={
// 								<Suspense fallback={<Loading />}>
// 									<HubProfileLazy />
// 								</Suspense>
// 							}
// 						/>
// 					) : null}
// 					<Route id='testPage' path='tests' element={<TestPage />} />
//
// 					{!is_only_brand ? (
// 						<Route
// 							id='feed'
// 							path='feed'
// 							element={
// 								<Suspense fallback={<Loading />}>
// 									<FeedLazy />
// 								</Suspense>
// 							}
// 						/>
// 					) : null}
// 					{!is_only_brand ? (
// 						<Route
// 							id='tags'
// 							path='tags'
// 							element={
// 								<Suspense fallback={<Loading />}>
// 									<TagsLazy />
// 								</Suspense>
// 							}
// 						/>
// 					) : null}
// 					{!is_only_brand ? (
// 						<Route id='infx' path='x'>
// 							<Route path=':persona_id'>
// 								<Route index element={<InfXNetworkLoader />} />
// 								<Route
// 									path={':network'}
// 									element={
// 										<Suspense fallback={<Loading />}>
// 											<InfXLazy />
// 										</Suspense>
// 									}
// 								/>
// 							</Route>
// 						</Route>
// 					) : null}
// 					{!is_only_brand ? (
// 						<Route
// 							id='qr'
// 							path='qr'
// 							element={
// 								<Suspense fallback={<Loading />}>
// 									<QRLazy />
// 								</Suspense>
// 							}
// 						/>
// 					) : null}
//
// 					<Route
// 						id='campaigns'
// 						path='campaigns/*'
// 						element={
// 							<Suspense fallback={<Loading />}>
// 								<CampaignLazy />
// 							</Suspense>
// 						}
// 					/>
// 					<Route
// 						id='brand'
// 						path='brand/*'
// 						element={
// 							<Suspense fallback={<Loading />}>
// 								<BrandRoutesLazy />
// 							</Suspense>
// 						}
// 					/>
// 					{/* <Route */}
// 					{/* 	path="ping" */}
// 					{/* 	element={null} */}
// 					{/* /> */}
// 					<Route path='*' element={<Navigate to={'/'} />} />
// 				</Route>
// 			) : (
// 				<Route
// 					path='*'
// 					element={
// 						<Suspense fallback={<Loading />}>
// 							<AuthRoutesLazy />
// 						</Suspense>
// 					}
// 				/>
// 			)}
// 			<Route
// 				path='/c/*'
// 				element={
// 					<Suspense fallback={<Loading />}>
// 						<CampaignTokenizedLazy />
// 					</Suspense>
// 				}
// 			/>
// 			<Route
// 				path='p'
// 				element={
// 					<Suspense fallback={<Loading />}>
// 						<ProfileLazy />
// 					</Suspense>
// 				}
// 			>
// 				<Route
// 					path=':name'
// 					element={
// 						<Suspense fallback={<Loading />}>
// 							<ProfileLazy />
// 						</Suspense>
// 					}
// 				/>
// 			</Route>
// 		</Routes>
// 	)
// }

const AppContainer = () => (
	<QueryParamProvider adapter={ReactRouter6Adapter}>
		<div className='Root'>
			<TailwindIndicator />
			<ReactNotifications />
			<QueryClientProvider client={queryClient}>
				<ReactQueryDevtools buttonPosition='bottom-left' initialIsOpen={false} />
				<Provider store={store}>
					<ScrollToTop />
					<AuthProvider>
						<SideDrawerProvider>
							<Outlet />
						</SideDrawerProvider>
					</AuthProvider>
				</Provider>
			</QueryClientProvider>
			<ReloadPrompt />
		</div>
		<Toaster />
	</QueryParamProvider>
)

const isOnlyBrand = () => {
	const user = JSON.parse(localStorage.getItem('user'))
	return user.role === 'brand' || user.role === 'none'
}
const isAdmin = () => {
	const user = JSON.parse(localStorage.getItem('user'))
	return user.role === 'admin'
}
const r = createBrowserRouter([
	{
		path: '/',
		element: <AppContainer />,
		children: [
			{
				path: '/',
				id: 'root',
				loader: async ({ context, params, request }) => {
					try {
						if (request.url.includes('reset_token')) {
							const token = request.url.split('reset_token=')[1]
							return redirect('/auth/reset-password' + '?reset_token=' + token)
						}
						if (!queryClient.getQueryData(['HUB', 'AUTH'])) {
							const data = await axios.get(`${endpoint}/auth/check`, { withCredentials: true, timeout: 10000 })
							if (data.status !== 200) return redirect('/auth/login')

							queryClient.setQueryData(['HUB', 'AUTH'], data.data)

							return { user: data }
						}
						return null
					} catch (e) {
						return redirect('/auth/login')
					}
				},
				element: <SiteLayout />,
				children: [
					{
						id: 'identification',
						index: true,
						loader: async () => {
							if (isOnlyBrand()) return redirect('/home')
							return null
						},
						element: (
							<Suspense fallback={<Loading />}>
								<Identification />
							</Suspense>
						),
					},
					{
						loader: async () => {
							if (isOnlyBrand()) return redirect('/home')
							return null
						},

						path: 'old-identification',
						element: (
							<Suspense fallback={<Loading />}>
								<HomeLazy />
							</Suspense>
						),
					},
					{
						path: 'test',
						element: <TestPage />,
					},
					{
						path: 'home',

						element: (
							<Suspense fallback={<Loading />}>
								<UserHomeLazy />
							</Suspense>
						),
					},
					{
						loader: async () => {
							if (isAdmin()) return null
							return redirect('/home')
						},

						path: 'admin',
						element: (
							<Suspense fallback={<Loading />}>
								<AdminPageLazy />
							</Suspense>
						),
					},
					{
						loader: async () => {
							if (isOnlyBrand()) return redirect('/home')
							return null
						},

						path: 'CreatorDenReports',
						element: (
							<Suspense fallback={<Loading />}>
								<CreatorDenReportLazy />
							</Suspense>
						),
					},
					brand_dashboard_routes,
					{
						loader: async () => {
							if (isOnlyBrand()) return redirect('/home')
							return null
						},

						path: 'faq',
						element: (
							<Suspense fallback={<Loading />}>
								<FAQLazy />
							</Suspense>
						),
					},
					{
						path: 'campaigns',
						children: campaign_routes,
					},
					{
						loader: async () => {
							if (isOnlyBrand()) return redirect('/home')
							return null
						},
						path: 'feed',
						element: (
							<Suspense fallback={<Loading />}>
								<FeedLazy />
							</Suspense>
						),
					},
					{
						path: 'profile',
						element: (
							<Suspense fallback={<Loading />}>
								<HubProfileLazy />
							</Suspense>
						),
					},
					{
						loader: async () => {
							if (isOnlyBrand()) return redirect('/home')
							return null
						},
						path: 'tags',
						element: (
							<Suspense fallback={<Loading />}>
								<TagsLazy />
							</Suspense>
						),
					},
					{
						loader: async () => {
							if (isOnlyBrand()) return redirect('/home')
							return null
						},

						path: 'qr',
						element: (
							<Suspense fallback={<Loading />}>
								<QRLazy />
							</Suspense>
						),
					},
					{
						loader: async () => {
							if (isOnlyBrand()) return redirect('/home')
							return null
						},

						path: 'x',
						children: [
							{
								path: ':persona_id',
								loader: async () => {
									if (isOnlyBrand()) return redirect('/home')
									return null
								},

								children: [
									{
										index: true,
										loader: async () => {
											if (isOnlyBrand()) return redirect('/home')
											return null
										},

										element: (
											<Suspense fallback={<Loading />}>
												<InfXNetworkLoader />
											</Suspense>
										),
									},
									{
										path: ':network',
										loader: async () => {
											if (isOnlyBrand()) return redirect('/home')
											return null
										},

										element: (
											<Suspense fallback={<Loading />}>
												<InfXLazy />
											</Suspense>
										),
									},
								],
							},
						],
					},
				],
			},
			auth_routes,
			{
				path: 'p',
				element: (
					<Suspense fallback={<Loading />}>
						<ProfileLazy />
					</Suspense>
				),
			},
			{
				path: 'c',
				children: campaign_tokenized_routes,
			},
			{
				path: ':name',
				element: (
					<Suspense fallback={<Loading />}>
						<ProfileLazy />
					</Suspense>
				),
			},
		],
	},
])
export default function Page() {
	return <RouterProvider router={r} />
}
