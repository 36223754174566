import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'xui'
import Flex from './Flex'

export default function IXLoading({
	closeable = false,
	show = false,
	text = 'SAYFA YÜKLENİYOR',
	is_absolute = false,
	block = false,
}) {
	const [should_hide, set_should_hide] = React.useState(false)

	React.useEffect(() => {
		set_should_hide(false)
	}, [show ? '1' : '2'])

	if (!show || should_hide) return null

	return (
		<>
			{block && <div className='blocker'></div>}
			<div
				style={{
					...{
						position: 'fixed',
						top: 'calc(50% - 60px)',
						left: 'calc(50% - 200px)',
						width: 400,
						height: 120,
						border: '1px solid var(--main-low)',
						borderRadius: 7,
						backgroundColor: '#fff',
						zIndex: 999,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					},
					...(is_absolute ? { position: 'absolute', zIndex: 6 } : {}),
				}}
			>
				<Flex justify={'center'} align={'center'} style={{ width: '100%' }}>
					<img style={{ height: 20, width: 'auto' }} src={'/images/logo-shub.png'} alt={'shub'} />
				</Flex>
				<Flex className={'loading-indicator'} justify={'center'} align={'center'} style={{ fontSize: 15, marginTop: 10 }}>
					<LoadingSpinner />
					<div
						style={{ fontWeight: 500, color: 'var(--main)', textAlign: 'center', textTransform: 'uppercase', marginRight: 8 }}
					>
						{text}
					</div>
				</Flex>
				{closeable ? (
					<Button
						onClick={() => set_should_hide(true)}
						style={{ fontSize: 12, fontWeight: '800', marginTop: 5 }}
						size={'small'}
						type={'dashed'}
					>
						HIDE
					</Button>
				) : null}
			</div>
		</>
	)
}

IXLoading.propTypes = {
	show: PropTypes.bool,
	text: PropTypes.string,
}

const LoadingSpinner = () => (
	<svg
		className='ml-2 mr-2 h-4 w-4 flex-shrink-0 flex-grow animate-spin text-sky-700'
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
	>
		<circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
		<path
			className='opacity-75'
			fill='currentColor'
			d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
		></path>
	</svg>
)
