import useOutsideClick from 'hooks/useOutsideClick'
import { useEffect, useRef, useState } from 'react'

const placements = {
	top: 'top-0 transform -translate-y-full',
	'top-start': 'top-0 left-0 transform -translate-y-full',
	'top-end': 'top-0 left-0 transform -translate-y-full',

	right: 'bottom-0 right-0 transform  translate-y-full',
	'right-start': 'top-0 -right-2 transform  translate-x-full',
	'right-end': 'bottom-0 -right-2 transform  translate-x-full ',

	bottom: 'bottom-0  left-1/2 -translate-x-1/2 translate-y-full',
	'bottom-start': 'bottom-0 left-0  translate-y-full',
	'bottom-end': '-bottom-2 right-0  translate-y-full mt-1',

	left: 'left-0 top-0  -translate-x-full ',
	'left-start': '',
	'left-end': '',
}
type PopoverProps = {
	content: any
	children: any
	placement?:
		| 'top'
		| 'top-start'
		| 'top-end'
		| 'right'
		| 'right-start'
		| 'right-end'
		| 'bottom'
		| 'bottom-start'
		| 'bottom-end'
		| 'left'
		| 'left-start'
		| 'left-end'
	trigger?: 'click' | 'hover'
	popoverClasses?: string
	visible?: boolean
	set_visible?: (visible: boolean) => void
	zIndex?: number
}

const PopoverCSS = ({
	content,
	children,
	placement = 'bottom',
	trigger = 'click',
	popoverClasses = '',
	visible,
	set_visible,
	zIndex = 80,
}: PopoverProps) => {
	const [open, setOpen] = useState(false)
	useEffect(() => {
		setOpen(visible || false)
		return () => {
			setOpen(false)
		}
	}, [visible])

	useEffect(() => {
		if (set_visible) set_visible(open)
	}, [open, set_visible])
	const node = useRef<HTMLDivElement | null>(null)
	useOutsideClick(node, () => {
		setOpen(false)
	})
	return (
		<>
			<div
				ref={node}
				className={`relative flex items-center justify-center z-${zIndex}`}
				{...(trigger === 'hover'
					? {
							onMouseEnter: () => setOpen(true),
							onMouseLeave: () => setOpen(false),
					  }
					: {
							onClick: () => {
								if (!open && !set_visible) setOpen(true)
							},
					  })}
			>
				<div
					onClick={() => {
						if (open && set_visible) setOpen(false)
					}}
					className={'w-full appearance-none'}
				>
					{typeof children === 'function' ? children({ props: { open } }) : children}
				</div>
				<div
					style={{ zIndex: zIndex }}
					className={`absolute bg-white p-2 transition-all ease-in-out ${placements[placement]} ${
						open ? 'pointer-events-auto flex opacity-100' : 'pointer-events-none opacity-0'
					}  rounded-lg border border-gray-200 shadow-md ${popoverClasses}`}
				>
					{typeof content === 'function' ? content({ props: { open } }) : content}
				</div>
			</div>
		</>
	)
}

const Label = ({ children, className }: { children: any; className: string }) => {
	return <div className={`text-sm text-gray-600 ${className} `}>{children}</div>
}
PopoverCSS.Label = Label
const Divider = () => {
	return <div className='w-full border-b border-gray-300'></div>
}
PopoverCSS.Divider = Divider

export { PopoverCSS }
