import {
	UseSuspenseQueryOptions,
	UseSuspenseQueryResult,
	useMutation,
	useQuery,
	useSuspenseQuery,
} from '@tanstack/react-query'
import { influencerApi } from 'config/api'
import { useParams } from 'react-router-dom'
import { apiConfig } from './api'
import { Instagram } from './influencer.instagram.types'
import { Projects } from './influencer.projects.types'
import { Similars } from './influencer.similar.types'
import { DataRoot, Tiktok } from './influencer.tiktok.types'
import { Youtube } from './influencer.youtube.types'

type SocialMediaData<Platform extends string> = Platform extends 'instagram'
	? Instagram
	: Platform extends 'tiktok'
	? Tiktok
	: Platform extends 'youtube'
	? Youtube
	: // Add more platforms and their corresponding data types here
	never

type GetSocialMediaDataOptions<Platform extends string> = UseSuspenseQueryOptions<
	SocialMediaData<Platform>,
	undefined,
	SocialMediaData<Platform>,
	string[]
>

export function useGetSocialMediaData<Platform extends string = 'instagram'>(
	options?: Omit<GetSocialMediaDataOptions<Platform>, 'queryKey' | 'queryFn'>
): UseSuspenseQueryResult<SocialMediaData<Platform>, undefined> {
	const {
		network = 'instagram',
		persona_id,
		...rest
	} = useParams<{ network: 'instagram' | 'youtube' | 'tiktok'; persona_id: string }>()

	const config = influencerApi.INFLUENCER

	return useSuspenseQuery({
		queryKey: [config.key, persona_id, network],
		queryFn: async () => {
			try {
				const { data } = await apiConfig.fetch<DataRoot<SocialMediaData<Platform>>>(config.url(persona_id, network))
				return data?.data
			} catch (error) {
				return undefined
			}
		},
		staleTime: 1000 * 60 * 60,

		...options,
	})
}
type YoutubePost = {
	_id: string
	id: string
	category_name: string
	comment_count: number
	description: string
	dislike_count?: number
	favorite_count: number
	hashtags: string[]
	like_count: number
	mentions: string[]
	thumbnail_url: string
	timestamp: string
	title: string
	view_count: number
	thumbnail: string
	is_shorts: boolean
}
type TiktokPost = {
	_id: string
	id: string
	username: string
	views_count: number
	caption: string
	comments_count: number
	likes_count: number
	timestamp: string
	hashtags: string[]
	mentions: string[]
	thumbnail: string
}

type InstagramPost = {
	_id: string
	id: string
	caption: string
	comments: number
	estimated_impressions: number
	is_likes_hidden: boolean
	is_video: boolean
	likes: number
	shortcode: string
	tagged_users: string[]
	taken_at_timestamp: number
	video_play_count?: number
	video_view_count?: number
	follower_count: number
	collab_follower_count?: number
	product_type: string
	thumbnail?: string
}

type PostData<Platform extends string> = Platform extends 'instagram'
	? InstagramPost[]
	: Platform extends 'tiktok'
	? TiktokPost[]
	: Platform extends 'youtube'
	? YoutubePost[]
	: // Add more platforms and their corresponding data types here
	never

type GetPostDataOptions<Platform extends string> = UseSuspenseQueryOptions<
	PostData<Platform>,
	undefined,
	PostData<Platform>,
	string[]
>

export function usePosts<Platform extends string = 'instagram'>(
	options?: GetPostDataOptions<Platform>
): UseSuspenseQueryResult<PostData<Platform>, undefined> {
	const { network = 'instagram', persona_id } = useParams<{
		network: 'instagram' | 'youtube' | 'tiktok'
		persona_id: string
	}>()
	const config = influencerApi.POSTS
	return useSuspenseQuery({
		queryKey: [config.key, persona_id, network],
		queryFn: async () => {
			try {
				const { data } = await apiConfig.fetch<DataRoot<PostData<Platform>>>(config.url(persona_id, network))
				return data?.data
			} catch (error) {
				return []
			}
		},
		staleTime: 1000 * 60 * 60,
		initialData: [],
		initialDataUpdatedAt: 0,
		...options,
	})
}

const useGetVRData = () => {
	const config = influencerApi.INFX_VR_DATA_15
	const { persona_id, network = 'instagram' } = useParams<{
		persona_id: string
		network: 'instagram' | 'youtube' | 'tiktok'
	}>()
	return useSuspenseQuery({
		queryKey: [config.key, persona_id, network],
		queryFn: async () => {
			try {
				const { data } = await apiConfig.fetch<
					{
						x: number
						y: number
						y2: number
					}[]
				>(config.url(persona_id, network))
				return data
			} catch (error) {
				return []
			}
		},
		staleTime: 1000 * 60 * 60,
	})
}

const useGetERData = () => {
	const config = influencerApi.INFX_ER_DATA_15
	const { persona_id, network = 'instagram' } = useParams<{
		persona_id: string
		network: 'instagram' | 'youtube' | 'tiktok'
	}>()
	return useSuspenseQuery({
		queryKey: [config.key, persona_id, network],
		queryFn: async () => {
			try {
				const { data } = await apiConfig.fetch<
					{
						x: number
						y: number
					}[]
				>(config.url(persona_id, network))
				return data
			} catch (error) {
				return []
			}
		},
		staleTime: 1000 * 60 * 60,
	})
}
export type Report = {
	_id: string
	title: string
	last_updated: number
	__v: number
	data: any
	dbUpdatedAt?: string
	denominator: number
}
const useReports = () => {
	const config = influencerApi.REPORT
	return useSuspenseQuery({
		queryKey: [config.key],
		queryFn: async () => {
			const { data } = await apiConfig.post<Report[]>(config.url(), config.body())
			return data
		},
		staleTime: 1000 * 60 * 60,
	})
}

export interface GraphData {
	engagement_change: EngagementChange[]
}

export interface EngagementChange {
	taken_at_timestamp: number
	edge_liked_by?: number
	edge_media_to_comment?: number
	likes: number
	comments: number
	follower_count: number
	shortcode: string
}
const useGraphData = () => {
	const networks = useNetwork()
	const config = influencerApi.GRAPH_DATA
	return useSuspenseQuery({
		queryKey: [config.key, networks?.data?.instagram_username],
		queryFn: async () => {
			try {
				const { data } = await apiConfig.fetch<GraphData>(config.url(networks.data.instagram_username))
				return data
			} catch (error) {
				return {
					engagement_change: [],
				}
			}
		},
		staleTime: 1000 * 60 * 60,
	})
}

type StoryLinks = {
	code: 200
	message: 'ok'
	data: Record<string, number>
}

const useStoryLinks = () => {
	const config = influencerApi.STORY_LINKS
	const { persona_id } = useParams<{ persona_id: string }>()
	return useSuspenseQuery({
		queryKey: [config.key, persona_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<StoryLinks>(config.url(persona_id))
			return data?.data
		},
		staleTime: 1000 * 60 * 60,
	})
}

export interface ContentTimestampData {
	code: number
	message: string
	data: ContentTimestamps[]
}

export interface ContentTimestamps {
	_id: string
	is_video: boolean
	taken_at_timestamp: number
	product_type: string
}

const useContentTimestamps = () => {
	const config = influencerApi.CONTENT_TIMESTAMPS
	const { persona_id } = useParams<{ persona_id: string }>()
	return useSuspenseQuery({
		queryKey: [config.key, persona_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<ContentTimestampData>(config.url(persona_id))
			return data
		},
		staleTime: 1000 * 60 * 60,
	})
}

const useSimilar = () => {
	const config = influencerApi.SIMILARS
	const { persona_id, network = 'instagram' } = useParams<{
		persona_id: string
		network: 'instagram' | 'tiktok' | 'youtube'
	}>()
	return useQuery({
		queryKey: [config.key, persona_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<Similars[]>(config.url(persona_id, network))
			return data
		},
		staleTime: 1000 * 60 * 60,
		enabled: persona_id !== undefined,
	})
}

const useProjects = () => {
	const config = influencerApi.PROJECTS
	const { persona_id } = useParams<{ persona_id: string }>()
	return useSuspenseQuery({
		queryKey: [config.key, persona_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<Projects>(config.url(persona_id))
			return data?.data
		},
		staleTime: 1000 * 60 * 60,
		initialData: {
			ig_posts: [],
			ig_stories: [],
			tt_posts: [],
			yt_posts: [],
		},
		initialDataUpdatedAt: 0,
	})
}
type NetworkType = {
	code: 200
	message: 'ok'
	data: {
		instagram?: boolean
		tiktok?: boolean
		youtube?: boolean
		instagram_username?: string
		tiktok_username?: string
		youtube_username?: string
	}
}

export const getInfluencerNetworkTypes = async (persona_id: string) => {
	const { data } = await apiConfig.fetch<NetworkType>(influencerApi.NETWORKS.url(persona_id))
	return data?.data
}

const useNetwork = () => {
	const config = influencerApi.NETWORKS
	const { persona_id } = useParams<{ persona_id: string }>()

	return useQuery({
		queryKey: [config.key, persona_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<NetworkType>(config.url(persona_id))
			return data?.data
		},
		staleTime: 1000 * 60 * 60,
		enabled: persona_id !== undefined,
	})
}

export type Messages = Message[]

export interface Message {
	_id: string
	owner: Owner
	content: string
	updatedAt: string
	createdAt: string
	room: string
	__v: number
	__enc_content?: boolean
}

export interface Owner {
	_id: string
	email: string
	username?: string
}

const useMessages = () => {
	const config = influencerApi.MESSAGES
	const { persona_id } = useParams<{ persona_id: string }>()
	return useQuery({
		queryKey: [config.key, persona_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<Messages>(config.url(persona_id))
			return data
		},
		staleTime: 1000 * 60 * 10,
		initialData: [],
		initialDataUpdatedAt: 0,
		enabled: persona_id !== undefined,
	})
}

export interface Contacts {
	code: number
	message: string
	data: ContactsData[]
}

export interface ContactsData {
	_id: string
	persona_id: string
	via: string
	at: string
	__enc_via: boolean
	__enc_at: boolean
	__v: number
}
const useContacts = () => {
	const config = influencerApi.CONTACTS
	const { persona_id } = useParams<{ persona_id: string }>()
	return useQuery({
		queryKey: [config.key, persona_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<Contacts>(config.url(persona_id))
			return data?.data
		},
		staleTime: 1000 * 60 * 60,
		enabled: persona_id !== undefined,
	})
}
export interface HiglightsData {
	code: number
	message: string
	data: Highlight[]
}

export interface Highlight {
	_id: string
	network: string
	persona_id: string
	__v: number
	available: string[]
	createdAt: string
	current: string[]
	updatedAt: string
	network_id: string
}

const useHighlights = () => {
	const config = influencerApi.HIGHLIGHTS
	const { persona_id } = useParams<{ persona_id: string }>()
	return useQuery({
		queryKey: [config.key, persona_id],
		queryFn: async () => {
			const { data } = await apiConfig.fetch<HiglightsData>(config.url(persona_id))
			return data?.data
		},
		staleTime: 1000 * 60 * 60,
		enabled: persona_id !== undefined,
	})
}

const useRerunSimilars = () => {
	const config = influencerApi.RERUN_SIMILARS
	const { persona_id, network } = useParams<{ persona_id: string; network: 'instagram' | 'tiktok' | 'youtube' }>()
	const similars = useSimilar()
	return useMutation({
		mutationKey: [config.key],
		mutationFn: async () => {
			const { data } = await apiConfig.fetch<any>(config.url(persona_id, network))
			return data
		},
		onSuccess: () => {
			similars.refetch()
		},
	})
}

const useSaveHighlights = () => {
	const config = influencerApi.SAVE_HIGHLIGHTS
	const { persona_id } = useParams<{ persona_id: string }>()
	const highlights = useHighlights()
	return useMutation({
		mutationKey: [config.key],
		mutationFn: async (data: ReturnType<typeof config.body>) => {
			const { data: res } = await apiConfig.post<any>(config.url(persona_id), config.body(data))
			return res
		},
		onSuccess: () => {
			highlights.refetch()
		},
	})
}
const influencer = {
	useMeta: useGetSocialMediaData,

	useGetPosts: usePosts,
	useER: useGetERData,
	useReports,
	useGraphData,
	useProjects,
	useStoryLinks,
	useContentTimestamps,
	useSimilars: useSimilar,
	useMessages,
	useNetwork,
	useContacts,
	useHighlights,
	useGetVRData,
	useRerunSimilars,
	useSaveHighlights,
}

export default influencer
