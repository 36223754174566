import { useEffect, useState } from 'react'
import { SetURLSearchParams, useSearchParams } from 'react-router-dom'
import { URLSearchParams } from 'url'
import { z } from 'zod'

type URLKey = 'network' | 'template_name' | 'timespan' | 'outliers'
const networkDefaultValues = z.enum(['instagram', 'youtube', 'tiktok']).array()
type NetworkDefaultValues = z.infer<typeof networkDefaultValues>
const network = {
	key: 'network',
	defaultValue: ['instagram', 'youtube', 'tiktok'],
	values: ['instagram', 'youtube', 'tiktok'],
	update: (searchParams, value) => {
		searchParams((e) => {
			e.set('network', value.join(','))
			return e
		})
	},
	parse: (searchParams) => {
		const value = searchParams.get('network')
		return value ? value.split(',') : network.defaultValue
	},
} as Network
type Network = {
	key: 'network'
	defaultValue: NetworkDefaultValues
	values: NetworkDefaultValues
	update: (searchParams: SetURLSearchParams, value: NetworkDefaultValues) => void
	parse: (searchParams: URLSearchParams) => NetworkDefaultValues
}

const timespanDefaultValues = z.enum(['120', '180', '360', 'all']).array()
type TimespanDefaultValues = z.infer<typeof timespanDefaultValues>
const timespan = {
	key: 'timespan',
	defaultValue: '120',
	values: ['120', '180', '360', 'all'],
	update: (searchParams, value) => {
		searchParams((e) => {
			e.set('timespan', value)
			return e
		})
	},
	parse: (searchParams: URLSearchParams) => {
		const value = searchParams.get('timespan')
		return value ? value : timespan.defaultValue
	},
} as Timespan
type Timespan = {
	key: 'timespan'
	defaultValue: TimespanDefaultValues[number]
	values: TimespanDefaultValues
	update: (searchParams: SetURLSearchParams, value: TimespanDefaultValues[number]) => void
	parse: (searchParams: URLSearchParams) => TimespanDefaultValues[number]
}

const template_name = {
	key: 'template_name',
	defaultValue: 'Default',
	values: 'Default',
	update: (searchParams, value) => {
		searchParams((e) => {
			e.set('template_name', value)
			return e
		})
	},
	parse: (searchParams: URLSearchParams) => {
		const value = searchParams.get('template_name')
		return value ? value : template_name.defaultValue
	},
} as TemplateName
type TemplateName = {
	key: 'template_name'
	defaultValue: string
	values: string
	update: (searchParams: SetURLSearchParams, value: string) => void
	parse: (searchParams: URLSearchParams) => string
}
type Config = {
	network: Network
	template_name: TemplateName
	timespan: Timespan
	outliers: null
}

const config = {
	network,
	template_name,
	timespan,
	outliers: null,
} as Config
export function useURL(key: keyof Config) {
	const [searchParams, setSearchParams] = useSearchParams()
	const [value, setValue] = useState(config[key].defaultValue)

	useEffect(() => {
		setValue(config[key].parse(searchParams))
	}, [searchParams.get(key)])
	return {
		value,
		defaultValues: config[key].defaultValue,
		update: (value: Config[URLKey]['values']) => {
			config[key].update(setSearchParams, value)
		},
		parse: () => config[key].parse(searchParams),
	}
}
