import { IconBrandTiktok, IconChevronUp, IconFlame, IconPhoto, IconSearch, IconShare, IconTag } from '@tabler/icons'
import {
	IconAlertTriangle,
	IconApps,
	IconAt,
	IconBookmark,
	IconBrandInstagram,
	IconBrandYoutube,
	IconBriefcase,
	IconCalendarEvent,
	IconChevronLeft,
	IconChevronRight,
	IconChevronsLeft,
	IconChevronsRight,
	IconClipboard,
	IconClock,
	IconCreditCard,
	IconDeviceDesktop,
	IconDeviceFloppy,
	IconEye,
	IconEyeOff,
	IconFilter,
	IconForms,
	IconHash,
	IconHeart,
	IconHome,
	IconInfoCircle,
	IconLanguage,
	IconLayout2,
	IconLayoutList,
	IconLink,
	IconListDetails,
	IconMapPin,
	IconMenu,
	IconMessage,
	IconMessage2,
	IconPercentage,
	IconPlayerPlay,
	IconPlus,
	IconQrcode,
	IconReload,
	IconReportAnalytics,
	IconSettings,
	IconSlideshow,
	IconSocial,
	IconSpeakerphone,
	IconStar,
	IconTrash,
	IconUser,
	IconUserSearch,
	IconUsers,
	IconVideo,
	IconX,
} from '@tabler/icons-react'
import { YoutubeIcon } from 'components/icons/Youtube'

const Icons = {
	pagination: {
		double_left: IconChevronsLeft,
		left: IconChevronLeft,
		right: IconChevronRight,
		double_right: IconChevronsRight,
	},
	clock: IconClock,
	percentage: IconPercentage,
	bookmark: IconBookmark,
	share: IconShare,
	tag: IconTag,
	fire: IconFlame,
	caretup: IconChevronUp,
	location: IconMapPin,
	mention: IconAt,
	photo: IconPhoto,
	hashtag: IconHash,
	lists: IconListDetails,
	projects: IconApps,
	community: IconSocial,
	play: IconPlayerPlay,
	back: IconChevronLeft,
	copy: IconClipboard,
	add: IconPlus,
	menu: IconMenu,
	user: IconUser,
	search: IconSearch,
	users: IconUsers,
	likes: IconHeart,
	comments: IconMessage2,
	message: IconMessage,
	estimated_impressions: IconEye,
	X: IconX,
	instagram: IconBrandInstagram,
	youtube: IconBrandYoutube,
	tiktok: IconBrandTiktok,
	language: IconLanguage,
	off: IconEyeOff,
	delete: IconTrash,
	save: IconDeviceFloppy,
	home: IconHome,
	reports: IconReportAnalytics,
	identification: IconUserSearch,
	qr: IconQrcode,
	brand: IconBriefcase,
	settings: IconSettings,
	campaigns: IconLayoutList,
	content: IconLayout2,
	monitor: IconDeviceDesktop,
	credits: IconCreditCard,
	filter: IconFilter,
	calendar: IconCalendarEvent,
	link: IconLink,
	video: IconVideo,
	reach: IconSpeakerphone,
	star: IconStar,
	refresh: IconReload,
	input: IconForms,
	presentation: IconSlideshow,
	info: IconInfoCircle,
	youtubeReal: YoutubeIcon,
	infoTriangle: IconAlertTriangle,
}

export { Icons }
