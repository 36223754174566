import { useEffect } from "react";

export default function useOutsideClick(referencedNode, callback) {
	const handleOutsideClick = (e) => {
		if (referencedNode.current.contains(e.target)) {
			// inside click,
			return;
		}
		// outside click
		
		callback();
	};

	useEffect(
		() => {
			// add when mounted
			document.addEventListener("mousedown", handleOutsideClick);
			// return function to be called when unmounted
			return () => {
				document.removeEventListener("mousedown", handleOutsideClick);
			};
		},
		[],
	);
}
