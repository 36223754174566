import api from 'api'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const InfXNetworkLoader = () => {
	const networks = api.influencer.useNetwork()
	const { persona_id } = useParams<{ persona_id: string }>()
	const navigate = useNavigate()
	useEffect(() => {
		if (networks.isLoading) return
		if (networks.data?.instagram) navigate(`/x/${persona_id}/instagram`)
		else if (networks.data?.tiktok) navigate(`/x/${persona_id}/tiktok`)
		else if (networks.data?.youtube) navigate(`/x/${persona_id}/youtube`)
	}, [networks.isLoading, networks.data])
	return (
		<div className='flex h-sidebar w-full flex-col items-center justify-center '>
			<LoadingSpinner className='h-7 w-7' />
			<p className='mt-2 font-semibold'>Loading</p>
		</div>
	)
}
export default InfXNetworkLoader
