import Axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
const { endpoint } = window

const api = {
	fetch_api: async ({ api_path }) => {
		const res = await Axios.get(`${endpoint}${api_path}`, window.getHeaders())
		if (res && res.status === 200 && res.data) {
			return res.data.data
		}
	},
	post_api: async ({ api_path, template }) => {
		const res = await Axios.post(`${endpoint}${api_path}`, template, window.getHeaders())
		if (res && res.status === 200 && res.data) {
			return res.data.data
		}
	},
	delete_api: async ({ api_path }) => {
		const res = await Axios.delete(`${endpoint}${api_path}`, window.getHeaders())
		if (res && res.status === 200 && res.data) {
			return res.data
		}
	},
}

function* fetcher_influencerx_templates({ payload: { api_path } }) {
	try {
		const data = yield call(api.fetch_api, { api_path })
		const newData = [...data].map((d) => {
			const elements = [...d.elements].map((e) => {
				const settings = {}
				e.settings.forEach((s) => (s.key ? (settings[s.key] = JSON.parse(s.value)) : null))
				return { ...e, settings: settings || {} }
			})
			return { ...d, elements }
		})
		yield put({ type: 'FETCH_INFLUENCERX_TEMPLATES', payload: newData })
	} catch (e) {}
}

export function* fetch_influencerx_templates() {
	yield takeLatest('INFLUENCERX_API_FETCH_TEMPLATES', fetcher_influencerx_templates)
}

function* saver_influencerx_templates({ payload: { api_path, template } }) {
	try {
		const data = yield call(api.post_api, { api_path, template })
		const newData = [...data.elements].map((e) => {
			const settings = {}
			e.settings.forEach((s) => (s.key ? (settings[s.key] = JSON.parse(s.value)) : null))
			return { ...e, settings: settings || {} }
		})
		yield put({
			type: 'SAVE_INFLUENCERX_TEMPLATE',
			payload: { ...data, elements: newData },
		})
	} catch (e) {}
}

export function* save_influencerx_templates() {
	yield takeLatest('INFLUENCERX_API_SAVE_TEMPLATE', saver_influencerx_templates)
}

function* deleter_influencerx_templates({ payload: { api_path } }) {
	try {
		const data = yield call(api.delete_api, { api_path })
		yield put({ type: 'DELETE_INFLUENCERX_TEMPLATE', payload: data })
	} catch (e) {}
}

export function* delete_influencerx_templates() {
	yield takeLatest('INFLUENCERX_API_DELETE_TEMPLATE', deleter_influencerx_templates)
}

function* updater_influencerx_templates({ payload: { api_path, template } }) {
	try {
		const data = yield call(api.post_api, { api_path, template })
		const newData = [...data.elements].map((e) => {
			const settings = {}
			e.settings.forEach((s) => (s.key ? (settings[s.key] = JSON.parse(s.value)) : null))
			return { ...e, settings: settings || {} }
		})
		yield put({
			type: 'UPDATE_INFLUENCERX_TEMPLATE',
			payload: { ...data, elements: newData },
		})
	} catch (e) {}
}

export function* update_influencerx_templates() {
	yield takeLatest('INFLUENCERX_API_UPDATE_TEMPLATE', updater_influencerx_templates)
}
