export const StoryIcon = ({ className = 'w-5 h-5', stroke = '#666' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='500'
			height='500'
			stroke={stroke}
			className={` text-white ${className}`}
			fill='none'
			viewBox='0 0 500 500'
		>
			<path
				strokeLinecap='round'
				strokeWidth='31'
				d='M249.64 16C379.074 16 484 120.765 484 250c0 85.992-46.457 161.151-115.678 201.82m-64.442 25.882C286.471 481.82 268.311 484 249.64 484c-40.63 0-78.845-10.323-112.158-28.487m-48.233-34.897c-32.352-30.332-56.074-69.733-67.096-114.138M16 231.534c2.288-29.26 9.965-57.008 22.043-82.257m48.362-67.182a235.742 235.742 0 018.95-8.238'
			/>
		</svg>
	)
}

export const ReelsIcon = ({ fill = '#444', className = 'w-5 h-5' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='500'
			height='500'
			fill={fill}
			alt='reels'
			className={`${className}`}
			viewBox='0 0 500 500'
		>
			<path
				fillOpacity='.9'
				d='M321.053 326.127c14.035-8.103 14.035-28.361 0-36.464l-98.685-56.976c-14.035-8.103-31.579 2.026-31.579 18.232V364.87c0 16.206 17.544 26.335 31.579 18.232l98.685-56.975z'
			/>
			<path
				fillOpacity='.9'
				fillRule='evenodd'
				d='M142.105 0C63.623 0 0 63.623 0 142.105v215.79C0 436.377 63.623 500 142.105 500h215.79C436.377 500 500 436.377 500 357.895v-215.79C500 63.623 436.377 0 357.895 0h-215.79zm26.107 125H42.029c6.625-41.766 39.107-74.922 80.529-82.553L168.212 125zm157.082 0H213.603l-46.649-84.21h111.769L325.294 125zm132.677 0h-87.283l-46.649-84.21h35.172c49.855 0 91.19 36.484 98.76 84.21zM40.789 164.474h418.422v194.737c0 55.228-44.772 100-100 100H140.789c-55.228 0-100-44.772-100-100V164.474z'
				clipRule='evenodd'
			/>
		</svg>
	)
}
export const IGVideo = ({ className = 'w-5 h-5', fill = '#555' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='500'
			height='500'
			alt='reels'
			fill={fill}
			className={`${className}`}
			viewBox='0 0 500 500'
		>
			<path d='M432.5 237.51c10 5.773 10 20.207 0 25.98L130.25 437.994c-10 5.774-22.5-1.443-22.5-12.99V75.996c0-11.547 12.5-18.764 22.5-12.99L432.5 237.51z' />
		</svg>
	)
}

export const PhotoIcon = ({ className = 'w-5 h-5', fill = '#666' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='500'
			height='500'
			fill={fill}
			alt='reels'
			className={` text-white ${className}`}
			viewBox='0 0 500 500'
		>
			<path
				fillRule='evenodd'
				d='M384.92 274.435c0 74.409-60.406 134.729-134.92 134.729-74.515 0-134.921-60.32-134.921-134.729 0-74.409 60.406-134.729 134.921-134.729 74.514 0 134.92 60.32 134.92 134.729zm-43.65 0c0 50.335-40.863 91.14-91.27 91.14s-91.27-40.805-91.27-91.14 40.863-91.14 91.27-91.14 91.27 40.805 91.27 91.14z'
			/>
			<path
				fillRule='evenodd'
				d='M0 168.328c0-36.475 29.613-66.044 66.14-66.044h43.063c12.146 0 23.883-17.032 35.274-33.563C155.216 53.138 165.648 38 175.824 38h153.16c9.233 0 19.685 15.725 30.251 31.62 10.796 16.244 21.71 32.664 31.562 32.664h43.064c36.527 0 66.139 29.569 66.139 66.044v227.628C500 432.431 470.389 462 433.862 462H66.138C29.61 462 0 432.431 0 395.956V168.328zm458.995 0v227.628c0 13.861-11.252 25.097-25.133 25.097H66.138c-13.88 0-25.133-11.236-25.133-25.097V168.328c0-13.859 11.253-25.097 25.135-25.097h43.063c24.07 0 40.312-15.583 47.193-22.841 8.204-8.654 15.539-19.289 20.72-26.801l.153-.221c4.392-6.369 7.577-10.977 10.224-14.421h128.469c2.525 3.45 5.538 7.948 9.455 13.84l.452.681c4.908 7.384 11.607 17.466 18.734 25.647 3.745 4.299 8.904 9.616 15.404 14.099 6.365 4.39 16.977 10.017 30.79 10.017h43.064c13.881 0 25.134 11.238 25.134 25.097z'
			/>
		</svg>
	)
}
export const CarouselIcon = ({ className = 'w-5', fill = '#555' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='500'
			height='500'
			alt='reels'
			fill={fill}
			className={`w-5 ${className}`}
			viewBox='0 0 500 500'
		>
			<path
				fillRule='evenodd'
				d='M0 78.947C0 35.346 35.346 0 78.947 0H325c43.601 0 78.947 35.346 78.947 78.947V325c0 43.601-35.346 78.947-78.947 78.947H78.947C35.346 403.947 0 368.601 0 325V78.947zm47.368 18.421c0-27.614 22.386-50 50-50H306.58c27.614 0 50 22.386 50 50V306.58c0 27.614-22.386 50-50 50H97.369c-27.615 0-50-22.386-50-50V97.369z'
				clipRule='evenodd'
			/>
			<path d='M176.316 500c-32.88 0-61.066-20.101-72.939-48.684h300.57c26.161 0 47.369-21.208 47.369-47.369v-300.57C479.899 115.25 500 143.436 500 176.316v244.737C500 464.654 464.654 500 421.053 500H176.316z' />
		</svg>
	)
}
