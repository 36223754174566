import { Slot } from '@radix-ui/react-slot'
import { cva, VariantProps } from 'class-variance-authority'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { cn } from 'lib/helper'
import * as React from 'react'

const buttonVariants = cva(
	'inline-flex items-center justify-center rounded-md disabled:cursor-not-allowed text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background transition-all',
	{
		variants: {
			variant: {
				primary: 'text-white bg-primary hover:bg-sky-700 border-secondary-foreground/60 border',
				'primary-colorful':
					'text-white transition-all bg-gradient-to-br from-sky-500 to-sky-600 hover:from-sky-600 hover:to-sky-700',
				success: 'text-white transition-all bg-green-500 hover:bg-green-600',
				secondary: 'text-black bg-gray-100 border-gray-200 hover:bg-gray-200',
				default: 'text-black bg-white border border-gray-200 hover:bg-gray-100',
				outline: 'hover:bg-accent hover:text-foreground',
				dashed: 'text-black bg-white border-gray-200 border border-dashed hover:bg-gray-100',
				filtered: 'text-sky-700 bg-white border-sky-600 border border-dashed hover:bg-gray-100',
				danger: 'text-white bg-red-500 hover:bg-red-600 border-red-500',
				info: 'font-semibold text-gray-500 bg-yellow-300 hover:bg-yellow-400',
				link: 'underline-offset-4 hover:underline text-primary',
				text: 'bg-white border-transparent hover:bg-gray-100 text-slate-600',
			},
			size: {
				small: 'px-1.5 py-0.5 text-xs rounded',
				default: 'px-3 py-1 text-xs rounded-md',
				large: 'px-3  py-1  text-sm  rounded-md',
				xl: 'px-6 py-3 text-lg rounded-lg',
				action: 'p-2  text-sm rounded-md',
				icon: 'h-8 w-8',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
	asChild?: boolean
	icon?: React.ReactNode
	loading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, disabled, loading, children, icon, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button'

		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				disabled={disabled || loading}
				{...props}
			>
				{loading ? <LoadingSpinner className='mr-2 h-3 w-3 ' /> : null}
				{icon && !loading ? <div className='mr-2'>{icon}</div> : null}
				{children}
			</Comp>
		)
	}
)
Button.displayName = 'Button'

export { Button, buttonVariants }
