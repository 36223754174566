import { useAuthProvider } from 'providers/AuthProvider'
import { Link } from 'react-router-dom'
import { Alert, Button, Input } from 'xui'

const Register = () => {
	const {
		loginProps: { email, setEmail, password, setPassword, firstName, setFirstName, lastName, setLastName },
		register: _register,
		registering: logging_in,
		error,
		info,
	} = useAuthProvider()

	const login = async () => {
		_register()
	}

	const Error = () => (error !== '' ? <Alert type={'warning'} message={error} /> : null)
	const Info = () => (info !== '' ? <Alert type={'info'} message={info} /> : null)

	return (
		<div className='flex w-96 flex-col gap-2'>
			<p className='mb-2'>
				Already have an account?{' '}
				<Link to='/auth/login' className='font-semibold text-blue-500'>
					Login
				</Link>
			</p>
			<b className='text-lg font-semibold'>Your Name</b>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<Input
					placeholder={'Your First Name'}
					style={{ width: '50%', marginBottom: 10, marginRight: 2, textAlign: 'center' }}
					value={firstName}
					size='large'
					onChange={(e) => setFirstName(e.target.value)}
				/>
				<Input
					placeholder={'Your Last Name'}
					style={{ width: '50%', marginBottom: 10, marginLeft: 2, textAlign: 'center' }}
					value={lastName}
					size='large'
					onChange={(e) => setLastName(e.target.value)}
				/>
			</div>

			<b className='text-lg font-semibold'>E-mail</b>
			<Input
				placeholder={'Your Email Address'}
				type={'email'}
				style={{ width: '100%', marginBottom: 10, textAlign: 'center' }}
				value={email}
				size='large'
				onChange={(e) => setEmail(e.target.value)}
				onPressEnter={login}
			/>
			<b className='text-lg font-semibold'>Password</b>
			<Input
				type={'password'}
				placeholder={'*********'}
				style={{ width: '100%', marginBottom: 10, textAlign: 'center' }}
				value={password}
				size='large'
				onChange={(e) => setPassword(e.target.value)}
				onPressEnter={login}
			/>
			<Button size='large' type={'primary'} style={{ width: '100%', marginBottom: 10 }} onClick={login}>
				{logging_in ? <Loading /> : 'Register'}
			</Button>
			<Error />
			<Info />
		</div>
	)
}

const styles = {
	label: {
		fontWeight: '300',
		fontSize: 16,
		marginBottom: 5,
	},
}

export default Register

const Loading = () => (
	<svg className='h-5 w-5 animate-spin text-white' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
		<circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
		<path
			className='opacity-75'
			fill='currentColor'
			d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
		></path>
	</svg>
)
