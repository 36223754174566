import axios from 'axios'
const { endpoint } = window
const headers = window.getHeaders()
export const apiConfig = {
	fetch_api: async ({ api_path }: { api_path: string }) => {
		return fetch(`${endpoint}${api_path}`, headers)
			.then((r) => r.json())
			.then((r) => (r && r.data && r.data ? r.data : null))
	},
	fetch_api_axios: async ({ api_path }: { api_path: string }) => await axios.get(`${endpoint}${api_path}`, headers),
	fetch: async <T extends object | object[]>(api_path: string) => await axios.get<T>(`${endpoint}/${api_path}`, headers),
	post: async <T extends object | object[]>(api_path: string, body: any) =>
		await axios.post<T>(`${endpoint}/${api_path}`, body, headers),
	patch: async <T extends object | object[]>(api_path: string, body: object) => {
		const data = await axios.patch<T>(`${endpoint}/${api_path}`, body, window.getHeaders())
		return data
	},
	post_api: async (payload: { api_path: string; body: any }) => {
		const { api_path, body } = payload
		return fetch(`${endpoint}${api_path}`, {
			method: 'POST',
			headers: {
				...headers.headers,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})
			.then((r) => r.json())
			.then((r) => (r && r.data ? r.data : null))
	},
	post_api_axios: async ({ api_path, body }: { api_path: string; body: any }) => {
		const data = await axios.post(`${endpoint}${api_path}`, body, headers)
		return data
	},
	delete_api: async ({ api_path }: { api_path: string }) => {
		const res = await axios.delete(`${endpoint}/${api_path}`, headers)
		if (res && res.status === 200 && res.data) {
			return res.data
		}
	},
}
