import { CircleStackIcon, ComputerDesktopIcon, ServerIcon } from "@heroicons/react/24/outline"
import { useDevelopmentMode } from "hooks/useDevelopmentMode"
import { PopoverCSS } from "xui"

export const SourceItem = () => {
  const devMode = useDevelopmentMode()
  const getEndpoint = () =>
    window.localStorage.getItem('endpoint') ? JSON.parse(window.localStorage.getItem('endpoint')) : 'prod'
  const changeEndpoint = (endpoint) => {
    window.localStorage.setItem('endpoint', JSON.stringify(endpoint))
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }
  return (
    (devMode === false) ? null :
      <PopoverCSS
        placement="bottom-end"
        content={
          <div className="flex flex-col w-40 h-auto space-y-2">
            <div
              className={`inline-flex space-x-2 w-full items-center hover:bg-gray-300 transition-colors rounded-lg p-2 cursor-pointer ${getEndpoint() === 'prod' ? 'bg-gray-200' : ''
                }`}
              onClick={() => changeEndpoint('prod')}
            >
              <ServerIcon className="w-5 h-5" /> <p>Production</p>
            </div>
            <div
              className={`inline-flex space-x-2 w-full items-center hover:bg-gray-300 transition-colors rounded-lg p-2 cursor-pointer ${getEndpoint() === 'importer' ? 'bg-gray-200' : ''
                }`}
              onClick={() => changeEndpoint('importer')}
            >
              <CircleStackIcon className="w-5 h-5" /> <p>Test/Importer</p>
            </div>
            <div
              className={`inline-flex space-x-2 w-full items-center hover:bg-gray-300 transition-colors rounded-lg p-2 cursor-pointer ${getEndpoint() === 'localhost' ? 'bg-gray-200' : ''
                }`}
              onClick={() => changeEndpoint('localhost')}
            >
              <ComputerDesktopIcon className="w-5 h-5" /> <p>Localhost</p>
            </div>
          </div>
        }
      >
        <div className="flex items-center justify-center ml-2 cursor-pointer">
          <div className="flex items-center justify-center w-auto h-8 p-1 text-sm font-extrabold text-center text-gray-500 uppercase bg-gray-100 border-2 border-gray-300 rounded-full cursor-pointer hover:text-gray-700 hover:border-gray-400">
            {
              {
                prod: <ServerIcon className="w-5 h-5 mr-1" />,
                importer: <CircleStackIcon className="w-5 h-5 mr-1" />,
                localhost: <ComputerDesktopIcon className="w-5 h-5 mr-1" />,
              }[getEndpoint()]
            }
            {{ prod: 'P', importer: 'T', localhost: 'L' }[getEndpoint()]}
          </div>
        </div>
      </PopoverCSS>
  )
}