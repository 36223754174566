import { Children, useEffect, useState } from 'react'
import { CheckboxGroupProps, CheckboxProps } from './Checkbox.d'
import styles from './Checkbox.module.css'
const Checkbox = ({
	autoFocus = false,
	checked = false,
	disabled = false,
	onChange = () => {},
	label = '',
	children = '',
	name = '',
	properties = {},
	className = '',
}: CheckboxProps) => {
	return (
		<div className={`inline-flex items-center justify-center ${className}`}>
			<input
				type='checkbox'
				name={name}
				className={` ${styles.checkbox} rounded-md transition-all  focus:ring-2`}
				checked={checked}
				disabled={disabled}
				autoFocus={autoFocus}
				onChange={onChange}
			/>
			{label !== '' || children !== '' ? <div className='ml-1 mr-2 text-gray-700'>{label || children}</div> : null}
		</div>
	)
}
const Group = ({ children, value = [], options = [], onChange = () => {} }: CheckboxGroupProps) => {
	const [childrenWithProps, setChildrenWithProps] = useState<any>(null)
	useEffect(() => {
		setChildrenWithProps(
			Children.map(children, (child: any) => {
				const clone = (
					<child.type key={child.key} checked={value.includes(child.props.value)} ref={child.ref} {...child.props} />
				)
				return clone
			})
		)
		return () => {
			setChildrenWithProps(null)
		}
	}, [children, value])
	return (
		<div>
			{(childrenWithProps || []).length ? childrenWithProps : null}
			{options.length
				? options.map((e, k) => (
						<Checkbox
							key={k}
							checked={value.includes(e)}
							onChange={() => {
								let val
								if (value.includes(e)) val = value.filter((d) => d !== e)
								else val = [...value, e]
								onChange(val)
							}}
						>
							{e}
						</Checkbox>
				  ))
				: null}
		</div>
	)
}

;(Checkbox as any).Group = Group
export { Checkbox }
