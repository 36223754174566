import Flex from 'components/Flex'
import _ from 'lodash'
import { IoLogoInstagram, IoLogoTiktok, IoLogoYoutube } from 'react-icons/io5'

export const SidebarHeader = ({ image, name, persona, score = 0, timestamp = 0, onClick = () => {} }) => {
	return (
		<div
			className='flex h-auto w-full cursor-pointer flex-row rounded-lg border border-gray-200 bg-white p-2 shadow-md'
			onClick={onClick}
		>
			<img src={image} alt='' className='h-auto w-12 rounded-lg' />
			<div className='ml-2 flex flex-grow flex-col justify-center'>
				<h3 className='text-lg font-semibold'>{name}</h3>
				<Flex align={'center'} className='space-x-3'>
					<div
						className='flex flex-row items-center justify-end border-gray-300 pr-1 tracking-wider text-orange-600'
						style={{ width: 'auto', marginLeft: 3 }}
					>
						<IoLogoInstagram style={{ marginRight: 5 }} />
						<>{_.concat(persona.ig_posts || [], persona.ig_stories || []).length}</>{' '}
					</div>
					<div
						className='flex flex-row items-center justify-end border-gray-300 pr-1 tracking-wider text-red-700'
						style={{ width: 'auto', marginLeft: 5 }}
					>
						<IoLogoYoutube style={{ marginRight: 5 }} />
						{(persona.yt_posts || []).length}{' '}
					</div>
					<div
						className='flex flex-row items-center justify-end pr-1 tracking-wider text-gray-800'
						style={{ width: 'auto', marginLeft: 0 }}
					>
						<IoLogoTiktok style={{ marginLeft: 5 }} />
						{(persona.tt_posts || []).length}{' '}
					</div>
				</Flex>
			</div>
			<div className={'flex flex-col items-center justify-center rounded-lg border-gray-200'}>
				<div
					style={{ ...badge(score) }}
					className='flex h-1/2 flex-grow-0 items-center justify-center rounded-lg px-2 pb-0.5 font-semibold shadow-md'
				>
					{score.toFixed(2)}
				</div>
			</div>
		</div>
	)
}
const badge = (score) => {
	const custom_score_style = {}
	if (score) {
		if (score > 9) {
			custom_score_style.background = '#56E549'
			custom_score_style.color = '#000'
		} else if (score > 8) {
			custom_score_style.background = '#D3EF34'
			custom_score_style.color = '#000'
		} else if (score > 7) {
			custom_score_style.background = '#EDD434'
			custom_score_style.color = '#262208'
		} else if (score > 6) {
			custom_score_style.background = '#DD853E'
			custom_score_style.color = '#965A2A'
		} else {
			custom_score_style.background = '#9B2222'
			custom_score_style.color = 'rgba(255,255,255,0.6)'
		}
	}
	return custom_score_style
}
