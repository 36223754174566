export type LoadableTypes =
	| "update_monitor"
	| "add_monitor"
	| "remove_monitor"
	| "force_rerun"
	| "hub_news_list"
	| "hub_news_list_edit"
	| "rerun_similars"
	| "add_missing_content"
	| "missing_content_page_loading"
	| "data_input_edit_modal"
	| `force_rerun_${string}`
	| `modal_id_${string}`
	| "basic_monitor"
	| "lock_all_modal"
	| "hide_all_modal"
	| "delete_influencer_campaign_modal"
	| `calculate_weekly_${string}`
	| `invite_communuty_${string}`
	| `remove_communuty_${string}`
	| 'create_post_persona_linked_tag_options'
	| 'add_bulk_ig_influencers'
	| 'change_content_picture'
	| 'story_new_modal'
	| 'story_new_modal_accept'
	| 'monitor_edit_modal'
	| 'monitor_tag_edit'
	| 'export_monitor_csv'
	| "community_create_modal"
	| "community_remove_influencer_modal"
	| "community_delete"
	| "community_invite_modal"
	| "community_edit_modal"
	| "community_loading_influencers"
	| "community_create_bulletin_modal"
	| "project_create_modal"
	| "project_is_dirty"
	| "project_is_dirty_modal"
	| "project_delete_modal"
	| "update_brand_project"
	| "publish_brand_project"
	| "project_unpublish_modal"
	;
export type LoadableValues = "waiting" | "error" | "loading" | "closed" | "other";

type LoadableState = Record<LoadableTypes, LoadableValues>;

const initialState = {
	update_monitor: "closed",
	add_monitor: "closed",
	remove_monitor: "closed",
	force_rerun: "closed",
	hub_news_list: "closed",
	hub_news_list_edit: "closed",
	rerun_similars: "closed",
	missing_content_page_loading: "closed",
	add_missing_content: "closed",
	data_input_edit_modal: "closed",
	basic_monitor: "closed",
	lock_all_modal: "closed",
	hide_all_modal: "closed",
	delete_influencer_campaign_modal: "closed",
	create_post_persona_linked_tag_options: "closed",
	add_bulk_ig_influencers: "closed",
	change_content_picture: "closed",
	story_new_modal: "closed",
	story_new_modal_accept: "closed",
	monitor_edit_modal: "closed",
	monitor_tag_edit: "closed",
	export_monitor_csv: "closed",
	community_create_modal: "closed",
	community_remove_influencer_modal: "closed",
	community_delete: "closed",
	community_invite_modal: "closed",
	community_edit_modal: "closed",
	community_loading_influencers: "closed",

	community_create_bulletin_modal: "closed",
	project_create_modal: "closed",
	project_is_dirty: "closed",
	project_is_dirty_modal: "closed",
	project_delete_modal: "closed",
	publish_brand_project: "closed",
	project_unpublish_modal: "closed"

} as LoadableState;

const reducer = (
	state = initialState,
	{ type, payload }: {
		type: string,
		payload: { key: LoadableTypes, value: LoadableValues },
	},
): LoadableState => {
	switch (type) {
		case "UPDATE_LOADABLE":
			{
				return { ...state, [payload.key]: payload.value };
			}
		default:
			return state;
	}
};

export default reducer;

export const $update_loadable = (key: LoadableTypes, value: LoadableValues) => {
	return { type: "UPDATE_LOADABLE", payload: { key, value } };
};
