import { UseMutationOptions, useMutation, useQuery } from '@tanstack/react-query'
import { apiConfig } from 'api/api'
import { brandApi } from 'config/api'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import { useToast } from 'hooks/useToast'
import { EndDateParam, StartDateParam, convertTimespanToUnix } from 'search-params/brand'
import { ActiveBrand, BrandRecentContent, Brands, Campaigns, Contents, Influencers, ListDataXY } from 'types/Brand'
import { useQueryParams } from 'use-query-params'
type BrandIdParam = { brand_id: string }
interface AxiosObject<T> {
	message: string
	code: number
	status: number
	data: T
}

export const useTimeSelector = () => {
	const [{ start_date, end_date }] = useQueryParams({
		start_date: StartDateParam,
		end_date: EndDateParam,
	})
	return convertTimespanToUnix(start_date, end_date)
}

const useGetBrandRequest = () => {
	const { brand_id } = useParams<BrandIdParam>()
	const navigate = useNavigate()
	const config = brandApi.GET_BRAND_REQUEST
	const { toast } = useToast()
	return useQuery({
		queryKey: [config.key, brand_id],
		queryFn: async () => {
			try {
				const response = await apiConfig.fetch<AxiosObject<ActiveBrand>>(config.url(brand_id))
				return response.data.data
			} catch (error) {
				navigate('/home')
				toast({
					description: `You don't have access to this brand`,
					title: 'Error',
					variant: 'destructive',
				})
			}
		},

		staleTime: dayjs().add(1, 'hour').valueOf(),
		enabled: brand_id !== undefined,
	})
}

export const getAllBrands = {
	queryKey: [brandApi.GET_BRANDS_REQUEST.key, true],
	queryFn: async () => {
		const response = await apiConfig.fetch<AxiosObject<Brands>>(brandApi.GET_BRANDS_REQUEST.url())
		return response.data?.data
	},

	staleTime: dayjs().add(1, 'hour').valueOf(),
}
const useGetAllBrands = (navigatable = true) => {
	const config = brandApi.GET_BRANDS_REQUEST
	return useQuery({
		queryKey: [config.key, navigatable],
		queryFn: async () => {
			const response = await apiConfig.fetch<AxiosObject<Brands>>(config.url())
			return response.data?.data
		},

		staleTime: dayjs().add(1, 'hour').valueOf(),
	})
}
const useGetRecentContent = () => {
	const { brand_id } = useParams<BrandIdParam>()
	const config = brandApi.GET_BRAND_RECENT_CONTENT_REQUEST
	const { active_date_from, active_date_to } = useTimeSelector()
	return useQuery({
		queryKey: [config.key, brand_id, active_date_from, active_date_to],
		queryFn: async () => {
			const response = await apiConfig.post<AxiosObject<BrandRecentContent>>(
				config.url(brand_id),
				config.body({
					date_from: active_date_from,
					date_to: active_date_to,
				})
			)
			return response.data?.data
		},
		enabled: brand_id !== undefined,
		staleTime: dayjs().add(1, 'hour').valueOf(),
	})
}
const useGetBrandWidgetData = () => {
	const { brand_id } = useParams<BrandIdParam>()
	const config = brandApi.GET_BRAND_WIDGET_DATA_REQUEST
	const { active_date_from, active_date_to } = useTimeSelector()
	return useQuery({
		queryKey: [config.key, brand_id, active_date_from, active_date_to],
		queryFn: async () => {
			const response = await apiConfig.post<AxiosObject<WidgetData>>(
				config.url(brand_id),
				config.body({
					date_from: active_date_from,
					date_to: active_date_to,
				})
			)
			return response.data?.data
		},
		enabled: brand_id !== undefined,
		staleTime: dayjs().add(1, 'hour').valueOf(),
	})
}
const useGetBrandInvitations = () => {
	const config = brandApi.GET_BRAND_INVITATIONS_REQUEST
	return useQuery({
		queryKey: [config.key],
		queryFn: async () => {
			const response = await apiConfig.fetch<any>(config.url())

			return response.data?.data
		},
	})
}

const useGetBrandCampaigns = () => {
	const config = brandApi.GET_BRAND_CAMPAIGNS_REQUEST
	const { brand_id } = useParams<BrandIdParam>()

	const { active_date_from, active_date_to } = useTimeSelector()
	return useQuery({
		queryKey: [config.key, brand_id, active_date_from, active_date_to],
		queryFn: async () => {
			const response = await apiConfig.post<AxiosObject<any>>(
				config.url(brand_id),
				config.body({
					date_from: active_date_from,
					date_to: active_date_to,
				})
			)
			const newData = [...(response?.data?.data?.data || [])].map((inf) => {
				const igp = [...inf.ig_posts].map((i) => ({ ...i, c_type: 'ig_post' }))
				const igs = [...inf.ig_stories].map((i) => ({ ...i, c_type: 'ig_story' }))
				const ytp = [...inf.yt_posts].map((i) => ({
					...i,
					c_type: 'yt_post',
					taken_at_timestamp: dayjs(i.timestamp).unix(),
				}))
				const ttp = [...inf.tt_posts].map((i) => ({
					...i,
					c_type: 'tt_post',
					taken_at_timestamp: dayjs(i.timestamp).unix(),
				}))
				return {
					...inf,
					contents: _([...igp, ...igs, ...ytp, ...ttp])
						.sortBy('taken_at_timestamp')
						.value(),
				}
			})

			return {
				data: newData,
				count: newData.length,
				last_updated: response?.data?.data.last_updated,
			} as Campaigns
		},
		enabled: brand_id !== undefined,
		initialData: {
			data: [],
			count: 0,
			last_updated: 0,
		},
		initialDataUpdatedAt: 0,
		staleTime: dayjs().add(1, 'hour').valueOf(),
	})
}

const useGetBrandContents = () => {
	const config = brandApi.GET_BRAND_CONTENTS_REQUEST
	const { brand_id } = useParams<BrandIdParam>()
	const { active_date_from, active_date_to } = useTimeSelector()
	return useQuery({
		queryKey: [config.key, brand_id, active_date_from, active_date_to],
		queryFn: async () => {
			const response = await apiConfig.post<AxiosObject<any>>(
				config.url(brand_id),
				config.body({
					date_from: active_date_from,
					date_to: active_date_to,
				})
			)
			const igp = [...response.data.data.ig_posts].map((i) => ({ ...i, c_type: 'ig_post' }))
			const igs = [...response.data.data.ig_stories].map((i) => ({ ...i, c_type: 'ig_story' }))
			const ytp = [...response.data.data.yt_posts].map((i) => ({
				...i,
				c_type: 'yt_post',
				taken_at_timestamp: dayjs(i.timestamp).unix(),
				engagement: (i.like_count || 0) + (i.comment_count || 0),
			}))
			const ttp = [...response.data.data.tt_posts].map((i) => ({
				...i,
				c_type: 'tt_post',
				taken_at_timestamp: dayjs(i.timestamp).unix(),
				engagement: (i.likes_count || 0) + (i.comments_count || 0),
			}))

			return {
				data: _([...igp, ...igs, ...ytp, ...ttp])
					.sortBy('taken_at_timestamp')
					.keyBy('id')
					.value(),
				count: response.data.data.count,
				last_updated: response.data.data.last_updated,
			} as Contents
		},
		enabled: brand_id !== undefined,
		initialData: {
			data: {},
			count: 0,
			last_updated: 0,
		},
		initialDataUpdatedAt: 0,
		staleTime: dayjs().add(1, 'hour').valueOf(),
	})
}

const useGetBrandProgressData = () => {
	const config = brandApi.GET_BRAND_PROGRESS_GRAPH_REQUEST
	const { brand_id } = useParams<BrandIdParam>()

	const { active_date_from, active_date_to } = useTimeSelector()
	return useQuery({
		queryKey: [config.key, brand_id, active_date_from, active_date_to],
		queryFn: async () => {
			const response = await apiConfig.post<AxiosObject<any>>(
				config.url(brand_id),
				config.body({
					date_from: active_date_from,
					date_to: active_date_to,
				})
			)
			if (!response?.data?.data) {
				throw new Error('» no data')
			}

			const graphData = _.sortBy([...response.data.data.data], (pg) => pg.date)
			// ! const postsData = _.sortBy([...data.posts_data], pg => pg.date);
			// ! const storiesData = _.sortBy([...data.stories_with_link_data], pg => pg.date);
			const stories_data = _.sortBy([...response.data.data.stories_data], (pg) => pg.date)
			const posts_data = _.sortBy([...response.data.data.posts_data], (pg) => pg.date)
			const new_stories_data = [
				// { id: "Stories Count", data: [] },
				{ id: 'Impressions', data: [] },
				{ id: 'Reach', data: [] },
				{ id: 'Link Visit', data: [] },
			]
			const new_posts_data = [
				// { id: "Posts Count", data: [] },
				{ id: 'Impressions', data: [] },
				// { id: "Link Visit", data: [] },
				{ id: 'Reach', data: [] },
				// { id: "Views", data: [] },
			]
			const newData = [
				{ id: 'Engagement', data: [] },
				{ id: 'Impressions', data: [] },
				{ id: 'Link Visit', data: [] },
				{ id: 'IG Posts Count', data: [] },
				{ id: 'Reach', data: [] },
				{ id: 'IG Stories Count', data: [] },
				{ id: 'Views', data: [] },
				{ id: 'IG Reels', data: [] },
				{ id: 'IGTV', data: [] },
				{ id: 'Video Posts', data: [] },
			]
			// ! {
			// !    engagement: {
			// !        id: 'engagement',
			// !        data: []
			// !    }
			// ! }
			const daily_data = [
				{ id: 'Engagement', data: [], ig: [], yt: [], tt: [] },
				{ id: 'Impressions', data: [], ig: [], yt: [], tt: [] },
				{ id: 'Link Visit', data: [] },
				{ id: 'IG Posts Count', data: [] },
				{ id: 'Reach', data: [], ig: [], yt: [], tt: [] },
				{ id: 'IG Stories Count', data: [] },
				{ id: 'Views', data: [], ig: [], yt: [], tt: [] },
				{ id: 'IG Reels', data: [] },
				{ id: 'IGTV', data: [] },
				{ id: 'Video Posts', data: [] },
			]
			_(graphData)
				.sortBy('date')
				.reduce((prev, e) => {
					if (e.engagements > (prev.engagements || 0)) {
						newData[0].data.push({ x: e.date, y: e.engagements })
						daily_data[0].data.push({
							x: e.date,
							y: e.engagements - (prev.engagements || 0),
						})
					}
					if (e.ig.engagements > (prev.ig.engagements || 0)) {
						daily_data[0].ig.push({
							x: e.date,
							y: e.ig.engagements - (prev.ig.engagements || 0),
						})
					}
					if (e.yt.engagements > (prev.yt.engagements || 0)) {
						daily_data[0].yt.push({
							x: e.date,
							y: e.yt.engagements - (prev.yt.engagements || 0),
						})
					}
					if (e.tt.engagements > (prev.tt.engagements || 0)) {
						daily_data[0].tt.push({
							x: e.date,
							y: e.tt.engagements - (prev.tt.engagements || 0),
						})
					}
					if (e.impression > (prev.impression || 0)) {
						newData[1].data.push({ x: e.date, y: e.impression })
						daily_data[1].data.push({
							x: e.date,
							y: e.impression - (prev.impression || 0),
						})
					}
					if (e.ig.impression > (prev.ig.impression || 0)) {
						daily_data[1].ig.push({
							x: e.date,
							y: e.ig.impression - (prev.ig.impression || 0),
						})
					}
					if (e.yt.impression > (prev.yt.impression || 0)) {
						daily_data[1].yt.push({
							x: e.date,
							y: e.yt.impression - (prev.yt.impression || 0),
						})
					}
					if (e.tt.impression > (prev.tt.impression || 0)) {
						daily_data[1].tt.push({
							x: e.date,
							y: e.tt.impression - (prev.tt.impression || 0),
						})
					}

					if (e.link_visit > (prev.link_visit || 0)) {
						newData[2].data.push({ x: e.date, y: e.link_visit })
						daily_data[2].data.push({
							x: e.date,
							y: e.link_visit - (prev.link_visit || 0),
						})
					}
					if (e.posts_count > (prev.posts_count || 0)) {
						newData[3].data.push({ x: e.date, y: e.posts_count })
						daily_data[3].data.push({
							x: e.date,
							y: e.posts_count - (prev.posts_count || 0),
						})
					}
					if (e.reach > (prev.reach || 0)) {
						newData[4].data.push({ x: e.date, y: e.reach })
						daily_data[4].data.push({
							x: e.date,
							y: e.reach - (prev.reach || 0),
						})
					}
					if (e.ig.reach > (prev.ig.reach || 0)) {
						daily_data[4].ig.push({
							x: e.date,
							y: e.ig.reach - (prev.ig.reach || 0),
						})
					}
					if (e.yt.reach > (prev.yt.reach || 0)) {
						daily_data[4].yt.push({
							x: e.date,
							y: e.yt.reach - (prev.yt.reach || 0),
						})
					}
					if (e.tt.reach > (prev.tt.reach || 0)) {
						daily_data[4].tt.push({
							x: e.date,
							y: e.tt.reach - (prev.tt.reach || 0),
						})
					}

					if (e.stories_count > (prev.stories_count || 0)) {
						newData[5].data.push({ x: e.date, y: e.stories_count })
						daily_data[5].data.push({
							x: e.date,
							y: e.stories_count - (prev.stories_count || 0),
						})
					}
					if (e.views > (prev.views || 0)) {
						newData[6].data.push({ x: e.date, y: e.views })
						daily_data[6].data.push({
							x: e.date,
							y: e.views - (prev.views || 0),
						})
					}
					if (e.ig.views > (prev.ig.views || 0)) {
						daily_data[6].ig.push({
							x: e.date,
							y: e.ig.views - (prev.ig.views || 0),
						})
					}
					if (e.yt.views > (prev.yt.views || 0)) {
						daily_data[6].yt.push({
							x: e.date,
							y: e.yt.views - (prev.yt.views || 0),
						})
					}
					if (e.tt.views > (prev.tt.views || 0)) {
						daily_data[6].tt.push({
							x: e.date,
							y: e.tt.views - (prev.tt.views || 0),
						})
					}

					if (e.reels_count > (prev.reels_count || 0)) {
						newData[7].data.push({ x: e.date, y: e.reels_count })
						daily_data[7].data.push({
							x: e.date,
							y: e.reels_count - (prev.reels_count || 0),
						})
					}
					if (e.igtv_count > (prev.igtv_count || 0)) {
						newData[8].data.push({ x: e.date, y: e.igtv_count })
						daily_data[8].data.push({
							x: e.date,
							y: e.igtv_count - (prev.igtv_count || 0),
						})
					}
					if (e.video_posts_count > (prev.video_posts_count || 0)) {
						newData[9].data.push({ x: e.date, y: e.video_posts_count })
						daily_data[9].data.push({
							x: e.date,
							y: e.video_posts_count - (prev.video_posts_count || 0),
						})
					}
					return e
				})
			_(stories_data)
				.sortBy('date')
				.reduce((prev, e) => {
					// if (e.stories_count > (prev.stories_count || 0)) { new_stories_data[0].data.push({ x: e.date, y: e.stories_count });}
					if (e.impression > (prev.impression || 0)) {
						new_stories_data[0].data.push({ x: e.date, y: e.impression })
					}
					if (e.reach > (prev.reach || 0)) {
						new_stories_data[1].data.push({ x: e.date, y: e.reach })
					}
					if (e.link_visit > (prev.link_visit || 0)) {
						new_stories_data[2].data.push({ x: e.date, y: e.link_visit })
					}
					return e
				})
			_(posts_data)
				.sortBy('date')
				.reduce((prev, e) => {
					// if (e.posts_count > (prev.posts_count || 0)) { new_posts_data[0].data.push({ x: e.date, y: e.posts_count });}
					if (e.impression > (prev.impression || 0)) {
						new_posts_data[0].data.push({ x: e.date, y: e.impression })
					}
					// if (e.link_visit > (prev.link_visit || 0)) { new_posts_data[2].data.push({ x: e.date, y: e.link_visit });}
					if (e.reach > (prev.reach || 0)) {
						new_posts_data[1].data.push({ x: e.date, y: e.reach })
					}
					// if (e.views > (prev.views || 0)) { new_posts_data[4].data.push({ x: e.date, y: e.views });}
					return e
				})
			new_posts_data.push(newData[0])
			return {
				data: newData,
				start_date: response.data.data.start_date,
				last_updated: response.data.data.last_updated,
				data_daily: daily_data,
				stories_data: new_stories_data,
				posts_data: new_posts_data,
			} as ProgressGraphData
		},
		initialData: {
			data: [],
			data_daily: [],
			stories_data: [],
			posts_data: [],
			start_date: 0,
			last_updated: 0,
		},
		initialDataUpdatedAt: 0,
		enabled: brand_id !== undefined,
		staleTime: dayjs().add(1, 'hour').valueOf(),
	})
}

const useGetBrandInfluencers = () => {
	const { brand_id } = useParams<BrandIdParam>()

	const config = brandApi.GET_BRAND_INFLUENCERS_REQUEST
	const { active_date_from, active_date_to } = useTimeSelector()
	return useQuery({
		queryKey: [config.key, brand_id, active_date_from, active_date_to],
		queryFn: async () => {
			const { data } = await apiConfig.post<AxiosObject<any>>(
				config.url(brand_id),
				config.body({
					date_from: active_date_from,
					date_to: active_date_to,
				})
			)

			const newData = [...(data?.data?.data || [])].map((inf) => {
				const igp = [...inf.ig_posts].map((i) => ({ ...i, c_type: 'ig_post' }))
				const igs = [...inf.ig_stories].map((i) => ({ ...i, c_type: 'ig_story' }))
				const ytp = [...inf.yt_posts].map((i) => ({
					...i,
					c_type: 'yt_post',
					taken_at_timestamp: dayjs(i.timestamp).unix(),
				}))
				const ttp = [...inf.tt_posts].map((i) => ({
					...i,
					c_type: 'tt_post',
					taken_at_timestamp: dayjs(i.timestamp).unix(),
				}))
				return {
					...inf,
					contents: _([...igp, ...igs, ...ytp, ...ttp])
						.sortBy('taken_at_timestamp')
						.value(),
					content_with_insights:
						inf.ig_posts_stats.has_insights +
						inf.ig_stories_stats.has_insights +
						inf.yt_posts_stats.has_insights +
						inf.tt_posts_stats.has_insights,
					video_views: inf.ig_posts_stats.video_views + inf.yt_posts_stats.video_views + inf.tt_posts_stats.video_views,
					reach: inf.ig_posts_stats.reach + inf.ig_stories_stats.reach + inf.yt_posts_stats.reach + inf.tt_posts_stats.reach,
					engagements: inf.ig_posts_stats.engagements + inf.yt_posts_stats.engagements + inf.tt_posts_stats.engagements,
				}
			})
			return {
				data: newData,
				count: newData.length,
				last_updated: data?.data?.data.last_updated,
			} as Influencers
		},
		initialData: {
			data: [],
			count: 0,
			last_updated: 0,
		},
		initialDataUpdatedAt: 0,

		enabled: brand_id !== undefined,
		staleTime: dayjs().add(1, 'hour').valueOf(),
	})
}

type CreateBrandData = { name: string; is_agency: boolean; industry: string }
type MutationOptions<T> = Omit<UseMutationOptions<unknown, unknown, T, unknown>, 'mutationFn'>
const usePostBrandCreate = (options?: Partial<MutationOptions<CreateBrandData>>) => {
	const config = brandApi.POST_BRAND_CREATE_REQUEST
	const brands = useGetAllBrands()
	const { toast } = useToast()
	return useMutation({
		mutationFn: async ({ name, is_agency, industry }: CreateBrandData) => {
			const response = await apiConfig.post<any>(config.url(), config.body({ name, is_agency, industry }))
			brands.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while creating the brand`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}

type AddCampaign = { link: string }
const usePostAddCampaignToBrand = (options?: Partial<MutationOptions<AddCampaign>>) => {
	const config = brandApi.POST_BRAND_CAMPAIGN_ADD_BY_LINK_REQUEST
	const { brand_id } = useParams<BrandIdParam>()
	const campaigns = useGetBrandCampaigns()
	const { toast } = useToast()
	return useMutation({
		mutationFn: async (body: AddCampaign) => {
			await apiConfig.post<AxiosObject<null>>(config.url(brand_id), config.body(body))
			campaigns.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while adding the campaign`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}

const useDeleteManualCampaign = (options?: Partial<MutationOptions<{ campaign_id: string }>>) => {
	const config = brandApi.POST_BRAND_CAMPAIGN_REMOVE_MANUAL_REQUEST
	const { brand_id } = useParams<BrandIdParam>()
	const campaigns = useGetBrandCampaigns()
	const { toast } = useToast()
	return useMutation({
		mutationFn: async (body: { campaign_id: string }) => {
			await apiConfig.post<AxiosObject<null>>(config.url(brand_id), config.body(body))
			campaigns.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while removing the campaign`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}
type InviteToBrand = {
	brand_id: string
	email: string
	role_type: string
	opt_view_campaigns: boolean
	opt_view_content_insights: boolean
	opt_view_brand_influencers_tab: boolean
	opt_view_brand_monitors_tab: boolean
	opt_manage_campaigns: boolean
	opt_view_brand_overview_tab: boolean
	opt_view_brand_credits_tab: boolean
	opt_view_brand_settings_tab: boolean
	opt_view_brand_campaigns_tab: boolean
}

const usePostInviteToBrand = (options?: Partial<MutationOptions<InviteToBrand>>) => {
	const config = brandApi.POST_BRAND_INVITE_MEMBER_REQUEST
	const { brand_id } = useParams<BrandIdParam>()
	const { toast } = useToast()
	const brand = useGetBrandRequest()

	return useMutation({
		mutationFn: async (body: InviteToBrand) => {
			await apiConfig.post<any>(config.url(brand_id), config.body(body))
			brand.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while inviting the user`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}

type AddAgency = { agency_id: string }
const usePostAddAgency = (options?: Partial<MutationOptions<AddAgency>>) => {
	const config = brandApi.POST_BRAND_ADD_AGENCY_REQUEST
	const { brand_id } = useParams<BrandIdParam>()
	const { toast } = useToast()
	const brand = useGetBrandRequest()
	const brands = useGetAllBrands()

	return useMutation({
		mutationFn: async (body: AddAgency) => {
			await apiConfig.post<any>(config.url(brand_id), config.body(body.agency_id))
			brand.refetch()
			brands.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while adding the agency`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}
type RemoveAgency = { agency_id: string }
const usePostRemoveAgency = (options?: Partial<MutationOptions<RemoveAgency>>) => {
	const config = brandApi.POST_BRAND_REMOVE_AGENCY_REQUEST
	const { brand_id } = useParams<BrandIdParam>()
	const { toast } = useToast()
	const brand = useGetBrandRequest()
	const brands = useGetAllBrands()

	return useMutation({
		mutationFn: async (body: RemoveAgency) => {
			await apiConfig.post<any>(config.url(brand_id), config.body(body.agency_id))
			brand.refetch()
			brands.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while removing the agency`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}
type ChangeParent = { parent_id: string }
const usePostChangeParent = (options?: Partial<MutationOptions<ChangeParent>>) => {
	const config = brandApi.POST_BRAND_CHANGE_PARENT_BRAND_REQUEST
	const { brand_id } = useParams<BrandIdParam>()
	const { toast } = useToast()
	const brand = useGetBrandRequest()
	const brands = useGetAllBrands()

	return useMutation({
		mutationFn: async (body: ChangeParent) => {
			await apiConfig.post<any>(config.url(brand_id), config.body(body.parent_id))
			brand.refetch()
			brands.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while changing the parent`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}

const usePostApproveInvitation = (options?: Partial<MutationOptions<{ brand_id: string }>>) => {
	const config = brandApi.POST_APPROVE_BRAND_INVITATION_REQUEST
	const brands = useGetAllBrands()
	const invitations = useGetBrandInvitations()
	const { toast } = useToast()
	return useMutation({
		mutationFn: async (body: { brand_id: string }) => {
			await apiConfig.post<any>(config.url(body.brand_id), config.body())

			brands.refetch()
			invitations.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while approving the invitation`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}

const useEnableCredits = () => {
	const { brand_id } = useParams<BrandIdParam>()
	const brand = useGetBrandRequest()
	const { toast } = useToast()
	return useMutation({
		mutationFn: async (body: { enabled: boolean }) => {
			const { data } = await apiConfig.post<AxiosObject<{ enabled: boolean }>>(
				`admin_api/brand/${brand_id}/credits_enabled`,
				body
			)
			return data
		},
		onSuccess: (data) => {
			toast({
				description: `Credits are now ${!data.data.enabled ? 'enabled' : 'disabled'}`,
				title: 'Success',
			})
			brand.refetch()
		},
	})
}

type RemoveParent = { parent_id: string }
const usePostRemoveParent = (options?: Partial<MutationOptions<RemoveParent>>) => {
	const config = brandApi.POST_BRAND_REMOVE_PARENT_BRAND_REQUEST
	const { brand_id } = useParams<BrandIdParam>()
	const { toast } = useToast()
	const brand = useGetBrandRequest()
	const brands = useGetAllBrands()

	return useMutation({
		mutationFn: async (body: RemoveParent) => {
			await apiConfig.post<any>(config.url(brand_id), config.body(body.parent_id))
			brand.refetch()
			brands.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while removing the parent`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}

export type ManageMemberRole = {
	user_id: string
	role_type: string
	opt_view_campaigns: boolean
	opt_view_content_insights: boolean
	opt_view_brand_influencers_tab: boolean
	opt_view_brand_monitors_tab: boolean
	opt_manage_campaigns: boolean
	opt_view_brand_campaigns_tab: boolean
	opt_view_brand_overview_tab: boolean
	opt_view_brand_settings_tab: boolean
	opt_view_brand_credits_tab: boolean
}
const usePostManageMemberRole = (options?: Partial<MutationOptions<ManageMemberRole>>) => {
	const config = brandApi.POST_BRAND_MEMBER_ROLE_REQUEST
	const { brand_id } = useParams<BrandIdParam>()
	const { toast } = useToast()
	const brand = useGetBrandRequest()

	return useMutation({
		mutationFn: async (body: ManageMemberRole) => {
			await apiConfig.post<any>(config.url(brand_id), config.body(body))
			brand.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while managing the member role`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}
type RemoveFromBrand = { email: string }
const usePostRemoveInvitationFromBrand = (options?: Partial<MutationOptions<RemoveFromBrand>>) => {
	const config = brandApi.POST_BRAND_REMOVE_INVITATION_REQUEST
	const { brand_id } = useParams<BrandIdParam>()
	const { toast } = useToast()
	const brand = useGetBrandRequest()

	return useMutation({
		mutationFn: async (body: RemoveFromBrand) => {
			await apiConfig.post<any>(config.url(brand_id), config.body(body))
			brand.refetch()
		},
		...options,
		onError: () => {
			toast({
				description: `An error occurred while removing the invitation`,
				title: 'Error',
				variant: 'destructive',
			})
		},
	})
}

export const brand = {
	useAll: useGetAllBrands,
	useById: useGetBrandRequest,
	invitations: useGetBrandInvitations,
	influencers: useGetBrandInfluencers,
	recentContent: useGetRecentContent,
	widget: useGetBrandWidgetData,
	campaigns: useGetBrandCampaigns,
	contents: useGetBrandContents,
	progressData: useGetBrandProgressData,
	createBrand: usePostBrandCreate,
	inviteToBrand: usePostInviteToBrand,
	removeInvitationFromBrand: usePostRemoveInvitationFromBrand,
	addNewCampaign: usePostAddCampaignToBrand,
	manageMemberRole: usePostManageMemberRole,
	addAgency: usePostAddAgency,
	removeAgency: usePostRemoveAgency,
	changeParent: usePostChangeParent,
	removeParent: usePostRemoveParent,
	approveInvitation: usePostApproveInvitation,
	deleteManualCampaign: useDeleteManualCampaign,
	useEnableCredits,
}

export interface WidgetData {
	data: Data
	last_updated: number
}

export interface Data {
	campaigns_count: number
	influencer_count: number
	personas_count: number
	total_impressions: number
	total_reach: number
	engagement_rate_per_reach: number
	total_link_visits: number
	total_engagements: number
	total_views: number
	engagement_rate: number
	social_reach: number
	total_content: number
	total_posts: number
	total_stories: number
	ig: Ig
	tt: Tt
	yt: Yt
	other: number
}

export interface Ig {
	social_reach: number
	count_influencers: number
	count_posts: number
	count_stories: number
	reach: number
	impressions: number
	er_per_reach: number
	link_visits: number
	engagements: number
	views: number
	engagement_rate: number
}

export interface Tt {
	social_reach: number
	count_influencers: number
	count_posts: number
	reach: number
	impressions: number
	views: number
	engagements: number
	er_per_reach: number
	er_per_view: number
}

export interface Yt {
	social_reach: number
	count_influencers: number
	count_posts: number
	reach: number
	impressions: number
	views: number
	video_display: number
	engagements: number
	positive_er_per_reach: number
	negative_er_per_reach: number
	positive_er_per_view: number
	negative_er_per_view: number
}

type ProgressGraphData = {
	stories_data: any[]
	posts_data: any[]
	data: { data: { x: any; y: any }[] }[]
	start_date: number
	last_updated: number
	data_daily: (
		| { id: 'Engagement'; data: ListDataXY[]; ig: ListDataXY[]; yt: ListDataXY[]; tt: ListDataXY[] }
		| { id: 'Impressions'; data: ListDataXY[]; ig: ListDataXY[]; yt: ListDataXY[]; tt: ListDataXY[] }
		| { id: 'Link Visit'; data: ListDataXY[]; ig: never[]; yt: never[]; tt: never[] }
		| { id: 'IG Posts Count'; data: ListDataXY[]; ig: never[]; yt: never[]; tt: never[] }
		| { id: 'Reach'; data: ListDataXY[]; ig: ListDataXY[]; yt: ListDataXY[]; tt: ListDataXY[] }
		| { id: 'IG Stories Count'; data: ListDataXY[]; ig: never[]; yt: never[]; tt: never[] }
		| { id: 'Views'; data: ListDataXY[]; ig: ListDataXY[]; yt: ListDataXY[]; tt: ListDataXY[] }
		| { id: 'IG Reels'; data: ListDataXY[]; ig: never[]; yt: never[]; tt: never[] }
		| { id: 'IGTV'; data: ListDataXY[]; ig: never[]; yt: never[]; tt: never[] }
		| { id: 'Video Posts'; data: ListDataXY[]; ig: never[]; yt: never[]; tt: never[] }
	)[]
}
