import { IconAlertCircle, IconEyeOff } from '@tabler/icons'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ReactNode } from 'react'

export const Card = ({
	children,
	title,
	loading = false,
	recalculating = false,
	noData = false,
	className = '',
	info = null,
	contentBackground = true,
	rightComponent = null,
	noSetHeight = false,
}: {
	children: React.ReactNode
	title: React.ReactNode
	loading?: boolean
	recalculating?: boolean
	noData?: boolean
	className?: string
	info?: React.ReactNode
	contentBackground?: boolean
	rightComponent?: React.ReactNode
	noSetHeight?: boolean
}) => {
	return (
		<div className={`${className} flex flex-col  rounded-lg border border-slate-100 bg-white shadow-md`}>
			<div className='flex flex-row justify-between gap-2 rounded-t-lg border-b border-slate-100 bg-slate-100 p-2'>
				{title}
				<Spacer />
				{recalculating ? (
					<div className='inline-flex items-center justify-center gap-1'>
						<LoadingSpinner className='h-3 w-3' />
						<p className='text-xs font-medium'>Recalculating</p>
					</div>
				) : null}
				{info}
				{rightComponent}
			</div>
			<div className='h-full w-full p-2'>
				<div className={`${noSetHeight ? '' : 'max-h-72'} w-full  ${contentBackground ? 'rounded-md bg-gray-200 p-2' : ''}`}>
					<AnimatePresence mode='wait'>{children}</AnimatePresence>
				</div>
			</div>
		</div>
	)
}

const Title = ({ children, icon }: { children: string; icon?: React.ReactNode }) => {
	return (
		<div className='line-clamp-1 inline-flex items-center text-tiny font-semibold'>
			{icon && <span className='mr-1'>{icon}</span>}
			<span className='line-clamp-1'>{children}</span>
		</div>
	)
}

const Spacer = () => {
	return <div className='h-auto flex-shrink flex-grow' />
}

const Body = React.forwardRef<HTMLDivElement, { children: ReactNode; type?: string; className?: string }>(
	({ children, className, type = 'content' }, ref) => {
		return (
			<motion.div
				ref={ref}
				key={type}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				className={className}
			>
				{children}
			</motion.div>
		)
	}
)
Body.displayName = 'Body'

const Fallback = ({
	icon,
	text,
	children,
}: {
	icon?: React.ReactNode
	children?: React.ReactNode
	text?: string | React.ReactNode
}) => {
	return (
		<Body type='fallback' className='flex h-68 flex-col items-center justify-center space-y-2'>
			{icon ? icon : <IconAlertCircle size={32} />}
			<span className='font-medium'>
				{text ? (
					text
				) : (
					<>
						An error occurred while loading the data. <br /> Please try again later or contact support.
					</>
				)}
			</span>
			{children}
		</Body>
	)
}

export const CardAnimated = ({
	children,
	title,
	loading = false,
	recalculating = false,
	noData = false,
	className = '',
	info = null,
	contentBackground = true,
	rightComponent = null,
	noSetHeight = false,
}: {
	children: React.ReactNode
	title: React.ReactNode
	loading?: boolean
	recalculating?: boolean
	noData?: boolean
	className?: string
	info?: React.ReactNode
	contentBackground?: boolean
	rightComponent?: React.ReactNode
	noSetHeight?: boolean
}) => {
	return (
		<div className={`${className} flex flex-col  rounded-lg border border-slate-100 bg-white shadow-md`}>
			<div className='flex flex-row justify-between gap-2 rounded-t-lg border-b border-slate-100 bg-slate-100 p-2'>
				{title}
				<Spacer />
				{recalculating ? (
					<div className='inline-flex items-center justify-center gap-1'>
						<LoadingSpinner className='h-3 w-3' />
						<p className='text-xs font-medium'>Recalculating</p>
					</div>
				) : null}
				{info}
				{rightComponent}
			</div>
			<div className='h-full w-full p-2'>
				<AnimatePresence mode='wait'>
					{loading && (
						<motion.div
							key={'loading'}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							className='flex h-72 w-full flex-col items-center justify-center gap-2 rounded-lg bg-gray-200 p-2'
						>
							<LoadingSpinner className='h-8 w-8' />
							<p className='text-tiny font-semibold'>Loading</p>
						</motion.div>
					)}
					{noData && !loading && (
						<motion.div
							key={'noData'}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							className='flex h-72 flex-col items-center justify-center gap-3 rounded-lg bg-gray-200 p-2'
						>
							<IconEyeOff size={32} />
							<p className='font-semibold'>No Data</p>
						</motion.div>
					)}
					{!loading && !noData && (
						<motion.div
							key={'content'}
							className={`${noSetHeight ? '' : 'max-h-72'} w-full  ${contentBackground ? 'rounded-md bg-gray-200 p-2' : ''}`}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
						>
							{children}
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	)
}

const Loading = () => {
	return (
		<motion.div
			key={'loading'}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className='flex h-72 w-full flex-col items-center justify-center gap-2 rounded-lg bg-gray-200 p-2'
		>
			<LoadingSpinner className='h-8 w-8' />
			<p className='text-tiny font-semibold'>Loading</p>
		</motion.div>
	)
}

const NoData = () => {
	return (
		<div className='flex h-72 flex-col items-center justify-center gap-3 rounded-lg bg-gray-200 p-2'>
			<IconEyeOff size={32} />
			<p className='font-semibold'>No Data</p>
		</div>
	)
}

Card.Title = Title
Card.Spacer = Spacer
Card.Body = Body
Card.Fallback = Fallback
Card.Loading = Loading
Card.NoData = NoData
