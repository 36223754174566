import { XMarkIcon } from '@heroicons/react/24/outline'
import { SideDrawerProps } from './SideDrawer.types'
export const SideDrawer = ({
	isOpen = false,
	setIsOpen,
	children,
	title = 'Drawer',
	closeButton = false,
	footer = null,
	style = {},
	width = null,
	backdrop = true,
}: SideDrawerProps) => {
	return (
		<>
			{backdrop ? (
				<div
					className={`fixed inset-0 bg-black/50 ${
						!isOpen ? 'pointer-events-none opacity-0' : 'pointer-events-auto bg-opacity-50'
					} transition-all duration-300 `}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (backdrop) setIsOpen(false)
					}}
					style={{ zIndex: 2000 }}
				></div>
			) : null}
			<div
				className={`fixed bottom-2 top-2 w-slideOver rounded-lg bg-white px-2 pb-3   ${
					isOpen ? 'right-12 translate-x-10 opacity-100 shadow-lg' : 'right-0 translate-x-full opacity-0'
				} flex flex-col  overflow-hidden transition-all duration-300 `}
				style={{ zIndex: 2001, ...style, ...(width ? { width: width } : {}) }}
			>
				<div className='mb-2 flex flex-row items-center justify-between bg-white pt-2.5 text-base font-semibold'>
					{title}
					{closeButton && (
						<button onClick={() => setIsOpen(false)}>
							<XMarkIcon className='h-4 w-4' />
						</button>
					)}
				</div>
				<div className='scrollable h-full w-full flex-shrink flex-grow overflow-y-auto rounded-lg bg-gray-300 p-2 shadow-md'>
					{children}
				</div>
				{footer && <div className='mt-2 w-full rounded-lg border border-gray-100 bg-gray-50 px-3 py-2 '>{footer}</div>}
			</div>
		</>
	)
}
