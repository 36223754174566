import { useState } from 'react'
import { Outlet } from 'react-router-dom'

const images = [
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/1.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/2.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/3.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/4.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/5.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/6.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/7.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/8.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/9.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/10.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/11.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/12.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/13.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/14.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/15.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/16.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/17.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/18.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/19.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/20.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/21.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/22.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/23.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/24.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/25.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/26.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/27.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/28.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/29.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/30.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/31.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/32.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/33.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/34.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/35.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/36.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/37.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/38.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/39.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/40.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/41.jpg`,
	`https://s3.eu-central-1.wasabisys.com/creatorhub-assets/login-assets/42.jpg`,
]
const texts = [
	{ title: '150.000+ Creators', description: 'are being analysed on CreatorHub every day' },
	{ title: '650+ branded campaigns', description: 'have been managed with CreatorHub Campaign Trackers ' },
	{ title: '400 million impressions', description: 'have been generated by campaigns managed with CreatorHub' },
	{ title: '15 million engagement', description: 'have been generated by campaigns managed with CreatorHub' },
	{ title: 'Team Management', description: 'Collaborate with your team members across dashboards and campaigns' },
	{
		title: 'Track your campaigns',
		description: 'View content generated by influencers and benchmark/compare their performance with 30+ metrics.',
	},
	{ title: 'Audience Breakdown', description: 'Creators in Turkey have an average of %61 female audience' },
	{ title: 'Audience Breakdown', description: 'Creators in Turkey have an average of %39 male audience' },
	{ title: 'Audience Breakdown', description: 'Creators in Turkey have an average of %39 18-24yo audience' },
	{
		title: 'Audience Interests',
		description:
			'An influencer based in Turkey frequently creates content about Entertainment, Fashion, Beauty and Babycare.',
	},
	{
		title: 'CreatorDen Influence Score',
		description: 'is a scoring system out of 10, that is created on profile growth and engagement metrics',
	},
	{
		title: 'Reach Rate',
		description: 'is an influencer marketing metric that indicates the percentage of followers reached',
	},
	{
		title: 'Higher Follower Count does not mean higher Reach',
		description: 'The reach rate of an influencer depends on many metrics including engagement rate and audience quality',
	},
	{
		title: 'Power of Influence',
		description: 'Our algorithm estimates accurate value for an influencer’s potential brand collaboration',
	},
	{ title: 'Engagement Rate', description: 'is the percentage of influencer audience engaged with influencer content' },
]
export const Container = () => {
	const [image, setImage] = useState(images[Math.floor(Math.random() * images.length - 1)])
	const [text, setText] = useState(texts[Math.floor(Math.random() * texts.length - 1)])
	return (
		<div className='flex h-screen w-full flex-row bg-black' style={{ backgroundImage: `url(${image})` }}>
			<div className='flex  h-full w-full flex-col items-center justify-center gap-4 bg-white p-20 lg:w-auto'>
				<div className='w-full'>
					<img className='h-8 w-auto flex-shrink-0' src='/images/logo-shub.png' />
				</div>
				<Outlet />
			</div>
			<div className='hidden w-full flex-col items-center justify-center lg:flex'>
				<div
					className={'login-information'}
					style={{
						minWidth: 200,
						maxWidth: 500,
						marginLeft: 70,
						color: 'white',
						background: 'rgba(0,0,0,0.4)',
						borderRadius: 12,
						padding: 25,
					}}
				>
					<div style={{ fontSize: 28, fontWeight: '600' }}>{text?.title}</div>
					<div style={{ fontSize: 20, fontWeight: '300' }}>{text?.description}</div>
				</div>
			</div>
		</div>
	)
}
