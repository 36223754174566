import api from 'api'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

const contentFilterTypes = ['story', 'photo_post', 'video_post', 'reels', 'youtube', 'tiktok'] as const
export type ContentFilter = (typeof contentFilterTypes)[number]
export const useCampaignQueries = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [start_date, set_start_date] = useState(new Date())
	const [end_date, set_end_date] = useState(new Date())
	const [creators, set_creators] = useState<string[]>([])
	const [content_filter, set_content_filter] = useState<ContentFilter[]>([...contentFilterTypes])
	const { campaign_id } = useParams<{ campaign_id: string }>()
	const campaign = api.campaign.useById()
	useEffect(() => {
		const start_date = searchParams.get('start_date')
		if (start_date) {
			set_start_date(new Date(Number(searchParams.get('start_date'))))
		} else {
			set_start_date(campaign.data?.start_date ? new Date(campaign.data?.start_date) : new Date())
		}
	}, [searchParams.get('start_date'), campaign.data?.start_date])
	useEffect(() => {
		const end_date = searchParams.get('end_date')
		if (end_date) {
			set_end_date(new Date(Number(searchParams.get('end_date'))))
		} else {
			set_end_date(campaign.data?.end_date ? new Date(campaign.data?.end_date) : new Date())
		}
	}, [searchParams.get('end_date'), campaign.data?.end_date])
	useEffect(() => {
		const creators = searchParams.get('creators')

		if (creators) {
			set_creators(creators.split('+'))
		} else if (creators === '') {
			set_creators([])
		} else {
			set_creators(campaign.data?.personas_all.map((e) => e._id) ?? [])
		}
	}, [searchParams.get('creators')])
	useEffect(() => {
		const content_types = searchParams.get('content_types')

		if (content_types) {
			set_content_filter(content_types.split('+') as ContentFilter[])
		} else if (content_types === '') {
			set_content_filter([])
		} else {
			set_content_filter([...contentFilterTypes])
		}
	}, [searchParams.get('content_types')])

	return {
		access_token: searchParams.get('access_token') ?? null,
		start_date,
		end_date,
		content_id: searchParams.get('content_id'),
		set_content_id: (content_id?: string) => {
			if (content_id) {
				setSearchParams((params) => {
					params.set('content_id', content_id)
					return params
				})
			} else {
				setSearchParams((params) => {
					params.delete('content_id')
					params.delete('statistics_file')
					params.delete('content_type')
					return params
				})
			}
		},
		details: searchParams.get('details'),
		set_details: (content_id?: string) => {
			if (content_id) {
				setSearchParams((params) => {
					params.set('details', content_id)
					return params
				})
			} else {
				setSearchParams((params) => {
					params.delete('details')
					return params
				})
			}
		},
		content_types: content_filter,
		set_content_types: (content_type: ContentFilter) => {
			const content_types = [...content_filter]
			if (content_types.includes(content_type)) {
				content_types.splice(content_types.indexOf(content_type), 1)
			} else {
				content_types.push(content_type)
			}
			if (content_types.length === contentFilterTypes.length) {
				setSearchParams((params) => {
					params.delete('content_types')
					return params
				})
			} else {
				setSearchParams((params) => {
					params.set('content_types', content_types.join('+'))
					return params
				})
			}
		},
		select_all_content_types: () => {
			setSearchParams((params) => {
				params.delete('content_types')
				return params
			})
		},
		deselect_all_content_types: () => {
			setSearchParams((params) => {
				params.set('content_types', '')
				return params
			})
		},
		set_start_date: (date: Date) =>
			setSearchParams((params) => ({
				...Object.fromEntries(params.entries()),
				start_date: date.valueOf().toString(),
			})),
		set_end_date: (date: Date) =>
			setSearchParams((params) => ({ ...Object.fromEntries(params.entries()), end_date: date.valueOf().toString() })),
		set_dates: (start_date: Date, end_date: Date) =>
			setSearchParams((params) => ({
				...Object.fromEntries(params.entries()),
				start_date: start_date.valueOf().toString(),
				end_date: end_date.valueOf().toString(),
			})),
		creators,
		set_creators: (creator: string[]) => {
			if (creator.length === campaign.data?.personas_all.length) {
				setSearchParams((params) => {
					params.delete('creators')
					return params
				})
			} else {
				setSearchParams((params) => {
					params.set('creators', creator.join('+'))
					return params
				})
			}
		},
	}
}
