import React from 'react'
import PropTypes from 'prop-types';

export default function Flex(props) {
  const { row = 'true', wrap = 'no-wrap', align = 'flex-start', justify = 'flex-start', style = {} } = props;
  return (
    <div {...props} style={{ display: 'flex', flexDirection: row === 'true' ? 'row' : 'column', alignItems: align, justifyContent: justify, flexWrap: wrap, ...style }}>
      {props.children}
    </div>
  )
}

Flex.propTypes = {
  row: PropTypes.string,
  wrap: PropTypes.any,
  align: PropTypes.any,
  justify: PropTypes.any,
  style: PropTypes.object,
};