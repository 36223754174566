import React from "react";
import styles from "./Alert.module.css";
import { AlertProps } from "./Alert.d";

export const Alert: React.FC<AlertProps> = ({ type, message, style = {} }) => {
	return (
		<div className={`${styles[type]} w-full h-auto py-2 border border-dashed rounded-lg px-2 m-1`} style={style}>
            {message}
        </div>
	);
};
