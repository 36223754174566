import * as TogglePrimitive from '@radix-ui/react-toggle'
import { cva, VariantProps } from 'class-variance-authority'
import { cn } from 'lib/helper'
import * as React from 'react'

const toggleVariants = cva(
	'inline-flex items-center justify-center rounded-md text-sm font-medium  data-[state=off]:opacity-40 data-[state=on]:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ring-offset-background  transition-all',
	{
		variants: {
			variant: {
				default: 'bg-transparent',
				outline: 'bg-transparent border border-input hover:bg-accent hover:text-accent-foreground',
				ig_post: 'bg-ig-post',
				ig_story: 'bg-ig-story',
				tt_post: 'bg-tt-post',
				yt_post: 'bg-yt-post',
				yt: 'bg-yt-post',
				ig_combined: 'bg-gradient-to-br from-ig-post to-ig-story',
			},
			size: {
				default: 'h-10 px-3',
				sm: 'h-9 px-2.5',
				xs: 'h-8 px-2',
				lg: 'h-11 px-5',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
)

const Toggle = React.forwardRef<
	React.ElementRef<typeof TogglePrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, variant, size, ...props }, ref) => (
	<TogglePrimitive.Root ref={ref} className={cn(toggleVariants({ variant, size, className }))} {...props} />
))

Toggle.displayName = TogglePrimitive.Root.displayName

export { Toggle, toggleVariants }
