import api from 'api'
import _ from 'lodash'
import { useAuthProvider } from 'providers/AuthProvider'
import { useCallback, useMemo } from 'react'

export const useBrandPermissionSelector = () => {
	const { data: brand } = api.brand.useById()
	const { getUser } = useAuthProvider()

	const my_user_id = () => getUser()?._id
	const my_role = useCallback(
		() => _.find(brand?.roles || [], (r) => r.user._id === my_user_id()),
		[brand?.roles, getUser()?._id]
	)
	const role_type_a_o = useMemo(
		() => my_role?.()?.role_type === 'admin' || my_role?.()?.role_type === 'owner',
		[my_role()?.role_type]
	)
	return {
		getUser,
		user_type: getUser()?.role,
		user_id: my_user_id(),
		role_type:
			getUser()?.role === 'creatorden' || getUser()?.role === 'admin' ? 'owner' : my_role?.()?.role_type || 'member',
		opt_view_campaigns:
			role_type_a_o || getUser()?.role === 'creatorden' || getUser()?.role === 'admin'
				? true
				: my_role?.()?.opt_view_campaigns,
		opt_view_content_insights:
			role_type_a_o || getUser()?.role === 'creatorden' || getUser()?.role === 'admin'
				? true
				: my_role?.()?.opt_view_content_insights || false,
		opt_view_brand_influencers_tab:
			role_type_a_o || getUser()?.role === 'creatorden' || getUser()?.role === 'admin'
				? true
				: my_role?.()?.opt_view_brand_influencers_tab || false,
		opt_view_brand_monitors_tab:
			role_type_a_o || getUser()?.role === 'creatorden' || getUser()?.role === 'admin'
				? true
				: my_role?.()?.opt_view_brand_monitors_tab || false,
		opt_view_brand_settings_tab:
			role_type_a_o || getUser()?.role === 'admin' ? true : my_role?.()?.opt_view_brand_settings_tab || false,
		opt_view_brand_campaigns_tab:
			role_type_a_o || getUser()?.role === 'admin' ? true : my_role?.()?.opt_view_brand_campaigns_tab || false,
		opt_view_brand_credits_tab:
			role_type_a_o || getUser()?.role === 'admin' ? true : my_role?.()?.opt_view_brand_credits_tab || false,
		opt_view_brand_overview_tab:
			role_type_a_o || getUser()?.role === 'admin' ? true : my_role?.()?.opt_view_brand_overview_tab || false,
		permissions: {
			...my_role?.(),
		},
	}
}
