import { LoadingSpinner } from 'components/LoadingSpinner'
import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import { IoLogoInstagram, IoLogoTiktok, IoLogoYoutube } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { InfluencerSearchResult } from '.'

export const ItemListerInfluencer = ({
	isLoading,
	data,
	showCount = 6,
}: {
	isLoading: boolean
	data: InfluencerSearchResult[]
	showCount?: number
}) => {
	return (
		<AnimatePresence mode='wait'>
			{isLoading ? (
				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 20 }}
					className='flex h-auto w-full flex-col items-center justify-center gap-2 border border-gray-100 p-4 shadow'
				>
					<LoadingSpinner className='h-5 w-5' />
					<p>Loading</p>
				</motion.div>
			) : (
				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 20 }}
					className='grid grid-cols-1 gap-2 rounded-lg bg-gray-100 p-2 md:grid-cols-2'
				>
					{data.length > 0 ? (
						_(data)
							.take(showCount)
							.map((influencer) => {
								return (
									<div
										onClick={() => {
											window.open(`/x/${influencer._id}`, '_blank')
										}}
										key={influencer._id}
										className='flex cursor-pointer flex-row items-center justify-between rounded-lg border border-gray-50 bg-white px-2 py-1 text-black shadow transition-all hover:bg-gray-100 hover:text-black hover:shadow-md'
									>
										<h3 className='text-tiny font-medium'>{influencer.name}</h3>
										<div className='flex flex-row'>
											{influencer.instagram_id ? (
												<Link
													to={`/x/${influencer._id}/instagram`}
													target='_blank'
													onClick={(e) => {
														e.stopPropagation()
													}}
													rel='noopener noreferrer'
													className={`ml-1 rounded-lg  bg-gradient-to-br from-purple-500 to-orange-400 p-1.5 text-white shadow transition-all hover:text-white hover:shadow-lg focus:outline-none`}
												>
													<IoLogoInstagram style={{ width: 16, height: 16 }} />
												</Link>
											) : null}
											{influencer.tiktok_id ? (
												<Link
													to={`/x/${influencer._id}/tiktok`}
													target='_blank'
													rel='noopener noreferrer'
													onClick={(e) => {
														e.stopPropagation()
													}}
													className={`ml-1 rounded-lg  bg-gray-700 p-1.5 text-white shadow transition-all hover:text-white hover:shadow-lg focus:outline-none `}
												>
													<IoLogoTiktok style={{ width: '16px', height: '16px' }} />
												</Link>
											) : null}
											{influencer.youtube_id ? (
												<Link
													to={`/x/${influencer._id}/youtube`}
													target='_blank'
													onClick={(e) => {
														e.stopPropagation()
													}}
													rel='noopener noreferrer'
													className={`ml-1 rounded-lg  bg-gradient-to-br from-red-700 to-red-600 p-1.5 text-white shadow transition-all hover:text-white hover:shadow-lg focus:outline-none`}
												>
													<IoLogoYoutube style={{ width: 16, height: 16 }} />
												</Link>
											) : null}
										</div>
									</div>
								)
							})
							.value()
					) : (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							className='col-span-2 flex h-full w-full flex-col items-center justify-center'
						>
							<h3 className='text-base font-medium'>No Creators Found</h3>
						</motion.div>
					)}
				</motion.div>
			)}
		</AnimatePresence>
	)
}
