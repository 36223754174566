
export interface PersonaLinkedTagOptionTypes {

	greentags: string[]
	is_active: boolean
	is_private: boolean
	match_type: string
	name: string,
	regex: string,
	_id: string

}
interface State {
	loading: boolean,
	me: {
		_id: string,
		active: boolean,
		role: string,
		email: string,
		firstName: string,
		lastName: string,
		opt_in_daily_digest: boolean,
		avatar: string,
		company: string,
		ig: string,
		yt: string,
		tt: string,
		position: string,
		phone: string,
		username: string,
	},
	brand_updates: {
		last_updated: number,
		data: {
			_id: string,
			title: string,
			description: string,
			v: string,
			is_visible: boolean,
		}[],
	},
	persona_linked_tag_options: {
		last_updated: number,
		data: PersonaLinkedTagOptionTypes[]
	},
	templates: {
		instagram: string,
		tiktok: string,
		youtube: string
	}
}
const initialState = {
	loading: true,
	me: {
		"_id": "",
		"active": false,
		"role": "user",
		"firstName": "",
		"lastName": "",
		"email": "",
		"username": "",
		"opt_in_daily_digest": true,
		"avatar": "",
		"company": "",
		"ig": "",
		"yt": "",
		"tt": "",
		"position": "",
		"phone": "",
	},
	brand_updates: { last_updated: 0, data: [] },
	persona_linked_tag_options: {
		last_updated: 0,
		data: [],
	},
	templates: {
		instagram: "Default",
		tiktok: "Default",
		youtube: "Default"
	}
} as State;

const reducer = (
	state = initialState,
	{ type, payload }: { type: string, payload: any },
): State => {
	switch (type) {
		case "FETCH_ME":
			{
				return { ...state, loading: false, me: { ...state.me, ...payload }, templates: { ...state.templates, ...payload.templates } };
			}
		case "UPDATE_USER_NAME_SURNAME":
			{
				return { ...state, me: { ...state.me, ...payload } };
			}
		case "UPDATE_USER":
			{
				return { ...state, me: { ...state.me, ...payload } };
			}
		case "UPDATE_DAILY_DIGEST":
			{
				return { ...state, me: { ...state.me, ...payload } };
			}
		case "UPDATE_PROFILE_PICTURE":
			{
				return { ...state, me: { ...state.me, ...payload } };
			}
		case "FETCH_BRAND_UPDATES":
			{
				return {
					...state,
					brand_updates: {
						...state.brand_updates,
						data: [...payload],
						last_updated: Date.now(),
					},
				};
			}
		case "UPDATE_INFX_PROFILE": {
			return { ...state, templates: { ...state.templates, ...payload.templates } };
		}
		case "PERSONA_LINKED_TAG_OPTIONS":
			{
				return { ...state, persona_linked_tag_options: { ...state.persona_linked_tag_options, data: [...payload], last_updated: Date.now() } };
			}

		default:
			return state;
	}
};

export default reducer;

export const $fetch_user = (then = () => { }) => (
	dispatch: (
		arg0: { type: string, payload: { api_path: string, type: string } },
	) => void,
) => {
	dispatch({
		type: "PROFILE_API_GET_USER",
		payload: { api_path: "/api/me", type: "fetch_api" },
	});
};

export const $update_username = (
	firstName: string,
	lastName: string,
	then = () => { },
) => (
	dispatch: (
		arg0: {
			type: string,
			payload: {
				api_path: string,
				type: string,
				firstName: string,
				lastName: string,
			},
		},
	) => void,
	) => {
		dispatch({
			type: "PROFILE_API_PATCH_USER_NAME_SURNAME",
			payload: {
				api_path: "/api/me/name",
				type: "patch_api",
				firstName: firstName,
				lastName: lastName,
			},
		});
	};
export const $update_user = (
	{ firstName, lastName, company, ig, yt, tt, position, phone }: {
		firstName: string,
		lastName: string,
		company: string,
		ig: string,
		yt: string,
		tt: string,
		position: string,
		phone: string,
	},
	then = () => { },
) => (
	dispatch: (
		arg0: {
			type: string,
			payload: {
				api_path: string,
				type: string,
				firstName: string,
				lastName: string,
				ig: string,
				yt: string,
				tt: string,
				position: string,
				phone: string,
				company: string,
			},
		},
	) => void,
	) => {
		dispatch({
			type: "PROFILE_API_PATCH_USER",
			payload: {
				api_path: "/api/me/",
				type: "patch_api",
				firstName,
				lastName,
				company,
				ig,
				yt,
				tt,
				position,
				phone,
			},
		});
	};

export const $update_profile_picture = (photo: any, then = () => { }) => (
	dispatch: (
		arg0: {
			type: string,
			payload: { api_path: string, type: string, photo: any },
		},
	) => void,
) => {
	dispatch({
		type: "PROFILE_API_POST_PROFILE_PICTURE",
		payload: { api_path: "/api/me/avatar", type: "post_api", photo },
	});
};

export const $update_opt_in_daily_digest = (then = () => { }) => (
	dispatch: (
		arg0: { type: string, payload: { api_path: string, type: string } },
	) => void,
) => {
	dispatch({
		type: "PROFILE_API_UPDATE_DAILY_DIGEST",
		payload: { api_path: "/api/me/opt_in_daily_digest", type: "patch_api" },
	});
};

export const $fetch_brand_updates = (then = () => { }) => (
	dispatch: (
		arg0: { type: string, payload: { api_path: string, type: string } },
	) => void,
) => {
	dispatch({
		type: "PROFILE_API_GET_BRAND_UPDATES",
		payload: { api_path: "/api/brand_updates", type: "fetch_api" },
	});
};
export const $fetch_brand_updates_admin = (then = () => { }) => (
	dispatch: (
		arg0: { type: string, payload: { api_path: string, type: string } },
	) => void,
) => {
	dispatch({
		type: "PROFILE_API_GET_BRAND_UPDATES",
		payload: { api_path: "/admin_api/brand_updates", type: "fetch_api" },
	});
};
export const $patch_update_infx_template = (network: "instragram" | "youtube" | "tiktok", template_name: string, then = () => { }) => (
	dispatch: (
		arg0: { type: string, payload: { api_path: string, type: string, network: "instragram" | "youtube" | "tiktok", template_name: string } },
	) => void,
) => {
	dispatch({
		type: "PROFILE_API_PATCH_UPDATE_INFX_TEMPLATE",
		payload: {
			api_path: "/api/me/template",
			type: "patch_api",
			network,
			template_name
		},
	});
};

export const $post_brand_updates = (
	update: {
		v: string,
		title: string,
		description: string,
		_id: string,
		is_visible: boolean,
	},
	then = () => { },
) => (
	dispatch: (
		arg0: {
			type: string,
			payload: {
				api_path: string,
				type: string,
				v: string,
				title: string,
				description: string,
				_id: string,
				is_visible: boolean,
			},
		},
	) => void,
	) => {
		dispatch({
			type: "PROFILE_API_POST_BRAND_UPDATES",
			payload: {
				api_path: "/admin_api/brand_updates",
				type: "post_api",
				...update,
			},
		});
	};
export const $post_brand_updates_new = (
	update: { v: string, title: string, description: string, is_visible: boolean },
	then = () => { },
) => (
	dispatch: (
		arg0: {
			type: string,
			payload: {
				api_path: string,
				type: string,
				v: string,
				title: string,
				description: string,
				is_visible: boolean,
			},
		},
	) => void,
	) => {
		dispatch({
			type: "PROFILE_API_POST_BRAND_UPDATES",
			payload: {
				api_path: "/admin_api/brand_updates",
				type: "post_api",
				...update,
			},
		});
	};
export const $fetch_persona_linked_tag_options = (then = () => { }) => (
	dispatch: (
		arg0: { type: string, payload: { api_path: string, type: string } },
	) => void,
) => {
	dispatch({
		type: "ADMIN_API_GET_PERSONA_LINKED_TAG_OPTIONS",
		payload: {
			api_path: "/admin_api/personalinkedtagoptions",
			type: "fetch_api",
		},
	});
};
export const $post_persona_linked_tag_options = (
	name: string,
	regex: string,
	greentags: string[],
	match_type: string,
	is_active: boolean,
	is_private: boolean,
	then = () => { }) => (
		dispatch: (
			arg0: {
				type: string, payload: {
					api_path: string, type: string, name: string,
					regex: string,
					greentags: string[],
					match_type: string,
					is_active: boolean,
					is_private: boolean,
					then: () => void
				}
			},
		) => void,
	) => {
		dispatch({
			type: "ADMIN_API_POST_PERSONA_LINKED_TAG_OPTIONS",
			payload: {
				api_path: "/admin_api/personalinkedtagoptions",
				type: "post_api",
				name,
				regex,
				greentags,
				match_type,
				is_active,
				is_private,
				then
			},
		});
	};
export const $postadminsetdefaulttemplate = (
	network: string,
	template_id: string,
	then = () => { },
) => (
	dispatch: (
		arg0: {
			type: string,
			payload: {
				api_path: string,
				type: string,
				network: string,
				template_id: string,
			},
		},
	) => void,
	) => {
		dispatch({
			type: "ADMIN_API_POST_LIST_SET_DEFAULT_TEMPLATE",
			payload: {
				api_path: "/admin_api/templates/default",
				type: "post_api",
				network,
				template_id,
			},
		});
	};