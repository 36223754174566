import api from 'api'
import { useBrandPermissionSelector } from 'hooks/useBrandPermissionSelector'
import { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { H3, Icons, Widget } from 'ui'
import BrandNavigation from '../components/BrandNavigation'

export const useRedirectIfNoPermission = () => {
	const { user_type, permissions: permissionList } = useBrandPermissionSelector()
	const { brand_id } = useParams()
	const navigate = useNavigate()
	const current_path = window.location.pathname.split('/').at(3) || 'overview'
	const permissions = {
		overview: 'opt_view_brand_overview_tab',
		campaigns: 'opt_view_brand_campaigns_tab',
		creators: 'opt_view_brand_influencers_tab',
		communities: 'opt_view_brand_communities_tab',
		projects: 'opt_view_brand_projects_tab',
		monitors: 'opt_view_brand_monitors_tab',
		settings: 'opt_view_brand_settings_tab',
		credits: 'opt_view_brand_credits_tab',
		lists: 'opt_view_brand_lists_tab',
	}
	useEffect(() => {
		if (user_type === 'admin' || user_type === 'creatorden') return
		if (permissionList[permissions[current_path]]) return
		if (current_path !== 'modules') return navigate(`/brand/${brand_id}/modules`)
	}, [])
}

const BrandModules = () => {
	const currentBrand = api.brand.useById()
	const {
		user_type,
		opt_view_brand_influencers_tab,
		opt_view_brand_monitors_tab,
		opt_view_brand_overview_tab,
		opt_view_brand_credits_tab,
		opt_view_brand_campaigns_tab,
		opt_view_brand_settings_tab,
	} = useBrandPermissionSelector()
	const { brand_id } = useParams()
	const navigate = useNavigate()
	if (currentBrand.isLoading) return <div>Loading...</div>
	const pages = [
		{
			title: 'Overview',
			icon: Icons.home,
			type: '',
			visible: opt_view_brand_overview_tab,
		},
		{
			title: 'Campaigns',
			icon: Icons.campaigns,
			type: 'campaigns',
			visible: opt_view_brand_campaigns_tab,
		},
		{
			title: 'Creators',
			icon: Icons.users,
			type: 'creators',
			visible: opt_view_brand_influencers_tab,
		},
		{
			title: 'Community',
			icon: Icons.community,
			type: 'communities',
			visible: user_type === 'admin' || user_type === 'creatorden',
			soon: user_type !== 'admin',
		},
		{
			title: 'Projects',
			icon: Icons.projects,
			type: 'projects',
			visible: user_type === 'admin' || user_type === 'creatorden',
			soon: user_type !== 'admin',
		},
		{
			title: 'Monitors',
			icon: Icons.monitor,
			type: 'monitors',
			visible: opt_view_brand_monitors_tab || user_type === 'admin' || user_type === 'creatorden',
		},
		{
			title: 'Brand Settings',
			icon: Icons.settings,
			type: 'settings',
			visible: opt_view_brand_settings_tab || user_type === 'admin' || user_type === 'creatorden',
		},
		{
			title: 'Credits',
			icon: Icons.credits,
			type: 'credits',
			visible: opt_view_brand_credits_tab || user_type === 'admin' || currentBrand.data?.credits_enabled,
		},
	]
	return (
		<div className='flex flex-col'>
			<BrandNavigation title={currentBrand?.data?.name ?? ''} />
			<div className='grid empty:hidden grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4'>
				{pages.map((item) => {
					const Icon = item.icon
					return (
						item.visible && (
							<Widget
								key={item.type}
								onClick={() => {
									if (item.soon) return
									navigate(`/brand/${brand_id}/${item.type}`)
								}}
								icon={<Icon className='h-6 w-6' />}
								title={<Widget.Title className='text-xl capitalize text-slate-700'>{item.title}</Widget.Title>}
							/>
						)
					)
				})}
			</div>
			{pages.filter((e) => e.visible).length === 0 ? (
				<div className='flex flex-row items-center gap-3 shadow-md h-[calc(100vh-190px)] justify-center w-full rounded-md bg-white'>
					<Icons.info className='h-12 w-12 text-gray-300' />
					<div className='flex flex-col'>
						<H3>No Modules</H3>
						<p className='text-gray-500 text-center'>
							You don't have permission to view any modules. Please contact your admin.
						</p>
					</div>
				</div>
			) : null}
		</div>
	)
}

export default BrandModules

export const CheckPermission = () => {
	useRedirectIfNoPermission()
	return (
		<div>
			<Outlet />
		</div>
	)
}
