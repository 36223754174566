import dayjs from 'dayjs'
import _, { toInteger } from 'lodash'

export const toCamelCase = (str) => str.substring(0, 1).toUpperCase() + str.substring(1, str.length).toLowerCase()
export const fDateTime = (ts) => dayjs.unix(ts / 1000).format('DD.MM.YYYY hh:mm')
export const sortNumeric = (a, b) => (Number(a) === Number(b) ? 0 : Number(a) > Number(b) ? 1 : -1)
export const goTo = (url) => window.open(url)
export const tr_thousandmillion = (num) => {
	var n = num
	if (num >= 1000000) {
		n = (
			<div>
				{Math.round(n / 100000) / 10}
				<b style={{ marginLeft: 3, color: 'var(--main)' }}>m</b>
			</div>
		)
	} else if (num >= 1000) {
		n = (
			<div>
				{Math.round(n / 100) / 10}
				<b style={{ marginLeft: 3, color: 'var(--main-mid)' }}>b</b>
			</div>
		)
	}
	return n
}
export const tr_thousandmillion_raw = (num) => {
	var n = num
	if (num >= 1000000) {
		n = Math.round(n / 100000) / 10 + 'm'
	} else if (num >= 1000) {
		n = Math.round(n / 1000) + 'b'
	}
	return n
}
export const colors = [
	'hsl(205, 70%, 55%)',
	'hsl(196, 94%, 69%)',
	'hsl(211, 82%, 33%)',
	'hsl(196, 95%, 92%)',
	'hsl(205, 70%, 55%)',
	'hsl(196, 94%, 69%)',
	'hsl(211, 82%, 33%)',
	'hsl(196, 95%, 92%)',
]

export const chart_theme = {
	axis: {
		domain: {
			line: {
				stroke: 'transparent',
				strokeWidth: 1,
			},
		},
		ticks: {
			line: {
				stroke: '#777777',
				strokeWidth: 0,
			},
			text: {},
		},
		legend: {
			text: {
				fontSize: 16,
				fill: '#ccc',
			},
		},
	},
	grid: {
		line: {
			stroke: '#ececec',
		},
	},
	colors: _.reverse(['#D62827', '#2CA02C', '#FF7F0E', '#1F77B4']),
	background: 'transparent',
	fontFamily: 'Quicksand',
	fontSize: 12,
	textColor: '#333',
}

export const countries = {
	AF: 'Afghanistan',
	AX: 'Åland Islands',
	AL: 'Albania',
	DZ: 'Algeria',
	AS: 'American Samoa',
	AD: 'AndorrA',
	AO: 'Angola',
	AI: 'Anguilla',
	AQ: 'Antarctica',
	AG: 'Antigua and Barbuda',
	AR: 'Argentina',
	AM: 'Armenia',
	AW: 'Aruba',
	AU: 'Australia',
	AT: 'Austria',
	AZ: 'Azerbaijan',
	BS: 'Bahamas',
	BH: 'Bahrain',
	BD: 'Bangladesh',
	BB: 'Barbados',
	BY: 'Belarus',
	BE: 'Belgium',
	BZ: 'Belize',
	BJ: 'Benin',
	BM: 'Bermuda',
	BT: 'Bhutan',
	BO: 'Bolivia',
	BA: 'Bosnia and Herzegovina',
	BW: 'Botswana',
	BV: 'Bouvet Island',
	BR: 'Brazil',
	IO: 'British Indian Ocean Territory',
	BN: 'Brunei Darussalam',
	BG: 'Bulgaria',
	BF: 'Burkina Faso',
	BI: 'Burundi',
	KH: 'Cambodia',
	CM: 'Cameroon',
	CA: 'Canada',
	CV: 'Cape Verde',
	KY: 'Cayman Islands',
	CF: 'Central African Republic',
	TD: 'Chad',
	CL: 'Chile',
	CN: 'China',
	CX: 'Christmas Island',
	CC: 'Cocos (Keeling) Islands',
	CO: 'Colombia',
	KM: 'Comoros',
	CG: 'Congo',
	CD: 'Congo, Democratic Republic',
	CK: 'Cook Islands',
	CR: 'Costa Rica',
	CI: 'Cote D"Ivoire',
	HR: 'Croatia',
	CU: 'Cuba',
	CY: 'Cyprus',
	CZ: 'Czech Republic',
	DK: 'Denmark',
	DJ: 'Djibouti',
	DM: 'Dominica',
	DO: 'Dominican Republic',
	EC: 'Ecuador',
	EG: 'Egypt',
	SV: 'El Salvador',
	GQ: 'Equatorial Guinea',
	ER: 'Eritrea',
	EE: 'Estonia',
	ET: 'Ethiopia',
	FK: 'Falkland Islands (Malvinas)',
	FO: 'Faroe Islands',
	FJ: 'Fiji',
	FI: 'Finland',
	FR: 'France',
	GF: 'French Guiana',
	PF: 'French Polynesia',
	TF: 'French Southern Territories',
	GA: 'Gabon',
	GM: 'Gambia',
	GE: 'Georgia',
	DE: 'Germany',
	GH: 'Ghana',
	GI: 'Gibraltar',
	GR: 'Greece',
	GL: 'Greenland',
	GD: 'Grenada',
	GP: 'Guadeloupe',
	GU: 'Guam',
	GT: 'Guatemala',
	GG: 'Guernsey',
	GN: 'Guinea',
	GW: 'Guinea-Bissau',
	GY: 'Guyana',
	HT: 'Haiti',
	HM: 'Heard Island and Mcdonald Islands',
	VA: 'Holy See (Vatican City State)',
	HN: 'Honduras',
	HK: 'Hong Kong',
	HU: 'Hungary',
	IS: 'Iceland',
	IN: 'India',
	ID: 'Indonesia',
	IR: 'Iran',
	IQ: 'Iraq',
	IE: 'Ireland',
	IM: 'Isle of Man',
	IL: 'Israel',
	IT: 'Italy',
	JM: 'Jamaica',
	JP: 'Japan',
	JE: 'Jersey',
	JO: 'Jordan',
	KZ: 'Kazakhstan',
	KE: 'Kenya',
	KI: 'Kiribati',
	KP: 'Korea (North)',
	KR: 'Korea (South)',
	XK: 'Kosovo',
	KW: 'Kuwait',
	KG: 'Kyrgyzstan',
	LA: 'Laos',
	LV: 'Latvia',
	LB: 'Lebanon',
	LS: 'Lesotho',
	LR: 'Liberia',
	LY: 'Libyan Arab Jamahiriya',
	LI: 'Liechtenstein',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	MO: 'Macao',
	MK: 'Macedonia',
	MG: 'Madagascar',
	MW: 'Malawi',
	MY: 'Malaysia',
	MV: 'Maldives',
	ML: 'Mali',
	MT: 'Malta',
	MH: 'Marshall Islands',
	MQ: 'Martinique',
	MR: 'Mauritania',
	MU: 'Mauritius',
	YT: 'Mayotte',
	MX: 'Mexico',
	FM: 'Micronesia',
	MD: 'Moldova',
	MC: 'Monaco',
	MN: 'Mongolia',
	MS: 'Montserrat',
	MA: 'Morocco',
	MZ: 'Mozambique',
	MM: 'Myanmar',
	NA: 'Namibia',
	NR: 'Nauru',
	NP: 'Nepal',
	NL: 'Netherlands',
	AN: 'Netherlands Antilles',
	NC: 'New Caledonia',
	NZ: 'New Zealand',
	NI: 'Nicaragua',
	NE: 'Niger',
	NG: 'Nigeria',
	NU: 'Niue',
	NF: 'Norfolk Island',
	MP: 'Northern Mariana Islands',
	NO: 'Norway',
	OM: 'Oman',
	PK: 'Pakistan',
	PW: 'Palau',
	PS: 'Palestinian Territory, Occupied',
	PA: 'Panama',
	PG: 'Papua New Guinea',
	PY: 'Paraguay',
	PE: 'Peru',
	PH: 'Philippines',
	PN: 'Pitcairn',
	PL: 'Poland',
	PT: 'Portugal',
	PR: 'Puerto Rico',
	QA: 'Qatar',
	RE: 'Reunion',
	RO: 'Romania',
	RU: 'Russian Federation',
	RW: 'Rwanda',
	SH: 'Saint Helena',
	KN: 'Saint Kitts and Nevis',
	LC: 'Saint Lucia',
	PM: 'Saint Pierre and Miquelon',
	VC: 'Saint Vincent and the Grenadines',
	WS: 'Samoa',
	SM: 'San Marino',
	ST: 'Sao Tome and Principe',
	SA: 'Saudi Arabia',
	SN: 'Senegal',
	RS: 'Serbia',
	ME: 'Montenegro',
	SC: 'Seychelles',
	SL: 'Sierra Leone',
	SG: 'Singapore',
	SK: 'Slovakia',
	SI: 'Slovenia',
	SB: 'Solomon Islands',
	SO: 'Somalia',
	ZA: 'South Africa',
	GS: 'South Georgia and the South Sandwich Islands',
	ES: 'Spain',
	LK: 'Sri Lanka',
	SD: 'Sudan',
	SR: 'Suriname',
	SJ: 'Svalbard and Jan Mayen',
	SZ: 'Swaziland',
	SE: 'Sweden',
	CH: 'Switzerland',
	SY: 'Syrian Arab Republic',
	TW: 'Taiwan, Province of China',
	TJ: 'Tajikistan',
	TZ: 'Tanzania',
	TH: 'Thailand',
	TL: 'Timor-Leste',
	TG: 'Togo',
	TK: 'Tokelau',
	TO: 'Tonga',
	TT: 'Trinidad and Tobago',
	TN: 'Tunisia',
	TR: 'Turkey',
	TM: 'Turkmenistan',
	TC: 'Turks and Caicos Islands',
	TV: 'Tuvalu',
	UG: 'Uganda',
	UA: 'Ukraine',
	AE: 'United Arab Emirates',
	GB: 'United Kingdom',
	US: 'United States',
	UM: 'United States Minor Outlying Islands',
	UY: 'Uruguay',
	UZ: 'Uzbekistan',
	VU: 'Vanuatu',
	VE: 'Venezuela',
	VN: 'Viet Nam',
	VG: 'Virgin Islands, British',
	VI: 'Virgin Islands, U.S.',
	WF: 'Wallis and Futuna',
	EH: 'Western Sahara',
	YE: 'Yemen',
	ZM: 'Zambia',
	ZW: 'Zimbabwe',
}

export function quartile(data) {
	return [getPercentile(data, 25), getPercentile(data, 50), getPercentile(data, 75)]
}
function getPercentile(data, percentile) {
	data.sort()
	var index = (percentile / 100) * data.length
	var result
	if (Math.floor(index) === index) {
		result = (data[index - 1] + data[index]) / 2
	} else {
		result = data[Math.floor(index)]
	}
	return result
}
export const isMobile = {
	Android: function () {
		return navigator.userAgent.match(/Android/i)
	},
	BlackBerry: function () {
		return navigator.userAgent.match(/BlackBerry/i)
	},
	iOS: function () {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i)
	},
	Opera: function () {
		return navigator.userAgent.match(/Opera Mini/i)
	},
	Windows: function () {
		return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
	},
	any: function () {
		return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()
	},
}

export function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
}
export const languages = {
	aa: 'Afar',
	ab: 'Abkhazian',
	ae: 'Avestan',
	af: 'Afrikaans',
	ak: 'Akan',
	am: 'Amharic',
	an: 'Aragonese',
	ar: 'Arabic',
	as: 'Assamese',
	av: 'Avaric',
	ay: 'Aymara',
	az: 'Azerbaijani',
	ba: 'Bashkir',
	be: 'Belarusian',
	bg: 'Bulgarian',
	bh: 'Bihari languages',
	bi: 'Bislama',
	bm: 'Bambara',
	bn: 'Bengali',
	bo: 'Tibetan',
	br: 'Breton',
	bs: 'Bosnian',
	ca: 'Catalan; Valencian',
	ce: 'Chechen',
	ch: 'Chamorro',
	co: 'Corsican',
	cr: 'Cree',
	cs: 'Czech',
	cu: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
	cv: 'Chuvash',
	cy: 'Welsh',
	da: 'Danish',
	de: 'German',
	dv: 'Divehi; Dhivehi; Maldivian',
	dz: 'Dzongkha',
	ee: 'Ewe',
	el: 'Greek, Modern (1453-)',
	en: 'English',
	eo: 'Esperanto',
	es: 'Spanish; Castilian',
	et: 'Estonian',
	eu: 'Basque',
	fa: 'Persian',
	ff: 'Fulah',
	fi: 'Finnish',
	fj: 'Fijian',
	fo: 'Faroese',
	fr: 'French',
	fy: 'Western Frisian',
	ga: 'Irish',
	gd: 'Gaelic; Scottish Gaelic',
	gl: 'Galician',
	gn: 'Guarani',
	gu: 'Gujarati',
	gv: 'Manx',
	ha: 'Hausa',
	he: 'Hebrew',
	hi: 'Hindi',
	ho: 'Hiri Motu',
	hr: 'Croatian',
	ht: 'Haitian; Haitian Creole',
	hu: 'Hungarian',
	hy: 'Armenian',
	hz: 'Herero',
	ia: 'Interlingua (International Auxiliary Language Association)',
	id: 'Indonesian',
	ie: 'Interlingue; Occidental',
	ig: 'Igbo',
	ii: 'Sichuan Yi; Nuosu',
	ik: 'Inupiaq',
	io: 'Ido',
	is: 'Icelandic',
	it: 'Italian',
	iu: 'Inuktitut',
	ja: 'Japanese',
	jv: 'Javanese',
	ka: 'Georgian',
	kg: 'Kongo',
	ki: 'Kikuyu; Gikuyu',
	kj: 'Kuanyama; Kwanyama',
	kk: 'Kazakh',
	kl: 'Kalaallisut; Greenlandic',
	km: 'Central Khmer',
	kn: 'Kannada',
	ko: 'Korean',
	kr: 'Kanuri',
	ks: 'Kashmiri',
	ku: 'Kurdish',
	kv: 'Komi',
	kw: 'Cornish',
	ky: 'Kirghiz; Kyrgyz',
	la: 'Latin',
	lb: 'Luxembourgish; Letzeburgesch',
	lg: 'Ganda',
	li: 'Limburgan; Limburger; Limburgish',
	ln: 'Lingala',
	lo: 'Lao',
	lt: 'Lithuanian',
	lu: 'Luba-Katanga',
	lv: 'Latvian',
	mg: 'Malagasy',
	mh: 'Marshallese',
	mi: 'Maori',
	mk: 'Macedonian',
	ml: 'Malayalam',
	mn: 'Mongolian',
	mr: 'Marathi',
	ms: 'Malay',
	mt: 'Maltese',
	my: 'Burmese',
	na: 'Nauru',
	nb: 'Bokmål, Norwegian; Norwegian Bokmål',
	nd: 'Ndebele, North; North Ndebele',
	ne: 'Nepali',
	ng: 'Ndonga',
	nl: 'Dutch; Flemish',
	nn: 'Norwegian Nynorsk; Nynorsk, Norwegian',
	no: 'Norwegian',
	nr: 'Ndebele, South; South Ndebele',
	nv: 'Navajo; Navaho',
	ny: 'Chichewa; Chewa; Nyanja',
	oc: 'Occitan (post 1500)',
	oj: 'Ojibwa',
	om: 'Oromo',
	or: 'Oriya',
	os: 'Ossetian; Ossetic',
	pa: 'Panjabi; Punjabi',
	pi: 'Pali',
	pl: 'Polish',
	ps: 'Pushto; Pashto',
	pt: 'Portuguese',
	qu: 'Quechua',
	rm: 'Romansh',
	rn: 'Rundi',
	ro: 'Romanian; Moldavian; Moldovan',
	ru: 'Russian',
	rw: 'Kinyarwanda',
	sa: 'Sanskrit',
	sc: 'Sardinian',
	sd: 'Sindhi',
	se: 'Northern Sami',
	sg: 'Sango',
	si: 'Sinhala; Sinhalese',
	sk: 'Slovak',
	sl: 'Slovenian',
	sm: 'Samoan',
	sn: 'Shona',
	so: 'Somali',
	sq: 'Albanian',
	sr: 'Serbian',
	ss: 'Swati',
	st: 'Sotho, Southern',
	su: 'Sundanese',
	sv: 'Swedish',
	sw: 'Swahili',
	ta: 'Tamil',
	te: 'Telugu',
	tg: 'Tajik',
	th: 'Thai',
	ti: 'Tigrinya',
	tk: 'Turkmen',
	tl: 'Tagalog',
	tn: 'Tswana',
	to: 'Tonga (Tonga Islands)',
	tr: 'Turkish',
	ts: 'Tsonga',
	tt: 'Tatar',
	tw: 'Twi',
	ty: 'Tahitian',
	ug: 'Uighur; Uyghur',
	uk: 'Ukrainian',
	ur: 'Urdu',
	uz: 'Uzbek',
	ve: 'Venda',
	vi: 'Vietnamese',
	vo: 'Volapük',
	wa: 'Walloon',
	wo: 'Wolof',
	xh: 'Xhosa',
	yi: 'Yiddish',
	yo: 'Yoruba',
	za: 'Zhuang; Chuang',
	zh: 'Chinese',
	zu: 'Zulu',
}

export const formatNumber = (number, maximumFractionDigits = 1) =>
	Intl.NumberFormat('en-US', { maximumFractionDigits }).format(number)
export const formatFloatingNumber = (number, digits = 2) =>
	Intl.NumberFormat('en-US', { maximumFractionDigits: digits, minimumFractionDigits: digits }).format(number)

export const formatNum = (n) => {
	if (n < 10) return n
	if (n < 1e3) return formatNumber(n, 0)
	if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'k'
	if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'm'
	if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'b'
	if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}
export const formatMetricNumber = (n) => {
	if (n < 10) return `${n.toFixed(0)}`
	if (n < 1e3) return `${formatNumber(n, 0)}`
	if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'k'
	if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'm'
	if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'b'
	if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
	return ''
}
export const copyToClipboard = (string) => {
	navigator.clipboard.writeText(string)
}

export const standardizeUnix = (date) => {
	while (date > 10000000000) {
		date = date / 10
	}
	return toInteger(date)
}
export const trToEng = (text) =>
	text
		.toLocaleLowerCase('tr')
		.replace(/ğ/gim, 'g')
		.replace(/ü/gim, 'u')
		.replace(/ş/gim, 's')
		.replace(/ı/gim, 'i')
		.replace(/ö/gim, 'o')
		.replace(/ç/gim, 'c')

export const followerCategories = {
	pico: [0, 10000],
	nano: [10_000, 20_000],
	micro: [20_000, 50_000],
	midi: [50_000, 100_000],
	macro: [100_000, 250_000],
	mega: [250_000, 1_000_000],
	giga: [1_000_000, 5_000_000],
	tera: [5_000_000, 100_000_000],
}

export const followerCategory = (followers) => {
	if (followers < followerCategories.pico[1]) return 'pico'
	if (followers < followerCategories.nano[1]) return 'nano'
	if (followers < followerCategories.micro[1]) return 'micro'
	if (followers < followerCategories.midi[1]) return 'midi'
	if (followers < followerCategories.macro[1]) return 'macro'
	if (followers < followerCategories.mega[1]) return 'mega'
	if (followers < followerCategories.giga[1]) return 'giga'
	if (followers < followerCategories.tera[1]) return 'tera'
}

export const logger = (...data) => {
	// eslint-disable-next-line no-undef
	if (process.env.NODE_ENV === 'development') console.log(...data)
}
