import { AnyAction, Dispatch } from "redux"
import { SagaMiddleware } from "redux-saga"
import { Community, CommunityBulletin, CommunityDetails, CommunityIgPost, CommunityMemberDetailed, CommunitySearch } from "types/Community"

type CommunityState = {
  data: Record<string, Community>,
  details: Record<string, CommunityDetails>,
  members: Record<string, (CommunityMemberDetailed & { ig_posts: CommunityIgPost[] })[]>,
  ig_posts: Record<string, CommunityIgPost[]>,
  invitations: Record<string, (CommunityMemberDetailed & { ig_posts: CommunityIgPost[] })[]>,
  bulletins: Record<string, CommunityBulletin[]>,
  invitation_requests: Record<string, (CommunityMemberDetailed & { ig_posts: CommunityIgPost[] })[]>,
  search_personas: {
    count: number,
    data: CommunitySearch[]
  },
  last_updated: number,
}
const initialState = {
  data: {},
  details: {},
  members: {},
  invitations: {},
  invitation_requests: {},
  search_personas: {
    count: 0,
    data: [],
  },
  bulletins: {},
  ig_posts: {},
  last_updated: 0,
} as CommunityState

type Actions = { type: string, payload: any }

const reducer = (state = initialState, { type, payload }: Actions): CommunityState => {
  switch (type) {

    case "COMMUNITIES":
      return { ...state, data: { ...payload }, last_updated: Date.now() }
    case "COMMUNITY":
      return { ...state, details: { ...state.details, [payload._id]: { ...payload } }, last_updated: Date.now() }
    case "COMMUNITY_MEMBERS": {
      return {
        ...state,
        members: { ...state.members, [payload.community_id]: payload.data },
        ig_posts: { ...state.ig_posts, [payload.community_id]: payload.ig_posts },
      }

    }
    case "COMMUNITY_SEARCH_PERSONAS": {
      return { ...state, search_personas: { data: [...payload.data], count: payload.count } }
    }
    case "COMMUNITY_SEARCH_PERSONAS_CLEAR": {
      return { ...state, search_personas: { data: [], count: 0 } }
    }
    case "COMMUNITY_INVITATIONS": {
      return { ...state, invitations: { ...state.invitations, [payload.community_id]: payload.data } }
    }
    case "COMMUNITY_INVITATION_REQUESTS": {
      return { ...state, invitation_requests: { ...state.invitation_requests, [payload.community_id]: payload.data } }
    }
    case "COMMUNITY_BULLETINS": {
      return { ...state, bulletins: { ...state.bulletins, [payload.community_id]: [...payload.data] } }
    }
    default:
      return state
  }
}

export default reducer

export const $fetch_communities = (brand_id: string, community_id?: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
): AnyAction => {
  return dispatch({
    type: "GET_COMMUNITIES",
    payload: { brand_id, community_id },
  });
};

export const $fetch_community = (brand_id: string, community_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "GET_COMMUNITY",
    payload: { brand_id, community_id },
  });
};
export const $fetch_community_bulletins = (brand_id: string, community_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "GET_COMMUNITY_BULLETINS",
    payload: { brand_id, community_id },
  });
};
export const $create_community_bulletins = (
  brand_id: string, community_id: string,
  title: string, text: string,
  then = () => { }) => (
    dispatch: Dispatch<AnyAction>,
  ) => {
    dispatch({
      type: "CREATE_COMMUNITY_BULLETIN",
      payload: { brand_id, community_id, title, text },
    });
  };
export const $delete_community_bulletins = (brand_id: string, community_id: string, bulletin_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "DELETE_COMMUNITY_BULLETIN",
    payload: { brand_id, community_id, bulletin_id },
  });
};
export const $community_search_personas = (
  brand_id = "", min_er = 0, max_er = 9999, min_followers = 0, max_followers = 9_999_999_999,
  regex_biography = "", regex_username: string = "", regex_name: string = "",
  ageGroup = "",
  genderGroup: string = "",

  then = () => { }) => (
    dispatch: Dispatch<AnyAction>,
  ) => {
    dispatch({
      type: "POST_SEARCH_COMMUNITY_PERSONAS",
      payload: {
        brand_id,
        min_er,
        max_er,
        min_followers,
        max_followers,
        regex_biography,
        regex_username,
        regex_name,
        ageGroup, genderGroup
      },
    });
  };
export const $create_community = (brand_id: string, name: string, description: string, file: any, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "POST_CREATE_COMMUNITY",
    payload: {
      brand_id,
      name,
      description,
      file
    },
  });
};
export const $delete_community = (brand_id: string, community_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "DELETE_COMMUNITY",
    payload: {
      brand_id,
      community_id
    },
  });
};
export const $fetch_community_members = (brand_id: string, community_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "GET_COMMUNITY_MEMBERS",
    payload: {
      brand_id,
      community_id
    },
  });
};
export const $remove_community_member = (brand_id: string, community_id: string, persona_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "DELETE_COMMUNITY_MEMBER",
    payload: {
      brand_id,
      community_id,
      persona_id
    },
  });
};
export const $fetch_community_invitations = (brand_id: string, community_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "GET_COMMUNITY_INVITATIONS",
    payload: {
      brand_id,
      community_id
    },
  });
};
export const $invite_community_member = (brand_id: string, community_id: string, persona_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "POST_INVITE_COMMUNITY_MEMBER",
    payload: {
      brand_id,
      community_id,
      persona_id
    },
  });
};
export const $cancel_invite_community_member = (brand_id: string, community_id: string, persona_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "DELETE_COMMUNITY_INVITATION",
    payload: {
      brand_id,
      community_id,
      persona_id
    },
  });
};
export const $fetch_community_requests = (brand_id: string, community_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "GET_COMMUNITY_INVITATION_REQUESTS",
    payload: {
      brand_id,
      community_id,
    },
  });
};
export const $accept_community_member_request = (brand_id: string, community_id: string, persona_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "POST_ACCEPT_COMMUNITY_MEMBER_REQUEST",
    payload: {
      brand_id,
      community_id,
      persona_id
    },
  });
};
export const $reject_community_member_request = (brand_id: string, community_id: string, persona_id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "POST_REJECT_COMMUNITY_MEMBER_REQUEST",
    payload: {
      brand_id,
      community_id,
      persona_id
    },
  });
};



export const $upload_community_photo = (brand_id: string, community_id: string, file: any, id: string, then = () => { }) => (
  dispatch: Dispatch<AnyAction>,
) => {
  dispatch({
    type: "POST_UPDATE_COMMUNITY_PHOTO",
    payload: {
      brand_id,
      community_id,
      file,
      id
    },
  });
};