import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { createRoot } from 'react-dom/client'
// import { onServiceWorkerUpdate } from '@3m1/service-worker-updater';
import { PostHogProvider } from 'posthog-js/react'
import TagManager from 'react-gtm-module'
import App from './App'
import './index.css'
// rendering code.
// eslint-disable-next-line no-undef
process.env.NODE_ENV === 'production' &&
	Sentry.init({
		dsn: 'https://9189585d391c4df6a597ade3fbbeb5cb@o4504793934725120.ingest.sentry.io/4504793938526208',
		integrations: [new BrowserTracing(), new Sentry.Replay()],
		tracesSampleRate: 0.3,
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
	})
const options = {
	api_host: 'https://app.posthog.com',
	disable_session_recording: import.meta.env.DEV,
}
const getUser = () => (window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : {})
const tagManagerArgs = {
	gtmId: 'GTM-N8HJN2L',
	// auth: "M7Op2AklUS_RWlRUApOBg",
	// preview: "env-7"
	dataLayer: {
		user_id: getUser()?._id || null,
		hub_id: getUser()?._id || null,
		highest_role: window.localStorage.getItem('hr') || getUser()?.role || null,
	},
}

TagManager.initialize(tagManagerArgs)

if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
	window.location.protocol = 'https:'
}

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
	<PostHogProvider apiKey={'phc_2LT4tJPIbEAq8Pq4sOCYvnMpN15JEqIwyzBklD126rG'} options={options}>
		<App />
	</PostHogProvider>
)

// unregister()
// serviceWorker.register({
//     onupdate: (registration) => {
//         onServiceWorkerUpdate(registration);
//     }
// })
